import { QuestionScaleWithCutoffs } from "@sequel-care/types"
import { QuestionnaireScaleWithCutoffs } from "@sequel-care/types/Questionnaire"
import { QuestionnaireListItem } from "@sequel-care/types"
import { Dictionary } from "lodash"
import { ButtonHTMLAttributes, DetailedHTMLProps, HTMLAttributes, PropsWithChildren } from "react"
import { classNames } from "utils"
import { getAttrsByCutoff } from "utils/graphs"

const colors: Dictionary<string> = {
    green: "bg-med-green text-med-green",
    yellow: "bg-warning text-warning",
    red: "bg-negative text-negative",
    blue: "bg-med-blue text-med-blue",
    gray: "bg-text-blue/10 text-text-blue"
}

export type TagColor = keyof typeof colors

type TagProps = PropsWithChildren<
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> &
        DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
            color: string | TagColor
            as?: "div" | "button"
        }
>

export const Tag = ({ color, className, as = "div", ...props }: TagProps) => {
    const Component = as

    const styles = colors[color] ? {} : { color: color, backgroundColor: color + "40" }
    return (
        <Component
            {...props}
            style={styles}
            className={classNames(
                colors[color],
                "flex justify-center bg-opacity-20 rounded-3xl px-3 py-2 text-sm h-auto",
                className
            )}
        />
    )
}

export const ScoreTag = ({
    score,
    scale
}: {
    score: number
    scale: QuestionScaleWithCutoffs | QuestionnaireScaleWithCutoffs
}) => {
    return (
        <Tag className="!py-1 !px-4 min-w-[4rem] w-fit" color={getAttrsByCutoff(score, scale)}>
            {score}
        </Tag>
    )
}
