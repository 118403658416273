import { EventType } from "@prisma/client"
import { addDays, getDay } from "date-fns"
import { omit } from "lodash"
import { FormEventHandler, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { useDispatch } from "store"
import { useAllCarePlans, useCurrentUser } from "store/hooks"
import { addEvents, closeContentLibrary, setEventsForGant } from "store/patient/actions"
import { EventTemplate, PatientPage } from "types/Misc"
import { DateString } from "utils/dates"
import ContentLibHeader from "../ContentLibHeader"
import { getInitialConfigState } from "../utils"
import EventConfigFooter from "./EventConfigFooter"
import { useCarePlanConfigsField, convertWeekDayByUserSetting } from "./utils"
import DOMPurify from "dompurify"
import { RenderFields } from "forms"
import { useInitialWeekRange } from "components/PatientOverview/utils"
import { useRouter } from "next/router"

const QuestionnaireEventPackConfig = ({
    events,
    planId,
    patientId
}: {
    events: EventTemplate[]
    planId: number
    patientId: number
}) => {
    const dispatch = useDispatch()
    const { t, i18n } = useTranslation("common")
    const fields = useCarePlanConfigsField()
    const [errors, setErrors] = useState({})
    const allCarePlans = useAllCarePlans()
    const currentUser = useCurrentUser()
    const initialWeekRange = useInitialWeekRange()
    const router = useRouter()
    const [currentPage] = (router.query.page as [PatientPage]) || []

    const [state, setState] = useState(() => ({
        configs: events.map((event, index) => {
            const eventConfig = getInitialConfigState(i18n, { eventTemplate: event })
            const weekDay = convertWeekDayByUserSetting(event.week_day, currentUser.preferences.week_start)
            const day = getDay(new Date())
            const eventDay = weekDay ?? index
            const dayDiff = day > eventDay ? 7 + eventDay - day : eventDay - day
            eventConfig.time.date_on_timeline =
                dayDiff !== 0 ? addDays(eventConfig.time.date_on_timeline, dayDiff) : eventConfig.time.date_on_timeline
            return eventConfig
        })
    }))

    const { title, description } = allCarePlans.find(({ id }) => id === planId)

    const [loading, setLoading] = useState(false)

    const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault()

        const events = state.configs.map((eventConfig) => {
            return {
                ...omit(eventConfig, ["recurrence", "availableFor", "start"]),
                date_on_timeline: DateString.from(eventConfig.time.date_on_timeline),
                expires_within: eventConfig.available_for,
                type: "questionnaire" as EventType,
                questionnaire_id: eventConfig.questionnaire_id || undefined,
                collaborators: [],
                recurrence: {
                    interval: eventConfig.recurrence.interval * (eventConfig.recurrence.unit === "weeks" ? 7 : 1),
                    end: eventConfig.recurrence.end.toJSON()
                }
            }
        })
        setLoading(true)

        try {
            await dispatch(addEvents(events, currentPage, initialWeekRange))

            dispatch(closeContentLibrary())
            dispatch(setEventsForGant(null))
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast(t("genericError"), { type: "error" })
        }
    }

    return (
        <form className="flex flex-col grow" onSubmit={onSubmit}>
            <ContentLibHeader view="config" title={<span className="w-1/2">{title}</span>} />
            <div
                className="text-text-blue px-12"
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}
            />
            <div className="p-12">
                <RenderFields {...{ fields, state, setState, errors, setErrors }} />
            </div>
            <EventConfigFooter eventTemplate={state.configs} loading={loading} patientId={patientId} />
        </form>
    )
}

export default QuestionnaireEventPackConfig
