import { CsvFilters, CsvInputField, ExportFieldList } from "@sequel-care/types/Export"
import { OrganizationUpdateData } from "@sequel-care/types/Misc"
import { OrganizationData } from "@sequel-care/types/User"
import { CarePlanPayload } from "@sequel-care/types/Event"
import { ApiDelete, ApiGet, ApiPost, ApiPut } from "./ApiRequest"
import { FlatCarePlan } from "@sequel-care/types"

export const makePatientDemo = async (patientId: number, userId: number) => {
    const instance = new ApiGet<{ success: boolean }>(`/admin/${patientId}/${userId}/demo`)
    instance.callerName = makePatientDemo.name
    return instance.run()
}

export const getFields = async () => {
    const instance = new ApiGet<ExportFieldList>("/admin/export/fields")
    instance.callerName = getFields.name
    return instance.run()
}

export const getPatientsCsv = async (fields: CsvInputField[], filters: CsvFilters) => {
    const instance = new ApiPost("/admin/export/csv", { fields, filters })
    instance.callerName = getPatientsCsv.name
    return instance.run()
}

export const updateOrganization = async (settings: OrganizationUpdateData) => {
    const instance = new ApiPut<{ organization: OrganizationData; upload_url?: string }>(
        "/organization/settings",
        settings
    )
    instance.callerName = updateOrganization.name
    return instance.run()
}

export const setPublished = async (id: number, is_published: boolean) => {
    const instance = new ApiPut(`questionnaire/publish/${id}?publish=${is_published}`)
    instance.callerName = setPublished.name
    return instance.run()
}

export const agreeToUploadTerms = async (teamId: number) => {
    const instance = new ApiPut<Date>(`user/agree-to-upload-terms?id=${teamId}`)
    instance.callerName = setPublished.name
    return instance.run()
}

export const getCarePlans = async (organization_id: number) => {
    const instance = new ApiGet<FlatCarePlan[]>(`/careplan/${organization_id}`)

    return instance.run()
}

export const createCarePlan = async (careplan: Omit<CarePlanPayload, "id" | "related_diagnoses">) => {
    const instance = new ApiPost<CarePlanPayload[]>("/careplan", careplan)

    return instance.run()
}

export const updateCarePlan = async (careplan: Omit<CarePlanPayload, "related_diagnoses">) => {
    const instance = new ApiPut<CarePlanPayload[]>("/careplan", careplan)

    return instance.run()
}

export const deleteCarePlan = async (carePlanId: number) => {
    const instance = new ApiDelete(`/careplan/${carePlanId}`)
    instance.callerName = deleteCarePlan.name
    return instance.run()
}

export const setCarePlanAsDefault = async (organization_id: number, plan_id: number) => {
    const instance = new ApiPut(`/careplan/set-default/${organization_id}?plan=${plan_id}`)
    instance.callerName = setCarePlanAsDefault.name
    return instance.run()
}
export const removeDefaultCarePlan = async (organization_id: number) => {
    const instance = new ApiPut(`/careplan/remove-default/${organization_id}`)
    instance.callerName = removeDefaultCarePlan.name
    return instance.run()
}
