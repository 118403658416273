import { useEffect, useState } from "react"
import { TherapistSidebar } from "types/AppSidebar"

import { BasicUser } from "@sequel-care/types"
import { getUserPatients } from "api"
import { Profile, ProfileRow } from "components/Profile"
import makeRenderUserList, {
    defineTeamMemberField,
    defineUserField,
    openChatFromUserProfile
} from "components/Profile/utils"
import Link from "next/link"

import { useDispatch } from "store"
import { useCurrentPatient, useCurrentRole, useCurrentUser, useFindUserById } from "store/hooks"
import { openUserModal } from "store/modal/actions"
import { goToThePrevSidebar } from "store/sidebars/actions"
import { useConversations } from "../../utils/conversations"
import { FeatureFlags } from "../../utils/FeatureFlags"

type UserPatient = { patient: BasicUser; isCaseManager: boolean }

const RenderUserPatients = makeRenderUserList<UserPatient>({
    WrapperComponent: ({ item: { patient }, children, className }) => {
        const isOverviewEnabled = FeatureFlags.isEnabled("overview")

        return (
            <Link passHref href={`/patient/${patient.id}/${isOverviewEnabled ? "overview" : "timeline"}`}>
                <a className={className}>{children}</a>
            </Link>
        )
    },
    getData: ({ patient, isCaseManager }) => ({ user: patient, role: isCaseManager ? "case_manager" : "therapist" })
})

const displayedFields = [
    defineUserField({
        key: "gender",
        label: "common:gender",
        RenderValue: ({ value, t }) => t(`common:genders.${value}`)
    }),
    defineUserField({ key: "email", label: "common:email" }),
    defineUserField({ key: "phone", label: "common:phone" }),
    defineTeamMemberField({ key: "license", label: "common:license" }),
    defineTeamMemberField({ key: "case_load", label: "common:caseLoad", infoText: "common:caseLoadInfo" }),
    defineTeamMemberField({
        key: "profession",
        label: "common:profession",
        RenderValue: ({ value, t }) => t(`common:professions.${value}`)
    }),
    defineTeamMemberField({
        key: "specialization",
        label: "common:specialization",
        RenderValue: ({ value, t }) => t(`common:specializations.${value}`)
    })
]

const TherapistProfile = ({ sidebar }: { sidebar: TherapistSidebar }) => {
    const dispatch = useDispatch()
    const currentRole = useCurrentRole()
    const currentUser = useCurrentUser()
    const currentPatient = useCurrentPatient()
    const conversations = useConversations(currentPatient?.id)

    const userInOrg = useFindUserById(sidebar.userId)
    const [userPatients, setUserPatients] = useState<UserPatient[] | null>(null)
    const isAdmin = currentRole.role === "admin"
    const isMyProfile = currentUser.id === userInOrg.user.id
    useEffect(() => {
        const loadUserPatients = async () => {
            if ((!isAdmin && !isMyProfile) || !userInOrg) return

            try {
                if (userPatients !== null) setUserPatients(null)
                setUserPatients(await getUserPatients(userInOrg.user.id))
            } catch (error) {
                // ApiRequest will already log this if necessary
            }
        }

        loadUserPatients()
    }, [userInOrg])

    const handleOpenChat = () => {
        dispatch(openChatFromUserProfile(conversations, userInOrg.user))
    }

    if (!userInOrg) return null

    return (
        <Profile
            user={userInOrg.user}
            role={userInOrg.role}
            profession={userInOrg.profession}
            isEditable={isAdmin || isMyProfile}
            onProfileEdit={() => {
                dispatch(openUserModal(userInOrg))
            }}
            onSidebarGoBack={() => {
                dispatch(goToThePrevSidebar())
            }}
            onOpenChat={handleOpenChat}
            onOpenSettings={() => {
                /* For patients only */
            }}
            onSendMail={() => {
                /* For patients only */
            }}
            isPatient={false}
            isSendMailEnabled={false}
            isChatAvailable={currentPatient !== null && !isMyProfile}
        >
            {displayedFields.map((fieldProps) => {
                const { key } = fieldProps
                return (
                    <ProfileRow
                        key={key}
                        fieldId={key}
                        value={{ ...userInOrg, ...userInOrg.user }[key]}
                        {...fieldProps}
                    />
                )
            })}
            {(isAdmin || isMyProfile) && (
                <ProfileRow
                    fieldId="collaboratorsOnPatients"
                    label={"common:collaboratorsOnPatients"}
                    value={userPatients}
                    RenderValue={RenderUserPatients}
                />
            )}
        </Profile>
    )
}

export default TherapistProfile
