import { useFloating } from "@floating-ui/react-dom";
import { noop } from "lodash";
import { createContext, useContext } from "react";

type FloatingProps = ReturnType<typeof useFloating>

export const MenuContext = createContext({
    isOpen: false,
    onToggle: noop,
    floatingProps: {} as FloatingProps,
    id: null as string
})

export const useMenuContext = () => useContext(MenuContext)