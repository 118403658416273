import { useCallback, useState } from "react"

const useBoolean = (initialValue = false): [boolean, Function, Function] => {
    const [state, setState] = useState(initialValue)

    const setTrue = useCallback(() => setState(true), [])
    const setFalse = useCallback(() => setState(false), [])

    return [state, setTrue, setFalse]
}

export default useBoolean
