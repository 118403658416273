import { PatientWithDiagnoses } from "@sequel-care/types"
import SuperSelect from "forms/SuperSelect"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { getUserName } from "utils"
import { defaultFilterClasses } from "./FilterInput"

type PatientFilterProps = {
    value: number[]
    setFilter: (selected: number[]) => void
    patients: PatientWithDiagnoses<string, string>[]
}

export const PatientFilter = ({ value, setFilter, patients }: PatientFilterProps) => {
    const { t } = useTranslation("patient")

    const patientItems = useMemo(() => {
        return patients.map((patient) => ({ id: patient.id, label: getUserName(patient.user) }))
    }, [patients])

    return (
        <SuperSelect
            id={"patients"}
            items={patientItems}
            value={value}
            setValue={setFilter}
            placeholder={t("export.allPatients")}
            usePlaceholderAsAll
            classList={defaultFilterClasses}
            contentWidth="w-64"
            withSelectionIcon={true}
        />
    )
}
