import { QuestionnaireListItem } from "@sequel-care/types"
import { GraphItem } from "../types"
import { trackerQuestionColors } from "components/PatientOverview/utils"
import { TrackerQuestionData } from "@sequel-care/types/Tracker"

export const getGraphItemFromQuestionnaire = (
    questionnaire: QuestionnaireListItem,
    overrides?: Partial<GraphItem>
): GraphItem => {
    const {
        questions,
        questionnaire_scale: { calculation, cutoffs, direction },
        scales
    } = questionnaire
    if (!questionnaire.questionnaire_scale)
        return {
            ...questionnaire,
            id: `q${questionnaire.id}`,
            type: "line" as const,
            ...overrides,
            max_score: 0,
            scale_direction: "positive",
            cutoffs: []
        }

    const denominator = calculation === "avg" ? scales.length : 1
    const max_score =
        scales.reduce((acc, { id, max, calculation }) => {
            const scaleQuestions = questions.filter((question) => question.scale_id === id)
            const scaleMax = calculation === "avg" ? max : max * scaleQuestions.length
            return acc + scaleMax
        }, 0) / denominator

    return {
        ...questionnaire,
        id: `q${questionnaire.id}`,
        max_score,
        cutoffs,
        scale_direction: direction,
        ...overrides,
        type: "line" as const
    }
}

export const getGraphEntriesForTracker = (id: string, question?: TrackerQuestionData): GraphItem => ({
    id: `t${id}`,
    scale_direction: "positive",
    max_score: id === "hours_of_sleep_last_night" ? 24 : question?.definition?.max ?? 1,
    color: trackerQuestionColors[id as keyof typeof trackerQuestionColors],
    type: id.includes("major") ? ("bar" as const) : ("line" as const),
    name: "tracker"
})
