import { SVGProps } from "react"

const ThoughtsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.794 3.803C7.23 2.555 6.264 5.184 6.228 6.655c-2.477 2.17-1.277 6.17 3.134 5.348 2.428 2.038 5.877 1.687 7.649-.407 6.272.871 6.12-6.664 1.59-6.576-.36-3.708-5.488-4.795-7.806-1.217Z"
      stroke="#3C7FF9"
      strokeWidth={1.32}
    />
    <circle cx={16.208} cy={8.249} r={1} fill="#3C7FF9" />
    <circle cx={13.382} cy={8.249} r={1} fill="#3C7FF9" />
    <circle cx={10.554} cy={8.249} r={1} fill="#3C7FF9" />
    <circle
      cx={3.488}
      cy={4.787}
      r={1.5}
      transform="rotate(-86.55 3.488 4.787)"
      fill="#3C7FF9"
    />
    <circle
      cx={1.76}
      cy={1.86}
      r={1.002}
      transform="rotate(-86.55 1.76 1.86)"
      fill="#3C7FF9"
    />
  </svg>
)

export default ThoughtsIcon
