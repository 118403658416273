import { CalendarIcon, EnvelopeIcon, LightBulbIcon } from "@heroicons/react/24/outline"
import { sendEmailWithDetails } from "api"
import { Button, Modal, Spinner } from "components/common"
import { Tag } from "components/common/Tag"
import Settings from "components/icons/Settings"
import UserAvatar from "components/UserAvatar"
import { differenceInYears } from "date-fns"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { useDispatch } from "store"
import { useCurrentPatient, useDiagnoses } from "store/hooks"
import { closeModal, openFeaturesSettingsModal, openPatientModal } from "store/modal/actions"
import { pushToLibraryStack } from "store/patient/actions"
import { FeatureFlags } from "utils/FeatureFlags"
import { getUserName } from "../../utils"
import NextRow from "./NextRow"

export const PatientWhatNextModal = ({
    isOpen,
    params
}: {
    isOpen: boolean
    params: { patientId: number; isAfterPatientCreation?: boolean }
}) => {
    const dispatch = useDispatch()
    const patient = useCurrentPatient()
    const { t } = useTranslation()
    const [isMailSending, setIsMailSending] = useState(false)
    const isPatientLoading = (!patient || patient.id !== params?.patientId) && isOpen
    const diagnoses = useDiagnoses()
    const age = differenceInYears(new Date(), new Date(patient?.user?.date_of_birth))

    const auth0PatientsEnabled = FeatureFlags.isEnabled("auth0-patients-update")
    const featureSettingsEnabled = FeatureFlags.isEnabled("feature-settings")

    const handleClose = () => dispatch(closeModal())

    const handleSendMessage = async () => {
        try {
            setIsMailSending(true)
            await sendEmailWithDetails(patient.id)
            toast(t("patient:whatNext.emailIsSent"), { type: "success" })
        } catch (error) {
            console.error(error)
            toast(t("common:oopsWentWrong"), { type: "error" })
        } finally {
            setIsMailSending(false)
        }
    }

    const handlePatientPlanExplore = async () => {
        dispatch(pushToLibraryStack({ view: "questionnaire.cards", patientId: patient.id }))
        handleClose()
    }

    const handleFeatureSettings = async () => {
        handleClose()

        dispatch(openFeaturesSettingsModal({ patient_id: patient.id }))
    }

    return (
        <Modal
            show={isOpen}
            onClose={handleClose}
            theme="white"
            scrollAuto
            cardClassName="pt-2 w-[800px] h-fit rounded-3xl"
        >
            {isPatientLoading && (
                <div className="flex justify-center my-40">
                    <Spinner size={25} className="text-med-blue w-9" />
                </div>
            )}
            {!isPatientLoading && patient && (
                <div className="w-full p-12">
                    {params?.isAfterPatientCreation && (
                        <div className="text-dark-blue font-semibold text-3xl mb-12">{t("patient:whatNext.title")}</div>
                    )}
                    <div className="bg-sidebar rounded-xl flex">
                        <UserAvatar
                            size={35}
                            user={patient.user}
                            noImageUrl={"/images/no-picture.png"}
                            className="m-7"
                        />
                        <div className="flex flex-col my-9 flex-1">
                            <div className="text-dark-blue text-xl">{getUserName(patient.user)}</div>
                            <div className="text-text-blue text-sm mb-4">{age}</div>
                            <Tag color="gray" className="w-fit">
                                {diagnoses.find((diagnosis) => diagnosis.id === patient.primary_diagnosis)?.name}
                            </Tag>
                        </div>
                        <div className="flex flex-col justify-center">
                            <Button
                                theme="secondary_dark"
                                className="!text-base w-40 h-12 mr-12 rounded"
                                onClick={() => dispatch(openPatientModal({ patientFile: patient }))}
                            >
                                {t("patient:whatNext.editDetails")}
                            </Button>
                        </div>
                    </div>
                    <div className="px-12">
                        <div className="text-dark-blue font-medium text-xl mt-14 mb-10">
                            {t("patient:whatNext.subtitle")}
                        </div>
                        {auth0PatientsEnabled && (
                            <NextRow
                                text={t("patient:whatNext.emailBlockText")}
                                Icon={EnvelopeIcon}
                                Button={
                                    <Button
                                        theme="secondary_dark"
                                        className="!text-base w-40 rounded py-4"
                                        onClick={handleSendMessage}
                                        disabled={isMailSending}
                                    >
                                        {isMailSending ? <Spinner size={8} /> : t("patient:whatNext.emailBlockBtn")}
                                    </Button>
                                }
                            />
                        )}
                        <NextRow
                            text={t("patient:whatNext.planBlockText")}
                            Icon={CalendarIcon}
                            Button={
                                <Button
                                    theme="secondary_dark"
                                    className="!text-base w-40 rounded py-4"
                                    onClick={handlePatientPlanExplore}
                                >
                                    {t("patient:whatNext.planBlockBtn")}
                                </Button>
                            }
                        />
                        {featureSettingsEnabled && (
                            <NextRow
                                text={t("patient:whatNext.featuresBlockText")}
                                Icon={Settings}
                                Button={
                                    <Button
                                        theme="secondary_dark"
                                        className="!text-base w-40 rounded py-4"
                                        onClick={handleFeatureSettings}
                                    >
                                        {t("patient:whatNext.featuresBlockBtn")}
                                    </Button>
                                }
                            />
                        )}
                        <NextRow text={t("patient:whatNext.tipBlockText")} Icon={LightBulbIcon} />
                    </div>
                    <div className="flex justify-end">
                        <Button theme="dark_borderless" className="text-lg mt-8 w-40" onClick={handleClose}>
                            {t("common:back")}
                        </Button>
                        <Button
                            theme="dark_primary"
                            className="text-lg mt-8 w-40"
                            onClick={handleClose}
                            data-testid="what-next-modal-done-bnt"
                        >
                            {t("common:done")}
                        </Button>
                    </div>
                </div>
            )}
        </Modal>
    )
}
