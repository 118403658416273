export const CLEAR_CURRENT_PATIENT = "patient/CLEAR_CURRENT_PATIENT"
export const SET_CURRENT_PATIENT = "patient/SET_CURRENT_PATIENT"
export const SET_CURRENT_COLLABORATOR = "patient/SET_CURRENT_COLLABORATOR"
export const SET_COMMENTS = "patient/SET_COMMENTS"
export const SET_CURRENT_PATIENT_FEATURES = "patient/SET_CURRENT_PATIENT_FEATURES"

export const SET_EVENTS_BY_DATE = "patient/SET_EVENTS_BY_DATE"
export const CLEAR_LAST_FETCH_DATA = "patient/CLEAR_LAST_FETCH_DATA"
export const DELETE_EVENT = "patient/DELETE_EVENT"
export const SET_PATIENT_LOADING = "patient/SET_PATIENT_LOADING"
export const UPDATE_EVENT_STATUS = "patient/UPDATE_EVENT_STATUS"

export const UPDATE_PERMISSION = "patient/UPDATE_PERMISSION"
export const ADD_COLLABORATOR = "patient/ADD_COLLABORATOR"
export const REMOVE_PERMISSION = "patient/REMOVE_PERMISSION"

export const ADD_CONVERSATION_DATA = "patient/ADD_CONVERSATION_DATA"
export const ADD_CONVERSATION_MESSAGES = "patient/ADD_CONVERSATION_MESSAGES"

export const CREATE_COMMENT = "patient/CREATE_COMMENT"
export const UPDATE_COMMENT = "patient/UPDATE_COMMENT"
export const DELETE_COMMENT = "patient/DELETE_COMMENT"

export const UPDATE_PATIENT_DASHBOARD = "patient/UPDATE_PATIENT_DASHBOARD"
export const UPDATE_GRAPH_STATE = "patient/UPDATE_GRAPH_STATE"
export const SET_DASHBOARD_CARDS = "patient/SET_DASHBOARD_CARDS"
export const UPDATE_OVERVIEW_DATA = "patient/UPDATE_OVERVIEW_DATA"
export const READ_PATIENT_NOTIFICATIONS = "patient/READ_PATIENT_NOTIFICATIONS"
export const SET_GOALS_DATA = "patient/SET_GOALS_DATA"
export const UPDATE_GOALS_ACHIEVE = "patient/UPDATE_GOALS_ACHIEVE"
export const UPDATE_GOAL = "/patient/UPDATE_GOAL"
export const CREATE_GOAL = "patient/CREATE_GOAL"

export const SET_EVENTS_FOR_GANTT = "patient/SET_EVENTS_FOR_GANTT"

export const SET_CONTENT_LIBRARY = "patient/SET_CONTENT_LIBRARY"
export const GO_TO_PREV_CONTENT = "patient/GO_TO_PREV_CONTENT"
export const SET_LIBRARY_SEARCH = "patient/SET_LIBRARY_SEARCH"
export const ADD_PATIENT_QUESTIONNAIRE = "patient/ADD_PATIENT_QUESTIONNAIRE"
export const SET_COLLABORATOR_MANAGER = "patient/SET_COLLABORATOR_MANAGER"

export const TOGGLE_AUTH_SUCCESS = "patient/TOGGLE_AUTH_SUCCESS"

export const UPDATE_CURRENT_PATIENT = "patient/UPDATE_CURRENT_PATIENT"
export const UPDATE_EVENT = "patient/UPDATE_EVENT"

export const SET_TRACKER_MANAGER = "patient/SET_TRACKER_MANAGER"
export const SET_TRACKER = "patient/SET_TRACKER"
export const ADD_TRACKER_MEASURE = "patient/ADD_TRACKER_MEASURE"
export const ADD_TRACKER_GROUP = "patient/ADD_TRACKER_GROUP"
export const SET_TRACKER_DASHBOARD = "patient/SET_TRACKER_DASHBOARD"
