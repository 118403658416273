import { SVGProps } from "react"

const EditIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="none" viewBox="0 0 23 23" {...props}>
        <g
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            clipPath="url(#clip0_9453_224378)"
        >
            <path d="M3.834 19.167h3.833L17.73 9.104a2.71 2.71 0 10-3.834-3.833L3.835 15.334v3.833zM12.938 6.229l3.833 3.833"></path>
        </g>
        <defs>
            <clipPath id="clip0_9453_224378">
                <path fill="#fff" d="M0 0H23V23H0z"></path>
            </clipPath>
        </defs>
    </svg>
)

export default EditIcon
