import { Button } from "components/common"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { goToPrevContent } from "store/patient/actions"

import QuestionnaireGantt from "../QuestionnaireGantt"
import { EventConfigState } from "./types"

const EventConfigFooter = ({
    loading,
    eventTemplate,
    patientId
}: {
    loading: boolean
    eventTemplate: EventConfigState | EventConfigState[]
    patientId: number
}) => {
    const { t } = useTranslation("common")
    const dispatch = useDispatch()
    return (
        <>
            <QuestionnaireGantt eventTemplate={eventTemplate} patientId={patientId} />
            <div className="grow shrink-0 flex items-end">
                <div className="flex justify-end items-center w-full px-12 py-8 gap-8">
                    <button
                        onClick={() => dispatch(goToPrevContent)}
                        className="text-dark-blue hover:underline underline-offset-2"
                    >
                        {t("back")}
                    </button>
                    <Button data-testid="event_config-submit" theme="primary" className="w-36" loading={loading}>
                        {t("done")}
                    </Button>
                </div>
            </div>
        </>
    )
}

export default EventConfigFooter
