import SuperSelect from "forms/SuperSelect"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useAllQuestionnaires } from "store/hooks"
import { defaultFilterClasses } from "./FilterInput"

type Props = {
    value: number[]
    setFilter: (selected: number[]) => void
}

const QuestionnairesFilter = ({ value, setFilter }: Props) => {
    const { t } = useTranslation("")
    const questionnaires = useAllQuestionnaires()

    const items = useMemo(() => questionnaires.map(({ id, title }) => ({ id, label: title })), [questionnaires])
    return (
        <SuperSelect
            id={"questionnaires"}
            items={items}
            value={value}
            setValue={setFilter}
            placeholder={t("export.allQuestionnaires")}
            usePlaceholderAsAll
            classList={defaultFilterClasses}
            contentWidth="w-64"
            withSelectionIcon={true}
        />
    )
}

export default QuestionnairesFilter
