import { SVGProps } from "react"
const SwitchHorizontalIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        viewBox="0 0 24 24"
        {...props}
    >
        <path stroke="none" d="M0 0h24v24H0z" />
        <path d="m16 3 4 4-4 4M10 7h10M8 13l-4 4 4 4M4 17h9" />
    </svg>
)
export default SwitchHorizontalIcon
