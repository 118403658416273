import EditPolicySelector from "components/Patient/EditPolicySelector"
import { Dialog } from "components/common"
import { Dispatch, SetStateAction } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { useDispatch } from "store"
import { useCurrentUser } from "store/hooks"
import { deleteEvent, getEventsByDate } from "store/patient/actions"
import { toggleCurrentSidebar } from "store/sidebars/actions"
import { EventDeleteDialogData } from "types/Misc"
import { DateString } from "utils/dates"

type EventDeleteDialogProps = {
    eventDeleteDialog: EventDeleteDialogData
    setEventDeleteDialog: Dispatch<SetStateAction<EventDeleteDialogData>>
}

export const EventDeleteDialog = ({ eventDeleteDialog, setEventDeleteDialog }: EventDeleteDialogProps) => {
    const currentUser = useCurrentUser()
    const { t } = useTranslation("patient")
    const dispatch = useDispatch()

    const onDelete = async () => {
        const deleted = await dispatch(deleteEvent(eventDeleteDialog))
        if (!deleted) {
            toast(t("eventManager.deleteFailed"), { type: "error" })
            return false
        } else toast(t("eventManager.deleteSuccess"), { type: "success" })

        dispatch(getEventsByDate({ date: DateString.today(), page: "timeline", forceFetch: true }))
        dispatch(toggleCurrentSidebar())
        setEventDeleteDialog(null)
        return true
    }

    return eventDeleteDialog ? (
        <Dialog
            open={Boolean(eventDeleteDialog)}
            onClose={() => setEventDeleteDialog(null)}
            title={t("eventManager.deleteDialog.title") as string}
            onConfirm={onDelete}
            type="delete"
        >
            {!eventDeleteDialog.event.recurrence ? (
                <p className="text-sm text-text-blue">
                    {t("eventManager.deleteDialog.text", { context: currentUser.gender })}
                </p>
            ) : (
                <EditPolicySelector
                    action="delete"
                    policy={eventDeleteDialog.policy}
                    setPolicy={(policy) => setEventDeleteDialog({ ...eventDeleteDialog, policy })}
                />
            )}
        </Dialog>
    ) : null
}
