import i18next from "i18next"
import { initReactI18next } from "react-i18next"

import localeEn from "locales/en"
import localeHe from "locales/he"

export const i18nInit = () =>
    i18next.use(initReactI18next).init({
        lng: "en",
        // debug: true,
        ns: Object.keys(localeEn),
        resources: {
            en: localeEn,
            he: localeHe
        }
    })
