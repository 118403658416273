import { SequelConversation } from "utils/conversations"

export type ListData = { itemCount: number; listOffset: number }
export const getListData = (conversation: SequelConversation) => {
    if (!conversation) return {} as ListData

    const { messageCount, rawMessageListSize, messageDisplayList } = conversation
    const listOffset = messageCount.all > rawMessageListSize ? 1 : 0
    return { itemCount: messageDisplayList.length + listOffset, listOffset }
}

export const loaderHeight = 48
