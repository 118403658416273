import { useMemo } from "react"
import { ArrowLeftOnRectangleIcon } from "@heroicons/react/24/solid"
import { Menu } from "components/common"
import { AvatarWithName } from "components/UserAvatar"
import { useTranslation } from "react-i18next"
import { useCurrentUser } from "store/hooks"
import { useAuthContext } from "utils/auth0/AuthProvider"
import { useRouter } from "next/router"
import { useDispatch } from "react-redux"

const UserMenu = () => {
    const currentUser = useCurrentUser()
    const { i18n, t } = useTranslation("user")
    const { signOut } = useAuthContext()
    const dispatch = useDispatch()
    const router = useRouter()

    const options = useMemo(() => {
        return [
            {
                onClick: () => {
                    signOut()
                    router.replace("/")
                    dispatch({ type: "store/RESET" })
                },
                id: "logout-btn",
                children: (
                    <>
                        <ArrowLeftOnRectangleIcon className="w-5" /> {t("auth.logout")}
                    </>
                )
            }
        ]
    }, [])

    return (
        <>
            <Menu
                placement={i18n.language === "he" ? "left-start" : "right-start"}
                offsetOptions={{ mainAxis: 0, crossAxis: 7 }}
                id="settings-logout-menu"
            >
                <Menu.Button className="w-full px-6 py-5 cursor-pointer select-none">
                    {currentUser && <AvatarWithName user={currentUser} />}
                </Menu.Button>
                <Menu.Panel className="w-60 bg-white shadow-md border border-border rounded-sm">
                    {options.map((option, index) => (
                        <div
                            key={index}
                            {...option}
                            className="flex items-center gap-2 py-4 px-6 cursor-pointer hover:bg-border-blue hover:text-dark-blue"
                        />
                    ))}
                </Menu.Panel>
            </Menu>
        </>
    )
}

export default UserMenu
