import * as React from "react"
import { SVGProps } from "react"

const TaskIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
	viewBox="0 0 24 24"
    {...props}
  >
    <g
      clipPath="url(#a)"
      stroke="currentColor"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m5.714 9.107 3.507 3.75 9.35-10" />
      <path d="M18.571 10v6.428a2.143 2.143 0 0 1-2.143 2.143H3.572a2.143 2.143 0 0 1-2.143-2.143V3.572a2.143 2.143 0 0 1 2.143-2.143h9.643" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default TaskIcon
