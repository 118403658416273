import { defineField } from "forms"
import { DiagnosisSelect } from "forms/SuperSelect/wrappers"
import TextArea from "forms/TextArea"
import { noopTFunction } from "utils"

export type ClinicalInfo<T = string> = {
    primary_diagnosis: number
    comorbidities: number[]
    medication: string
    notes: T
}

export const getClinicalFields = (t = noopTFunction) =>
    [
        defineField({
            id: "primary_diagnosis",
            label: t("patient:patientForm.primaryDiagnosis") as string,
            initialValue: null,
            Component: DiagnosisSelect<number>,
            style: "tags",
            validations: { required: { value: true, message: "Please choose a primary diagnosis" } }
        }),
        defineField({
            id: "comorbidities",
            label: t("patient:patientForm.comorbidities") as string,
            initialValue: [],
            Component: DiagnosisSelect<number[]>,
            withSelectionIcon: true,
            style: "tags",
            validations: {
                required: { value: false },
                custom: { isValid: (value) => value.length !== 0, message: "Please choose comorbidities" }
            }
        }),
        defineField({
            id: "medication",
            placeholder: t("patient:patientForm.medication")
        }),
        defineField({
            id: "notes",
            placeholder: t("patient:patientForm.notes"),
            Component: TextArea
        })
    ] as const
