import { Dialog } from "components/common"
import React, { Dispatch, SetStateAction } from "react"
import { useTranslation } from "react-i18next"
import { useCurrentUser } from "store/hooks"

type ExitFormDialogProps = {
    exitFormDialog: Boolean
    setExitFormDialog: Dispatch<SetStateAction<Boolean>>
    onConfirm: () => boolean
}

const ExitFormDialog = ({
    exitFormDialog,
    setExitFormDialog,
    onConfirm
}: ExitFormDialogProps) => {
    const { t } = useTranslation("patient")
    const currentUser = useCurrentUser()

    return exitFormDialog ? (
        <Dialog
            open={true}
            onClose={() => setExitFormDialog(null)}
            title={t("common:exitForm.title") as string}
            onConfirm={onConfirm}
            buttonText={t("common:close") as string}
            type="warn"
        >
            <p className="text-sm text-negative ltr:text-left rtl:text-right">
                {t("common:exitForm.text", { context: currentUser.gender })}
            </p>
        </Dialog>
    ) : null
}

export default ExitFormDialog
