import { BasicUser } from "@sequel-care/types"
import { AvatarWithName } from "components/UserAvatar"
import { FormControlProps } from "forms"
import SuperSelect from "forms/SuperSelect"
import { BasicItem } from "forms/SuperSelect/types"
import { pick } from "lodash"
import { useMemo } from "react"
import { useCurrentPatient } from "store/hooks"
import { basicUserPick, getUserName } from "utils"

const RenderItem = ({ item }: { item: BasicItem & { user: BasicUser } }) => <AvatarWithName user={item.user} size={6} />

export function AssigneeField<V extends number | number[]>(props: FormControlProps<V>) {
    const patient = useCurrentPatient()

    const availableAssignees = useMemo(() => {
        const assignees = patient.contacts.map((contact) => ({
            id: contact.id,
            label: getUserName(contact) + ` (${contact.relation_type})`,
            user: pick(contact, basicUserPick),
            isAvailableForSelection: !contact.disabled && contact.has_auth
        }))
        assignees.unshift({
            id: patient.user_id,
            label: getUserName(patient.user),
            user: pick(patient.user, basicUserPick),
            isAvailableForSelection: true
        })
        return assignees
    }, [patient.contacts])

    return <SuperSelect items={availableAssignees} {...props} style="tags" RenderItem={RenderItem} />
}
