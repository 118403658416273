import { BasicUser } from "@sequel-care/types"
import { AvatarWithName } from "components/UserAvatar"
import { FormControlProps } from "forms"
import SuperSelect from "forms/SuperSelect"
import { useMemo } from "react"
import { usePatientCollaborators } from "store/hooks"
import { getUserName } from "utils"

const useCollaboratorList = () => {
    const collaborators = usePatientCollaborators()
    return useMemo(() => {
        return collaborators
            .filter(({ permissions }) => permissions.some(({ permission }) => permission === "limited"))
            .map(({ user }) => ({ ...user, label: getUserName(user) }))
    }, [collaborators])
}

const RenderItem = ({ item }: { item: BasicUser & { label: string } }) => <AvatarWithName user={item} size={6} />

export function CollaboratorSelector<V extends number | number[]>(props: FormControlProps<V>) {
    const items = useCollaboratorList()
    return <SuperSelect disabled={!items.length} items={items} {...props} style="tags" RenderItem={RenderItem} />
}
