import { GraphResolution } from "@sequel-care/types"
import { ScoreData } from "@sequel-care/types/Event"
import { ById } from "@sequel-care/types/utils"
import {
    addDays,
    addMonths,
    addWeeks,
    differenceInCalendarDays,
    differenceInCalendarMonths,
    differenceInCalendarWeeks,
    getDay,
    getWeek,
    getYear,
    isBefore,
    isSameDay,
    isSameMonth,
    isSameWeek,
    subDays
} from "date-fns"
import i18next from "i18next"
import { meanBy } from "lodash"
import store from "store"
import { GraphState } from "types/Redux"
import { localeFormat, weekdays } from "utils/dates"
import { getRangeEdges } from "utils/graphs"
import { GraphItem } from "../types"

const resolutionFunctions: {
    [key in GraphResolution]: { isInResolutionUnit: typeof isSameDay; addResolutionUnit: typeof addDays }
} = {
    day: { isInResolutionUnit: isSameDay, addResolutionUnit: addDays },
    week: { isInResolutionUnit: isSameWeek, addResolutionUnit: addWeeks },
    month: { isInResolutionUnit: isSameMonth, addResolutionUnit: addMonths }
}

export const getGraphData = ({
    items,
    loading,
    graphState,
    scoresById,
    alwaysUseEventId
}: {
    items: ById<GraphItem>
    loading: boolean
    graphState: GraphState
    scoresById: ById<ScoreData[]>
    alwaysUseEventId?: boolean
}) => {
    if (!items || loading || !scoresById) return { scores: {}, labels: [] }

    const { dates, rangeStart, formatted } = getDates(graphState)
    const { isInResolutionUnit, addResolutionUnit } = resolutionFunctions[graphState.resolution]

    return {
        labels: formatted,
        scores: Object.fromEntries(
            Object.values(items).map(({ id }) => {
                const aggregatedScores = [] as ScoreData[]
                let start = rangeStart
                let scoreList: ScoreData[] = []

                const insertItem = () => {
                    const event =
                        alwaysUseEventId || graphState.resolution === "day"
                            ? scoreList.at(-1) ?? scoreList?.find(({ date_on_timeline }) => Boolean(date_on_timeline))
                            : undefined
                    aggregatedScores.push({
                        event_id: event?.event_id,
                        date: start,
                        date_on_timeline: event?.date_on_timeline,
                        score: scoreList.length ? Math.round(meanBy(scoreList, ({ score }) => score)) : null
                    })
                }

                const today = new Date()
                scoresById[id]?.forEach((event) => {
                    if (isBefore(event.date, rangeStart)) return

                    while (!isInResolutionUnit(event.date, start)) {
                        if (isBefore(today, start)) return

                        insertItem()
                        start = addResolutionUnit(start, 1)
                        scoreList = []
                    }

                    scoreList.push(event)
                })

                if (scoreList.length) insertItem()

                return [id, fillGapsInScoreList(aggregatedScores, isInResolutionUnit, dates)]
            })
        )
    }
}

export const getDates = ({
    range,
    resolution
}: GraphState): { dates: Date[]; rangeStart: Date; formatted: string[] } => {
    const {
        global: { user },
        patient: { currentPatient }
    } = store.getState()
    let { start, end } = getRangeEdges(range, currentPatient)
    if (!start) return { dates: [], rangeStart: undefined, formatted: [] }

    switch (resolution) {
        case "day": {
            const dayCount = differenceInCalendarDays(end, start) + 1
            const dates = [...Array(dayCount)].map((_, index) => subDays(end, dayCount - index - 1))
            return {
                dates,
                rangeStart: start,
                formatted: dates.map((date) =>
                    localeFormat(date, user.preferences.date_format === "d/m/y" ? "d/L" : "L/d", i18next)
                )
            }
        }

        case "week": {
            const weekStartsOn = weekdays.indexOf(user.preferences.week_start) as 0 | 1
            const daysAfterWeekStart = getDay(start) - weekStartsOn
            start = subDays(start, daysAfterWeekStart)
            const weekCount = differenceInCalendarWeeks(end, start) + 1

            const dates = [...Array(weekCount)].map((_, index) => addWeeks(start, index))
            return {
                dates,
                rangeStart: start,
                formatted: dates.map((date) => {
                    const firstDayOfYear = getDay(new Date(getYear(date), 0, 1))
                    // Week calculation is based on the following (which date-fns does not take into account for some reason):
                    // * If January 1 falls on a Monday, Tuesday, Wednesday or Thursday, then the week of January 1 is Week 1.
                    // * If January 1 falls on a Friday, Saturday, or Sunday however, then January 1 is considered to be part of the last week of the previous year and Week 1 will begin on the first Monday after January 1.
                    const week = firstDayOfYear >= 5 ? getWeek(subDays(date, 1), { weekStartsOn }) : getWeek(date)
                    return i18next.language !== "he" ? "W" + week : `שבוע ${week}`
                })
            }
        }

        case "month": {
            const monthCount = differenceInCalendarMonths(end, start) + 1

            const dates = [...Array(monthCount)].map((_, index) => addMonths(start, index))
            return {
                dates,
                rangeStart: start,
                formatted: dates.map((date) => localeFormat(date, "MMM", i18next))
            }
        }
    }
}

function fillGapsInScoreList(scores: ScoreData[], comparison: typeof isSameDay, dates: Date[]) {
    const scoresWithoutGaps: ScoreData[] = []
    dates.forEach((date) => {
        const event = scores.find(({ date: scoreDate }) => comparison(date, scoreDate))
        if (!event) return scoresWithoutGaps.push(null)

        scoresWithoutGaps.push(event)
    })

    return scoresWithoutGaps
}
