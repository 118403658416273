import { offset } from "@floating-ui/react-dom"
import { Tooltip } from "components/common"
import { DetailedHTMLProps, HTMLAttributes } from "react"
import { classNames } from "utils"

interface TruncatedStringProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    string: string
    maxLen: number
    delay?: number
}

const TruncatedString = ({ string, maxLen, delay, ...props }: TruncatedStringProps) => {
    if (!string) return null
    props.className = classNames(props.className, "cursor-default")
    return string.length > maxLen ? (
        <Tooltip
            content={string}
            placement="bottom-start"
            middleware={[offset({ mainAxis: -4, crossAxis: -7 })]}
            delay={delay}
        >
            <div {...props}>{string.slice(0, maxLen) + "..."}</div>
        </Tooltip>
    ) : (
        <div {...props}>{string}</div>
    )
}

export default TruncatedString
