import { PatientStatus } from "@sequel-care/types"
import SuperSelect from "forms/SuperSelect"
import { Dispatch, SetStateAction, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { classNames } from "utils"

const statuses: PatientStatus[] = ["current", "past"]

const StatusFilter = ({
    status,
    setStatus,
    contentWidth = "w-64"
}: {
    status: PatientStatus
    setStatus: Dispatch<SetStateAction<PatientStatus>>
    contentWidth?: `w-${number}`
}) => {
    const { t } = useTranslation("patient")
    const options = useMemo(
        () => statuses.map((status) => ({ id: status, label: t(`export.patientStatuses.${status}`) })),
        [t]
    )

    return (
        <SuperSelect
            id={"statusFilter"}
            items={options}
            value={status}
            withSearch={false}
            placeholder={t("export.allPatients")}
            usePlaceholderAsAll
            classList={{
                input: classNames("flex border border-border-blue px-4 py-3 h-[3.125rem] !text-base", contentWidth)
            }}
            setValue={setStatus}
        />
    )
}

export default StatusFilter
