import { BasicUser } from "@sequel-care/types"
import UserAvatar from "components/UserAvatar"
import { Fragment } from "react"
import Skeleton from "react-loading-skeleton"
import { SkeletonPlaceholder } from "types/Misc"
import { classNames, getUserName } from "utils"
import { ItemListProps, RenderFieldProps } from "./types"

const skeletonList = new Array(6).fill({ skeleton: true }) as SkeletonPlaceholder[]

export function ifUserDisabled<ItemType extends { user: BasicUser }>(item: ItemType) {
    return !item.user.has_auth
}

function ItemList<Item extends { id: number }>({
    items,
    onItemClick,
    fields,
    actions,
    ifDisabled
}: ItemListProps<Item>) {
    const list = items ?? skeletonList

    return (
        <>
            {list.map((item, index) => {
                const isSkeleton = "skeleton" in item
                const isClickable = onItemClick && !isSkeleton
                const hasBorder = index !== 0
                const isDisabled = !isSkeleton && ifDisabled?.(item)

                const onClick = () => (isClickable ? onItemClick(item) : undefined)

                return (
                    <Fragment key={!isSkeleton ? item.id : index}>
                        {fields.map(({ key, RenderField }, index) => (
                            <RenderField
                                value={isSkeleton ? undefined : item[key]}
                                isSkeleton={isSkeleton}
                                classes={classNames(
                                    hasBorder && "border-t",
                                    isDisabled && "bg-disabled",
                                    isClickable && "cursor-pointer"
                                )}
                                key={index}
                                onClick={onClick}
                            />
                        ))}
                        <div
                            className={classNames(
                                hasBorder && "border-t",
                                isDisabled && "bg-disabled",
                                "flex text-med-blue justify-center items-center gap-1"
                            )}
                        >
                            {actions?.length
                                ? actions.map((Action, index) => <Action key={index} item={item} />)
                                : null}
                        </div>
                    </Fragment>
                )
            })}
        </>
    )
}

export default ItemList

const headerTextClasses = "text-secondary "

export function ItemGrid<Item extends { id: number }>(
    gridProps: ItemListProps<Item> & { labels: string[]; gridTemplateColumns: string; labelsBg: `bg-${string}` }
) {
    const { labels, gridTemplateColumns } = gridProps
    return (
        <div className="overflow-y-scroll px-1 h-full w-full">
            <div
                className={classNames(gridProps.labelsBg, "grid grow w-full sticky top-0")}
                style={{ gridTemplateColumns }}
            >
                {labels.map((label, index) => (
                    <span key={index} className={headerTextClasses}>
                        {label.toUpperCase()}
                    </span>
                ))}
            </div>
            <div className="grid mb-4 grow w-full max-h-content" style={{ gridTemplateColumns }}>
                <ItemList {...gridProps} />
            </div>
        </div>
    )
}

export const AvatarField = ({ value, isSkeleton, classes, onClick }: RenderFieldProps<BasicUser>) => {
    return (
        <div
            className={classNames(classes, "flex gap-4 p-4 items-center")}
            data-testid={isSkeleton ? "skeleton-block" : "patient-block"}
            onClick={onClick}
        >
            {!isSkeleton ? (
                <UserAvatar user={value} size={10} />
            ) : (
                <Skeleton width="2rem" height="2rem" borderRadius="100%" />
            )}
            <div>
                <div data-testid={"user-name"}>{!isSkeleton ? getUserName(value) : <Skeleton width="20rem" />}</div>
                <div
                    className="text-sm text-gray-500"
                    id={!isSkeleton ? value.id.toString() : ""}
                    data-testid={"user-email"}
                >
                    {!isSkeleton ? value.email : <Skeleton width="10rem" />}
                </div>
            </div>
        </div>
    )
}
