import { FC, useEffect, useMemo, useRef, useState } from "react"
import Link from "next/link"
import { useCurrentRole } from "store/hooks"
import { useTranslation } from "react-i18next"
import { useSettingsSections } from "components/Settings"
import NotificationsDisplay from "components/Notifications/NotificationsDisplay"
import UserMenu from "./UserMenu"
import PatientList from "./PatientList"
import { MenuItem, RenderMenuItems } from "./MenuComponents"
import { DashboardIcon, DataExportIcon, MessageIcon, UsersIcon } from "components/icons"
import { FeatureFlags } from "utils/FeatureFlags"
import UserSettingsSection from "./UserSettingsSection"

const organizationDisplayClassName = "flex justify-between items-center py-4 pl-8 pr-4 text-xl font-bold"
const buttonHeight = 45
const Sidebar: FC = () => {
    const { t } = useTranslation()
    const currentRole = useCurrentRole()
    const sectionRef = useRef<HTMLDivElement>()
    const [openSection, setOpen] = useState("sidebar-toggle-patient-profile-list")
    const settingsSections = useSettingsSections()

    const [menuHeightById, setHeight] = useState({})
    useEffect(() => {
        if (sectionRef.current) {
            const calculateHeight = () => {
                const children = Array.from(sectionRef.current.children)
                const toggleChildren = children.filter(({ id }) => id.includes("sidebar-toggle"))
                const staticHeight = Array.from(sectionRef.current.children).reduce(
                    (acc, child: HTMLElement) => acc + (child.id.includes("sidebar-toggle") ? 0 : child.offsetHeight),
                    0
                )
                setHeight(
                    Object.fromEntries(
                        toggleChildren.map((child) => {
                            const { paddingTop, paddingBottom } = getComputedStyle(child)
                            return [
                                child.id,
                                openSection === child.id
                                    ? sectionRef.current.offsetHeight -
                                      parseInt(paddingTop) -
                                      parseInt(paddingBottom) -
                                      staticHeight -
                                      buttonHeight * (toggleChildren.length - 1)
                                    : buttonHeight
                            ]
                        })
                    )
                )
            }
            calculateHeight()

            window.addEventListener("resize", calculateHeight)
            return () => window.removeEventListener("resize", calculateHeight)
        }
    }, [sectionRef, openSection])

    const menuItems = useMemo<{ top: MenuItem[]; bottom: MenuItem[] }>(() => {
        const isAdmin = currentRole.role === "admin"
        const bottom: MenuItem[] = [...settingsSections]
        const chatEnabled = FeatureFlags.isEnabled("global-chat")

        const top = [
            {
                name: isAdmin ? t("patient:allPatients") : t("patient:myPatients"),
                href: isAdmin ? "/patients" : "/patients/#my-patients",
                Icon: UsersIcon,
                testId: "menu-item-patients"
            },
            {
                name: t("common:dashboard"),
                href: "/dashboard",
                Icon: DashboardIcon,
                testId: "menu-item-dashboard"
            }
        ]

        if (chatEnabled)
            top.push({
                name: t("patient:chat.chat_one"),
                href: "/chat",
                Icon: MessageIcon,
                testId: "menu-item-chat"
            })
        if (currentRole.role === "admin" && FeatureFlags.isEnabled("csv-export"))
            bottom.push({
                name: t("common:dataExport"),
                href: "/administration/export",
                Icon: DataExportIcon
            })

        return {
            top,
            bottom
        }
    }, [currentRole, t, settingsSections])

    const bottomSlidingMenuProps = { openSection, sectionRef, setOpen, menuHeightById }
    return (
        <div className="flex shrink-0 flex-col w-72 bg-dark-blue sticky top-0 text-white h-screen divide-y divide-text-blue">
            <div className="flex flex-col self-start w-full divide-y divide-text-blue">
                <div className="h-20 flex items-center px-8">
                    <Link href={"/"} passHref>
                        <img
                            src="/logo/white/1x.png"
                            srcSet="/logo/white/2x.png 2x, /logo/white/3x.png 3x"
                            className="h-6 w-auto sm:h-8 cursor-pointer"
                            alt="SequelCare Logo"
                        />
                    </Link>
                </div>
                <div className={organizationDisplayClassName}>{currentRole?.organization.name}</div>
                <RenderMenuItems items={menuItems.top} beforeItems={<NotificationsDisplay />} />
            </div>

            <div
                className="flex flex-col flex-1 self-end w-full divide-y divide-text-blue bottom-0 justify-end overflow-auto"
                ref={sectionRef}
            >
                <PatientList {...bottomSlidingMenuProps} />
                {menuItems.bottom.length && (
                    <RenderMenuItems items={menuItems.bottom} title="administration" toggle={bottomSlidingMenuProps} />
                )}
                <UserSettingsSection />
                <UserMenu />
            </div>
        </div>
    )
}

export default Sidebar
