import { noop } from "lodash"
import { createContext } from "react"
import { FormState, FormWithStepsProps, Step } from "./types"

export type FormContextValue<Steps extends readonly Step[], State extends FormState<Steps> = FormState<Steps>> = Pick<
    FormWithStepsProps<Steps, State>,
    "errors" | "setErrors" | "state" | "setState"
>

export const FormContext = createContext({
    state: {},
    setState: noop,
    errors: {},
    setErrors: noop
} as FormContextValue<readonly Step[]>)
