import { CalendarIcon } from "@heroicons/react/24/outline"
import { GraphResolution } from "@sequel-care/types"
import SuperSelect from "forms/SuperSelect"
import { BasicItem } from "forms/SuperSelect/types"
import { getItemsFromEnum } from "forms/SuperSelect/utils"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "store"
import { useGraphByKey } from "store/hooks"
import { updateGraphState } from "store/patient/actions"
import { PatientDashboardRanges } from "types/Misc"
import { GraphType } from "types/Redux"
import { classNames } from "utils"

const resolutions: { id: GraphResolution; disabledOn?: PatientDashboardRanges[] }[] = [
    { id: "day" },
    { id: "week", disabledOn: [PatientDashboardRanges.LAST_7_DAYS] },
    {
        id: "month",
        disabledOn: [PatientDashboardRanges.LAST_7_DAYS, PatientDashboardRanges.LAST_30_DAYS]
    }
]

const renderFunctions = {
    RenderItem: ({ item }: { item: BasicItem }) => <div data-testid="range_selector-option">{item.label}</div>,
    RenderButton: ({ item }: { item: BasicItem }) => (
        <div className="flex items-center gap-3 justify-start h-full">
            <CalendarIcon className="w-5 h-5" />
            {item.label}
        </div>
    )
}

export const RangeSelector = ({
    range,
    setRange,
    noRangeKey = "noFilter",
    width,
    className,
    labelShort = false
}: {
    range: PatientDashboardRanges
    setRange?: (range: PatientDashboardRanges) => void
    noRangeKey?: string
    width?: `w-${number}`
    className: string
    labelShort?: boolean
}) => {
    const { t, i18n } = useTranslation("patient")

    const items = useMemo(
        () =>
            getItemsFromEnum(PatientDashboardRanges, (item) =>
                t(`dashboard.${item === "noFilter" ? noRangeKey : item}`, { context: labelShort ? "short" : undefined })
            ),
        [t, noRangeKey]
    )

    width = width || (i18n.language !== "he" ? "w-56" : "w-72")

    return (
        <span data-testid="range_selector" className={classNames("relative", width)}>
            <SuperSelect
                id="range-selector"
                classList={{ input: className }}
                items={items}
                value={range ?? PatientDashboardRanges.LAST_30_DAYS}
                setValue={setRange}
                withSearch={false}
                keepOriginalSorting
                {...renderFunctions}
            />
        </span>
    )
}

export const ResolutionSelector = ({ graphKey }: { graphKey: GraphType }) => {
    const dispatch = useDispatch()
    const { resolution, range } = useGraphByKey(graphKey)

    return (
        <div className="flex shrink-0 cursor-pointer border border-border-blue rounded-lg overflow-hidden">
            {resolutions.map((item) => {
                const isDisabled = item.disabledOn?.includes(range)
                return (
                    <button
                        key={item.id}
                        onClick={() => dispatch(updateGraphState(graphKey, { resolution: item.id }))}
                        disabled={isDisabled}
                        className={classNames(
                            "font-bold text-base p-3 py-2",
                            resolution === item.id ? "bg-border-blue" : "bg-sidebar hover:bg-border-blue/50",
                            isDisabled ? " text-gray-300 cursor-default" : "text-text-blue"
                        )}
                    >
                        {item.id[0].toUpperCase()}
                    </button>
                )
            })}
        </div>
    )
}
