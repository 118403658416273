import { classNames } from "utils"
import { UsersIcon } from "components/icons"

const GroupAvatar = ({ className }: { className?: string }) => {
    return (
        <div
            className={classNames(
                "flex items-center justify-center rounded-full h-8 w-8 bg-bg text-xl text-med-blue",
                className
            )}
        >
            <UsersIcon />
        </div>
    )
}

export default GroupAvatar
