import { ChevronRightIcon } from "@heroicons/react/24/outline"
import useResizeObserver from "hooks/useResizeObserver"
import { useCallback, useEffect, useRef, useState } from "react"
import { classNames } from "utils"
import { useToggleContext } from "./context"
import { ButtonProps, ChevronProps, ChevronRotation, ChevronSize, ContentProps } from "./types"
import { useTranslation } from "react-i18next"
import { Language } from "@prisma/client"

const sizeToClassname: { [size in ChevronSize]: string } = {
    small: "w-3",
    medium: "w-4",
    large: "w-5"
}

const rotationToClassname = (lang: Language): { [rotation in ChevronRotation]: string } => ({
    top: "-rotate-90",
    right: lang === "he" ? "rotate-180" : "rotate-0",
    bottom: "rotate-90",
    left: lang === "he" ? "rotate-0" : "rotate-180"
})

export const ToggleChevron = ({
    closedRotation = "right",
    openRotation = "bottom",
    size = "medium",
    noToggle = false
}: ChevronProps) => {
    const { i18n } = useTranslation()
    const rotationClassNames = rotationToClassname(i18n.language as Language)

    const { isOpen, onToggle } = useToggleContext()
    const currentRotation = isOpen ? openRotation : closedRotation
    return (
        <ChevronRightIcon
            onClick={!noToggle ? onToggle : undefined}
            className={classNames(
                sizeToClassname[size],
                rotationClassNames[currentRotation],
                "cursor-pointer transition"
            )}
        />
    )
}

export const ToggleContent = ({
    className,
    children,
    transitionDuration = 300,
    minChildrenToDisplay = 0,
    maxHeight
}: ContentProps) => {
    const [dimensions, setDimensions] = useState({ height: 0, width: 0 })
    const ref = useResizeObserver<HTMLDivElement>(setDimensions)
    const { isOpen } = useToggleContext()

    const getMinPx = useCallback(() => {
        const min =
            ref.current && minChildrenToDisplay
                ? Array.from(ref.current.children)
                      .slice(0, minChildrenToDisplay)
                      .reduce((acc, child: HTMLElement) => (acc += child.offsetHeight), 0)
                : 0
        return `${min}px`
    }, [minChildrenToDisplay])

    const [height, setHeight] = useState(minChildrenToDisplay ? "auto" : getMinPx())
    useEffect(() => {
        if (!isOpen && height !== "auto") setHeight(getMinPx())
    }, [dimensions])

    const [receivedInitialHeight, setReceivedInitialHeight] = useState(false)
    useEffect(() => {
        if (height !== "auto" && !receivedInitialHeight) setReceivedInitialHeight(true)
    }, [height])

    const childrenIsList = Array.isArray(children)
    const previousMinChildren = useRef(null)
    useEffect(() => {
        if (ref.current) {
            const getHeight = () => ref.current.offsetHeight + "px"
            const minPx = getMinPx()

            if (!receivedInitialHeight || previousMinChildren.current !== minChildrenToDisplay) {
                if (!isOpen && childrenIsList && ref.current.children.length) setHeight(minPx)
                previousMinChildren.current = minChildrenToDisplay
            } else if (!isOpen && height !== minPx) {
                setHeight(getHeight())

                let frame: number | null = null
                frame = requestAnimationFrame(() => {
                    frame = requestAnimationFrame(() => setHeight(minPx))
                })
                return () => cancelAnimationFrame(frame)
            } else if (isOpen) {
                setHeight(getHeight())
                const autoHeightTimeout = setTimeout(() => setHeight("auto"), transitionDuration)
                return () => clearTimeout(autoHeightTimeout)
            }
        }
    }, [isOpen, ref.current, minChildrenToDisplay])

    return (
        <div
            className={"transition-all overflow-hidden"}
            style={{
                height,
                transitionDuration: transitionDuration + "ms"
            }}
        >
            <div
                className={classNames(className, "h-fit")}
                ref={ref}
                style={{
                    maxHeight: maxHeight
                        ? maxHeight - parseInt(getComputedStyle(ref.current).paddingBottom) + "px"
                        : undefined
                }}
            >
                {childrenIsList && !receivedInitialHeight ? children.slice(0, minChildrenToDisplay) : children}
            </div>
        </div>
    )
}

export const ToggleButton = ({ chevron, showChevron, className, children, onClick, ...props }: ButtonProps) => {
    const { onToggle, isOpen } = useToggleContext()
    return (
        <div
            {...props}
            ref={props.ref ? props.ref : undefined}
            onClick={(event) => {
                onToggle()
                onClick?.(event)
            }}
            className={classNames(className, "cursor-pointer flex justify-between items-center")}
        >
            {typeof children === "function" ? children({ isOpen }) : children}
            {showChevron ? <ToggleChevron {...chevron} noToggle /> : null}
        </div>
    )
}
