import { ForwardRefRenderFunction, PropsWithChildren, ReactChild, forwardRef } from "react"
import { classNames } from "utils"

const SidebarSection: ForwardRefRenderFunction<
    HTMLDivElement,
    PropsWithChildren<{ title: ReactChild; withoutBorder?: boolean; className?: string }>
> = ({ children, title, className, withoutBorder = false }, ref) => {
    return (
        <div
            className={classNames("px-12 flex flex-col", className, !withoutBorder && "border-b border-border-blue")}
            ref={ref}
        >
            <div className="text-xl text-dark-blue font-medium">{title}</div>
            {children}
        </div>
    )
}

export default forwardRef(SidebarSection)
