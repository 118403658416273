import { Modal } from "components/common"
import { FormError } from "forms/types"
import { Dictionary, isEqual, last } from "lodash"
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import FormWithSteps from "."
import ExitFormDialog from "./ExitFormDialog"
import { FormState, Step } from "./types"

type FormModalProps<Steps extends readonly Step[]> = {
    show: boolean
    setShow: Dispatch<SetStateAction<boolean>>
    useStepList: ({ isEdit }: { isEdit: boolean }) => Steps
    onSubmit: (
        state: FormState<Steps>,
        setErrors: Dispatch<SetStateAction<Dictionary<FormError>>>
    ) => Promise<boolean> | boolean
    initialState: () => FormState<Steps>
    title: string
    CompletedState?: (props: FormState<Steps>) => JSX.Element
    promptOnExit?: boolean
    isEdit?: boolean
    stepClassName?: string
}

function FormModal<Steps extends readonly Step[]>({
    show,
    setShow,
    useStepList,
    onSubmit: onSubmitProp,
    initialState,
    title,
    CompletedState,
    promptOnExit = false,
    isEdit = false,
    stepClassName = "h-[50vh]"
}: FormModalProps<Steps>) {
    const { t } = useTranslation("common")
    const stepList = useStepList({ isEdit })
    const [state, setState] = useState(initialState())
    const [errors, setErrors] = useState({} as Dictionary<FormError>)
    const [completed, setCompleted] = useState(false)

    useEffect(() => {
        const newState = initialState()
        if (!isEqual(state, newState)) setState(newState)

        if (show) {
            setCompleted(false)
            setState(initialState())
            setErrors({})
        }
    }, [initialState, show])

    const onClose = () => {
        setShow(false)
        return true
    }

    const onSubmit = async () => {
        const submitResult = await onSubmitProp(state, setErrors)
        if (submitResult === true) {
            if (CompletedState) setCompleted(true)
            else onClose()
        }
    }

    const additionalButtons = useMemo(
        () => [
            {
                theme: "dark_borderless" as const,
                className: "flex items-center gap-2 w-30 font-light",
                children: <>{t("common:close")}</>,
                onClick: onClose,
                "data-testid": "close-modal-btn"
            }
        ],
        [t]
    )

    const [exitFormDialog, setExitFormDialog] = useState(false)
    return (
        <>
            <Modal
                show={show}
                onClose={completed || !promptOnExit ? onClose : () => setExitFormDialog(true)}
                theme="white"
                scrollAuto
                cardClassName="pt-2 w-256 rounded-3xl"
                data-testid="modal-block"
            >
                <FormWithSteps
                    stepClassName={stepClassName}
                    getSubmitProps={(currentStep) => ({
                        className: "bg-dark-blue border border-dark-blue w-40",
                        children: t(currentStep !== last(stepList) ? "next" : "done") as string
                    })}
                    steps={stepList}
                    tabView
                    {...{
                        state,
                        setState,
                        onSubmit,
                        title,
                        additionalButtons,
                        unlockAllSteps: isEdit,
                        errors,
                        setErrors
                    }}
                />
                {CompletedState && <CompletedState completed={completed} {...state} onClose={onClose} />}
            </Modal>
            <ExitFormDialog onConfirm={onClose} {...{ exitFormDialog, setExitFormDialog }} />
        </>
    )
}

export default FormModal
