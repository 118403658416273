import Image, { ImageProps } from "next/image"

const images = {
    "chat-bubble": "f8a4205c-77fd-499c-8a38-15cbe714df00",
    "no-picture": "bd1e72dc-4a7d-4afe-d16d-db999f7f4c00",
    "empty-timeline": "f45932f8-e4cb-4d21-874b-ff346b217400",
    "completed-img": "1e562c16-5799-4425-61d7-cfa0e1d24000",
    looking: "ce8dfe81-a131-4c24-83bd-b37ec6959900",
    "no-notifications": "1c00a2ba-bfc5-4583-71c3-edc55c079400"
}

type Props = {
    imageId?: keyof typeof images
    variant: "small" | "medium" | "large"
    noImageId?: keyof typeof images
}

const imageDeliveryUrl = "https://imagedelivery.net/m6RExtdDMTgOxFpHJkwHhA/[imageId]/[variant]"

const CloudImg = ({ imageId, variant, src, noImageId, ...rest }: Props & Partial<ImageProps>) => {
    if (!src) src = imageDeliveryUrl.replace("[imageId]", images[imageId ?? noImageId])

    return <Image src={(src as string).replace("[variant]", variant)} {...rest} alt={rest.alt ?? ""} />
}

export default CloudImg
