import { MutableRefObject, useMemo, useCallback } from "react"
import { TFunction } from "react-i18next"
import { SearchProps } from "components/Search"
import { CardData, CategoryObj, RenderLibraryProps, WithIcon } from "./types"
import { eventTypeIconMap } from "utils"
import SearchResultsSection from "./SearchResultsSection"

type RenderSearchResultsProps<ItemType, CategoryType> = Pick<
    RenderLibraryProps<ItemType, CategoryType>,
    "categories" | "type" | "onAdd"
> & {
    setSearch: SearchProps["setSearch"]
    searchRef: MutableRefObject<HTMLInputElement>
    matchingCategories: CategoryType[]
    matchingItems: ItemType[]
    t: TFunction<"patient", undefined>
}

function RenderSearchResults<ItemType extends CardData, CategoryType extends CategoryObj>({
    categories,
    matchingCategories,
    matchingItems,
    type,
    onAdd,
    setSearch,
    searchRef,
    t
}: RenderSearchResultsProps<ItemType, CategoryType>) {
    const selectCategory = useCallback(
        (category: CategoryType) => {
            setSearch("")
            categories?.setCurrentCategory(category)
            searchRef.current?.blur()
        },
        [categories?.setCurrentCategory]
    )

    const preset = useMemo(() => {
        return [
            { id: "all", name: t(`contentLib.all.${type}`), Icon: eventTypeIconMap[type] } as WithIcon<CategoryType>,
            ...(categories?.preset ?? [])
        ]
    }, [type, categories?.preset])

    // Calling preventDefault in the wrapper here makes sure clicking inside the search results does not blur the search input
    return (
        <div onMouseDown={(e) => e.preventDefault()} className="grow mt-4">
            <div className="flex items-center gap-20">
                {preset.map((category) => (
                    <div
                        key={category.id}
                        className="flex items-center gap-4 text-dark-blue font-medium group cursor-pointer"
                        onClick={() => selectCategory(category)}
                    >
                        <div className="text-med-blue bg-med-blue bg-opacity-30 flex items-center justify-center w-10 h-10 rounded group-hover:shadow-md transition-shadow">
                            <category.Icon className="w-6 h-6 !m-0 text-lg" strokeWidth={1.75} />
                        </div>
                        {category.name}
                    </div>
                ))}
            </div>
            {categories && (
                <SearchResultsSection
                    title={categories.label}
                    items={matchingCategories}
                    getItemLabel={(item) => item.name}
                    onItemClick={selectCategory}
                    t={t}
                />
            )}
            <SearchResultsSection
                title={t(`${type}s`)}
                items={matchingItems}
                getItemLabel={(item) => item.title}
                onItemClick={onAdd}
                t={t}
            />
        </div>
    )
}

export default RenderSearchResults
