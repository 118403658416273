import { toCalendarDate } from "@internationalized/date"
import { CompletePatientFile, PatientFileInput } from "@sequel-care/types"
import { createPatient, updatePatient } from "api"
import { FormModal } from "forms/FormWithSteps"
import { Dictionary, omit } from "lodash"
import { HandleUserEmail, UserEmailHandler } from "modals/UserModal/UserEmailHandler"
import { useRouter } from "next/router"
import { useCallback, useMemo, useRef } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { useDispatch } from "store"
import { addPatientToList, fetchPatientTags, refetchPatientInList } from "store/global/actions"
import { useCurrentUser, useUsersInCurrentOrg } from "store/hooks"
import { closeModal, openPatientWhatNextModal } from "store/modal/actions"
import { getCurrentPatient } from "store/patient/actions"
import { ArrayElement } from "type-fest/source/exact"
import { uploadImageToCloudflare } from "utils"
import { getClientTimezone } from "utils/dates"
import { initialPatientAddState, initialPatientUpdateState, PatientFormState, usePatientStepList } from "."
import { isFile } from "../UserModal/utils"
import { contactIsNotEmpty } from "./fields"
import { sendMixpanelReport } from "utils/mixpanel"
import { CollaboratorRole, OrgRole } from "@prisma/client"
import { format } from "date-fns"
import { FeatureFlags } from "../../utils/FeatureFlags"

export type PatientModalProps = {
    isOpen: boolean
    params?: {
        patientFile: CompletePatientFile<string, string>
        onUpdate?: () => void
    }
}

type PatientFileKey = keyof (PatientFormState["patient"] & PatientFormState["treatment"] & PatientFormState["clinical"])
function getPatientData<O extends readonly PatientFileKey[]>(
    state: PatientFormState,
    email: string,
    patientFileOmit: O
) {
    const profileImage = state.user.profile_image
    return {
        user: {
            ...omit(state.user, "contacts"),
            date_of_birth: toCalendarDate(state.user.date_of_birth).toDate(getClientTimezone()).toJSON(),
            email,
            profile_image: isFile(profileImage) ? profileImage.name : profileImage === null ? null : undefined
        },
        patient_file: {
            ...(omit({ ...state.patient, ...state.treatment, ...state.clinical }, patientFileOmit) as Omit<
                PatientFileInput<string, string>,
                ArrayElement<O>
            >),
            treatment_start: format(state.treatment.treatment_start, "yyyy-MM-dd'T'HH:mm:ss'Z'")
        },
        contacts: state.user.contacts.filter(contactIsNotEmpty)
    }
}

const patientUpdateOmit = ["collaborators", "case_manager"] as const
const patientCreateOmit = ["collaborators", "case_manager"] as const

export const PatientModal = ({ isOpen, params }: PatientModalProps) => {
    const { t } = useTranslation("patient")
    const router = useRouter()
    const dispatch = useDispatch()
    const currentUser = useCurrentUser()
    const usersInCurrentOrg = useUsersInCurrentOrg()
    const isOverviewEnabled = FeatureFlags.isEnabled("overview")

    const handleUserEmail = useRef<HandleUserEmail<PatientFormState> | null>(null)

    const patientFile = params?.patientFile
    const onSubmit = useCallback(
        async (state: PatientFormState, setErrors: (errors: Dictionary<any>) => void) =>
            handleUserEmail.current(state, async (email) => {
                const collaborators = state.treatment.collaborators.map((collaboratorId) => {
                    const user = usersInCurrentOrg.find((user) => user.user_id === collaboratorId)

                    return {
                        id: collaboratorId,
                        role:
                            user?.role === OrgRole.external_therapist
                                ? CollaboratorRole.external
                                : CollaboratorRole.therapist
                    }
                })
                try {
                    const { user, image_upload_url = null } = patientFile
                        ? await updatePatient(patientFile.id, getPatientData(state, email, patientUpdateOmit))
                        : await createPatient({
                              ...getPatientData(state, email, patientCreateOmit),
                              collaborators,
                              case_manager: state.treatment.case_manager
                          })

                    toast(t(`patientForm.${patientFile ? "updatedSuccessfully" : "createdSuccessfully"}`), {
                        type: "success"
                    })

                    if (image_upload_url)
                        await uploadImageToCloudflare(state.user.profile_image as File, image_upload_url)

                    if (params) {
                        sendMixpanelReport("Patient was updated")
                        dispatch(getCurrentPatient())
                        dispatch(refetchPatientInList(patientFile.id))
                        dispatch(fetchPatientTags())
                        params.onUpdate && params.onUpdate()
                        return true
                    } else {
                        sendMixpanelReport("A new patient was added")
                        dispatch(addPatientToList(user.patient_file.id))
                        dispatch(fetchPatientTags())

                        router.push(`/patient/${user.patient_file.id}/${isOverviewEnabled ? "overview" : "timeline"}`)
                        setTimeout(() => {
                            dispatch(
                                openPatientWhatNextModal({
                                    patientId: user.patient_file.id,
                                    isAfterPatientCreation: true
                                })
                            )
                        }, 200)
                        return true
                    }
                } catch (error) {
                    if ("email" in error) {
                        const existingEmails = error.email as string[]
                        setErrors({
                            contacts: state.user.contacts.map((contact) =>
                                existingEmails.includes(contact.email)
                                    ? { email: t("patientForm.contact_email_exists") }
                                    : undefined
                            )
                        })
                    }
                    console.error(error)
                    throw error
                }
            }),
        [params, t]
    )

    const initialState = useMemo(
        () => (params ? initialPatientUpdateState(patientFile) : initialPatientAddState(currentUser, t)),
        [params]
    )

    return (
        <UserEmailHandler user={patientFile?.user} handlerRef={handleUserEmail}>
            <FormModal
                show={isOpen}
                setShow={() => dispatch(closeModal())}
                promptOnExit
                title={patientFile ? t("updatePatient") : t("addPatient")}
                useStepList={usePatientStepList}
                initialState={initialState}
                onSubmit={onSubmit}
                isEdit={!!patientFile}
            />
        </UserEmailHandler>
    )
}
