import { SVGProps } from "react"
const PercentageIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        viewBox="0 0 24 24"
        {...props}
    >
        <path stroke="none" d="M0 0h24v24H0z" />
        <circle cx={17} cy={17} r={1} />
        <circle cx={7} cy={7} r={1} />
        <path d="M6 18 18 6" />
    </svg>
)
export default PercentageIcon
