import { classNames } from "utils"
import { SVGProps } from "react"

const ChevronLeftIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        strokeWidth={2.5}
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
        className={classNames("transform scale-y-125", props.className)}
    >
        <path d="M0 0h24v24H0z" stroke="none" />
        <path d="m15 6-6 6 6 6" />
    </svg>
)

export default ChevronLeftIcon
