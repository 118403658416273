import { Button, Spinner } from "components/common"
import TopBar from "components/TopBar"
import Link from "next/link"
import { forwardRef, ForwardRefRenderFunction, ReactNode } from "react"
import { TFunction, useTranslation } from "react-i18next"
import { classNames } from "utils"

type SettingsAction = {
    Icon: JSX.Element
    translationKey: string
    href?: string
    onClick?: () => void
    testId?: string
}
type SettingsSectionComponent = ForwardRefRenderFunction<
    HTMLDivElement,
    {
        title: string
        actions?: SettingsAction[]
        loading?: boolean
        children?: ReactNode
        withDivide?: boolean
        wrapperClassName?: string
        topContent?: ReactNode
    }
>

const SettingsSection: SettingsSectionComponent = (
    { title, actions, children, loading, withDivide = true, wrapperClassName, topContent },
    ref
) => {
    const { t } = useTranslation("patient")
    return (
        <div className="relative flex flex-col h-full">
            <TopBar title={t(title)} titleClassName={"text-dark-blue text-2xl font-semibold"}>
                {actions?.length > 0 && (
                    <div className="flex gap-2">
                        {actions.map((action) =>
                            action.href ? (
                                <Link key={action.translationKey} href={action.href}>
                                    <RenderAction {...action} t={t} />
                                </Link>
                            ) : (
                                <RenderAction key={action.translationKey} {...action} t={t} />
                            )
                        )}
                    </div>
                )}
            </TopBar>
            {!loading ? (
                <>
                    {topContent ? <div>{topContent}</div> : null}
                    <div
                        className={classNames(
                            "w-full h-full flex flex-col items-center py-6 px-9 overflow-auto max-h-content gap-16",
                            wrapperClassName
                        )}
                        ref={ref}
                    >
                        {children}
                    </div>
                </>
            ) : (
                <div className="flex items-center justify-center grow" ref={ref}>
                    <Spinner size={10} />
                </div>
            )}
        </div>
    )
}

const RenderAction = ({ Icon, translationKey, onClick, t, testId }: SettingsAction & { t: TFunction }) => {
    return (
        <Button
            theme="primary"
            onClick={onClick}
            className="flex gap-2.5 items-center"
            style={{ padding: ".5rem .8rem" }}
            data-testid={testId}
        >
            {Icon}
            {t(`settingsSection.${translationKey}`)}
        </Button>
    )
}

export default forwardRef(SettingsSection)
