import { Tag } from "@prisma/client"
import { PatientStatus } from "@sequel-care/types"
import { Tag as TagComponent } from "components/common/Tag"
import { RenderFieldProps } from "components/Settings/types"
import { useTranslation } from "react-i18next"
import Skeleton from "react-loading-skeleton"
import { classNames } from "utils"

export const StatusField = ({
    value,
    isSkeleton,
    classes,
    onClick
}: RenderFieldProps & {
    value: PatientStatus
}) => {
    const { t } = useTranslation("patient")

    return (
        <div
            className={classNames(
                value === "current" ? "text-med-green" : "text-secondary-2",
                classes,
                "flex gap-1 items-center"
            )}
        >
            {isSkeleton ? (
                <Skeleton width="9rem" />
            ) : (
                <>
                    <div
                        className={classNames(
                            value === "current" ? "bg-med-green" : "bg-secondary-2",
                            "w-2.5 h-2.5 rounded-full shrink-0"
                        )}
                        onClick={onClick}
                    />
                    {t(`export.patientStatuses.${value}`)}
                </>
            )}
        </div>
    )
}

export const TagsField = ({ value, isSkeleton, classes, onClick }: RenderFieldProps & { value: Tag[] }) => (
    <div className={classNames("flex flex-wrap gap-2 py-5 items-center max-w-full", classes)} onClick={onClick}>
        {isSkeleton ? (
            <Skeleton width="25rem" />
        ) : (
            (value as Tag[])?.map(({ name }, index) => (
                <TagComponent color="gray" className="flex items-center whitespace-nowrap" key={index}>
                    {name}
                </TagComponent>
            ))
        )}
    </div>
)
