import { PencilIcon } from "@heroicons/react/24/solid"
import { EventHandlePolicy } from "@sequel-care/types/Event"
import { Button } from "components/common"
import EditPolicySelector from "components/Patient/EditPolicySelector"
import { ETypographyTypes, Typography } from "components/Typography"
import { FormEventHandler, useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import store, { useDispatch } from "store"
import { pushToLibraryStack } from "store/patient/actions"

const SelectEditPolicy = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation("patient")
    const [policy, setPolicy] = useState<EventHandlePolicy>("all")

    const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
        (e) => {
            e.preventDefault()
            const { patient } = store.getState()
            const currentLibraryPage = patient.contentLibrary.stack.at(-1)
            dispatch(
                pushToLibraryStack({
                    patientId: "preserve",
                    ...currentLibraryPage,
                    view: "config",
                    configProps: {
                        ...currentLibraryPage.configProps,
                        editPolicy: policy
                    }
                })
            )
        },
        [policy]
    )

    return (
        <form data-testid="edit-policy" className="p-8 text-med-blue flex flex-col" onSubmit={onSubmit}>
            <Typography type={ETypographyTypes.MED_BLUE} className="text-xl flex gap-2 items-center pb-4 !font-medium">
                <PencilIcon className="w-6" />
                {t("eventManager.editEvent")}
            </Typography>
            <EditPolicySelector policy={policy} setPolicy={setPolicy} action="edit" />
            <Button theme="primary" className="self-end mt-6">
                {t("common:continue")}
            </Button>
        </form>
    )
}

export default SelectEditPolicy
