import { ComponentProps, FC, Fragment, ReactChild, useRef, useState } from "react"
import { Dialog as RawDialog, Transition } from "@headlessui/react"
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid"
import { Button } from "components/common"
import { InformationCircleIcon } from "@heroicons/react/24/outline"
import { classNames } from "utils"
import { useTranslation } from "react-i18next"
import { TrashIcon, AlertTriangleIcon } from "components/icons"

type DialogType = "warn" | "info" | "delete"
type DialogTypeMap = {
    [key in DialogType]: {
        Icon: typeof ExclamationTriangleIcon
        textClasses: string
        buttonThemes?: { [key in "confirm" | "cancel"]?: ComponentProps<typeof Button>["theme"] }
    }
}

const types: DialogTypeMap = {
    warn: {
        Icon: AlertTriangleIcon,
        textClasses: "text-negative",
        buttonThemes: { confirm: "warn", cancel: "secondary_warn" }
    },
    info: { Icon: InformationCircleIcon, textClasses: "text-dark-blue" },
    delete: { Icon: TrashIcon, textClasses: "text-dark-blue" }
}

type DialogProps = {
    open: boolean
    onClose: () => void
    onDismiss?: () => void
    onConfirm: () => boolean | Promise<boolean>
    title: ReactChild
    buttonText?: ReactChild
    cancelText?: ReactChild
    type: DialogType
    additionalActions?: ReactChild
}

const Dialog: FC<DialogProps> = function Dialog({
    open,
    onClose,
    onConfirm,
    onDismiss,
    title,
    children,
    buttonText,
    cancelText,
    type = "warn",
    additionalActions
}) {
    const { t } = useTranslation("common")
    const cancelButtonRef = useRef(null)
    const [loading, setLoading] = useState(false)
    const { Icon, textClasses, buttonThemes } = types[type]

    const onConfirmClick = async () => {
        if (!onConfirm) return onClose()

        setLoading(true)
        if ((await onConfirm()) !== false) onClose()

        setLoading(false)
    }

    return (
        <Transition appear show={open} as={Fragment}>
            <RawDialog
                as="div"
                className="fixed z-50 inset-0 overflow-y-auto"
                initialFocus={cancelButtonRef}
                onClose={onClose}
            >
                <div className="flex items-end justify-center min-h-screen text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <RawDialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full !w-240">
                            <div className="flex gap-2 p-5">
                                <div
                                    className={classNames(
                                        "mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full  sm:mx-0 sm:h-10 sm:w-10",
                                        textClasses
                                    )}
                                >
                                    <Icon className="w-6 h-6" />
                                </div>
                                <div className="mt-3 ltr:text-left rtl:text-right sm:mt-0 sm:ml-4">
                                    <RawDialog.Title
                                        as="h3"
                                        className={classNames("text-lg leading-6 font-medium w-fit mb-2", textClasses)}
                                    >
                                        {title}
                                    </RawDialog.Title>
                                    {children}
                                </div>
                            </div>
                            <div className="py-5 px-6 border-t border-border sm:flex sm:flex-row-reverse flex gap-2">
                                <Button
                                    data-testid="dialog-submit-btn"
                                    className="min-w-[7rem]"
                                    loading={loading}
                                    theme={buttonThemes?.confirm ?? "primary"}
                                    onClick={onConfirmClick}
                                >
                                    {buttonText || t(type !== "delete" ? "confirm" : "delete")}
                                </Button>
                                <Button
                                    className="min-w-[7rem]"
                                    theme={buttonThemes?.cancel ?? "secondary"}
                                    onClick={onDismiss ?? onClose}
                                    ref={cancelButtonRef}
                                >
                                    {cancelText || t("cancel")}
                                </Button>
                                {additionalActions}
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </RawDialog>
        </Transition>
    )
}

export default Dialog
