import { Middleware, Placement, shift, useFloating } from "@floating-ui/react-dom"
import { Transition } from "@headlessui/react"
import useBoolean from "hooks/useBoolean"
import { PropsWithChildren, ReactChild, useEffect, useRef } from "react"
import { ModalCompatiblePortal } from "./Modal"

type TooltipProps = PropsWithChildren<{
    content: ReactChild
    placement?: Placement
    wrapperClassNames?: string
    delay?: number
    middleware?: Middleware[]
    ["data-testid"]?: string
}>

const Tooltip = ({
    children,
    content,
    wrapperClassNames,
    placement = "left",
    delay = 400,
    middleware = [],
    "data-testid": testId
}: TooltipProps) => {
    const { update, x, y, reference, floating, strategy } = useFloating({
        placement,
        middleware: [shift(), ...middleware]
    })

    const [hovered, setHoveredTrue, setHoveredFalse] = useBoolean()

    useEffect(() => {
        update()
    }, [hovered])

    const displayTimeout = useRef<any>()
    const onMouseEnter = () => {
        displayTimeout.current = setTimeout(setHoveredTrue, delay)
    }
    const onMouseLeave = () => {
        clearTimeout(displayTimeout.current)
        if (hovered) setHoveredFalse()
    }

    return (
        <>
            <div
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                className={wrapperClassNames}
                ref={reference}
                data-testid={testId}
            >
                {children}
            </div>
            <ModalCompatiblePortal>
                <div ref={floating} style={{ position: strategy, top: y ?? "", left: x ?? "" }} className="z-[1000]">
                    <Transition
                        show={hovered}
                        enter="transition duration-200"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        className="bg-gray-700 pointer-events-none text-white px-3 p-2 rounded m-2"
                    >
                        {content}
                    </Transition>
                </div>
            </ModalCompatiblePortal>
        </>
    )
}

export default Tooltip
