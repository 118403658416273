import { Image, View, useTheme } from "@aws-amplify/ui-react"

export const Header = () => {
    const { tokens } = useTheme()
    return (
        <View textAlign="center" padding={tokens.space.large}>
            <Image
                alt="Sequelcare logo"
                src="https://imagedelivery.net/m6RExtdDMTgOxFpHJkwHhA/f0bc4ece-8fce-4686-c111-13a93b83e100/medium"
                style={{ width: "100px" }}
            />
        </View>
    )
}

export const authenticatorFormFields = {
    confirmResetPassword: {
        confirmation_code: {
            label: "We’ve sent a verification code to your email. Please enter it below and set your new password",
            Placeholder: "Enter your verification code"
        }
    }
}
