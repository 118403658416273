import { PlusIcon, UserPlusIcon } from "@heroicons/react/24/outline"
import { OrganizationWithTeam } from "@sequel-care/types"
import { getAllOrganizations } from "api"
import { Toggle } from "components/common"
import CloudImg from "components/common/CloudImg"
import { OrganizationAddModal } from "components/OrganizationAdd"
import ItemList, { AvatarField } from "components/Settings/ItemList"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useCurrentRole } from "store/hooks"
import { openUserModal } from "store/modal/actions"
import SettingsSection from "./SettingsSection"
import { roleField } from "./utils"

const OrganizationList = () => {
    const [loading, setLoading] = useState(true)
    const [organizations, setOrganizations] = useState<OrganizationWithTeam[]>([])
    const [showModal, setShowModal] = useState(false)
    const currentRole = useCurrentRole()

    const actions = [
        {
            Icon: <PlusIcon className="w-4" />,
            translationKey: "newOrganization",
            onClick: () => setShowModal(true)
        }
    ]

    useEffect(() => {
        ;(async () => {
            try {
                setOrganizations(await getAllOrganizations(true))
            } catch (error) {
                console.error(error)
                // Nothing to do here
            } finally {
                setLoading(false)
            }
        })()
    }, [currentRole])

    return (
        <SettingsSection title="allOrganizations" loading={loading} actions={actions}>
            <div className="mb-4 divide-y divide-gray-200 rounded w-150">
                {organizations.map((organization) => (
                    <RenderOrganization key={organization.id} {...organization} />
                ))}
            </div>
            <OrganizationAddModal show={showModal} setShow={setShowModal} />
        </SettingsSection>
    )
}

const RenderOrganization = ({ id, logo_url, name, users }: OrganizationWithTeam) => {
    const dispatch = useDispatch()
    const { t } = useTranslation("common")

    return (
        <Toggle>
            <div>
                <div className="flex items-stretch py-4 gap-2 group">
                    <Toggle.Chevron />
                    <div>{logo_url && <CloudImg variant={"large"} width="20" height="20" src={logo_url} />}</div>
                    <div>{name}</div>
                    <div className="ltr:ml-auto rtl:mr-auto">
                        <UserPlusIcon
                            onClick={() => dispatch(openUserModal({ organization_id: id }))}
                            className="w-5 cursor-pointer hover:scale-110 text-gray-400 hover:text-dark-blue transition"
                        />
                    </div>
                </div>
                <Toggle.Content className="pb-5 px-8" transitionDuration={users.length <= 6 ? 300 : users.length * 30}>
                    {users.length ? (
                        <div className="grid" style={{ gridTemplateColumns: "2fr 1fr 0fr" }}>
                            <ItemList
                                items={users}
                                fields={[
                                    {
                                        key: "user",
                                        RenderField: AvatarField
                                    },
                                    roleField
                                ]}
                            />
                        </div>
                    ) : (
                        <div className="text-gray-400 italic">{t("noUsers")}</div>
                    )}
                </Toggle.Content>
            </div>
        </Toggle>
    )
}

export default OrganizationList
