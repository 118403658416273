import { autoUpdate, flip, offset, useFloating } from "@floating-ui/react-dom"
import { PlusSmallIcon } from "@heroicons/react/24/solid"
import { ModalCompatiblePortal } from "components/common"
import Search from "components/Search"
import { RenderLabel } from "forms/RenderLabel"
import { errorClassNames } from "forms/utils"
import useResizeObserver from "hooks/useResizeObserver"
import { noop, partition } from "lodash"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { classNames } from "utils"
import { SelectButton, TagsButton } from "./ButtonComponents"
import RenderList, { ItemComponent } from "./RenderList"
import { BasicItem, GenericValue, ReferenceEl, SuperSelectProps } from "./types"
import { useSearchForItem } from "./utils"

function SuperSelect<ValueType extends GenericValue, ItemType extends BasicItem = BasicItem>({
    id,
    items,
    value,
    setValue,
    contentWidth = "w-100",
    placeholder,
    usePlaceholderAsAll,
    style = "classic",
    classList,
    label,
    description,
    error,
    info,
    isRequired,
    withSearch = true,
    onAddNew,
    menuOffset = -1,
    RenderItem,
    RenderButton = RenderItem,
    withSelectionIcon,
    buttonRef,
    disabled,
    readOnly,
    keepOriginalSorting = false
}: SuperSelectProps<ValueType, ItemType>) {
    disabled = readOnly || disabled
    const allItem = useMemo(
        () => (placeholder && usePlaceholderAsAll ? ({ id: "all", label: placeholder } as ItemType) : null),
        [placeholder, usePlaceholderAsAll]
    )
    const canSelectMany = Array.isArray(value)

    const searchForItems = useSearchForItem(items)
    const selected = useMemo(
        () =>
            Array.isArray(value)
                ? value.map(searchForItems)
                : ![undefined, null].includes(value)
                ? [searchForItems(value)]
                : [],
        [value, canSelectMany, searchForItems]
    )

    const { t } = useTranslation("common")
    const [query, setQuery] = useState("")
    const [showList, setShowList] = useState(false)

    const sortedItems = useMemo(() => {
        // We don't add "selected" to the dependencies here, as we only want the order to change when the list opens and closes
        if (
            keepOriginalSorting ||
            !selected.length ||
            selected.some((item) => !item) ||
            items.every(({ children }) => Boolean(children))
        )
            return items

        const selectedIds = selected.map(({ id }) => id)
        const [selectedItems, remainingItems] = partition(items, (item) => selectedIds.includes(item.id))
        return selectedItems.concat(remainingItems)
    }, [items, showList])

    const { x, y, floating, reference, strategy, refs, update } = useFloating<ReferenceEl>({
        placement: "bottom-start",
        middleware: [flip(), offset(menuOffset)]
    })

    const setValueToEmpty = () => setValue((canSelectMany ? [] : null) as ValueType)

    const toggleSelection = ({ id }: ItemType) => {
        if (Array.isArray(value)) {
            const newValue = value.filter((itemId) => itemId !== id)
            if (value.length === newValue.length) newValue.push(id)
            if (!newValue.length) setValueToEmpty()
            else setValue(newValue as ValueType)
        } else {
            const newValue = id === value ? null : (id as ValueType)
            if (style === "classic" && !allItem && [undefined, null].includes(newValue)) return
            setValue(newValue)
        }
        if (!canSelectMany) setShowList(false)
    }

    useEffect(() => {
        if (showList) {
            const closeList = (e: MouseEvent) => {
                const target = e.target as Element
                if (
                    !target.closest(`[data-floating-menu]`) &&
                    !target.closest(`[data-floating-button]`) &&
                    (!target.closest(`#superSelectWrapper-${id}`) || target.closest("label"))
                ) {
                    setShowList(false)
                }
            }
            window.addEventListener("mouseup", closeList)

            const autoUpdateCleanup =
                refs.reference.current && refs.floating.current
                    ? autoUpdate(refs.reference.current, refs.floating.current, update)
                    : noop

            return () => {
                window.removeEventListener("mouseup", closeList)
                autoUpdateCleanup()
            }
        } else {
            setQuery("")
        }
    }, [showList])

    const toggleList = () => {
        if (!disabled) setShowList(!showList)
    }

    const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
    const resizeRef = useResizeObserver<ReferenceEl>(setDimensions)
    if (style === "classic" && refs?.reference?.current) resizeRef.current = refs?.reference?.current

    useEffect(() => {
        if (buttonRef) reference(buttonRef.current)
    }, [buttonRef?.current])

    return (
        <div id={`superSelectWrapper-${id}`} className={classList?.wrapper} tabIndex={0}>
            <RenderLabel
                {...{ id, isRequired, classList, info, description }}
                label={typeof label === "string" && label.includes(".") ? t(label) : label}
                ref={style === "tags" && !buttonRef ? reference : undefined}
            />
            <div className={classNames(classList?.inputWrapper, "relative")}>
                {style === "tags" && (
                    <TagsButton
                        showAddButton={canSelectMany || !value}
                        reference={!label && !buttonRef ? reference : undefined}
                        {...{ id, selected, toggleSelection, toggleList, RenderButton, disabled: disabled }}
                    />
                )}
                {style === "classic" && (
                    <SelectButton
                        isOpen={showList}
                        selected={selected || [allItem]}
                        buttonClassName={classList?.input}
                        {...{
                            id,
                            reference,
                            placeholder,
                            contentWidth,
                            toggleList,
                            RenderButton,
                            usePlaceholderAsAll,
                            disabled
                        }}
                    />
                )}
                {error && <span className={classNames(classList.error, errorClassNames)}>{error}</span>}
            </div>

            {showList && (
                <ModalCompatiblePortal>
                    <div
                        className={classNames(
                            "bg-white z-100 border border-border-blue rounded text-sm",
                            style === "tags" && contentWidth
                        )}
                        ref={floating}
                        style={{
                            top: y ?? 0,
                            left: x ?? 0,
                            position: strategy,
                            width: style === "classic" ? dimensions.width + "px" : undefined
                        }}
                        data-floating-menu
                    >
                        {withSearch && (
                            <Search
                                search={query}
                                setSearch={setQuery}
                                autoFocus
                                wrapperClassname="w-full border-b border-border-blue"
                            />
                        )}
                        <div className="py-1 max-h-[14rem] overflow-y-auto overflow-x-hidden w-full">
                            {onAddNew && (!withSearch || query.length > 0) && (
                                <ItemComponent
                                    item={{
                                        id: query,
                                        label: null
                                    }}
                                    RenderItem={() => (
                                        <div className="text-med-blue flex gap-2">
                                            <PlusSmallIcon className="w-4" />
                                            <div>
                                                <span className={classNames("font-medium", withSearch && "underline")}>
                                                    {t("addNew")}
                                                </span>
                                                {withSearch && `: ${query}`}
                                            </div>
                                        </div>
                                    )}
                                    onItemClick={() => {
                                        onAddNew(query)
                                        setShowList(false)
                                    }}
                                    selected={[]}
                                    style={style}
                                    isFiltered={false}
                                    isFirstLevel
                                />
                            )}
                            {allItem && !query && (
                                <ItemComponent
                                    item={allItem}
                                    onItemClick={() => {
                                        setValueToEmpty()
                                        setShowList(false)
                                    }}
                                    selected={!value || (Array.isArray(value) && !value.length) ? [allItem] : []}
                                    isFiltered={false}
                                    isFirstLevel
                                    {...{ withSelectionIcon, canSelectMany, style }}
                                />
                            )}
                            <RenderList
                                onItemClick={toggleSelection}
                                divideFirst={placeholder && usePlaceholderAsAll}
                                items={sortedItems}
                                query={withSearch ? query : undefined}
                                closeMenu={toggleList}
                                {...{ selected, style, RenderItem, withSelectionIcon, canSelectMany, onAddNew }}
                            />
                        </div>
                    </div>
                </ModalCompatiblePortal>
            )}
        </div>
    )
}

export default SuperSelect
