import { RelationType } from "@prisma/client"
import { PatientFormContact } from "@sequel-care/types/Patient"
import { defineField, initialValuesFromFields, PhoneField } from "forms"
import SuperSelect from "forms/SuperSelect"
import { getItemsFromEnum } from "forms/SuperSelect/utils"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { noopTFunction } from "utils"

const contactClassNames = {
    input: "border-none placeholder:text-text-blue/50 text-base text-dark-blue focus:ring-0 focus:outline-0 focus:!border focus:border-dark-blue w-full px-0 outline-0"
}
export const getContactFields = (t = noopTFunction, { emailDisabled }: { emailDisabled?: boolean } = {}) =>
    [
        defineField({
            id: "email",
            placeholder: t("common:email_short"),
            validations: {
                required: { value: true, message: t("user:userForm.validationMessages.emailRequired") },
                custom: {
                    isValid: (value: string) => /^\S+@\S+\.\S+$/.test(value),
                    message: t("user:userForm.validationMessages.emailInvalid")
                }
            },
            disabled: emailDisabled,
            classList: contactClassNames
        }),
        defineField({
            id: "relation_type",
            Component: SuperSelect<RelationType>,
            initialValue: "parent",
            withSearch: false,
            items: getItemsFromEnum(RelationType, (relation) => t(`common:relationType.${relation}`)),
            classList: contactClassNames
        }),
        defineField({
            id: "first_name",
            placeholder: t("common:firstname"),
            validations: { required: { value: true, message: t("patientForm.contactValidations.firstName") } },
            classList: contactClassNames,
            customDataTestId: "input-contact-first_name"
        }),
        defineField({
            id: "last_name",
            placeholder: t("common:lastname"),
            validations: { required: { value: true, message: t("patientForm.contactValidations.lastName") } },
            classList: contactClassNames,
            customDataTestId: "input-contact-last-name"
        }),
        defineField({
            id: "phone",
            placeholder: t("common:phone"),
            info: t("user:userForm.phoneInfo") as string,
            Component: PhoneField,
            validations: {
                custom: {
                    isValid: (value: string) => value.length && value.replace(/\s/gm, "").length > 2,
                    message: t("user:userForm.validationMessages.phoneInvalid")
                }
            },
            classList: contactClassNames
        })
    ] as const

export type ContactFields = ReturnType<typeof getContactFields>

export const useContactFields = (contact: PatientFormContact) => {
    const { t } = useTranslation()
    return useMemo(() => getContactFields(t, { emailDisabled: Boolean(contact?.id) }), [t, contact?.id])
}

export const getInitialContactValue = () => {
    const values = initialValuesFromFields(getContactFields()) as PatientFormContact
    values.id = 0
    return values
}

export const contactIsNotEmpty = (contact: PatientFormContact) => {
    return Object.entries(contact).some(([key, value]) => key !== "relation_type" && value)
}
