import { toCalendarDate } from "@internationalized/date"
import { CreateUserPayload } from "@sequel-care/types"
import { createUser, updateUser } from "api"
import { FormModal } from "forms/FormWithSteps"
import { pick } from "lodash"
import { useCallback, useMemo, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "store"
import { addUserInCurrentOrg, updateUserInCurrentOrg } from "store/global/actions"
import { closeModal } from "store/modal/actions"
import { CompleteUserInOrg } from "types/SchemaExtensions"
import { uploadImageToCloudflare } from "utils"
import { dateToCalendarDate } from "utils/dates"
import { sendMixpanelReport } from "utils/mixpanel"
import { initialState, UserFormState, useStepList } from "."
import CompletedState from "./CompletedState"
import { HandleUserEmail, UserEmailHandler } from "./UserEmailHandler"
import { isFile } from "./utils"

export type UserModalProps = {
    isOpen: boolean
    params?: CompleteUserInOrg<string>
}

type CompletedStateProps = ReturnType<typeof initialState> & { onClose: () => void; completed: boolean }

export const UserModal = ({ isOpen, params }: UserModalProps) => {
    const { t } = useTranslation("user")
    const dispatch = useDispatch()

    const existingUser = params?.user ?? null
    const orgId = params?.organization_id
    const handleUserEmail = useRef<HandleUserEmail<UserFormState> | null>(null)

    const onSubmit = useCallback(
        async (state: UserFormState) =>
            handleUserEmail.current(state, async (email) => {
                const { profile_image, date_of_birth } = state.user
                let response
                const payload: CreateUserPayload = {
                    user: {
                        ...state.user,
                        role: state.team_member.role === "caregiver" ? "therapist" : "admin",
                        date_of_birth: date_of_birth ? toCalendarDate(date_of_birth).toString() : undefined,
                        email,
                        profile_image: isFile(profile_image)
                            ? profile_image.name
                            : profile_image === null
                            ? null
                            : undefined
                    },
                    team_member: state.team_member
                }
                if (existingUser) {
                    response = await updateUser(existingUser.id, payload)
                } else {
                    payload.organization_id = orgId
                    response = await createUser(payload)
                    if (response) sendMixpanelReport("Team Member was added", { member_role: payload.user.role })
                }

                if (response?.image_upload_url)
                    await uploadImageToCloudflare(state.user.profile_image as File, response.image_upload_url)

                if (existingUser) {
                    dispatch(updateUserInCurrentOrg({ ...response.user, has_auth: Boolean(response.user.cognito_uid) }))
                } else dispatch(addUserInCurrentOrg(response.user))

                return true
            }),
        [existingUser, orgId]
    )

    const RenderCompletedState = useCallback(
        ({ user, onClose, completed }: CompletedStateProps) => {
            const isUpdateAction = Boolean(existingUser)
            return (
                <CompletedState
                    title={isUpdateAction ? t("updatedSuccessfully") : t("createdSuccessfully")}
                    {...{ user, onClose, completed, isUpdateAction }}
                />
            )
        },
        [existingUser]
    )

    const getInitialState = useMemo((): (() => UserFormState) => {
        return existingUser
            ? () => ({
                  user: {
                      ...existingUser,
                      date_of_birth: existingUser.date_of_birth
                          ? dateToCalendarDate(existingUser.date_of_birth)
                          : undefined
                  },
                  team_member: {
                      ...pick(params, ["license", "specialization", "profession", "case_load", "role"] as const)
                  }
              })
            : initialState
    }, [existingUser])

    return (
        <UserEmailHandler user={params?.user} handlerRef={handleUserEmail}>
            <FormModal
                title={existingUser ? t("userForm.updateUser") : t("userForm.addUser")}
                CompletedState={RenderCompletedState}
                promptOnExit
                show={isOpen}
                setShow={() => dispatch(closeModal())}
                initialState={getInitialState}
                isEdit={!!existingUser}
                {...{ onSubmit, useStepList }}
            />
        </UserEmailHandler>
    )
}
