import { SVGProps } from "react"

const MessageIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width=".85em"
        height=".85em"
        viewBox="0 0 31 27"
        strokeWidth={2}
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
    >
        {/* <path d="M0 0h24v24H0z" stroke="none" /> */}
        <path d="M1 26L3.09282 19.9143C1.28405 17.3212 0.629722 14.2505 1.25149 11.273C1.87326 8.29549 3.72881 5.61384 6.47314 3.72664C9.21748 1.83944 12.6638 0.875122 16.1715 1.01298C19.6791 1.15083 23.0092 2.38148 25.5428 4.4761C28.0763 6.57073 29.6408 9.38679 29.9454 12.4007C30.2499 15.4146 29.2739 18.4213 27.1986 20.8617C25.1234 23.302 22.0902 25.0101 18.663 25.6682C15.2359 26.3263 11.6479 25.8897 8.56633 24.4396L1 26" />
    </svg>
)

export default MessageIcon
