import { ActivityData } from "@sequel-care/types/Patient"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import FeedSidebar from "sidebars/Feed"
import { useOverviewActivity, usePatientLoading } from "store/hooks"
import { getOverviewData } from "store/patient/actions"
import { pushEventSidebarToStack, pushToSidebarStack } from "store/sidebars/actions"
import { eventTypeIconMap, getPatientIdFromPath } from "utils"
import { OverviewCard } from "./utils"
import FeedList from "./FeedList"

const ActivityCard = () => {
    const { t } = useTranslation("patient")
    const activity = useOverviewActivity()
    const overviewLoading = usePatientLoading("overview")
    const dispatch = useDispatch()

    return (
        <OverviewCard
            title={<span className="text-xl text-dark-blue">{t("overview.activity")}</span>}
            headerButton={
                <button
                    className="text-med-blue hover:underline cursor-pointer text-xs"
                    onClick={() => dispatch(pushToSidebarStack({ type: "activity", isOpen: true }))}
                >
                    {t("overview.viewMore")}
                </button>
            }
            wrapperClassNames={"h-80"}
        >
            <ActivityFeed acitivity={activity.slice(0, 4)} loading={overviewLoading} />
        </OverviewCard>
    )
}

export default ActivityCard

const ActivityFeed = ({ acitivity, loading }: { acitivity: ActivityData[]; loading: boolean }) => {
    const { t } = useTranslation("patient")
    const dispatch = useDispatch()
    const useItemOnClick = ({ status, id, date_on_timeline, type }: ActivityData) =>
        status === "completed" && !["reflection", "tracker"].includes(type)
            ? () =>
                  dispatch(
                      pushEventSidebarToStack({
                          id,
                          patient_id: getPatientIdFromPath(),
                          date_on_timeline: new Date(date_on_timeline),
                          context: { context: "activity", time: new Date() }
                      })
                  )
            : undefined
    const useText = (item: ActivityData) => item.text

    return (
        <FeedList
            itemList={acitivity}
            useItemOnClick={useItemOnClick}
            useItemText={useText}
            getIcon={({ type }) => {
                const Icon = eventTypeIconMap[type]
                return <Icon className="h-5 w-5 text-dark-blue translate-x-0.5" aria-hidden="true" />
            }}
            getTextEndNode={({ status }) =>
                status === "skipped" ? (
                    <span className="text-xs text-red-500 bg-red-50 p-1 text-center"> {t("skipped")}</span>
                ) : null
            }
            emptyState={{
                title: t("overview.emptyNotifications"),
                imageId: "no-notifications"
            }}
            loading={loading}
        />
    )
}

export const ActivitySidebar = () => {
    const { t } = useTranslation("patient")
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const acitivity = useOverviewActivity()

    useEffect(() => {
        if (acitivity.length < 5) {
            const fetchAll = async () => {
                setLoading(true)
                await dispatch(getOverviewData({ activity: {} }))
                setLoading(false)
            }
            fetchAll()
        }
    }, [])

    return (
        <FeedSidebar title={t("overview.activity")}>
            <ActivityFeed acitivity={acitivity} loading={loading} />
        </FeedSidebar>
    )
}
