import { Reducer } from "redux"
import * as types from "./types"
import { NotificationsState } from "types/Redux"
import { GetNotificationsPayload, GetInitialNotificationsPayload, UnreadNotifications } from "types/Misc"

const initialState: NotificationsState = {
    loading: false,
    count: {
        all: 0,
        comments: 0,
        alerts: 0
    },
    notifications: {
        all: [],
        comments: [],
        alerts: []
    },
    unread: {
        all: [],
        comments: [],
        alerts: []
    }
}

const notificationsReducer: Reducer<NotificationsState> = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.GET_PORTAL_NOTIFICATIONS: {
            const { category } = payload as GetNotificationsPayload
            let notifications = { ...state.notifications }
            let unread = { ...state.unread }
            notifications[category] = notifications[category].concat(payload.notifications)

            unread[category] = []
            notifications[category].forEach((notification) => {
                if (!notification.read) unread[category].push(notification.id)
            })
            return { ...state, notifications, unread }
        }
        case types.GET_PORTAL_NOTIFICATIONS_INITIAL: {
            const { notifications, count, unread } = payload as GetInitialNotificationsPayload

            return { ...state, notifications, count, unread }
        }
        case types.MARK_AS_READ: {
            const { readIds } = payload as { readIds: number[] }
            const unread = { ...state.unread }
            const notifications = { ...state.notifications }

            for (const k in unread) {
                const key = k as keyof UnreadNotifications
                unread[key] = unread[key].filter((id) => !readIds.includes(id))
                notifications[key] = notifications[key].map((notification) =>
                    readIds.includes(notification.id) ? { ...notification, read: true } : notification
                )
            }

            return { ...state, unread, notifications }
        }
        case types.SET_NOTIFICATIONS_LOADING: {
            return { ...state, loading: payload }
        }
        default:
            return state
    }
}

export default notificationsReducer
