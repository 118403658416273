import { BasicUser, TeamUser } from "@sequel-care/types"
import { PatientWithDiagnoses } from "@sequel-care/types/Patient"
import { charToColor } from "@sequel-care/utils"
import { Spinner } from "components/common"
import { CSSProperties, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { classNames } from "utils"
import { ConversationStore, MessageDisplayItem, SequelConversation } from "utils/conversations"
import { getDateAtHour0, localeFormat } from "utils/dates"
import DeleteIcon from "../../../components/icons/DeleteIcon"
import { isMessageDeleted } from "../../../utils/conversations/types"
import { Message } from "@twilio/conversations"
import { useUsersInCurrentOrg } from "store/hooks"

const colors = [
    "text-red-600",
    "text-orange-600",
    "text-yellow-600",
    "text-green-600",
    "text-teal-600",
    "text-blue-600",
    "text-indigo-600",
    "text-purple-600",
    "text-pink-600"
]

type RenderItemProps = {
    index: number
    style: CSSProperties
    conversation: SequelConversation
    isGroupChat: boolean
    currentUser: TeamUser
    currentPatient: PatientWithDiagnoses<string, string>
    onDelete: (item: MessageDisplayItem) => void
    className?: string
}
const RenderItem = ({
    index,
    style,
    conversation,
    isGroupChat,
    currentUser,
    currentPatient,
    onDelete,
    className
}: RenderItemProps) => {
    const { i18n } = useTranslation()
    const item = conversation.messageDisplayList[index]
    const [isHovered, setIsHovered] = useState(false)
    const [imageUrl, setImageUrl] = useState(null)
    const usersInCurrentOrg = useUsersInCurrentOrg()

    useEffect(() => {
        const predicate =
            item instanceof Message &&
            item?.attachedMedia?.length > 0 &&
            item?.attachedMedia?.[0]?.contentType?.startsWith("image")
        if (!predicate) return

        item?.attachedMedia?.[0]?.getContentTemporaryUrl().then((fileUrl) => {
            setImageUrl(fileUrl)
        })
    }, [item])

    if (!item)
        return (
            <div className="h-16 flex items-center justify-center text-gray-500" style={style}>
                <Spinner size={7} />
            </div>
        )

    if ("header" in item) {
        return (
            <div style={style}>
                <div
                    className={classNames(
                        index > 0 ? "mt-6" : "mt-3",
                        "rounded-full bg-bg shadow-sm border border-border-blue w-52 text-center text-text-blue self-center py-1.5 mb-3 mx-auto"
                    )}
                >
                    {localeFormat(
                        getDateAtHour0(item.date),
                        i18n.language !== "he" ? "E, d LLL" : "EEEE, d 'ב'LLL",
                        i18n
                    )}
                </div>
            </div>
        )
    }

    if (isMessageDeleted(item)) return <></>

    const authorUserId = ConversationStore.getUserIdFromIdentity(item.author)
    const currentUserIsAuthor = authorUserId === currentUser?.id

    const { author, role } = ((): { author?: BasicUser; role?: string } => {
        if (currentUserIsAuthor || !isGroupChat) return {}
        if (currentPatient?.user.id === authorUserId)
            return { author: currentPatient?.user, role: i18n.t(`common:roles.patient`) }
        const contact = currentPatient?.contacts.find((user) => authorUserId === user.id)
        if (contact) return { author: contact, role: i18n.t(`common:relationType.` + contact.relation_type) }
        const teamUser = usersInCurrentOrg.find(({ user_id }) => authorUserId === user_id)

        return { author: teamUser?.user, role: i18n.t(`common:roles.` + teamUser?.user.role) }
    })()

    return (
        <div
            className={classNames("flex flex-col gap-2 px-6", className)}
            style={style}
            onMouseEnter={() => setIsHovered(currentUserIsAuthor)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {isHovered && (
                <div
                    onClick={() => onDelete(item)}
                    className={classNames(
                        "rounded-2xl w-7 h-7 absolute ",
                        currentUserIsAuthor ? "self-end bg-bg " : "self-start bg-med-blue text-white"
                    )}
                >
                    <DeleteIcon className="w-5 h-7 m-auto" />
                </div>
            )}
            <div
                className={classNames(
                    currentUserIsAuthor
                        ? "self-end bg-med-blue text-white rounded-br-md"
                        : "self-start bg-bg rounded-bl-md",
                    "py-2.5 px-4 rounded-2xl mt-3"
                )}
            >
                {isGroupChat && author && (
                    <div className={charToColor(author.first_name, colors)}>
                        {author.first_name} {`(${role})`}
                    </div>
                )}
                {
                    // Using <img /> here, because media URL domain is dynamic,
                    // and can't be put in [next.config.js] at runtime.
                    imageUrl && <img src={imageUrl} className="w-40 h-40" alt={"media"} />
                }
                {item.body && item.body.split("\n").map((line, index) => <p key={index}>{line}</p>)}
            </div>
            <div
                className={classNames(
                    currentUserIsAuthor ? "self-end" : "self-start",
                    "text-sm text-secondary font-medium"
                )}
            >
                {localeFormat(item.dateCreated, "HH:mm" + (i18n.language !== "he" ? " aa" : ""), i18n)}
            </div>
        </div>
    )
}

export default RenderItem
