import { BasicGoal } from "@sequel-care/types"
import { upperFirst } from "lodash"
import Image from "next/image"
import { useMemo } from "react"
import { getPatientIdFromPath } from "utils"
import { FormControlProps } from "../../../../forms"
import SuperSelect from "../../../../forms/SuperSelect"
import { useAllGoals } from "../../../../store/hooks"
import { goalTypeToEventType } from "../utils"
import { useTranslation } from "react-i18next"

const RenderItem = ({ item }: { item: BasicGoal }) => <GoalSelectItem goal={item} />

export function GoalField({ state, value, getAdjacentFieldValue, ...props }: FormControlProps<number>) {
    const goals = useAllGoals()
    const patientId = getPatientIdFromPath()
    const { t } = useTranslation()
    const eventType = getAdjacentFieldValue("type")

    const availableGoals = useMemo(
        () =>
            goals
                ?.filter(({ id, is_achieved, is_deleted, patient_id, type }) => {
                    const allowedEventTypes = goalTypeToEventType[type]
                    const typeCheck = allowedEventTypes
                        ? allowedEventTypes.some((eType) => eType === eventType)
                        : patient_id === patientId
                    return (
                        !is_achieved &&
                        (patient_id === null || patient_id === patientId) &&
                        (!is_deleted || id === value) &&
                        typeCheck
                    )
                })
                ?.map((g) =>
                    g.is_deleted ? { ...g, label: t(`patient:goalsDashboard.deleted`, { name: g.label }) } : g
                ) ?? [],

        [goals, eventType]
    )

    return <SuperSelect items={availableGoals} value={value} RenderItem={RenderItem} {...props} />
}

const GoalSelectItem = ({ goal }: { goal: BasicGoal }) => {
    const size = 40
    return (
        <div className="flex flex-row items-center">
            <Image
                src={`/images/goals/${goal.img_id}.png`}
                width={size}
                height={size}
                layout={"fixed"}
                alt={goal.label}
            />

            <span className="mx-2">{upperFirst(goal.label)}</span>
        </div>
    )
}
