import Settings from "components/icons/Settings"
import Link from "next/link"
import { useTranslation } from "react-i18next"

const UserSettingsSection = () => {
    const { t } = useTranslation("user")
    const route = "/settings/user"
    return (
        <Link href={route} key={route}>
            <div className="flex flex-row items-center px-6 py-4 gap-3 hover:bg-white/10 cursor-pointer">
                <Settings className="text-xl" /> {t("userSettings.title")}
            </div>
        </Link>
    )
}

export default UserSettingsSection
