import { AppointmentStatus, EventStatus } from "@prisma/client"
import {
    BasicPermission,
    CollaboratorWithPermissions,
    CommentData,
    CommentUpdateData,
    EventUpdateData,
    PatientFileWithQuestionnaires,
    RawEventCreateInput
} from "@sequel-care/types"
import {
    EventComment,
    EventsByDate,
    ExistingEventActionPayload,
    FlatEvent,
    GetEventsPayload,
    TrackerEventWithResponse
} from "@sequel-care/types/Event"
import { GoalCreateRequestPayload, GoalUpdateRequestPayload } from "@sequel-care/types/Goals"
import { TrackerManagerData } from "@sequel-care/types/Patient"
import { PatientOrOrgId, TrackerQuestionInput } from "@sequel-care/types/Tracker"
import {
    addEvent as doEventAdd,
    addEvents as doAddEvents,
    addPatientCollaborator,
    createComment as doCreateComment,
    createCustomMeasure as doCreateCustomMeasure,
    deleteComment as doDeleteComment,
    deleteEvent as doEventDelete,
    fetchOverviewData,
    readPatientNotifications as doReadPatientNotifications,
    getEvents,
    getEventsForGantt as doGetEventsForGantt,
    getEventsInstance,
    getPatient,
    getPatientActivity,
    getPatientFeatures,
    getPatientGoals,
    getPatientQuestionnaireMetrics,
    getQuestionnaireEvents,
    getTrackerEvents,
    getTrackerQuestions as doGetTrackerQuestions,
    getTrackerResults as doGetTrackerResults,
    getTrackerSettings,
    removeCollaboratorPermission,
    setAppointmentStatus as doSetAppointmentStatus,
    toggleFamilyAuth as doToggleFamilyAuth,
    updateCollaboratorPermission,
    updateComment as doUpdateComment,
    updateEvent as doEventUpdate,
    updateEventStatus as doUpdateEventStatus,
    updateTracker,
    existingEventAction
} from "api"
import { addDays, differenceInCalendarDays, subDays } from "date-fns"
import { BasicItem } from "forms/SuperSelect/types"
import Router from "next/router"
import store from "store"
import {
    addGoalToList,
    getUserRecentlyAddedEvents,
    setGlobalLoading,
    setGlobalTracker,
    setOrganizationTrackerManager,
    setUpcomingAppointment,
    updateCurrentSidebar,
    updateGoalInList
} from "store/global/actions"
import { SetRequired } from "type-fest"
import { EventDeleteDialogData, PatientDashboardRanges, PatientPage } from "types/Misc"
import { AppAction, ContentLibraryUpdate, DashboardGraphType, GraphState, PatientState } from "types/Redux"
import { createFetchRange, createTimelineRange, DateString } from "utils/dates"
import { EventUpdateTracker } from "utils/EventUpdateTracker"
import { getRangeEdges } from "utils/graphs"
import { createGoalApiCall, deleteGoalApiCall, updateGoalAchieveStateApiCall, updateGoalApiCall } from "../../api/Goals"
import * as types from "./types"
import * as globalTypes from "store/global/types"
import { Dictionary, pick } from "lodash"
import { createByWeekRange } from "components/PatientOverview/utils"

export const getPatientIdFromRouter = () => parseInt(Router.query.patientId as string)

export const clearLastFetchData = () => ({ type: types.CLEAR_LAST_FETCH_DATA })
export const setCurrentPatient = (patient: PatientFileWithQuestionnaires<string, string>) => ({
    type: types.SET_CURRENT_PATIENT,
    payload: patient
})
export const setCurrentCollaborator = (collaborator: CollaboratorWithPermissions) => ({
    type: types.SET_CURRENT_COLLABORATOR,
    payload: collaborator
})
export const setComments = (comments: GetEventsPayload<string>["comments"]) => ({
    type: types.SET_COMMENTS,
    payload: comments
})
export const setCurrentPatientFeatures = (features: { [key: string]: boolean }) => ({
    type: types.SET_CURRENT_PATIENT_FEATURES,
    payload: features
})

export const clearCurrentPatient = { type: types.CLEAR_CURRENT_PATIENT }

export const getCurrentPatient =
    (patient_id?: number): AppAction =>
    async (dispatch, getState) => {
        try {
            dispatch(setGlobalLoading("patient", true))
            const patientId = patient_id ?? getPatientIdFromRouter()
            const [patient, features] = await Promise.all([getPatient(patientId), getPatientFeatures(patientId)])

            const currentUser = getState().global.user
            const currentCollaboratorIndex = patient.collaborators.findIndex(({ user, permissions }) => {
                return user.id === currentUser.id && permissions.length
            })
            const currentCollaborator = patient.collaborators[currentCollaboratorIndex]
            if (currentCollaborator) {
                patient.collaborators.splice(currentCollaboratorIndex, 1)
                patient.collaborators.unshift(currentCollaborator)
            }

            dispatch(setCurrentPatient(patient))
            dispatch(setCurrentCollaborator(currentCollaborator))
            dispatch(setCurrentPatientFeatures(features))
        } catch (error) {
            throw error
        } finally {
            dispatch(setGlobalLoading("patient", false))
        }
    }

export const setPatientLoading = (key: PatientPage, value: boolean) => ({
    type: types.SET_PATIENT_LOADING,
    payload: { key, value }
})
export const setEventsByDate = (data: GetEventsPayload<string>, fetchData?: PatientState["lastEventFetchData"]) => ({
    type: types.SET_EVENTS_BY_DATE,
    payload: { ...data, fetchData }
})
export const setQuestionnaireEvents = (
    { comments, events }: GetEventsPayload<string>,
    lastDashboardFetchData: PatientState["dashboard"]["lastDashboardFetchData"]
) => updatePatientDashboard({ questionnaireEvents: events, lastDashboardFetchData })

export const getDashboardData =
    (range: PatientDashboardRanges, graphKey: DashboardGraphType): AppAction =>
    async (dispatch) => {
        const page = graphKey === "overview" ? "overview" : "questionnaires"
        try {
            dispatch(setPatientLoading(page, true))

            await Promise.all([
                dispatch(getDashboardCards(range, graphKey)),
                dispatch(getEventsByDate({ range, page }))
            ])
        } catch (error) {
            console.error(error)
        } finally {
            dispatch(setPatientLoading(page, false))
        }
    }

export const getDashboardCards =
    (range: PatientDashboardRanges, graphKey: DashboardGraphType): AppAction =>
    async (dispatch, getState) => {
        const cardsInState = getState().patient.dashboard.cards
        if (!cardsInState[graphKey][range]?.length) {
            const cards =
                graphKey === "overview" ? await getPatientActivity(range) : await getPatientQuestionnaireMetrics(range)

            dispatch({ type: types.SET_DASHBOARD_CARDS, payload: { cards, range, graphKey } })
        }
    }

export const getGoalsData =
    (forceFetch?: boolean): AppAction<boolean> =>
    async (dispatch, getState) => {
        try {
            dispatch(setPatientLoading("goals", true))
            const cached = getState().patient.goals
            if (forceFetch || !cached) {
                const data = await getPatientGoals()
                dispatch({ type: types.SET_GOALS_DATA, payload: data })
            }
        } catch (error) {
            console.error(error)
            return false
        } finally {
            dispatch(setPatientLoading("goals", false))
            return true
        }
    }

export const updateGoalsAchieveStatus =
    (goal_id: number, is_achieved: boolean): AppAction<boolean> =>
    async (dispatch) => {
        try {
            const call = await updateGoalAchieveStateApiCall(goal_id, is_achieved)
            if (call.success) {
                const payload = {
                    id: goal_id,
                    is_achieved: is_achieved
                }
                dispatch(updateGoalInList({ id: goal_id, is_achieved }))
                dispatch({ type: types.UPDATE_GOALS_ACHIEVE, payload: payload })
                return true
            }
            return false
        } catch (error) {
            console.error(error)
            return false
        }
    }

export const updateGoal =
    (payload: GoalUpdateRequestPayload): AppAction<boolean> =>
    async (dispatch) => {
        try {
            const call = await updateGoalApiCall(payload)
            if (call.success) {
                dispatch(updateGoalInList(pick(call.goal, ["label", "type", "img_id", "description"])))
                await Promise.all([
                    dispatch(
                        getEventsByDate({ date: DateString.from(new Date()), page: "timeline", forceFetch: true })
                    ),
                    dispatch(getGoalsData(true))
                ])
                return true
            }
            return false
        } catch (error) {
            console.log(error)
            return false
        }
    }

export const deleteGoal =
    (goalId: number): AppAction<boolean> =>
    async (dispatch) => {
        try {
            const call = await deleteGoalApiCall(goalId)
            if (call.success) {
                await Promise.all([
                    dispatch(
                        getEventsByDate({ date: DateString.from(new Date()), page: "timeline", forceFetch: true })
                    ),
                    dispatch(getGoalsData(true)),
                    dispatch({
                        type: globalTypes.NOTIFY_GOAL_REMOVED,
                        payload: goalId
                    })
                ])
                return true
            }
            return false
        } catch (error) {
            console.log(error)
            return false
        }
    }

export const createGoal =
    (payload: GoalCreateRequestPayload): AppAction<boolean> =>
    async (dispatch) => {
        try {
            const call = await createGoalApiCall(payload)
            if (call.success) {
                dispatch({ type: types.CREATE_GOAL, payload: call.goal })
                dispatch(addGoalToList(call.goal, payload.patient_id))
                return true
            }
            return false
        } catch (error) {
            console.log(error)
            return false
        }
    }

export const setTrackerEvents = (
    events: TrackerEventWithResponse<string>[],
    lastDashboardFetchData: PatientState["dashboard"]["lastDashboardFetchData"]
) => updatePatientDashboard({ trackerEvents: events, lastDashboardFetchData })

type GetEventsProps = {
    date?: string
    page: PatientPage
    forceFetch?: Boolean
    range?: PatientDashboardRanges
}

type ModifiedGetEventsProps<Page extends PatientPage> = (Page extends "timeline"
    ? SetRequired<Omit<GetEventsProps, "range" | "page">, "date">
    : SetRequired<Omit<GetEventsProps, "date" | "page">, "range">) & {
    page: Page
}

export function getEventsByDate(props: ModifiedGetEventsProps<"overview" | "questionnaires">): AppAction
export function getEventsByDate(props: ModifiedGetEventsProps<"timeline">): AppAction
export function getEventsByDate({ date, range, page, forceFetch }: GetEventsProps): AppAction {
    return async (dispatch, getState) => {
        const patient = getState().patient.currentPatient
        const inTimeline = page === "timeline"
        const { start, end } = inTimeline ? createTimelineRange(date) : getRangeEdges(range, patient)

        date = inTimeline ? date : start ? DateString.from(start) : undefined

        const dataAlreadyFetched = () => {
            const { lastEventFetchData, dashboard } = getState().patient
            const lastData = inTimeline ? lastEventFetchData : dashboard.lastDashboardFetchData

            return (
                patient?.id === lastData.patientId &&
                (date === lastData.date || (!inTimeline && DateString.isBefore(lastData.date, date)))
            )
        }

        if (dataAlreadyFetched() && !forceFetch)
            // No need to fetch, we already have the data, but we need to abort in case another request is already running
            return getEventsInstance.abort()

        try {
            dispatch(setPatientLoading(page, true))
            const fetchData = { date, patientId: patient.id }
            if (inTimeline) {
                const eventData = await getEvents(patient.id, start, end)
                dispatch(setEventsByDate(eventData, fetchData))
                dispatch(setPatientLoading(page, false))
            } else {
                const [questionnaireResponse, trackerEventsResponse] = await Promise.all([
                    getQuestionnaireEvents(start, end),
                    getTrackerEvents(start, end)
                ])
                dispatch(setQuestionnaireEvents(questionnaireResponse, fetchData))
                dispatch(setTrackerEvents(trackerEventsResponse.tracker_events, fetchData))
                dispatch(setComments(questionnaireResponse.comments))
                dispatch(setPatientLoading(page, false))
            }
        } catch {
            // Something went wrong. ApiRequest will log this to the console
            if (dataAlreadyFetched())
                // If fetch data does not match, we know getEventsByRange has been called again, and we should let the new call take over loading state
                dispatch(setPatientLoading(page, false))
        }
    }
}

export const setEventsForGant = (events: EventsByDate) => ({ type: types.SET_EVENTS_FOR_GANTT, payload: events })

export const getEventsForGantt =
    (patientId: number, startDate?: Date): AppAction =>
    async (dispatch) => {
        dispatch({ type: types.SET_EVENTS_FOR_GANTT, payload: null })
        const { start } = createFetchRange(({ dayStart }) => {
            const dayDifference = startDate ? differenceInCalendarDays(startDate, dayStart) : undefined
            return { start: dayDifference ? addDays(dayStart, dayDifference) : dayStart }
        })

        const { events } = await doGetEventsForGantt(start, patientId)
        dispatch({ type: types.SET_EVENTS_FOR_GANTT, payload: events })
    }

export const deleteEvent =
    (eventData: EventDeleteDialogData): AppAction<boolean> =>
    async (dispatch) => {
        try {
            await doEventDelete(eventData)
            dispatch({ type: types.DELETE_EVENT, payload: eventData })

            return true
        } catch (error) {
            return false
        }
    }

export const addEvents =
    (events: RawEventCreateInput[], page: PatientPage, initialWeekRange: Date[]): AppAction<boolean> =>
    async (dispatch, getState) => {
        try {
            const patient = getState().patient.currentPatient
            const res = await doAddEvents(patient.id, patient.user_id, events)

            if (page === "timeline")
                dispatch(getEventsByDate({ page, date: res[0]?.date_on_timeline, forceFetch: true }))
            else await dispatch(getOverviewData({ eventsByWeek: createByWeekRange(initialWeekRange) }))

            dispatch(getUserRecentlyAddedEvents())
            return true
        } catch {
            return false
        }
    }

export const addEvent =
    (event: RawEventCreateInput): AppAction<FlatEvent<string>> =>
    async (dispatch, getState) => {
        try {
            const patient = getState().patient.currentPatient
            const res = await doEventAdd(patient.id, patient.user_id, event)
            if (event.questionnaire_id)
                dispatch({ type: types.ADD_PATIENT_QUESTIONNAIRE, payload: event.questionnaire_id })
            dispatch(getUserRecentlyAddedEvents())
            return res
        } catch (error) {
            return undefined
        }
    }

export const eventExistAction =
    (data: ExistingEventActionPayload): AppAction<boolean> =>
    async (dispatch, getState) => {
        try {
            const patient = getState().patient.currentPatient
            const event = await existingEventAction(patient.id, data)
            if (event.questionnaire_id)
                dispatch({ type: types.ADD_PATIENT_QUESTIONNAIRE, payload: event.questionnaire_id })

            dispatch(getUserRecentlyAddedEvents())
            dispatch(closeContentLibrary())
        } catch (error) {
            return undefined
        }
    }

export const updateEvent =
    (data: EventUpdateData): AppAction<boolean> =>
    async (dispatch) => {
        try {
            const updatedEvent = await doEventUpdate(data)
            EventUpdateTracker.emit(updatedEvent)
            if (data?.policy === "single") dispatch(setPatientLoading("timeline", true))

            dispatch(
                updateCurrentSidebar({
                    id: updatedEvent.id,
                    patient_id: data.patient_id,
                    date_on_timeline: new Date(updatedEvent.date_on_timeline)
                })
            )

            return true
        } catch (error) {
            return false
        }
    }

export const updatePermission =
    (
        permission: BasicPermission & {
            index: number
        }
    ): AppAction<boolean> =>
    async (dispatch) => {
        try {
            await updateCollaboratorPermission(permission)
            dispatch({ type: types.UPDATE_PERMISSION, payload: permission })
            return true
        } catch (error) {
            return false
        }
    }

export const addCollaborator =
    (permission: BasicPermission): AppAction<CollaboratorWithPermissions> =>
    async (dispatch, getState) => {
        try {
            const collaborator = await addPatientCollaborator(getState().patient.currentPatient.id, permission)
            dispatch({ type: types.ADD_COLLABORATOR, payload: collaborator })
            return collaborator
        } catch (error) {
            return null
        }
    }

export const removePermission =
    (permission: BasicPermission): AppAction<boolean> =>
    async (dispatch) => {
        try {
            await removeCollaboratorPermission(permission.id)
            dispatch({ type: types.REMOVE_PERMISSION, payload: permission })
            return true
        } catch (error) {
            return false
        }
    }

export const updateEventStatus =
    (eventId: number, status: EventStatus): AppAction<boolean> =>
    async (dispatch) => {
        try {
            await doUpdateEventStatus(eventId, status)
            dispatch({ type: types.UPDATE_EVENT_STATUS, payload: { eventId, status } })
            return true
        } catch (error) {
            return false
        }
    }

export const createComment =
    (eventId: number, data: CommentData, isFetched = false): AppAction<EventComment<string>> =>
    async (dispatch) => {
        try {
            const comment = await doCreateComment(eventId, data)
            if (!isFetched) dispatch({ type: types.CREATE_COMMENT, payload: { comment } })
            return comment
        } catch (error) {
            return null
        }
    }

export const updateComment =
    (comment: EventComment<string>, data: CommentUpdateData): AppAction<EventComment<string>> =>
    async (dispatch) => {
        try {
            const updatedComment = await doUpdateComment(comment, data)
            dispatch({ type: types.UPDATE_COMMENT, payload: updatedComment })
            return updatedComment
        } catch (error) {
            return null
        }
    }

export const deleteComment =
    (comment: EventComment<string>, isFetched = false): AppAction<boolean> =>
    async (dispatch) => {
        try {
            await doDeleteComment(comment.id)
            if (!isFetched) dispatch({ type: types.DELETE_COMMENT, payload: comment })
            return true
        } catch (error) {
            console.error(error)
            return false
        }
    }

export const updateGraphState = (key: string, updates: Partial<GraphState>) => ({
    type: types.UPDATE_GRAPH_STATE,
    payload: { key, updates }
})
export const updatePatientDashboard = (updates: Partial<PatientState["dashboard"]>) => ({
    type: types.UPDATE_PATIENT_DASHBOARD,
    payload: updates
})

export const setLibrarySearch = (searchValue: string) => ({
    type: types.SET_LIBRARY_SEARCH,
    payload: searchValue
})

export const goToPrevContent = { type: types.GO_TO_PREV_CONTENT }

export const pushToLibraryStack = (libData: ContentLibraryUpdate) => {
    const contentLibrary = store.getState().patient.contentLibrary
    const patientId = libData.patientId === "preserve" ? contentLibrary.patientId : libData.patientId
    return setContentLibrary({ patientId, stack: [...contentLibrary.stack, libData] })
}

export const closeContentLibrary = () => setContentLibrary({ patientId: null, stack: [] })

export const setContentLibrary = (libData: PatientState["contentLibrary"]) => ({
    type: types.SET_CONTENT_LIBRARY,
    payload: libData
})
export const setCollaboratorManager = (open: boolean) => ({ type: types.SET_COLLABORATOR_MANAGER, payload: { open } })

export const toggleFamilyAuth =
    (patientId: number, contactId: number): AppAction<boolean> =>
    async (dispatch) => {
        try {
            const res = await doToggleFamilyAuth(patientId, contactId)
            const payload = { contactId, has_auth: "uid" in res ? Boolean(res.uid) : false }
            dispatch({ type: types.TOGGLE_AUTH_SUCCESS, payload })
            return true
        } catch (error) {
            console.error(error)
            return false
        }
    }

export const setAppointmentStatus =
    ({
        eventId,
        status,
        date_on_timeline,
        inPatientPage
    }: {
        eventId: number
        status: AppointmentStatus
        date_on_timeline: string
        inPatientPage: boolean
    }): AppAction<boolean> =>
    async (dispatch) => {
        const { success } = await doSetAppointmentStatus(eventId, status, date_on_timeline)
        const updates = { appointment_status: status }

        if (success) {
            if (inPatientPage)
                dispatch({
                    type: types.UPDATE_EVENT,
                    payload: { eventId, date: date_on_timeline.slice(0, 10), updates }
                })
            else dispatch(setUpcomingAppointment(eventId, date_on_timeline, updates))
        }

        return success
    }

export const getTrackerData =
    (tracker_id: number, isPatient: boolean): AppAction<TrackerManagerData> =>
    async (dispatch) => {
        const data = await getTrackerSettings(tracker_id)
        if (isPatient) dispatch(setTrackerManager(data))
        else dispatch(setOrganizationTrackerManager(data))

        return data
    }

export const setTrackerManager = (data: TrackerManagerData) => ({
    type: types.SET_TRACKER_MANAGER,
    payload: data
})

export const updateTrackerManager =
    (updatePayload: { trackerId: number; data: TrackerManagerData }, isPatient: boolean): AppAction<boolean> =>
    async (dispatch) => {
        try {
            const res = await updateTracker(updatePayload)
            if (res) {
                if (isPatient) dispatch(setTrackerManager(updatePayload.data))
                else dispatch(setOrganizationTrackerManager(updatePayload.data))
                return true
            }
        } catch {
            return false
        }
    }

export const getTrackerQuestions =
    (getQuestionsPayload: Partial<PatientOrOrgId>): AppAction<boolean> =>
    async (dispatch) => {
        try {
            const data = await doGetTrackerQuestions(getQuestionsPayload)
            if (getQuestionsPayload.patientId) dispatch({ type: types.SET_TRACKER, payload: data })
            else dispatch(setGlobalTracker(data))
        } catch (error) {
            return false
        }
    }

export const createCustomMeasure =
    (data: TrackerQuestionInput, id: PatientOrOrgId): AppAction<boolean> =>
    async (dispatch) => {
        try {
            await doCreateCustomMeasure(data, id)
            await dispatch(getTrackerQuestions(id))
            return true
        } catch (error) {
            console.error(error)
            return false
        }
    }

export const getTrackerResults =
    (patientId: number, start: Date, end: Date, initial = false): AppAction<boolean> =>
    async (dispatch, getState) => {
        try {
            // if (initial) await dispatch(getTrackerData(patientId))
            const { earliestFetch } = getState().patient.tracker.dashboard
            start = subDays(start, 6)
            const startString = DateString.from(start)

            if (earliestFetch && DateString.isAfter(startString, earliestFetch)) return true
            //We lazy loaded previous week already, remove 7 days to prevent data duplication
            if (!initial) end = subDays(end, 7)

            const { colors, responses } = await doGetTrackerResults(patientId, start, end)

            const data = { ...getState().patient.tracker.dashboard.data }
            const completion = { ...getState().patient.tracker.dashboard.completion }

            responses.forEach(({ value, date, question_id, completed_at }) => {
                data[question_id] = data[question_id] ? { ...data[question_id] } : {}
                data[question_id][date] = value
                completion[date] = completed_at
            })

            dispatch({
                type: types.SET_TRACKER_DASHBOARD,
                payload: {
                    earliestFetch: DateString.isBefore(startString, earliestFetch ?? DateString.today())
                        ? startString
                        : earliestFetch,
                    colors,
                    data,
                    completion
                }
            })

            return true
        } catch (error) {
            console.error(error)
            return false
        }
    }

export const addTrackerMeasure = (
    measure: BasicItem & {
        group: string
    }
) => ({
    type: types.ADD_TRACKER_MEASURE,
    payload: measure
})

export const addTrackerGroup = (group: BasicItem) => ({
    type: types.ADD_TRACKER_GROUP,
    payload: group
})

export const getOverviewData =
    (params: Dictionary<any>): AppAction<Boolean> =>
    async (dispatch) => {
        const data = await fetchOverviewData(params)
        if (data) {
            dispatch({ type: types.UPDATE_OVERVIEW_DATA, payload: data })
            dispatch(setPatientLoading("overview", false))
        }

        return true
    }

export const readPatientNotifications =
    (id?: number): AppAction<Boolean> =>
    async (dispatch) => {
        const success = await doReadPatientNotifications(id)
        if (success) dispatch({ type: types.READ_PATIENT_NOTIFICATIONS, payload: id ? { id } : undefined })
        return success
    }

export const updateCurrentPatient = (updates: Partial<PatientFileWithQuestionnaires<string, string>>) => ({
    type: types.UPDATE_CURRENT_PATIENT,
    payload: updates
})

export const getPatientOverviewQuestionnaireEvents =
    (start: Date, end: Date): AppAction<Boolean> =>
    async (dispatch) => {
        try {
            dispatch(setPatientLoading("overviewQuestionnairesScore", true))
            const [questionnairesEvents, trackerEvents] = await Promise.all([
                getQuestionnaireEvents(start, end),
                getTrackerEvents(start, end)
            ])
            dispatch({
                type: types.UPDATE_OVERVIEW_DATA,
                payload: {
                    questionnairesScores: {
                        questionnairesEvents: questionnairesEvents.events,
                        trackerEvents: trackerEvents.tracker_events
                    }
                }
            })
            return true
        } catch (e) {
            return false
        } finally {
            dispatch(setPatientLoading("overviewQuestionnairesScore", false))
        }
    }
