import { FloatingWrapper, useMention } from "@remirror/react"
import { BasicUser } from "@sequel-care/types"
import UserAvatar from "components/UserAvatar"
import { keyBy } from "lodash"
import { useEffect, useMemo, useState } from "react"
import "remirror/styles/all.css"
import { classNames, getUserName } from "utils"

export interface IMentionsProps {
    users: BasicUser[]
}

export type Suggestion = {
    id: string
    label: string
    email: string
}

export default function Mentions({ users }: IMentionsProps) {
    const usersByKey = useMemo(() => keyBy(users, "id"), [users])
    const [suggestions, setSuggestions] = useState<Suggestion[]>([])
    const { state, getMenuProps, getItemProps, indexIsHovered, indexIsSelected } = useMention({
        items: suggestions
    })

    //Yotam repeller
    //UseEffect depends on state, which itself depends on suggestions
    useEffect(() => {
        if (!state) return

        const searchTerm = state.query.full.toLowerCase()
        const filteredUsers = users
            .map((user) => ({
                id: user.id.toString(),
                label: getUserName(user),
                email: user.email
            }))
            .filter((user) => user.label.toLowerCase().includes(searchTerm))
            .sort((userA: Suggestion, userB: Suggestion) => {
                return userA.label.localeCompare(userB.label)
            })
            .slice(0, 4)
        setSuggestions(filteredUsers)
    }, [state, users])

    const enabled = !!state

    return (
        <FloatingWrapper positioner="cursor" enabled={enabled} placement="bottom-start">
            <div {...getMenuProps()} className="border border-solid rounded-md bg-white overflow-hidden shadow-md">
                {enabled &&
                    suggestions.map((suggestion, index) => (
                        <div
                            key={suggestion.id}
                            className={classNames(
                                "flex flex-row gap-2 justify-start items-center p-2 pl-4",
                                (indexIsSelected(index) || indexIsHovered(index)) && "bg-bg"
                            )}
                            {...getItemProps({ item: suggestion, index })}
                        >
                            <UserAvatar size={8} user={usersByKey[suggestion.id]} />
                            <div className="flex flex-col justify-center">
                                <div className="text-dark-blue font-semibold">{suggestion.label}</div>
                                <div className="text-text-blue">{suggestion.email}</div>
                            </div>
                        </div>
                    ))}
            </div>
        </FloatingWrapper>
    )
}
