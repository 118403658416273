import { DependencyList, useCallback, useEffect, useState } from "react"

export const useActiveIds = (
    resetCallback: () => { activeIds: string[]; trackerQuestionIds?: string[] },
    resetDeps: DependencyList
) => {
    const [activeIds, setActiveIds] = useState<string[]>([])
    useEffect(() => setActiveIds(resetCallback().activeIds), resetDeps)

    const toggleItem = useCallback((toggleId: string) => {
        setActiveIds((activeIds) => {
            const isTrackerId = toggleId.includes("t")
            const updated = activeIds.filter((id) => (isTrackerId ? !id.includes("t") : id !== toggleId))
            if (activeIds.length === updated.length)
                isTrackerId ? updated.push(...resetCallback().trackerQuestionIds) : updated.push(toggleId)
            return updated
        })
    }, [])

    return { activeIds, toggleItem }
}
