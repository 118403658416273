import { ChevronDownIcon } from "@heroicons/react/24/solid"
import { TruncatedString } from "components/common"
import { PlusIcon, XIcon } from "components/icons"
import { defaultInputClassNames } from "forms"
import { Fragment } from "react"
import { useTranslation } from "react-i18next"
import { classNames } from "utils"
import { BasicItem, SuperSelectButtonProps } from "./types"

export const tagClassNames =
    "rounded-full bg-text-blue/10 py-1.5 px-4 flex items-center text-text-blue w-fit whitespace-nowrap gap-2"

export function TagsButton<ItemType extends BasicItem>({
    id,
    selected,
    toggleSelection,
    toggleList,
    reference,
    showAddButton,
    RenderButton,
    disabled
}: SuperSelectButtonProps<ItemType> & { showAddButton?: boolean; toggleSelection: (item: ItemType) => void }) {
    const { t } = useTranslation("common")

    return (
        <div className={classNames(disabled && "opacity-50", "flex gap-x-4 gap-y-3 flex-wrap")}>
            {selected.map((item) => (
                <div
                    className={classNames(tagClassNames, "text-sm")}
                    key={item.id}
                    data-testid={`tag-${id.toString()}-${item.label}`}
                >
                    {!RenderButton ? (
                        <TruncatedString string={item.label} maxLen={21} />
                    ) : (
                        <RenderButton item={item} closeMenu={toggleList} />
                    )}
                    <XIcon className="w-3 cursor-pointer translate-y-0.25" onClick={() => toggleSelection(item)} />
                </div>
            ))}
            {showAddButton && (
                <div
                    className={classNames(
                        tagClassNames,
                        disabled ? "cursor-default" : "cursor-pointer",
                        " text-sm !gap-0.5"
                    )}
                    onClick={toggleList}
                    ref={reference}
                    data-floating-button
                    data-testid={`tag-${id.toString()}-add`}
                >
                    <PlusIcon className="w-4 translate-y-px" /> {t("add")}
                </div>
            )}
        </div>
    )
}

export function SelectButton<ItemType extends BasicItem>({
    id,
    selected,
    toggleList,
    reference,
    isOpen,
    placeholder,
    buttonClassName,
    RenderButton,
    usePlaceholderAsAll,
    disabled
}: SuperSelectButtonProps<ItemType> & { isOpen: boolean; placeholder: string; usePlaceholderAsAll: boolean }) {
    const hasSelected = selected?.length > 0

    return (
        <div
            className={classNames(
                (hasSelected || usePlaceholderAsAll) ? "text-dark-blue" : "text-text-blue/50",
                "flex items-center justify-between gap-x-4 gap-y-3 text-sm",
                disabled ? "select-none" : "cursor-pointer",
                buttonClassName ?? defaultInputClassNames
            )}
            ref={reference}
            onClick={toggleList}
            data-testid={`input-${id}`}
        >
            <span className="truncate w-[95%]">
                {hasSelected
                    ? selected.map((item, index) =>
                          item ? (
                              <Fragment key={item.id}>
                                  {!RenderButton ? (
                                      `${index ? ", " : ""}${item.label}`
                                  ) : (
                                      <RenderButton item={item} closeMenu={toggleList} />
                                  )}
                              </Fragment>
                          ) : null
                      )
                    : placeholder}
            </span>
            {disabled ? null :<ChevronDownIcon className={classNames("w-5 h-5 transition", isOpen && "rotate-x-180 duration-300")} />}
        </div>
    )
}
