import { PatientSnapshot } from "@sequel-care/types"
import { Tag } from "components/common/Tag"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useAllQuestionnaires } from "store/hooks"
import { getAttrsByCutoff } from "utils/graphs"

const relationQuestionnaires = [
    { ids: ["30"], title: "feedback", labels: ["Low", "Medium", "High"] },
    { ids: ["44", "45"], title: "alliance", labels: ["Low", "Medium", "High"] }
]

const trackerQuestions = [
    { id: "major_positive_life_event_today", title: "dashboard.lifeEvents.positive", positiveValue: 1 },
    { id: "major_negative_life_event_today", title: "dashboard.lifeEvents.negative", positiveValue: 0 }
]
const AdditionalSnapshotData = ({ additionalInfo }: { additionalInfo: PatientSnapshot["additionalInfo"] }) => {
    const { t } = useTranslation("patient")
    const questionnaires = useAllQuestionnaires()

    const { hasRelationsData, hasLifeEventsData } = useMemo(() => {
        let hasLifeEventsData = false,
            hasRelationsData = false
        if (!Object.keys(additionalInfo).length) return { hasLifeEventsData, hasRelationsData }

        if (relationQuestionnaires.some(({ ids }) => ids.some((id) => id in additionalInfo))) hasRelationsData = true
        if (trackerQuestions.some(({ id }) => id in additionalInfo)) hasLifeEventsData = true

        return { hasLifeEventsData, hasRelationsData }
    }, [additionalInfo])
    return (
        <>
            {hasRelationsData && (
                <div className="text-dark-blue">
                    <span className="font-medium">{t("sidebar.patientTherapistRelations")}</span>
                    <div className="text-text-blue divide-y mt-6">
                        {relationQuestionnaires.map(({ ids, labels, title }) => {
                            const questionnaire_id = ids.find((questionnaire_id) =>
                                Object.entries(additionalInfo).some(([id, _]) => id === questionnaire_id)
                            )
                            const questionnaire = questionnaires.find(({ id }) => id.toString() === questionnaire_id)
                            if (!questionnaire) return null // TODO: Remove this. This is a temporary fix until we synchronize questionnaires between backend and frontend

                            const { questionnaire_scale } = questionnaire
                            const { color, label } = questionnaire_id
                                ? {
                                      color: getAttrsByCutoff(
                                          additionalInfo[questionnaire_id]?.score,
                                          questionnaire_scale
                                      ),
                                      label: getAttrsByCutoff(
                                          additionalInfo[questionnaire_id]?.score,
                                          questionnaire_scale,
                                          labels
                                      )
                                  }
                                : { color: "gray" as const, label: undefined }

                            return (
                                <div key={questionnaire_id} className="justify-between flex items-center py-2">
                                    {t(`sidebar.relations.${title}`)}
                                    <Tag className="py-1" color={color}>
                                        {label ?? t("common:notMeasured")}
                                    </Tag>
                                </div>
                            )
                        })}
                    </div>
                </div>
            )}
            {hasLifeEventsData && (
                <div className="text-dark-blue">
                    <span className="font-medium">{t("sidebar.additionalInfo")}</span>
                    <div className="text-text-blue divide-y mt-6">
                        {trackerQuestions.map(({ id, positiveValue, title }) => {
                            const value = additionalInfo[id]?.score
                            return (
                                typeof value === "number" && (
                                    <div key={id} className="justify-between flex items-center py-2">
                                        {t(title)}
                                        <Tag className="py-1" color={positiveValue === value ? "green" : "red"}>
                                            {t(`common:boolean.${Boolean(value)}`)}
                                        </Tag>
                                    </div>
                                )
                            )
                        })}
                    </div>
                </div>
            )}
        </>
    )
}
export default AdditionalSnapshotData
