import ChevronLeft from "components/icons/ChevronLeft"
import { ChangeEvent } from "react"
import { classNames } from "utils"
import { RenderLabel } from "./RenderLabel"
import { InputProps } from "./types"
import { defaultInputClassNames, errorClassNames } from "./utils"

const NumberField = ({ value, setValue, error, disabled, readOnly, ...props }: InputProps<number>) => {
    const { classList, min, max } = props

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {
            value,
            validity: { valid }
        } = e.target
        if (value === "") {
            setValue(undefined)
            return
        }

        const number = parseInt(value)
        if (!valid && (number < min || number > max)) return
        setValue(number)
    }
    return (
        <div className={classNames(classList?.wrapper, "relative flex items-center text-dark-blue")}>
            <RenderLabel {...props} />
            <div
                className={classNames(classList?.inputWrapper, error && "!border-red-500", "flex items-center w-full")}
            >
                <input
                    className={(classNames(classList?.input ?? defaultInputClassNames), "px-1 w-8 bg-transparent")}
                    pattern="[0-9]*"
                    value={value ?? ""}
                    disabled={disabled || readOnly}
                    onChange={(event) => {
                        onChange(event)
                    }}
                />
                {!(disabled || readOnly) && (
                    <div
                        className={
                            (classNames(classList?.input ?? defaultInputClassNames),
                            "flex flex-col justify-center font-light")
                        }
                    >
                        <button
                            type="button"
                            onClick={() => {
                                const val = value ?? 0
                                val !== max && setValue(val + 1)
                            }}
                        >
                            <ChevronLeft className="h-4 w-4 rotate-90 cursor-pointer" />
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                const val = value ?? 0
                                val !== min && setValue(val - 1)
                            }}
                        >
                            <ChevronLeft className="h-4 w-4 -rotate-90 cursor-pointer" />
                        </button>
                    </div>
                )}
            </div>
            {error && (
                <div className={classNames(errorClassNames, classList.error, "!whitespace-normal !-bottom-8")}>
                    {error}
                </div>
            )}
        </div>
    )
}
export default NumberField
