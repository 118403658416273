import { SVGProps } from "react"

const ExternalLink = (props: SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M8 4H3a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2v-5M7 11 17 1M12 1h5v5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default ExternalLink
