import { noop } from "lodash"
import { useRef, useEffect } from "react"

type ObserverCallback = (dimensions: { width: number; height: number }) => void

const getDimensions = (el: HTMLElement) => ({ height: el.offsetHeight, width: el.offsetWidth })

const callbacks = new WeakMap<HTMLElement, ObserverCallback>()
let resizeObserver: ResizeObserver

const useResizeObserver = <El extends HTMLElement = HTMLElement>(setDimensions: ObserverCallback) => {
    const ref = useRef<El>(null)

    useEffect(() => {
        const registerListeners = () => {
            if (!("ResizeObserver" in window)) {
                setTimeout(registerListeners, 100)
                return noop
            }

            if (!resizeObserver)
                resizeObserver = new ResizeObserver((entries) => {
                    entries.forEach((entry) => {
                        const el = entry.target as HTMLElement
                        callbacks.get(el)?.(getDimensions(el))
                    })
                })

            if (!ref.current || !resizeObserver) return noop

            const el = ref.current
            resizeObserver.observe(el)
            callbacks.set(el, setDimensions)

            setDimensions(getDimensions(el))

            return () => {
                resizeObserver.unobserve(el)
                callbacks.delete(el)
            }
        }

        return registerListeners()
    }, [ref.current, setDimensions])

    return ref
}

export default useResizeObserver
