import { UserCreateInput } from "@sequel-care/types"
import { FadeWrapper } from "components/common"
import { Button } from "components/common"
import CloudImg from "components/common/CloudImg"
import { useTranslation } from "react-i18next"

const CompletedState = ({
    user,
    onClose,
    title,
    completed,
    isUpdateAction
}: {
    user: Pick<UserCreateInput, "gender" | "first_name" | "last_name">
    onClose: () => void
    title: string
    completed: boolean
    isUpdateAction?: boolean
}) => {
    const { t } = useTranslation("user")
    return (
        <FadeWrapper
            className="absolute w-full h-full top-0 left-0 flex flex-col items-center pt-16 pb-20 gap-8 bg-white"
            transition="transition ease-in-out duration-500"
            show={completed}
        >
            <CloudImg variant={"large"} imageId="completed-img" width="240" height="240" className="shrink-0" />
            <h2 className="text-dark-blue text-bold text-2xl" data-testid={"completed-state-title"}>
                {title}
            </h2>
            {!isUpdateAction && (
                <p className="text-text-blue text-lg w-100 text-center">{t("inviteExplanation", user)}</p>
            )}
            <Button
                theme="primary"
                className="text-lg mt-8 w-40"
                onClick={onClose}
                data-testid={"completed-state-button-close"}
            >
                Close
            </Button>
        </FadeWrapper>
    )
}

export default CompletedState
