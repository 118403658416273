import { XIcon } from "components/icons"
import { ReactNode } from "react"
import { classNames } from "utils"
import { RenderLabel } from "./RenderLabel"
import { InputProps } from "./types"
import { defaultInputClassNames, errorClassNames } from "./utils"
import { Spinner } from "components/common"
import { useTranslation } from "react-i18next"

export function Input<T extends string>(props: InputProps<T> & { children?: ReactNode; customDataTestId?: string }) {
    const { i18n } = useTranslation()

    const {
        id,
        error,
        type,
        placeholder,
        value,
        setValue,
        classList,
        isRequired,
        disabled,
        readOnly,
        maxLength,
        onClear,
        children,
        customDataTestId,
        onBlur,
        isLoading,
        direction
    } = props

    const numberProps = props as InputProps<"number">
    return (
        <div className={classNames("w-full relative", classList?.wrapper)}>
            <RenderLabel {...props} />
            <div className={classNames(classList?.inputWrapper, "flex justify-between items-center")}>
                <input
                    id={`input-${id}`}
                    data-testid={customDataTestId ?? `input-${id}`}
                    type={type}
                    value={(value as string) ?? ""}
                    disabled={disabled || readOnly}
                    autoFocus={props.autoFocus ?? false}
                    autoComplete="off"
                    readOnly={readOnly}
                    maxLength={maxLength}
                    className={classNames(
                        classList?.input ?? defaultInputClassNames,
                        i18n.language === "he" && direction === "ltr" && "text-end",
                        onClear && "focus:outline-none",
                        "p-2 bg-tranparent",
                        error && "!border-red-500"
                    )}
                    placeholder={placeholder ? `${placeholder}${isRequired ? "*" : ""}` : undefined}
                    onChange={({ target }) => {
                        let newValue = type === "number" ? Number(target.value) : target.value
                        if ("min" in numberProps && (newValue as number) < numberProps.min) newValue = numberProps.min
                        if ("max" in numberProps && (newValue as number) > numberProps.max) newValue = numberProps.max

                        setValue(newValue as typeof props.value)
                    }}
                    onBlur={onBlur}
                    min={type == "number" ? ("min" in numberProps ? (numberProps.min as number) : 0) : undefined}
                    max={"max" in numberProps ? (numberProps.max as number) : undefined}
                    dir={direction}
                />
                {children}
                {onClear && !readOnly && <XIcon className="w-5 h-5" onClick={() => onClear()} />}
                {isLoading && <Spinner size={5} />}
            </div>
            {error && <div className={errorClassNames}>{error}</div>}
        </div>
    )
}

export default Input
