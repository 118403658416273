import { FlatEvent } from "@sequel-care/types"

type EventSubscription = (event: FlatEvent<string, string>) => void
type Unsubscribe = () => void

export class EventUpdateTracker {
    private static subscriptions: EventSubscription[] = []

    public static emit(event: FlatEvent<string, string>) {
        this.subscriptions.forEach((subscription) => subscription(event))
    }

    public static subscribe(callback: EventSubscription): Unsubscribe {
        this.subscriptions.push(callback)
        return () => (this.subscriptions = this.subscriptions.filter((subscription) => subscription !== callback))
    }
}
