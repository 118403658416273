import { ApiDelete, ApiPost, ApiPut } from "./ApiRequest"
import { GoalCreateRequestPayload, GoalObject, GoalUpdateRequestPayload } from "@sequel-care/types/Goals";

export type GoalApiEvaluationResult = {
    success: boolean,
    goal: GoalObject
}

export const updateGoalAchieveStateApiCall = async (goalId: number, isAchieved: boolean) => {
    const payload = { id: goalId, is_achieved: isAchieved }
    const instance = new ApiPut<{ success: boolean }>(`/goals`, payload)
    instance.callerName = updateGoalAchieveStateApiCall.name
    return instance.run()
}

export const updateGoalApiCall = async (payload: GoalUpdateRequestPayload) => {
    const instance = new ApiPut<GoalApiEvaluationResult>(`/goals`, payload)
    instance.callerName = updateGoalApiCall.name
    return instance.run()
}

export const createGoalApiCall = async (payload: GoalCreateRequestPayload) => {
    const instance = new ApiPost<GoalApiEvaluationResult>(`/goals`, payload)
    instance.callerName = createGoalApiCall.name
    return instance.run()
}

export const deleteGoalApiCall = async (goalId: number) => {
    const instance = new ApiDelete<{ success: boolean }>(`/goals/${goalId}`)
    instance.callerName = deleteGoalApiCall.name
    return instance.run()
}
