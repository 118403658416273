import "react-loading-skeleton/dist/skeleton.css"
import "react-toastify/dist/ReactToastify.css"
import "styles/commentPost.css"
import "styles/dualSlider.css"
import "styles/misc.css"
import "styles/rtl-fix.css"
import "tailwindcss/tailwind.css"

import { ToastContainer } from "react-toastify"
import Sidebar from "components/AppSidebar"
import Head from "next/head"
import { useEffect } from "react"
import store from "store"
import { useCurrentLanguage, useCurrentRole } from "store/hooks"
import ContentLibrary from "components/ContentLibrary"
import { JumpTo } from "components/Patient/JumpTo"
import { getDirFromLanguage } from "hooks/useDir"
import i18next from "i18next"
import { i18nInit } from "locales"
import mixpanel from "mixpanel-browser"
import { AppProps } from "next/app"
import SidebarStack from "sidebars"
import { sendMixpanelReport } from "utils/mixpanel"
import ModalContainer from "../modals"
import { Amplify } from "aws-amplify"
import { Authenticator } from "@aws-amplify/ui-react"
import "@aws-amplify/ui-react/styles.css"
import AppLoading from "components/AppLoading"
import { AuthProvider } from "utils/auth0"
import { Header, authenticatorFormFields } from "utils/auth0/authenticatorComponent"

i18nInit()

mixpanel.init(process.env.MIXPANEL_TOKEN ?? "dev_not_sending")
Amplify.configure(JSON.parse(process.env.AMPLIFY_CONFIG))
const App = ({ Component, pageProps }: Omit<AppProps, "Component"> & { Component: any }) => {
    const language = useCurrentLanguage()
    const currentRole = useCurrentRole()

    useEffect(() => {
        if (i18next.language !== language) i18next.changeLanguage(language)
        document.querySelector("body")?.setAttribute("dir", getDirFromLanguage(language))
    }, [language])

    useEffect(() => {
        if (currentRole) sendMixpanelReport("Logged In")
    }, [currentRole])

    return (
        <>
            <Head>
                <title>SequelCare | Virtual Care Provider Portal</title>
            </Head>

            <Authenticator hideSignUp components={{ Header }} formFields={authenticatorFormFields}>
                {({ signOut, user }) => (
                    <AuthProvider LoadingState={AppLoading} signOut={signOut} user={user}>
                        {currentRole === null ? null : (
                            <>
                                <div
                                    className={`flex ltr:font-inter ${
                                        language === "he" ? "rtl:font-assistant" : "rtl:font-open-sans"
                                    }`}
                                >
                                    <Sidebar />
                                    <main className="flex flex-col relative flex-grow min-h-screen max-h-screen overflow-y-auto">
                                        <Component {...pageProps} />
                                        <ToastContainer />
                                        <ModalContainer />
                                    </main>
                                </div>
                                <SidebarStack />
                                <ContentLibrary />
                                <JumpTo />
                            </>
                        )}
                    </AuthProvider>
                )}
            </Authenticator>
        </>
    )
}

export default store.wrapper().withRedux(App)
