import { FlatEvent } from "@sequel-care/types"
import { EventComment } from "@sequel-care/types/Event"
import { getEvent } from "api"
import { isSameDay } from "date-fns"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { useDashboardEvents, usePatientLoading, useTimelineEvents } from "store/hooks"
import { EventSidebar } from "types/AppSidebar"
import { PatientPage } from "types/Misc"
import { EventUpdateTracker } from "utils/EventUpdateTracker"

export type FetchedEventState = {
    event: FlatEvent<string> | null
    comments: EventComment<string>[]
    loading: boolean
    wasFetched: boolean
}

export const useSidebarEvent = (sidebar: EventSidebar) => {
    const [state, setState] = useState<FetchedEventState>({
        event: null,
        comments: [],
        loading: false,
        wasFetched: false
    })

    const router = useRouter()
    const [currentPage] = (router.query.page as [PatientPage]) || []
    const useEvents = currentPage === "timeline" ? useTimelineEvents : useDashboardEvents
    const patientLoading = usePatientLoading(currentPage)
    const byDate = useEvents()

    useEffect(() => {
        if (sidebar && !patientLoading) {
            const dateString = sidebar.date_on_timeline ? sidebar.date_on_timeline.toJSON() : undefined
            const fetchEvent = async () => {
                setState({ ...state, loading: true })
                const { event, comments } = await getEvent(sidebar.patient_id, sidebar.id, dateString)
                if (!event.date_on_timeline) event.date_on_timeline = dateString

                setState({ event, comments: comments ?? [], loading: false, wasFetched: true })
            }

            const { events } = byDate?.find((item) => isSameDay(new Date(item.date), new Date(dateString))) ?? {}
            const event = events?.find((event) => event.id === sidebar.id)

            if (event) {
                setState({ ...state, event })
            } else {
                fetchEvent()
                return EventUpdateTracker.subscribe((updatedEvent) => {
                    if (updatedEvent.id === sidebar.id || updatedEvent.generated_from === sidebar.id) fetchEvent()
                })
            }
        }
    }, [byDate, sidebar, patientLoading])

    return { ...state, setState }
}
