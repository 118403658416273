const colors = [
    "bg-red-600",
    "bg-orange-600",
    "bg-yellow-600",
    "bg-green-600",
    "bg-teal-600",
    "bg-blue-600",
    "bg-indigo-600",
    "bg-purple-600",
    "bg-pink-600"
]
export const charToColor = (char = "a", colorList = colors) => {
    return colorList[char.charCodeAt(0) % colorList.length]
}
