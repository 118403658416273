import { InformationCircleIcon } from "@heroicons/react/24/outline"
import { Tooltip } from "components/common"
import { DOMAttributes, forwardRef, ForwardRefRenderFunction, ReactChild } from "react"
import { classNames } from "utils"
import { FormControlProps } from "./types"
import { defaultLabelClassNames } from "./utils"

const RenderLabelComponent: ForwardRefRenderFunction<
    HTMLLabelElement,
    Pick<FormControlProps<unknown>, "label" | "info" | "description" | "id" | "classList" | "isRequired"> & {
        suffix?: ReactChild
        elProps?: DOMAttributes<Element>
    }
> = ({ label, info, description, id, classList, isRequired, suffix, elProps }, ref) => {
    if (!label) return null

    return (
        <>
            <label
                {...elProps}
                htmlFor={`input-${id}`}
                ref={ref}
                className={classNames(classList?.label ?? defaultLabelClassNames)}
            >
                {label}
                {isRequired && "*"}
                {suffix}
                {info && (
                    <Tooltip content={info} delay={400} placement="right">
                        <InformationCircleIcon className="w-4" />
                    </Tooltip>
                )}
            </label>
            {description && (
                <label className={classNames(defaultLabelClassNames, "text-text-blue border-none")}>
                    {description}
                </label>
            )}
        </>
    )
}

export const RenderLabel = forwardRef(RenderLabelComponent)
