import { GetNotificationsPayload, NotificationCategories, GetInitialNotificationsPayload } from "types/Misc"
import { ApiGet, ApiPut } from "./ApiRequest"

export const getPortalNotifications = async (category: NotificationCategories, take = 50, last_fetched_id: number) => {
    const instance = new ApiGet<GetNotificationsPayload>(`/notification/`, { category, take, last_fetched_id })
    instance.callerName = getPortalNotifications.name
    return instance.run()
}

export const markAsRead = async (ids: number[]) => {
    const instance = new ApiPut<{ ids: number[] }>(`/notification/markasread`, { ids })
    instance.callerName = markAsRead.name
    return instance.run()
}

export const getInitialPortalNotifications = async (take = 50) => {
    const instance = new ApiGet<GetInitialNotificationsPayload>(`/notification/initial`, { take })
    instance.callerName = getInitialPortalNotifications.name
    return instance.run()
}
