import { ExerciseQuestion } from "@prisma/client"
import { BasicQuestion } from "types/Misc"
import { classNames } from "utils"
import { useQuestionScale } from "./context"

export const ScaleGuideline = ({
    question,
    className
}: {
    question: BasicQuestion | ExerciseQuestion
    className?: string
}) => {
    const scale = useQuestionScale(question)

    if (!scale) return null
    return (
        <div
            data-testid={`sidebar-guideline-for-${scale.id}`}
            className={classNames("text-med-blue text-justify text-sm", className)}
        >
            {scale?.guideline}
        </div>
    )
}
