import SuperSelect from "forms/SuperSelect"
import { Dispatch, SetStateAction, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useUsersInCurrentOrg } from "store/hooks"
import { getUserName } from "utils"
import { defaultFilterClasses } from "./FilterInput"

const TherapistFilter = ({
    value,
    setTherapists
}: {
    value: number[]
    setTherapists: Dispatch<SetStateAction<number[]>>
}) => {
    const { t } = useTranslation("patient")
    const usersInOrg = useUsersInCurrentOrg()
    const users = useMemo(
        () => usersInOrg?.map(({ user }) => ({ id: user.id, label: getUserName(user) })) ?? [],
        [usersInOrg]
    )

    return (
        <SuperSelect
            id={"therapist_ids"}
            items={users}
            value={value}
            setValue={setTherapists}
            placeholder={t("export.allTherapists")}
            usePlaceholderAsAll
            classList={defaultFilterClasses}
            contentWidth="w-64"
            withSearch
            withSelectionIcon={true}
        />
    )
}

export default TherapistFilter
