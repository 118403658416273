import { SVGProps } from "react"

const DashboardIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
    >
        <path d="M0 0h24v24H0z" stroke="none" />
        <circle cx={12} cy={13} r={2} />
        <path d="M13.45 11.55 15.5 9.5M6.4 20a9 9 0 1 1 11.2 0z" />
    </svg>
)

export default DashboardIcon
