import ReactTextareaAutosize from "react-textarea-autosize"
import { classNames } from "utils"
import { RenderLabel } from "./RenderLabel"
import { FormControlProps } from "./types"
import { defaultInputClassNames, errorClassNames } from "./utils"

export function TextArea(props: FormControlProps<string>) {
    const { id, error, placeholder, value, setValue, classList, isRequired } = props

    return (
        <div className={classNames("relative", classList?.wrapper)}>
            <RenderLabel {...props} />
            <ReactTextareaAutosize
                minRows={3}
                id={`input-${id}`}
                data-testid={`input-${id}`}
                value={(value as string) ?? ""}
                autoComplete="off"
                className={classNames(classList?.input ?? defaultInputClassNames, "p-3", error && "!border-red-500")}
                placeholder={placeholder ? `${placeholder}${isRequired ? "*" : ""}` : undefined}
                onChange={(e) => setValue(e.target.value)}
            />
            {error && <div className={classNames(errorClassNames)}>{error}</div>}
        </div>
    )
}

export default TextArea
