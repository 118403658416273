import createAuth0Client, { Auth0Client, Auth0ClientOptions, LogoutOptions } from "@auth0/auth0-spa-js"
import { noop } from "lodash"
import { InactivityTracker } from "./InactivityTracker"
import { ProxiedAuth0Client } from "./types"

class AuthInternals {
    inactivityTracker: InactivityTracker

    private isInitializing: Promise<void>
    private isInitialized = false
    private clientInstance: Auth0Client
    private resolveInit = noop

    constructor() {
        this.isInitializing = new Promise<void>((resolve) => {
            this.resolveInit = () => {
                resolve()
                this.isInitialized = true
                this.resolveInit = noop
            }
        })

        this.inactivityTracker = new InactivityTracker(this.client)
    }

    async _initialize() {
        if (this.clientInstance) return

        this.clientInstance = await createAuth0Client({
            domain: process.env.AUTH0_DOMAIN,
            client_id: process.env.AUTH0_CLIENT_ID,
            redirect_uri: window.location.origin,
            audience: "https://api.sequel.care",
            scope: "roles",
            useRefreshTokens: true
        })
        this.resolveInit()
    }

    // Using this proxy, instead of the client directly, will make sure
    // that we don't call any methods before we finish initializing the client.
    client = new Proxy({} as ProxiedAuth0Client, {
        get: (_, prop: keyof ProxiedAuth0Client) => {
            const getMethod = () => {
                if (prop === "logout")
                    return (options: LogoutOptions) => {
                        // Always adding returnTo makes sure logout will redirect to the correct portal
                        return this.clientInstance.logout({ returnTo: window.origin, ...options })
                    }

                if (prop !== "getTokenSilently") return this.clientInstance[prop] as Function

                return async () => {
                    const token = await this.clientInstance.getTokenSilently()
                    this.inactivityTracker.updateExpiry(token)
                    return token
                }
            }

            return !this.isInitialized
                ? async (...args: any[]) => {
                      // Wait for client to initialize and then call the method.
                      await this.isInitializing
                      return getMethod()(...args)
                  }
                : getMethod()
        }
    })
}

export const auth0 = new AuthInternals()
