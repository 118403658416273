import { classNames } from "utils"
import { ModalCompatiblePortal } from "../Modal"
import { useMenuContext } from "./context"
import { ButtonProps, PanelProps } from "./types"

export const MenuButton = ({ className, children: Children }: ButtonProps) => {
    const {
        isOpen,
        onToggle,
        floatingProps: { reference },
        id
    } = useMenuContext()

    return (
        <div onClick={onToggle} ref={reference} className={className} id={id}>
            {typeof Children === "function" ? <Children {...{ isOpen, close: onToggle }} /> : Children}
        </div>
    )
}

export const MenuPanel = ({ className, children: Children }: PanelProps) => {
    const {
        isOpen,
        onToggle,
        floatingProps: { strategy, y, x, floating }
    } = useMenuContext()

    return isOpen ? (
        <ModalCompatiblePortal>
            <div
                id="menu-panel"
                ref={floating}
                className={classNames("z-100 my-2", className)}
                style={{ position: strategy, top: y ?? "", left: x ?? "" }}
            >
                {typeof Children === "function" ? Children({ isOpen, close: onToggle }) : Children}
            </div>
        </ModalCompatiblePortal>
    ) : null
}
