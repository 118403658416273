import { SVGProps } from "react"

const LetterAsterisk = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="19"
        fill="none"
        viewBox="0 0 20 19"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M11 15H3a2 2 0 01-2-2V3a2 2 0 012-2h14a2 2 0 012 2v7.5"
        ></path>
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M1 3l9 6 9-6"
        ></path>
        <path
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="0.1"
            d="M15.797 17.314a.704.704 0 101.406 0l-.084-1.786 1.614 1a.69.69 0 10.667-1.206l-1.673-.822 1.673-.822a.69.69 0 10-.667-1.205l-1.614.999.084-1.786a.704.704 0 10-1.406 0l.084 1.786-1.614-1a.69.69 0 10-.667 1.206l1.673.822-1.673.822a.69.69 0 10.668 1.205l1.613-.999-.084 1.786z"
        ></path>
    </svg>
)

export default LetterAsterisk
