import { PatientEngagementMontlyDataItem, ScoreData } from "@sequel-care/types"
import { addDays, differenceInCalendarDays, endOfWeek, isSameDay, startOfWeek } from "date-fns"
import { ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { classNames } from "utils"
import { createFetchRange, weekdays } from "utils/dates"
import { useMemo } from "react"
import { useAllQuestionnaires, useOverviewQuestionnaireScores, useWeekStart } from "../../store/hooks"
import { ById } from "@sequel-care/types/utils"
import { getGraphItemFromQuestionnaire, GraphItem, useActiveIds } from "../ScoreGraph"
import { getGraphEntriesForTracker } from "../ScoreGraph/utils/getGraphItemFromQuestionnaire"
import { TrackerQuestionData } from "@sequel-care/types/Tracker"

export type EngaementTooltipData = {
    opacity: number
    left: number
    top: number
    label: string
    data: Partial<PatientEngagementMontlyDataItem> | null
}

export enum OverviewEngagementTabs {
    MONTHS = "months",
    HEATMAP = "heatmap"
}

export const calculateTotalEngagement = (data: Partial<PatientEngagementMontlyDataItem>) => {
    return Object.values(data).reduce<{ totalDone: number; total: number }>(
        (acc, item) => {
            return {
                total: acc.total + item.total,
                totalDone: acc.totalDone + item.done
            }
        },
        {
            total: 0,
            totalDone: 0
        }
    )
}

export const getDefaultTooltipState = (): EngaementTooltipData => ({
    opacity: 0,
    top: -100,
    left: 0,
    label: "",
    data: null
})

export const useInitialWeekRange = () => {
    const weekStart = useWeekStart()
    const weekStartsOn = weekdays.indexOf(weekStart) as any

    return [startOfWeek(new Date(), { weekStartsOn }), endOfWeek(new Date(), { weekStartsOn })]
}

export const createByWeekRange = ([rangeStart, rangeEnd]: Date[]) =>
    createFetchRange(({ dayStart, dayEnd }) => {
        return {
            start: addDays(dayStart, differenceInCalendarDays(rangeStart, dayStart)),
            end: addDays(dayEnd, differenceInCalendarDays(rangeEnd, dayEnd))
        }
    })

export const OverviewCard = ({
    wrapperClassNames,
    headerButton,
    children,
    title
}: {
    wrapperClassNames?: string
    headerButton?: ReactNode
    children: ReactNode
    title: ReactNode
}) => {
    return (
        <div
            className={classNames(
                wrapperClassNames,
                "flex w-full bg-white border border-border-blue flex-col divide-y divide-border-blue"
            )}
        >
            <div className="flex justify-between p-4 items-center">
                {title}
                {headerButton}
            </div>
            {children}
        </div>
    )
}
const today = new Date()

export const Today = ({ date }: { date: Date }) => {
    const { t } = useTranslation("dates")

    if (isSameDay(today, date))
        return (
            <span className="text-med-green flex items-center gap-1 font-normal">
                <div className="bg-med-green rounded-full w-1 h-1" />
                {t("today")}
            </span>
        )

    return null
}

export const useOverviewQuestionnairesScoresGraphProps = (
    isTrackerEnabled: boolean,
    selectedQuestionnaireIds: number[],
    selectedTrackerQuestions: string[],
    trackerQuestions: TrackerQuestionData[]
) => {
    const { questionnaireIds, trackerQuestionIds } = useMemo(
        () => ({
            questionnaireIds: selectedQuestionnaireIds.map((id) => `q${id}`),
            trackerQuestionIds: selectedTrackerQuestions.map((id) => `t${id}`)
        }),
        [selectedQuestionnaireIds, selectedTrackerQuestions]
    )

    const allQuestionnaires = useAllQuestionnaires()
    const items = useMemo<ById<GraphItem>>(() => {
        return Object.fromEntries(
            questionnaireIds
                .map<[string, GraphItem]>((id) => {
                    const questionnaire = allQuestionnaires.find((q) => `q${q.id}` === id)
                    if (!questionnaire || !questionnaire.questionnaire_scale) return null

                    return [`q${id}`, getGraphItemFromQuestionnaire(questionnaire)]
                })
                .concat(
                    isTrackerEnabled && selectedTrackerQuestions
                        ? selectedTrackerQuestions.map((id) => [
                              `t${id}`,
                              getGraphEntriesForTracker(
                                  id,
                                  trackerQuestions?.find((question) => id === question.key)
                              )
                          ])
                        : []
                )
                .filter(Boolean)
        )
    }, [questionnaireIds, allQuestionnaires])

    const activeIdProps = useActiveIds(
        () => ({ activeIds: questionnaireIds.concat(trackerQuestionIds), trackerQuestionIds }),
        [questionnaireIds, trackerQuestionIds]
    )

    const scoresById = useScoresByQuestionnaireId()
    return { items, scoresById, ...activeIdProps }
}

export const useScoresByQuestionnaireId = () => {
    const overviewQuestionnaire = useOverviewQuestionnaireScores()

    return useMemo(() => {
        if (!overviewQuestionnaire?.questionnairesEvents) return {}

        const groupedEvents: ById<ScoreData[]> = {}

        Object.values(overviewQuestionnaire.questionnairesEvents)
            .flatMap(({ events }) => events)
            .forEach(({ questionnaire_id, event_id, score, completed_at, date_on_timeline }) => {
                const id = `q${questionnaire_id}`
                groupedEvents[id] ||= []
                groupedEvents[id].push({
                    event_id,
                    date: new Date(completed_at),
                    date_on_timeline: new Date(date_on_timeline),
                    score: Math.round(score)
                })
            })

        overviewQuestionnaire.trackerEvents.forEach(({ date_on_timeline, responses, event_id, questions }) => {
            trackerQuestionKeys.forEach((key) => {
                const id = questions.find(({ question }) => key === question)?.id
                if (!id || !responses[id]?.length) return

                const idStr = `t${key}`
                groupedEvents[idStr] ||= []
                groupedEvents[idStr].push(
                    ...responses[id].map((value) => {
                        const score = parseInt(value)
                        return {
                            event_id,
                            date: new Date(date_on_timeline),
                            date_on_timeline: new Date(date_on_timeline),
                            score: !isNaN(score) ? (key === "hours_of_sleep_last_night" ? score / 60 : score) : null
                        }
                    })
                )
            })
        })

        return groupedEvents
    }, [overviewQuestionnaire])
}

export const trackerQuestionKeys = [
    "major_positive_life_event_today",
    "major_negative_life_event_today",
    "hours_of_sleep_last_night",
    "last_night_sleep_quality"
]
export const trackerQuestionColors = {
    major_positive_life_event_today: "#34D399",
    major_negative_life_event_today: "#F87171",
    hours_of_sleep_last_night: "#6243D7",
    last_night_sleep_quality: "#A9ACFE"
} as const
