import { AppointmentStatus, UserPreferences } from "@prisma/client"
import { FlatEvent, TeamUser } from "@sequel-care/types"
import { omit } from "lodash"
import { Reducer } from "redux"
import { GlobalState } from "types/Redux"
import { CompleteUserInOrg } from "types/SchemaExtensions"
import * as types from "./types"

const initialState: GlobalState = {
    currentRole: null,
    usersInCurrentOrg: null,
    loading: [],
    user: null,
    chatToken: null,
    patientList: null,
    dashboardPatients: null,
    allQuestionnaires: [],
    allExercises: [],
    allCarePlans: [],
    diagnoses: [],
    patientTags: [],
    goals: [],
    appointments: { past: [], upcoming: [] },
    chat: {
        conversation: null,
        mode: null,
        newChatWith: null,
        patientId: null
    },
    settingsLoading: false,
    tracker: null
}

const globalReducer: Reducer<GlobalState> = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.SET_CURRENT_USER: {
            return { ...state, user: payload }
        }
        case types.SET_CHAT_TOKEN: {
            return { ...state, chatToken: payload }
        }
        case types.SET_CURRENT_ROLE: {
            return { ...state, currentRole: payload }
        }
        case types.SET_USERS_IN_CURRENT_ORG: {
            return { ...state, usersInCurrentOrg: payload }
        }
        case types.UPDATE_USER_IN_CURRENT_ORG: {
            const user = payload as TeamUser
            const newUserInOrg: CompleteUserInOrg = {
                ...user.team_member,
                user: omit(user, ["preferences", "team_member"])
            }
            return {
                ...state,
                usersInCurrentOrg: state.usersInCurrentOrg.map((userInOrg) => {
                    return userInOrg.user.id === user.id ? newUserInOrg : userInOrg
                })
            }
        }
        case types.ADD_USER_IN_CURRENT_ORG: {
            const user = payload as TeamUser
            const newUserInOrg: CompleteUserInOrg = {
                ...user.team_member,
                user: omit(user, ["preferences", "team_member"])
            }

            return { ...state, usersInCurrentOrg: [newUserInOrg, ...state.usersInCurrentOrg] }
        }
        case types.SET_GLOBAL_LOADING: {
            const updatedLoading = state.loading.filter((name) => name !== payload.name)
            if (payload.loading) updatedLoading.push(payload.name)

            return { ...state, loading: updatedLoading }
        }
        case types.SET_PATIENT_LIST: {
            return { ...state, patientList: payload }
        }
        case types.ADD_PATIENT_TO_LIST: {
            return { ...state, patientList: [payload, ...state.patientList] }
        }
        case types.REMOVE_PATIENT_FROM_LIST: {
            return { ...state, patientList: state.patientList.filter((patient) => patient.id !== payload) }
        }
        case types.REMOVE_PATIENT_FROM_LIST: {
            return { ...state, patientList: state.patientList.filter((patient) => patient.id !== payload) }
        }
        case types.UPDATE_PATIENT_IN_LIST: {
            return {
                ...state,
                patientList: state.patientList.map((p) => (p.id === payload.id ? payload : p))
            }
        }
        case types.UPDATE_PATIENT_IN_LIST_PARTIAL: {
            return {
                ...state,
                patientList: state.patientList.map((p) =>
                    p.id === payload.id
                        ? {
                              ...p,
                              treatment_end: payload.updates.treatment_end,
                              treatment_end_reason: payload.updates.treatment_end_reason,
                              status: payload.updates.status
                          }
                        : p
                )
            }
        }
        case types.SET_PATIENT_TAGS: {
            return { ...state, patientTags: payload }
        }
        case types.UPDATE_PASSWORD_IS_TEMP: {
            return { ...state, user: { ...state.user, password_is_temp: false } }
        }
        case types.SET_ALL_QUESTIONNAIRES: {
            return { ...state, allQuestionnaires: payload }
        }
        case types.ADD_NEW_QUESTIONNAIRE: {
            return { ...state, allQuestionnaires: [...state.allQuestionnaires, payload] }
        }
        case types.UPDATE_QUESTIONNAIRE: {
            const questionniareIndex = state.allQuestionnaires.findIndex(({ id }) => id === payload.id)
            if (questionniareIndex < 0) return state

            const allQuestionnaires = [...state.allQuestionnaires]
            allQuestionnaires[questionniareIndex] = { ...allQuestionnaires[questionniareIndex], ...payload }
            return { ...state, allQuestionnaires }
        }
        case types.SET_USERS_APPOINTMENTS: {
            return { ...state, appointments: payload }
        }
        case types.SET_PAST_APPOINTMENT_STATUS: {
            const { eventId, status, date_on_timeline } = payload as {
                eventId: number
                status: AppointmentStatus
                date_on_timeline: string
            }

            return {
                ...state,
                appointments: {
                    ...state.appointments,
                    past: state.appointments.past.map((appointment) =>
                        appointment.id === eventId && date_on_timeline === appointment.date_on_timeline
                            ? { ...appointment, appointment_status: status }
                            : appointment
                    )
                }
            }
        }
        case types.UPDATE_GLOBAL: {
            return { ...state, ...payload }
        }
        case types.SET_UPCOMING_APPOINTMENT: {
            const { eventId, updates, date } = payload as {
                eventId: number
                date: string
                updates: Partial<FlatEvent<string>>
            }
            return {
                ...state,
                appointments: {
                    ...state.appointments,
                    upcoming: state.appointments.upcoming.map((byDate) =>
                        byDate.date === date
                            ? {
                                  date,
                                  events: byDate.events.map((event) =>
                                      event.id === eventId ? { ...event, ...updates } : event
                                  )
                              }
                            : byDate
                    )
                }
            }
        }
        case types.SET_DASHBOARD_PATIENTS: {
            return {
                ...state,
                dashboardPatients: payload
            }
        }
        case types.UPDATE_ORGANIZATION: {
            return {
                ...state,
                currentRole: {
                    ...state.currentRole,
                    organization: payload
                }
            }
        }
        case types.SET_TEAM_AUTH: {
            const usersCopy = [...state.usersInCurrentOrg]
            const userIndex = usersCopy.findIndex(({ user_id }) => user_id === payload.userId)
            usersCopy[userIndex].user.has_auth = Boolean(payload.uid)
            return {
                ...state,
                usersInCurrentOrg: usersCopy
            }
        }
        case types.UPDATE_USER_PREFERENCES: {
            const preferences = payload as UserPreferences
            return {
                ...state,
                user: { ...state.user, preferences }
            }
        }
        case types.NOTIFY_GOAL_REMOVED: {
            const goalId = payload as number
            if (!goalId) return state
            return {
                ...state,
                goals: state.goals.map((g) => (g.id === goalId ? { ...g, is_deleted: true } : g))
            }
        }
        case types.SET_GLOBAL_CHAT: {
            return { ...state, chat: { ...state.chat, ...payload } }
        }
        case types.SET_GLOBAL_CAREPLANS: {
            return {
                ...state,
                allCarePlans: payload
            }
        }

        case types.SET_SETTINGS_LOADING: {
            return {
                ...state,
                settingsLoading: payload
            }
        }
        case types.ADD_EVENTS_TO_RECENT: {
            return {
                ...state,
                user: { ...state.user, created_events: payload }
            }
        }
        case types.SET_TRACKER: {
            return { ...state, tracker: payload }
        }
        case types.SET_ORGANIZATION_TRACKER_MANAGER: {
            return { ...state, tracker: { ...state.tracker, manager: payload } }
        }
        case types.ADD_GOAL_TO_LIST: {
            return {
                ...state,
                goals: [...state.goals, payload]
            }
        }
        case types.UPDATE_GOAL_IN_LIST: {
            return {
                ...state,
                goals: state.goals.map((goal) => (goal.id === payload.id ? { ...goal, ...payload } : goal))
            }
        }
        case types.SET_CAREPLAN_DEFAULT: {
            return {
                ...state,
                allCarePlans: state.allCarePlans.map((carePlan) => {
                    if (carePlan.id === payload.plan_id) return { ...carePlan, is_default: true }
                    if (carePlan.is_default) return { ...carePlan, is_default: false }

                    return carePlan
                })
            }
        }
        case types.REMOVE_CAREPLAN_DEFAULT: {
            return {
                ...state,
                allCarePlans: state.allCarePlans.map((carePlan) =>
                    carePlan.id === payload.plan_id ? { ...carePlan, is_default: false } : carePlan
                )
            }
        }
        default:
            return state
    }
}

export default globalReducer
