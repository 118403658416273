import { ArrowLeftIcon } from "@heroicons/react/24/outline"
import { Button } from "components/common"
import { ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "store"
import { goToPrevContent } from "store/patient/actions"
import { ContentLibraryData } from "types/Redux"
import { classNames } from "utils"

type LibView = ContentLibraryData["view"]

const ContentLibHeader = ({
    view,
    title,
    subtitle,
    withBack = false
}: {
    view: LibView
    title?: ReactNode
    subtitle?: ReactNode
    withBack?: boolean
}) => {
    const { t } = useTranslation("common")
    const dispatch = useDispatch()
    return (
        <div
            className={classNames(
                "text-dark-blue justify-between items-center text-2xl shrink-0 font-semibold sticky top-0 z-10",
                view.endsWith(".cards") ? "py-6" : "px-12 pt-8 pb-4"
            )}
            style={{ background: "linear-gradient(#fff, #fff 95%, transparent)" }}
        >
            {withBack ? (
                <Button theme="secondary" className="gap-3 py-3 rounded-md" onClick={() => dispatch(goToPrevContent)}>
                    <ArrowLeftIcon className="h-5 w-5" />
                    {t("back")}
                </Button>
            ) : (
                title
            )}
            {subtitle}
        </div>
    )
}

export default ContentLibHeader
