import { Language, UserRole } from "@prisma/client"
import { Dispatch, SetStateAction, SVGProps } from "react"

export type WithIcon<T> = T & { Icon: (props: SVGProps<SVGSVGElement>) => JSX.Element }

export type CardData = {
    id: number
    title: string
    language: Language
    target_role?: UserRole
    type?: RenderLibraryProps["type"] | "care_plan"
    professional_description?: string
    description?: string
    recommended_recurrence?: number
    is_deleted: boolean
}

export type CategoryObj = { id: string | number; name: string; order?: number }

export type RenderLibraryProps<ItemType = CardData, CategoryType = CategoryObj> = {
    type: "questionnaire" | "exercise"
    allItems: ItemType[]
    cardItems: ItemType[]
    onLearnMore?: (item: ItemType) => void
    onAdd: (item: ItemType) => void
    gridColumns: `grid-cols-${number}`
    categories?: {
        list: CategoryType[]
        label?: string
        preset?: WithIcon<CategoryType>[]
        currentCategory: CategoryType
        setCurrentCategory: Dispatch<SetStateAction<CategoryType>>
    }
    RenderBreadCrumb?: (props: {
        categories: RenderLibraryProps<any, CategoryType>["categories"]
        category: CategoryType
    }) => JSX.Element
}

export enum LibrarySort {
    LAST_ADD = "lastAdd",
    AZ = "az"
}
