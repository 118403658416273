import { useRef } from "react"
import { useTranslation } from "react-i18next"
import { VariableSizeList } from "react-window"
import { useDispatch } from "store"
import { useNotificationCount, useNotifications } from "store/hooks"
import { getNotifications } from "store/notifications/actions"
import { NotificationCategories } from "types/Misc"
import { getHeightFromClasses, getTextHeight } from "utils"
import NotificationItem, { notificationClassNames } from "./NotificationItem"
import { useNotificationRanges } from "./utils"

export const InfiniteList = ({ category }: { category: NotificationCategories }) => {
    const { t } = useTranslation("user")
    const dispatch = useDispatch()
    const notifications = useNotifications(category)
    const loadingData = useRef({ isLoading: false, fetchedOn: 0 })
    const totalNotifications = useNotificationCount(category)
    const hasNextPage = totalNotifications - notifications.length > 0

    const ref = useRef<VariableSizeList>()
    const itemCount = hasNextPage ? notifications.length + 1 : notifications.length
    const loadOffset = 15
    const notificationRangeMapping = useNotificationRanges(notifications, t)

    const onItemsRendered: VariableSizeList["props"]["onItemsRendered"] = async (indices) => {
        const { visibleStopIndex } = indices
        if (loadingData.current.fetchedOn !== visibleStopIndex && hasNextPage) {
            if (
                !loadingData.current.isLoading &&
                (itemCount < loadOffset || visibleStopIndex >= itemCount - loadOffset)
            ) {
                loadingData.current.fetchedOn = visibleStopIndex
                loadingData.current.isLoading = true
                await dispatch(getNotifications(category, 50, notifications[notifications.length - 1].id))
                ref.current?.resetAfterIndex(notifications.length)
                loadingData.current.isLoading = false
            }
        }
    }

    const getItemSize = (index: number) => {
        const notification = notifications[index]
        if (!notification) return 120

        const sumOffset = getHeightFromClasses(
            Object.values(
                notificationRangeMapping[index]
                    ? notificationClassNames
                    : { ...notificationClassNames, rangeTitleHeight: "h-0" }
            )
        )
        if (notification.body && notification.type === "question_alert") {
            return getTextHeight(notification.body, 642) + sumOffset
        }

        return getTextHeight(t(`portalNotifications.bodies.${notification.type}`), 642) + sumOffset
    }
    if (notifications.length === 0)
        return (
            <div className="flex items-center justify-center text-gray-400 italic h-16">
                {t("notifications.noNotifications")}
            </div>
        )

    return (
        <VariableSizeList
            height={300}
            itemCount={itemCount}
            itemSize={getItemSize}
            onItemsRendered={onItemsRendered}
            width={"100%"}
            ref={ref}
        >
            {({ index, style }) => {
                return (
                    <NotificationItem
                        style={style}
                        notification={notifications[index]}
                        withSeparator={!(index === 0 || Boolean(notificationRangeMapping[index]))}
                        rangeTitle={notificationRangeMapping[index]}
                        horizontalPadding="px-6"
                    />
                )
            }}
        </VariableSizeList>
    )
}
