import { Spinner } from "components/common"
import { Profile, ProfileRow } from "components/Profile"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { useDispatch } from "store"
import { useCurrentPatient, useCurrentRole, useCurrentUser } from "store/hooks"
import { openFeaturesSettingsModal, openPatientModal } from "store/modal/actions"
import { getCurrentPatient } from "store/patient/actions"
import { goToThePrevSidebar } from "store/sidebars/actions"
import { PatientSidebar } from "types/AppSidebar"
import { sendEmailWithDetails } from "../../api"
import { openChatFromUserProfile } from "../../components/Profile/utils"
import { useConversations } from "../../utils/conversations"
import { RenderProfileBlock } from "./components"
import { firstFieldBlock, fourthFieldBlock, secondFieldBlock, thirdFieldBlock } from "./fields"
import RenderContacts from "./RenderContacts"

const PatientProfile = ({ sidebar }: { sidebar: PatientSidebar }) => {
    const { t } = useTranslation()
    const patient = useCurrentPatient()
    const dispatch = useDispatch()
    const currentRole = useCurrentRole()
    const currentUser = useCurrentUser()
    const conversations = useConversations(patient?.id)

    useEffect(() => {
        if (!patient) {
            dispatch(getCurrentPatient(sidebar.patientId))
        }
    }, [sidebar])

    const caseManager = patient?.collaborators.find((c) => c.permissions.some((p) => p.permission === "case_manager"))
    const isCurrentUserCaseManager = caseManager?.user?.id === currentUser.id

    const handleEditProfile = () => {
        dispatch(openPatientModal({ patientFile: patient }))
    }

    const [isMailSending, setIsMailSending] = useState(false)
    const handleSendMessage = async () => {
        try {
            setIsMailSending(true)
            await sendEmailWithDetails(patient.id)
            toast(t("patient:whatNext.emailIsSent"), { type: "success" })
        } catch (error) {
            console.error(error)
            toast(t("common:oopsWentWrong"), { type: "error" })
        } finally {
            setIsMailSending(false)
        }
    }

    const handleOpenChat = () => {
        dispatch(openChatFromUserProfile(conversations, patient.user))
    }

    if (!patient)
        return (
            <div className="flex justify-center my-40">
                <Spinner size={25} className="text-med-blue w-9" />
            </div>
        )

    const showInsuranceDetails = patient.healthcare_provider || patient.ssn

    return (
        <Profile
            user={patient.user}
            role="patient"
            onProfileEdit={handleEditProfile}
            isEditable={currentRole.role === "admin" || isCurrentUserCaseManager}
            onSidebarGoBack={() => {
                dispatch(goToThePrevSidebar())
            }}
            onOpenChat={handleOpenChat}
            onOpenSettings={() => {
                dispatch(openFeaturesSettingsModal({ patient_id: patient.id }))
            }}
            onSendMail={handleSendMessage}
            isSendMailEnabled={!isMailSending}
            isPatient={true}
            isChatAvailable={true}
            patientName={patient.user.first_name}
        >
            <RenderProfileBlock id="patientDetails" fields={firstFieldBlock} entity={patient.user}>
                <ProfileRow
                    fieldId="patientForm.familyOr"
                    label={"patient:patientForm.familyOr"}
                    value={patient.contacts}
                    RenderValue={RenderContacts}
                />
            </RenderProfileBlock>
            <RenderProfileBlock id="treatmentDetails" fields={secondFieldBlock} entity={patient} />
            <RenderProfileBlock
                id="clinicalInfo"
                fields={thirdFieldBlock}
                entity={patient}
                noDivider={!showInsuranceDetails}
            />
            {(patient.healthcare_provider || patient.ssn) && (
                <RenderProfileBlock id="insuranceDetails" fields={fourthFieldBlock} entity={patient} noDivider />
            )}
        </Profile>
    )
}

export default PatientProfile
