type FeatureMap = Record<string, boolean>

export class FeatureFlags {
    public static map: FeatureMap = {}

    public static populate(map: FeatureMap) {
        this.map = map
    }

    public static isEnabled(featureId: string) {
        return Boolean(this.map[featureId])
    }

    public static isDisabled(featureId: string) {
        return !this.isEnabled(featureId)
    }
}
