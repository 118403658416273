import { BasicUser } from "@sequel-care/types"
import { JSONObject, Message } from "@twilio/conversations"

export type Unsubscribe = () => void
export type MessageCount = { all: number; unread: number }
export type MessageNotification = { participants: BasicUser[]; date: Date; patientId: number; conversationId: string }
export type MessageCountSubscription = (messageCount: MessageCount) => void

export type TemporaryMessage = Pick<Message, "body" | "dateCreated" | "author" | "conversation"> & { isTemp: true }

export type MessageDisplayItem = Message | TemporaryMessage | { header: true; date: string }

export type ConversationInstanceSubscriptions = {
    messages: ((messageList: MessageDisplayItem[]) => void)[]
    messageCount: MessageCountSubscription[]
}

export const isMessageDeleted = (item: MessageDisplayItem) => {
    return item instanceof Message && item.attributes && (item.attributes as JSONObject)["deleted"] === true
}
