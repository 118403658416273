import { Dialog } from "components/common"
import { EventComment } from "@sequel-care/types/Event"
import { Dispatch, SetStateAction } from "react"
import { useTranslation } from "react-i18next"
import { useCurrentUser } from "store/hooks"

type CommentDeleteDialogProps = {
    commentDeleteDialog: EventComment<string>
    setCommentDeleteDialog: Dispatch<SetStateAction<EventComment<string>>>
    onDelete: () => Promise<boolean>
}

export const CommentDeleteDialog = ({
    commentDeleteDialog,
    setCommentDeleteDialog,
    onDelete
}: CommentDeleteDialogProps) => {
    const currentUser = useCurrentUser()
    const { t } = useTranslation("patient")

    return Boolean(commentDeleteDialog) ? (
        <Dialog
            open={true}
            onClose={() => setCommentDeleteDialog(null)}
            title={t("comments.delete.title") as string}
            onConfirm={onDelete}
            type="delete"
        >
            <p className="text-sm text-text-blue">{t("comments.delete.text", { context: currentUser.gender })}</p>
        </Dialog>
    ) : null
}
