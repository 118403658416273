import { useTranslation } from "react-i18next"
import { isValidElement } from "react"
import { RenderValueFn } from "./types"
import { InformationCircleIcon } from "@heroicons/react/24/outline"
import { Tooltip } from "../common"
import { classNames } from "utils"

type ProfileRowProps<V> = {
    fieldId: string
    value: V
    RenderValue?: RenderValueFn<V>
    label: string
    infoText?: string
}

function ProfileRow<V>({ fieldId, value, RenderValue, label, infoText }: ProfileRowProps<V>) {
    const { t, i18n } = useTranslation()
    if (Array.isArray(value) ? !value.length : !value) return null

    const isPhoneRtl = i18n.language === "he" && fieldId === "phone"
    return (
        <>
            <div key={fieldId} className="w-full text-secondary-2 mt-1">
                <div className="flex ">
                    {t(label)}
                    {infoText && (
                        <Tooltip
                            placement="top"
                            content={
                                <div className="w-80">
                                    <div>{t(infoText)}</div>
                                </div>
                            }
                        >
                            <InformationCircleIcon className="h-5 w-5 ml-1 mt-0.5" />
                        </Tooltip>
                    )}
                </div>
                <div
                    className={classNames(
                        isPhoneRtl ? "justify-end" : undefined,
                        "border-x-0 px-0 py-1 focus:placeholder-dark-blue focus:text-dark-blue text-dark-blue flex flex-wrap"
                    )}
                    dir={isPhoneRtl ? "ltr" : undefined}
                >
                    {RenderValue ? (
                        <RenderValue value={value} t={t} />
                    ) : isValidElement(value) || typeof value === "string" || typeof value === "number" ? (
                        value
                    ) : null}
                </div>
            </div>
        </>
    )
}

export default ProfileRow
