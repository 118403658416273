import { PatientFileWithQuestionnaires } from "@sequel-care/types"
import { ProfileDisplayedField } from "components/Profile/types"
import { defineUserField } from "components/Profile/utils"
import { RenderDateValue, RenderCollaborators, RenderDiagnoses, RenderTags } from "./components"

type PatientType = PatientFileWithQuestionnaires<string, string>
function definePatientField<K extends keyof PatientType>(field: ProfileDisplayedField<K, PatientType[K]>) {
    return field
}

export const firstFieldBlock = [
    defineUserField({
        key: "gender",
        label: "common:gender",
        RenderValue: ({ value, t }) => t(`common:genders.${value}`)
    }),
    defineUserField({ key: "email", label: "common:email" }),
    defineUserField({ key: "phone", label: "common:phone" })
]

export const secondFieldBlock = [
    definePatientField({
        key: "treatment_start",
        label: "patient:patientForm.treatmentStartDate",
        RenderValue: RenderDateValue
    }),
    definePatientField({
        key: "treatment_end",
        label: "patient:patientForm.treatmentEndDate",
        RenderValue: RenderDateValue
    }),
    definePatientField({
        key: "treatment_end_reason",
        label: "patient:patientForm.treatment_end_reason",
        RenderValue: ({ value, t }) => t(`patient:treatmentEndForm.treatment_end_reasons.${value}`)
    }),
    definePatientField({ key: "collaborators", label: "common:collaborators", RenderValue: RenderCollaborators })
]

export const thirdFieldBlock = [
    definePatientField({ key: "tags", label: "patient:patientForm.tags", RenderValue: RenderTags }),
    definePatientField({ key: "primary_diagnosis", label: "common:primary_diagnosis", RenderValue: RenderDiagnoses }),
    definePatientField({ key: "comorbidities", label: "common:comorbidities", RenderValue: RenderDiagnoses }),
    definePatientField({ key: "medication", label: "patient:patientForm.medication" }),
    definePatientField({ key: "notes", label: "common:notes" })
]

export const fourthFieldBlock = [
    definePatientField({ key: "healthcare_provider", label: "patient:patientForm.insurance" }),
    definePatientField({ key: "ssn", label: "patient:patientForm.socialSecurityNumber" })
]
