import { Modal } from "components/common"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { FeatureFlags } from "../../utils/FeatureFlags"

export const JumpTo = () => {
    const router = useRouter()
    const [show, setShow] = useState(false)
    const isOverviewEnabled = FeatureFlags.isEnabled("overview")

    useEffect(() => {
        const detectJump = (e: KeyboardEvent) => {
            if (e.key !== "p" || (!e.ctrlKey && !e.metaKey)) return

            e.preventDefault()
            setShow(true)
        }

        window.addEventListener("keydown", detectJump)
        return () => window.removeEventListener("keydown", detectJump)
    }, [])

    return (
        <Modal show={show} theme="white" onClose={() => setShow(false)} scrollAuto centered>
            <form
                onSubmit={(e) => {
                    e.preventDefault()
                    const patientId = (e.target as HTMLFormElement).patientId.value
                    router.push(`/patient/${patientId}/${isOverviewEnabled ? "overview" : "timeline"}`)
                    setShow(false)
                }}
                className="p-6 flex items-center gap-4"
            >
                Jump to:{" "}
                <input placeholder="Patient ID" autoFocus type="number" name="patientId" className="rounded w-36" />
            </form>
        </Modal>
    )
}
