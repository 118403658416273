import { TreatmentEndReason } from "@prisma/client"
import { defaultInputClassNames, defineField } from "forms"
import { DateSelectorWithIcons } from "forms/DateSelector"
import { defineStep } from "forms/FormWithSteps"
import SuperSelect from "forms/SuperSelect"
import { getItemsFromEnum } from "forms/SuperSelect/utils"
import { useTranslation } from "react-i18next"
import { classNames } from "utils"

export const getTreatmentEndSteps = () => {
    const { t } = useTranslation("patient")
    return [
        defineStep({
            id: "treatment_end",
            name: "Treatment end",
            fieldGridColumns: 8,
            fields: [
                defineField({
                    id: "date",
                    label: t("common:date") as string,
                    initialValue: new Date(),
                    Component: DateSelectorWithIcons,
                    classList: {
                        wrapper: "flex flex-col gap-2",
                        label: "text-base text-dark-blue whitespace-nowrap",
                        input: classNames(defaultInputClassNames, "flex gap-3 justify-between w-48")
                    }
                }),
                defineField({
                    id: "reason",
                    placeholder: t("treatmentEndForm.reason"),
                    initialValue: null,
                    Component: SuperSelect<TreatmentEndReason>,
                    style: "classic",
                    withSearch: false,
                    items: getItemsFromEnum(TreatmentEndReason, (r) =>
                        t(`treatmentEndForm.treatment_end_reasons.${r}`)
                    ),
                    classList: {
                        wrapper: "col-span-5 mt-8",
                        input: classNames(defaultInputClassNames, "!p-3")
                    },
                    usePlaceholderAsAll: true
                })
            ]
        })
    ] as const
}
