import { PortalNotificationData } from "@sequel-care/types"
import UserAvatar from "components/UserAvatar"
import { CSSProperties } from "react"
import { useTranslation } from "react-i18next"
import Skeleton from "react-loading-skeleton"
import { useDispatch } from "react-redux"
import { useCurrentUser } from "store/hooks"
import { markAsRead } from "store/notifications/actions"
import { pushNotificationToSidebarStack } from "store/sidebars/actions"
import { classNames, getUserName } from "utils"
import { localeDistanceToNow } from "utils/dates"
import { useNotificationText, useUserForAvatar } from "./utils"

type NotificationItemProps = {
    notification?: PortalNotificationData
    style?: CSSProperties
    withSeparator?: boolean
    rangeTitle?: string
    onClick?: () => void
    horizontalPadding?: `px-${number}`
}

export const notificationClassNames = {
    titleHeight: "h-10",
    wrapperGap: "gap-4",
    padding: "py-4",
    rangeTitleHeight: "h-8"
}

const NotificationItem = ({
    notification,
    style,
    withSeparator = false,
    rangeTitle,
    onClick,
    horizontalPadding
}: NotificationItemProps) => {
    const dispatch = useDispatch()
    const { i18n, t } = useTranslation("notification")
    const currentUser = useCurrentUser()

    const userForAvatar = useUserForAvatar(notification)
    const userName = getUserName(userForAvatar)
    const fullName =
        notification?.read || !userName ? userName : `<b class="text-dark-blue font-medium"> ${userName} </b>`
    const __html = useNotificationText(notification, fullName, currentUser?.gender)

    const actionHandler = async () => {
        if (!notification) return
        dispatch(markAsRead([notification.id]))
        onClick?.()

        return dispatch(pushNotificationToSidebarStack(notification))
    }

    return (
        <div
            className={classNames(
                "flex flex-col cursor-pointer",
                notificationClassNames.wrapperGap,
                notificationClassNames.padding,
                horizontalPadding,
                withSeparator && "border-t border-border-blue/60"
            )}
            style={style}
            dir={i18n.language === "he" ? "rtl" : "ltr"}
            onClick={actionHandler}
        >
            {rangeTitle && <div className="text-secondary-2 text-sm">{rangeTitle}</div>}

            <div className="flex gap-4">
                {notification ? (
                    <UserAvatar className="my-1.5" user={userForAvatar} />
                ) : (
                    <Skeleton borderRadius={"50%"} width={"2rem"} height={"2rem"} />
                )}
                <div className="flex gap-4 justify-between items-start w-full">
                    <div className="flex flex-col gap-2">
                        {notification ? (
                            <div className="text-text-blue font-normal" dangerouslySetInnerHTML={{ __html }} />
                        ) : (
                            <Skeleton width={"16rem"} />
                        )}

                        <div className="text-secondary-2 text-xs">
                            {notification ? (
                                t("common:distanceAgo", {
                                    distance: localeDistanceToNow(new Date(notification.date), i18n)
                                })
                            ) : (
                                <Skeleton width="8rem" />
                            )}
                        </div>
                    </div>
                    {notification?.read === false && (
                        <div className="w-2.5 h-2.5 bg-med-blue rounded-full shrink-0 translate-y-2" />
                    )}
                </div>
            </div>
        </div>
    )
}

export default NotificationItem
