import { ApiDelete, ApiPost, ApiPut } from "./ApiRequest"
import { BasicPermission, CollaboratorWithPermissions } from "@sequel-care/types"

export const updateCollaboratorPermission = async (permission: BasicPermission) => {
    const instance = new ApiPut<BasicPermission>(`/collaborator/permission`, { permission })
    instance.callerName = updateCollaboratorPermission.name
    return instance.run()
}

export const addPatientCollaborator = async (patientId: number, permission: BasicPermission) => {
    const instance = new ApiPost<CollaboratorWithPermissions>(`/collaborator`, { permission, patientId })
    instance.callerName = addPatientCollaborator.name
    return instance.run()
}

export const removeCollaboratorPermission = async (permissionId: number) => {
    const instance = new ApiDelete(`/collaborator/permission/${permissionId}`)
    instance.callerName = removeCollaboratorPermission.name
    return instance.run()
}
