import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { RecurrenceUnit } from "types/Misc"

const useRecurrenceUnits = (isFrequency = false) => {
    const { t } = useTranslation("patient")
    return useMemo(
        () => [
            { label: isFrequency ? t("recurrence.daily") : t("dates:days"), value: "days" as RecurrenceUnit },
            { label: isFrequency ? t("recurrence.weekly") : t("dates:weeks"), value: "weeks" as RecurrenceUnit }
            // { label: isFrequency ? t("recurrence.monthly") : t("dates:months"), value: "months" as RecurrenceUnit }
        ],
        [t]
    )
}

export default useRecurrenceUnits
