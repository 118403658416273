import { useCurrentCollaborator, useCurrentPatient } from "store/hooks"
import ConversationList from "./ConversationList"
import ConversationCreate from "./ConversationCreate"
import ConversationDisplay from "./ConversationDisplay"
import { ConversationSidebar as ConversationSidebarData } from "types/AppSidebar"

const ConversationSidebar = ({ sidebar }: { sidebar: ConversationSidebarData }) => {
    const currentPatient = useCurrentPatient()
    const currentCollaborator = useCurrentCollaborator()

    if (sidebar?.type !== "conversation" || !currentCollaborator) return null

    if (sidebar.mode === "list") return <ConversationList currentPatient={currentPatient} />

    if (sidebar.mode.startsWith("create"))
        return <ConversationCreate currentPatient={currentPatient} inSidebar={true} mode={sidebar.mode} />

    if (sidebar.mode === "chat") return <ConversationDisplay {...sidebar} inSidebar={true} />
}

export default ConversationSidebar
