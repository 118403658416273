import { FlatEvent } from "@sequel-care/types"
import { OptionsPopover } from "components/PatientTimeline/EventCard/OptionsPopover"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { EventDeleteDialog } from "sidebars/Event/EventDeleteDialog"
import { useDispatch } from "store"
import { useCurrentCollaborator, useCurrentRole } from "store/hooks"
import { pushToLibraryStack } from "store/patient/actions"
import { EventDeleteDialogData } from "types/Misc"

type EventMenuProps = {
    event: FlatEvent<string>
    date: Date
}

const EventMenu = ({ event, date }: EventMenuProps) => {
    const [eventDeleteDialog, setEventDeleteDialog] = useState<EventDeleteDialogData>(null)
    const dispatch = useDispatch()

    const currentRole = useCurrentRole()
    const currentCollaborator = useCurrentCollaborator()
    const currentPermission = currentCollaborator?.permissions[0].permission

    const canEdit =
        !event.completed_at &&
        (currentPermission === "case_manager" ||
            (currentPermission === "crud" && event.created_by === currentRole.user_id))

    const { t } = useTranslation()
    const options = useMemo(() => {
        if (!canEdit) return null

        return [
            {
                label: t("common:edit"),
                description: t("patient:eventManager.editDetails"),
                callback: (event: FlatEvent<string>) => {
                    dispatch(
                        pushToLibraryStack({
                            view: event.recurrence ? "select_policy" : "config",
                            eventTemplates: event,
                            configProps: {
                                mode: "edit"
                            },
                            patientId: event.patient_id
                        })
                    )
                }
            },
            {
                label: t("common:delete"),
                description: t("patient:eventManager.removeEvent"),
                callback: (event: FlatEvent<string>) => setEventDeleteDialog({ event, policy: "single" })
            }
        ]
    }, [date, event, canEdit])

    return canEdit ? (
        <div className="absolute top-3 right-5 z-[20]">
            <OptionsPopover options={options} param={event} iconAlignment="vertical" buttonWidth="w-8" />
            <EventDeleteDialog {...{ eventDeleteDialog, setEventDeleteDialog }} />
        </div>
    ) : null
}

export default EventMenu
