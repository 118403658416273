import { Gender } from "@prisma/client"
import { FormControlProps } from "forms/types"
import { useDiagnosisTree } from "hooks/useDiagnosisTree"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { usePatientTags } from "store/hooks"
import SuperSelect from "."
import { BasicItem, SuperSelectProps } from "./types"

export function TagSelect<WithAddNew extends boolean = false>({
    info,
    withAddNew,
    value,
    setValue,
    ...selectProps
}: FormControlProps<WithAddNew extends true ? (number | string)[] : number[]> &
    Partial<SuperSelectProps<number[]>> & {
        withAddNew?: WithAddNew
    }) {
    const allPatientTags = usePatientTags()
    const items = useMemo(
        () =>
            allPatientTags
                .map<BasicItem>(({ id, name }) => ({ id: id, label: name }))
                .concat(value.filter((v) => typeof v === "string").map((v: string) => ({ id: v, label: v }))),
        [allPatientTags, value.filter((v) => typeof v === "string").length]
    )

    return (
        <SuperSelect
            {...selectProps}
            {...{ value, setValue, items }}
            onAddNew={withAddNew ? (newTag) => setValue((value) => [...value, newTag] as typeof value) : undefined}
        />
    )
}

export function GenderSelect<T extends "multi" | "single" = "multi">({
    info,
    ...selectProps
}: FormControlProps<T extends "multi" ? Gender[] : Gender> &
    Omit<SuperSelectProps<T extends "multi" ? Gender[] : Gender>, "items" | "style">) {
    const { t } = useTranslation("common")
    const genderItems = useMemo(
        () => Object.values(Gender).map((gender) => ({ id: gender, label: t(`genders.${gender}`) })),
        [t]
    )

    return <SuperSelect items={genderItems} withSearch={false} {...selectProps} />
}

export function DiagnosisSelect<V extends number | number[]>({
    withDefault = false,
    ...props
}: FormControlProps<V> & Omit<SuperSelectProps<V>, "items"> & { withDefault?: boolean }) {
    const items = useDiagnosisTree(withDefault)
    return <SuperSelect {...props} items={items} contentWidth="w-100" menuOffset={4} />
}
