import { sortBy } from "lodash"
import { useCallback } from "react"
import { BasicItem } from "./types"

export function useSearchForItem<ItemType extends BasicItem>(items: ItemType[]) {
    return useCallback(
        (itemId: number | string) => {
            const find: (items: ItemType[]) => ItemType = (items: ItemType[]) => {
                for (const item of items) {
                    if (item.id === itemId) return item
                    if ("children" in item) {
                        const foundChild = find(item.children as ItemType[])
                        if (foundChild) return foundChild
                    }
                }

                return null
            }

            return find(items)
        },
        [items]
    )
}

export function getItemsFromEnum<E extends Record<string | number, string | number>>(
    e: E,
    getLabel: (item: E[keyof E]) => string
): { id: E[keyof E]; label: string }[] {
    return sortBy(
        Object.values(e).map((item: E[keyof E]) => ({ id: item, label: getLabel(item) })),
        "label"
    )
}
