import { ExerciseQuestionType, QuestionType } from "@prisma/client"
import { Fragment } from "react"
import { isGuideline, isScaleGroup } from "types/Misc"
import { classNames } from "utils"
import { useQuestionGridContext } from "./context"
import { ScaleGuideline } from "./ScaleGuideline"
import { GridItem, RenderResponseFn, RenderScaleGroupFn } from "./types"

export { QuestionGridContext, useQuestionGridContext, useQuestionScale } from "./context"
export type { ContextWithExercise, ContextWithQuestionnaire } from "./context"
export { ScaleGuideline } from "./ScaleGuideline"
export type { RenderResponseFn, RenderScaleGroupFn } from "./types"

export const QuestionGrid = ({
    items,
    insideScaleGroup,
    responseComponents,
    RenderScaleGroup,
    gridTemplateColumns = "grid-cols-2"
}: {
    items: GridItem[]
    insideScaleGroup?: boolean
    responseComponents: Record<QuestionType | ExerciseQuestionType, RenderResponseFn>
    RenderScaleGroup?: RenderScaleGroupFn
    gridTemplateColumns?: `grid-cols-${string}`
}) => {
    const { responsesByQuestionId } = useQuestionGridContext()
    return (
        <div className={classNames("grid gap-y-5 gap-x-8 font-normal", gridTemplateColumns)}>
            {items.map((item, index) => {
                if (isScaleGroup(item)) {
                    if (!RenderScaleGroup) return null

                    const position =
                        items.length === 1 ? "single" : !index ? "first" : index === items.length - 1 ? "last" : null

                    const previousIsScaleGroup = isScaleGroup(items[index - 1])
                    const isFirst = position === "single" || position === "first"

                    return (
                        <div
                            key={`scale-group-${index}`}
                            className={classNames(
                                "col-span-2 border-border-blue",
                                !isFirst ? "py-4" : "pb-4",
                                !isFirst && !previousIsScaleGroup && "border-t",
                                position !== "last" && position !== "single" && "border-b",
                                previousIsScaleGroup && "-mt-2"
                            )}
                        >
                            <ScaleGuideline
                                question={item.questions[0]}
                                className={position !== "single" ? "pb-4" : "pb-6 -mt-2"}
                            />
                            <RenderScaleGroup items={item.questions} />
                        </div>
                    )
                }

                if (isGuideline(item)) return <div />

                const RenderResponse = responseComponents[item.type]
                const value = responsesByQuestionId?.[item.id]
                return (
                    <Fragment key={item.id}>
                        <div
                            className="font-medium text-secondary-2 break-words"
                            data-testid={`sidebar-question-${item.id}`}
                        >
                            {!insideScaleGroup && item.type.includes("scale") && <ScaleGuideline question={item} />}
                            {item.question}
                        </div>
                        {!responsesByQuestionId || (typeof value !== "undefined" && value !== null) ? (
                            <RenderResponse
                                question={item}
                                value={value}
                                groupSize={insideScaleGroup ? items.length : undefined}
                            />
                        ) : (
                            <div></div>
                        )}
                    </Fragment>
                )
            })}
        </div>
    )
}
