import { getUserName } from "utils"
import { useTranslation } from "react-i18next"
import { BasicUser } from "@sequel-care/types"
import UserAvatar from "components/UserAvatar"
import { ChevronLeftIcon } from "@heroicons/react/24/outline"
import { OrgRole, Profession } from "@prisma/client"

const ProfileHeader = ({
    user,
    profession,
    role,
    onSidebarGoBack
}: {
    user: BasicUser
    profession: Profession
    role?: OrgRole | "patient"
    onSidebarGoBack: () => void
}) => {
    const { t } = useTranslation("common")
    return (
        <div className="flex flex-col gap-4 items-center">
            <div className="flex w-full justify-end">
                <ChevronLeftIcon
                    onClick={onSidebarGoBack}
                    className="cursor-pointer hover:scale-110 hover:text-dark-blue w-6 absolute ltr:left-5 rtl:right-5 top-10"
                />
            </div>
            <UserAvatar size={40} user={user} noImageUrl={"/images/no-picture.png"} />
            <div className="flex flex-col items-center text-dark-blue">
                <span className="font-medium text-lg">{getUserName(user)}</span>
                <span className="font-medium text-sm">
                    {profession ? t(`common:professions.${profession}`) : null} ({t(`roles.${role}`)})
                </span>
            </div>
        </div>
    )
}

export default ProfileHeader
