import { DateSelectorWithIcons } from "forms/DateSelector"
import SuperSelect from "forms/SuperSelect"
import { Filters } from "pages/administration/export"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { createSetter } from "utils"

type TreatmentStartFilter = Filters["treatment_start"]
type Range = "before" | "after"
const ranges = ["before", "after"] as Range[]

const TreatmentStart = ({
    value,
    setTreatment
}: {
    value: TreatmentStartFilter
    setTreatment: (value: TreatmentStartFilter) => void
}) => {
    const { t } = useTranslation("dates")
    const items = useMemo<{ id: Range; label: string }[]>(
        () => ranges.map((range) => ({ id: range, label: t(range) })),
        [ranges]
    )
    return (
        <div className="flex gap-8">
            <SuperSelect
                id="treatment-start-prefix"
                value={value.prefix}
                items={items}
                setValue={createSetter(setTreatment, "prefix")}
                classList={{
                    input: "flex items-center justify-between border border-border-blue text-dark-blue px-4 py-3 h-[3.125rem] w-32"
                }}
                withSearch={false}
            />
            <DateSelectorWithIcons
                id="treatment_start"
                value={value.date ?? new Date()}
                setValue={createSetter(setTreatment, "date")}
                classList={{
                    wrapper: "flex gap-4 items-center justify-between border border-border-blue text-dark-blue px-4",
                    input: "flex gap-3 justify-between w-48"
                }}
                placeholder={t("patient:export.chooseDate")}
            />
        </div>
    )
}

export default TreatmentStart
