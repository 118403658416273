import { JWTPayload, ProxiedAuth0Client } from "./types"
import { minuteInMilliseconds } from "./utils"

type SubscriptionFn = (jwtExpiry?: number) => void

export class InactivityTracker {
    private _subscriptions: SubscriptionFn[] = []
    private refreshTimeout: ReturnType<typeof setTimeout>

    private _jwtExpiry = Infinity
    public get jwtExpiry() {
        return this._jwtExpiry * 1000 // We multiply by 1000 here to have _jwtExpiry in milliseconds
    }

    private _expiryWindow: number
    public get expiryWindow() {
        return this._expiryWindow
    }

    constructor(private auth0: ProxiedAuth0Client) {
        if (typeof window !== "undefined") {
            const resetLastActivity = () => this.registerUIActivity()
            window.addEventListener("click", resetLastActivity)
            window.addEventListener("keydown", resetLastActivity)
            window.addEventListener("visibilitychange", () => {
                if(document.visibilityState === "visible" && this.jwtExpiry < Date.now())
                    auth0.logout()
            })
        }
    }

    public get expiryData(){
        const expiresIn = this.jwtExpiry - Date.now()
        return { expiresIn, minutesToExpiry: Math.floor(expiresIn / minuteInMilliseconds) }
    }

    public registerUIActivity() {
        if (this.refreshTimeout)
            clearTimeout(this.refreshTimeout)

        const expiryOnStart = this.jwtExpiry
        const refetchToken = () => {
            if (this.jwtExpiry === expiryOnStart)
                // The token has not been refreshed while this timeout was waiting
                this.auth0.getTokenSilently({ ignoreCache: true })
        }

        if(this.expiryData.minutesToExpiry < 1)
            refetchToken()
        else
            this.refreshTimeout = setTimeout(() => {
                // This will run every time there's a click or keydown event on the window. These events don't always trigger an api call,
                // which means the token is not necessarily refreshed. To keep our token's expiry in sync with actual activity,
                // we call getTokenSilently here after fifteen seconds of inactivity.
                this.refreshTimeout = null
                refetchToken()
            }, 60000 /* 60 seconds */)
    }

    public subscribe(subscription: SubscriptionFn) {
        this._subscriptions.push(subscription)
        subscription(this.jwtExpiry)
    }

    public unsubscribe(subscription: SubscriptionFn) {
        this._subscriptions = this._subscriptions.filter((s) => s !== subscription)
    }

    public updateExpiry(jwt: string) {
        const decodedJwt = this.decodeJwt(jwt)
        if (!decodedJwt || decodedJwt.exp === this.jwtExpiry) return

        this._jwtExpiry = decodedJwt.exp
        this._expiryWindow = this.jwtExpiry - Date.now()
        this._subscriptions.forEach((subscription) => subscription(this.jwtExpiry))
    }

    private decodeJwt(token: string) {
        if (typeof window === "undefined") return null

        var base64Url = token.split(".")[1]
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
        var jsonPayload = decodeURIComponent(
            window
                .atob(base64)
                .split("")
                .map(function (c) {
                    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
                })
                .join("")
        )

        return JSON.parse(jsonPayload) as JWTPayload
    }
}
