import { XMarkIcon } from "@heroicons/react/24/solid"
import { Modal } from "components/common"
import { isArray } from "lodash"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useDispatch } from "store"
import { closeContentLibrary } from "store/patient/actions"
import { RootState } from "types/Redux"
import { classNames } from "utils"
import ExerciseDetails from "./Details/ExerciseDetails"
import PlanDetails from "./Details/PlanDetails"
import QuestionnaireDetails from "./Details/QuestionnaireDetails"
import EventConfig from "./EventConfig"
import QuestionnaireEventPackConfig from "./EventConfig/QuestionnairePackConfig"
import ExerciseLibrary from "./ExerciseLibrary"
import QuestionnaireLibrary from "./QuestionnaireLibrary"
import SelectEditPolicy from "./SelectEditPolicy"
import SelectType from "./SelectType"

const ContentLibrary = () => {
    const dispatch = useDispatch()
    const { i18n } = useTranslation("patient")

    const contentLibrary = useSelector((state: RootState) => state.patient.contentLibrary)
    const libData = contentLibrary.stack.at(-1)
    const withBack = contentLibrary.stack.length > 1

    const onClose = useCallback(() => dispatch(closeContentLibrary()), [])

    const eventTemplate = isArray(libData?.eventTemplates) ? libData.eventTemplates[0] : libData?.eventTemplates
    const isConfig = libData?.view === "config" && "eventTemplates" in libData
    const isCardView = libData?.view.endsWith(".cards")

    return (
        <Modal show={Boolean(libData)} onClose={onClose} theme="white" centered>
            {libData && libData.view !== "select_policy" && libData.view !== "select_type" && (
                <div className={classNames("flex w-[78rem] h-[85vh]")} dir={i18n.language === "he" ? "rtl" : "ltr"}>
                    {isCardView && libData.view === "questionnaire.cards" && (
                        <QuestionnaireLibrary searchInitialValue={libData.search ?? ""} />
                    )}
                    {isCardView && libData.view === "exercise.cards" && (
                        <ExerciseLibrary searchInitialValue={libData.search ?? ""} />
                    )}
                    {!isCardView && (
                        <div className={classNames("grow h-full flex flex-col")}>
                            {isConfig &&
                                (isArray(libData.eventTemplates) ? (
                                    <QuestionnaireEventPackConfig
                                        patientId={contentLibrary.patientId}
                                        events={libData.eventTemplates}
                                        planId={libData.planId}
                                    />
                                ) : (
                                    <EventConfig patientId={contentLibrary.patientId} {...{ eventTemplate, libData }} />
                                ))}
                            {libData?.view === "learn_more" ? (
                                Array.isArray(libData?.eventTemplates) && libData?.planId ? (
                                    <PlanDetails
                                        planId={libData?.planId}
                                        eventTemplates={libData?.eventTemplates}
                                        withBack={withBack}
                                    />
                                ) : eventTemplate.type === "questionnaire" ? (
                                    <QuestionnaireDetails
                                        questionnaireId={eventTemplate.questionnaire_id}
                                        withBack={withBack}
                                        enableAdd={Boolean(contentLibrary.patientId)}
                                    />
                                ) : (
                                    <ExerciseDetails exerciseId={eventTemplate.exercise_id} withBack={withBack} />
                                )
                            ) : null}
                        </div>
                    )}
                </div>
            )}
            {libData?.view === "select_policy" && <SelectEditPolicy />}
            {libData?.view === "select_type" && <SelectType />}
            <XMarkIcon
                className="absolute top-4 right-4 w-6 z-100 cursor-pointer hover:scale-110 transition-transform"
                onClick={onClose}
            />
        </Modal>
    )
}

export default ContentLibrary
