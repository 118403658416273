import { useEffect, useState } from "react"
import ConversationStore from "./ConversationStore"
import SequelConversation from "./SequelConversation"
import { MessageCount, MessageDisplayItem, MessageNotification } from "./types"
import { ChatPreference } from "@prisma/client"
import { getChatPreferencesApiCall } from "../../api/Chat"
import { ChatMigrationDataPayload } from "@sequel-care/types"
import { useCurrentUser } from "store/hooks"

export const useConversations = (patientId?: number) => {
    const [conversations, setConversations] = useState<SequelConversation[]>([])
    useEffect(() => ConversationStore.subscribe("conversations", setConversations, patientId), [patientId])
    return conversations
}

export const useConversationPreferences = (conversations: SequelConversation[]) => {
    const migrationPayload: ChatMigrationDataPayload[] = conversations.map((c) => ({
        sid: c.sid,
        users: c.participants.map((p) => p.id)
    }))
    const [preferences, setPreferences] = useState<ChatPreference[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    useEffect(() => {
        setIsLoading(true)
        getChatPreferencesApiCall(migrationPayload)
            .then(setPreferences)
            .then((_) => setIsLoading(false))
    }, [])
    return { isLoading, preferences }
}

export const useConversationConfig = (conversation: SequelConversation) => {
    const currentUser = useCurrentUser()

    return conversation.configuration?.[currentUser.id]
}

export const useMessages = (conversation: SequelConversation) => {
    const [messages, setMessages] = useState<MessageDisplayItem[]>([])
    useEffect(() => {
        if (conversation) return conversation.subscribe("messages", setMessages)
    }, [conversation])
    return messages
}

export function useMessageCount(): MessageCount
export function useMessageCount(options: { conversation: SequelConversation }): MessageCount
export function useMessageCount(options: { patientId: number }): MessageCount
export function useMessageCount(options: { patientId?: number; conversation?: SequelConversation } = {}): MessageCount {
    const [messageCount, setMessageCount] = useState<MessageCount>({ all: 0, unread: 0 })
    useEffect(() => {
        return "conversation" in options
            ? options.conversation.subscribe("messageCount", setMessageCount)
            : ConversationStore.subscribe("messageCount", setMessageCount, options.patientId)
    }, [options.patientId, options.conversation])
    return messageCount
}

export const useMessageNotifications = () => {
    const [messageNotifications, setMessageNotifications] = useState<MessageNotification[]>([])
    useEffect(() => {
        return ConversationStore.subscribe("messageNotifications", setMessageNotifications)
    }, [])
    return messageNotifications
}
