import { ById } from "@sequel-care/types/utils"
import { Tooltip } from "components/common"
import { Fragment, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { noopTFunction } from "utils"
import { GraphItem } from "./types"

type GraphLegendProps = {
    items: ById<GraphItem>
    activeIds: string[]
    toggleItem: (id: string) => void
}

const getLifeEventsItem = (t = noopTFunction) => {
    const title = t("measures.life_events")
    return {
        id: "t",
        color: "#46B5F4",
        friendly_name: title,
        title: title
    } as GraphItem
}

const GraphLegend = ({ items, activeIds, toggleItem }: GraphLegendProps) => {
    const { t } = useTranslation("questionnaire")
    const sortedItems = useMemo(() => {
        return Object.values(items).sort((itemA) => (itemA.id === "q0" || itemA.id.includes("t") ? -1 : 1))
    }, [items])

    return (
        <div className="flex gap-x-2 gap-y-4 mt-3 mb-10 min-h-[2.5rem] items-center flex-wrap w-5/6">
            {items &&
                sortedItems.map((item, index) => {
                    if (item.id.includes("t"))
                        return index ? (
                            <>
                                <LegendItem
                                    isActive={activeIds.some((id) => id.includes("t"))}
                                    item={getLifeEventsItem(t)}
                                    toggleItem={toggleItem}
                                />
                                <hr className="h-6 border-l-2 rounded mx-2.5" />
                            </>
                        ) : null

                    const isActive = activeIds.includes(item.id)

                    return (
                        <Fragment key={item.id}>
                            <LegendItem {...{ isActive, item, toggleItem }} />
                            {item.id === "q0" && <hr className="h-6 border-l-2 rounded mx-2.5" />}
                        </Fragment>
                    )
                })}
        </div>
    )
}

export default GraphLegend

const LegendItem = ({
    isActive,
    item,
    toggleItem
}: {
    isActive: boolean
    item: GraphItem
    toggleItem: GraphLegendProps["toggleItem"]
}) => {
    return (
        <button
            data-testid="graph-legend_tag"
            className="rounded-full px-4 py-1 border cursor-pointer text-center"
            style={{
                background: isActive && item.color,
                color: isActive ? "white" : item.color,
                borderColor: item.color
            }}
            onClick={() => toggleItem(item.id)}
            type="button"
        >
            {"title" in item ? (
                <Tooltip content={item.title} placement="bottom">
                    {item.friendly_name}
                </Tooltip>
            ) : (
                item.name
            )}
        </button>
    )
}
