import { OrgRole } from "@prisma/client"
import { OrganizationUser, PatientWithDiagnoses } from "@sequel-care/types"
import { SkeletonPlaceholder } from "types/Misc"
import { CompleteUserInOrg } from "types/SchemaExtensions"

export const statuses = ["enabled", "disabled"] as const
export type AuthStatus = (typeof statuses)[number]

export type TeamMemberFilters = {
    role: OrgRole
    query: string
    status: AuthStatus
}

export type AnyUser = PatientWithDiagnoses<string, string> | CompleteUserInOrg<string> | OrganizationUser

export type RenderFieldProps<ValueType = any> = {
    value: ValueType
    isSkeleton: boolean
    classes?: string
    onClick?: () => void
}

export type ListField<Item> = {
    key: keyof Item
    RenderField: (props: RenderFieldProps) => JSX.Element
}

export type ItemListProps<Item> = {
    items: Item[]
    onItemClick?: (item: Item) => void
    fields: ListField<Item>[]
    actions?: ((props: { item: Item | SkeletonPlaceholder }) => JSX.Element)[]
    ifDisabled?: (item: Item) => boolean
}
