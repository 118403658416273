import React, { SVGProps } from "react"

const NotificationBellOff = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 -960 960 960"
        stroke="currentColor"
        {...props}
    >
        <path
            d="M160-200v-60h80v-304q0-33 8.25-65T273-690l45 45q-9 19-13.5 39.46T300-564v304h319L75-805l42-42 726 727-42 42-122-122H160zm560-129l-60-60v-175q0-75-52.5-127.5T480-744q-35.135 0-67.568 13Q380-718 356-693l-46-45q23-23 50.5-38t59.5-22v-22q0-25 17.5-42.5T480-880q25 0 42.5 17.5T540-820v22q81 17 130.5 83.5T720-564v235zm-260-91zm20 340q-33 0-56.5-23.5T400-160h160q0 33-23.5 56.5T480-80zm28-461z"></path>
    </svg>
)

export default NotificationBellOff;
