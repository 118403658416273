import { RelationType } from "@prisma/client"
import { BasicUser, ContactUser } from "@sequel-care/types"
import { useMemo } from "react"
import { useCurrentUser } from "store/hooks"
import { getUserName } from "utils"
import { SequelConversation } from "utils/conversations"

export type ConversationParticipant = BasicUser & {
    relation_type?: RelationType
    patient_name?: string
    profession?: string
}

export const useParticipantsWithoutCurrentUser = (conversation: SequelConversation, override?: BasicUser[]) => {
    const currentUser = useCurrentUser()
    return useMemo(() => {
        return override ?? conversation?.participants.filter((user) => user.id !== currentUser.id) ?? []
    }, [conversation?.participants, override, currentUser])
}

export const matchQuery = (user: BasicUser, query: string) =>
    !query || getUserName(user).toLowerCase().includes(query.toLowerCase())

export const patientContactsToParticipants = (contacts: ContactUser[], patient_name?: string) =>
    contacts
        .filter(({ has_auth }) => has_auth)
        .map((contact) => ({
            ...contact,
            relation_type: contact.relation_type,
            patient_name
        }))
