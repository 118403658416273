import { ScoreData } from "@sequel-care/types/Event"
import { ById } from "@sequel-care/types/utils"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useGraphByKey } from "store/hooks"
import { GraphType } from "types/Redux"
import { GRAPH_STYLES } from "utils/graphs"
import { Dataset, GraphItem } from "../types"
import { getGraphData } from "./getGraphData"
import { getGraphOptions, getOnGraphClick } from "./getGraphOptions"

const useGraphProps = ({
    loading,
    graphKey,
    items,
    activeIds,
    scoresById,
    alwaysUseEventId
}: {
    loading: boolean
    graphKey: GraphType
    items: ById<GraphItem>
    activeIds: string[]
    scoresById: ById<ScoreData[]>
    alwaysUseEventId?: boolean
}) => {
    const { i18n, t } = useTranslation()
    const graphState = useGraphByKey(graphKey)

    const graphProps = useMemo(() => {
        const graphData = getGraphData({ items, loading, graphState, scoresById, alwaysUseEventId })
        const hasScores = Object.values(items).some(
            ({ id }) =>
                activeIds.includes(id) &&
                graphData.scores?.[id]?.some((v) => (graphState.resolution === "day" ? v !== null : Boolean(v?.score)))
        )

        let noDataIndication = ""
        if (!loading) {
            if (!activeIds.length) noDataIndication = t("patient:dashboard.selectItem")
            else if (!hasScores) noDataIndication = t("patient:dashboard.noData")
        }

        if (!items || !hasScores)
            return {
                options: getGraphOptions({ activeIds, datasets: [] }),
                noDataIndication,
                labels: null as string[],
                datasets: []
            }

        const datasets = getDatasets({ items, graphData, activeIds })

        return {
            options: getGraphOptions({
                items,
                activeIds,
                onClick: getOnGraphClick({ datasets, graphState }),
                hasData: !noDataIndication,
                datasets
            }),
            labels: graphData.labels,
            datasets,
            noDataIndication
        }
    }, [items, activeIds, scoresById, loading, graphState, i18n.language])

    return graphProps
}

const getDatasets = ({
    items,
    activeIds,
    graphData
}: {
    items: ById<GraphItem>
    activeIds: string[]
    graphData: ReturnType<typeof getGraphData>
}) => {
    return Object.values(items)
        .filter(({ id }) => activeIds.includes(id) && graphData.scores[id])
        .map<Dataset>((item) => {
            const { id, max_score, color } = item
            const scores = graphData.scores[id]

            return {
                spanGaps: true,
                type: item.type,
                label: "title" in item ? item.title : item.name,
                data: scores.map((scoreEvent) => {
                    if (!scoreEvent || !("score" in scoreEvent) || scoreEvent.score === null) return null
                    const scoreResult =
                        activeIds.length > 1 ? Math.round(scoreEvent.score * (100 / max_score)) : scoreEvent.score

                    return scoreResult > 100 ? 100 : scoreResult
                }),
                rawData: scores,
                itemId: id,
                cutoffs: [],
                backgroundColor: color,
                borderColor: color,
                pointBorderColor: GRAPH_STYLES.COLORS.border_blue,
                pointRadius: 4,
                pointHoverRadius: 6,
                hoverBackgroundColor: color,
                borderRadius: 2,
                barThickness: 2,
                maxBarThickness: 4,
                animation: {
                    duration: item.type === "bar" ? 0 : 1000
                }
            }
        })
}

export default useGraphProps
