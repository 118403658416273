import Settings from "../icons/Settings"
import EditIcon from "../icons/Edit"
import React from "react"
import { MessageIcon } from "../icons"
import LetterAsterisk from "../icons/LetterAsterisk"
import { Tooltip } from "../common"
import { useTranslation } from "react-i18next"

const ProfileActions = (
    {
        isPatient,
        isEditable,
        isSendMailEnabled,
        isChatAvailable,
        onSendMail,
        onProfileEdit,
        onOpenSettings,
        onOpenChat,
        patientName,
    }: {
        isPatient: boolean
        isEditable: boolean
        isSendMailEnabled: boolean
        isChatAvailable: boolean
        onSendMail: () => void
        onProfileEdit: () => void
        onOpenSettings: () => void
        onOpenChat: () => void
        patientName?: string
    }
) => {
    const { t } = useTranslation()
    return (
        <div className="flex flex-row justify-center gap-4 items-center">
            {
                isPatient && <Tooltip
                    content={<div className="text-sm pb-1">{t("patientActions.sendMail", { name: patientName })}</div>}
                    placement="bottom"
                >
                    <LetterAsterisk
                        color="#3C7FF9"
                        style={{
                            backgroundColor: "rgba(60,127,249,0.15)",
                            borderRadius: 4,
                            width: 40,
                            height: 40,
                            paddingTop: 11,
                            opacity: isSendMailEnabled ? 1 : 0.5
                        }}
                        onClick={() => {
                            if (isSendMailEnabled) onSendMail()
                        }}
                        className="w-6 h-6 cursor-pointer p-2" />
                </Tooltip>
            }
            {
                isEditable && <Tooltip
                    content={<div className="text-sm pb-1">{t("patientActions.edit")}</div>}
                    placement="bottom"
                >
                    <EditIcon
                        color="#3C7FF9"
                        style={{ backgroundColor: "rgba(60,127,249,0.15)", borderRadius: 4, width: 40, height: 40 }}
                        onClick={onProfileEdit}
                        className="w-6 h-6 cursor-pointer p-2" />
                </Tooltip>
            }
            {
                isPatient && <Tooltip
                    content={<div className="text-sm pb-1">{t("patientActions.features")}</div>}
                    placement="bottom"
                >
                    <Settings
                        color="#3C7FF9"
                        style={{ backgroundColor: "rgba(60,127,249,0.15)", borderRadius: 4, width: 40, height: 40 }}
                        onClick={onOpenSettings}
                        className="w-6 h-6 cursor-pointer p-2" />
                </Tooltip>
            }
            {
                isChatAvailable && <Tooltip
                    content={<div className="text-sm pb-1">{t("patientActions.chat")}</div>}
                    placement="bottom"
                >
                    <MessageIcon
                        color="#3C7FF9"
                        style={{ backgroundColor: "rgba(60,127,249,0.15)", borderRadius: 4, width: 40, height: 40 }}
                        onClick={onOpenChat}
                        className="w-6 h-6 cursor-pointer p-2.5" />
                </Tooltip>
            }
        </div>
    )
}

export default ProfileActions
