import { useMemo, useState } from "react"
import { PlusIcon } from "@heroicons/react/24/outline"
import Search from "components/Search"
import { ifUserDisabled, ItemGrid } from "components/Settings/ItemList"
import { useTranslation } from "react-i18next"
import store, { useDispatch } from "store"
import { useCurrentRole, useUsersInCurrentOrg } from "store/hooks"
import { toggleTherapistProfile } from "store/sidebars/actions"
import { CompleteUserInOrg } from "types/SchemaExtensions"
import { createSetter, getUserName } from "utils"
import { openUserModal } from "../../store/modal/actions"
import SettingsSection from "./SettingsSection"
import { AuthFilter, RoleFilter, ToggleAuthAction } from "./TeamListComponents"
import { TeamMemberFilters } from "./types"
import { teamFields } from "./utils"

const actions = [
    {
        Icon: <PlusIcon className="w-4" />,
        translationKey: "newMember",
        onClick: () => store.dispatch(openUserModal()),
        testId: `create-user-btn`
    }
]

const labelKeys = ["therapistName", "profession", "role", "active", "actions"]

const TeamSettings = () => {
    const usersInOrg = useUsersInCurrentOrg()
    const { t } = useTranslation("patient")
    const currentRole = useCurrentRole()
    const dispatch = useDispatch()
    const [filters, setFilters] = useState<TeamMemberFilters>({
        role: undefined,
        query: "",
        status: undefined
    })

    const labels = useMemo(() => labelKeys.map((key) => t(`therapist:${key}`)), [t])

    const filteredUsers = useMemo(
        () =>
            usersInOrg
                .filter(({ user, role }) => {
                    const userName = getUserName(user)
                    const query = filters.query.toLowerCase()

                    const matchesRole = !filters.role || role === filters.role
                    const matchesAuth = !filters.status || filters.status === (user.has_auth ? "enabled" : "disabled")

                    return (
                        (userName.toLowerCase().includes(query) || user.email.includes(query)) &&
                        matchesRole &&
                        matchesAuth
                    )
                })
                .sort((a, b) => (a.user.has_auth ? -1 : b.user.has_auth ? 1 : 0)),
        [filters, usersInOrg]
    )

    function onFilterChange<K extends keyof TeamMemberFilters>(key: K) {
        return createSetter(setFilters, key)
    }

    return (
        <SettingsSection title="teamMembers" actions={currentRole.role === "admin" ? actions : undefined}>
            <div className="mx-10 flex flex-col gap-16 h-full w-full">
                <div className="flex gap-4">
                    <RoleFilter role={filters.role} setRole={onFilterChange("role")} />
                    <AuthFilter status={filters.status} setStatus={onFilterChange("status")} />
                    <Search
                        search={filters.query}
                        setSearch={onFilterChange("query")}
                        wrapperClassname="!text-dark-blue bg-transparent border border-border-blue"
                        placeholder={t("common:searchNameOrEmail")}
                    />
                </div>
                <ItemGrid
                    items={filteredUsers}
                    onItemClick={(user: CompleteUserInOrg<string>) => {
                        dispatch(toggleTherapistProfile(user.user_id))
                    }}
                    ifDisabled={ifUserDisabled}
                    actions={currentRole.role === "admin" ? [ToggleAuthAction] : []}
                    gridTemplateColumns={"2fr 1fr 1fr 1fr 0.3fr"}
                    labels={labels}
                    labelsBg="bg-bg"
                    fields={teamFields}
                />
            </div>
        </SettingsSection>
    )
}

export default TeamSettings
