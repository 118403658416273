import { Button } from "components/common"
import CloudImg from "components/common/CloudImg"
import { FormControlProps } from "forms/types"
import { useTranslation } from "react-i18next"
import { classNames } from "utils"
import { isFile } from "./utils"

export type ProfileImage = string | File

const ProfilePictureUpload = ({ value, setValue, classList }: FormControlProps<ProfileImage>) => {
    const { t } = useTranslation("patient")

    return (
        <div className={classNames("flex flex-col items-center gap-4 translate-x-3", classList?.wrapper)}>
            <CloudImg
                src={isFile(value) ? URL.createObjectURL(value) : value?.replace?.("[variant]", "large")}
                noImageId="no-picture"
                variant="medium"
                width={180}
                height={180}
                className="rounded-full"
            />

            <label className="bg-dark-blue text-white text-sm rounded hover:bg-opacity-95 cursor-pointer flex justify-center items-center py-4 px-11 mt-4">
                {t("patientForm.uploadPhoto")}
                <input
                    type="file"
                    hidden
                    accept=".jpg,.jpeg,.png"
                    onChange={(event) => setValue(event.target.files[0])}
                />
            </label>

            <Button
                theme="dark_borderless"
                onClick={() => setValue(null)}
                type="button"
                className={classNames(
                    "!text-sm transition-opacity duration-75",
                    Boolean(isFile(value) || (typeof value === "string" && value))
                        ? "opacity-100"
                        : "opacity-0 pointer-events-none"
                )}
            >
                {t("patientForm.removePhoto")}
            </Button>
        </div>
    )
}

export default ProfilePictureUpload
