import { Tag } from "components/common/Tag"
import { AlertTriangleIcon } from "components/icons"
import TopBar from "components/TopBar"
import { ETypographyTypes, Typography } from "components/Typography"
import { RenderFields, validate } from "forms"
import { last } from "lodash"
import { FormEventHandler, useCallback, useState } from "react"
import { TFunction, useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { useGlobalLoading } from "store/hooks"
import { classNames, createSetter } from "utils"
import { FormContext } from "./context"
import FormButtons from "./FormButtons"
import StepsNav from "./StepsNav"
import { FormState, FormWithStepsProps, Step } from "./types"

export type { FormContextValue } from "./context"
export { default as FormModal } from "./FormModal"
export type { FormState, FormWithStepsProps, Step, StepComponentProps, StepIds } from "./types"
export { defineStep } from "./utils"
export { FormContext }

const handleError = (error: any, t: TFunction) => {
    const key = `errors.${error?.message === "email_exists" ? "auth/email-already-exists" : error?.message}`
    const message = t(`user:${key}`)

    toast(message !== key ? message : t("common:genericError"), { type: "error" })
    return message
}

function FormWithSteps<Steps extends readonly Step[], State extends FormState<Steps>>({
    wrapperClassName,
    stepClassName,
    title,
    state,
    setState,
    errors,
    setErrors,
    steps,
    onSubmit,
    getSubmitProps,
    additionalButtons,
    tabView,
    unlockAllSteps,
    backButton,
    onStepChange,
    forceHideFormButtons,
    warningMessage,
    prohibitStepChange
}: FormWithStepsProps<Steps, State>) {
    const [validationsDisabled, setValidationsDisabled] = useState(false)
    const [currentStep, setCurrentStep] = useState(steps[0])
    const [completedSteps, setCompletedSteps] = useState<string[]>(unlockAllSteps ? steps.map((s) => s.id) : [])

    const { t } = useTranslation()
    const pageLoading = useGlobalLoading()

    const [loading, setLoading] = useState(false)

    const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault()
        if (e.target !== e.currentTarget) return // When there is more than one form being rendered at the same time, one's onSubmit might trigger another. This prevents this from going through

        try {
            if (!validationsDisabled) {
                const newErrors = await validate(
                    state[currentStep.id as keyof State] ?? {},
                    currentStep.fields,
                    currentStep.validationOverrides
                )
                const errorsExtended = currentStep.onValidate
                    ? await currentStep.onValidate(state, newErrors)
                    : newErrors
                if (errorsExtended) return setErrors(errorsExtended)
            }

            if (currentStep.id !== last(steps).id) {
                if (onStepChange) onStepChange()
                const nextStepIndex = steps.findIndex((step) => step.id === currentStep.id) + 1
                setCompletedSteps((completed) => completed.filter((c) => c !== currentStep.id).concat([currentStep.id]))
                return setCurrentStep(steps[nextStepIndex])
            }

            setLoading(true)
            if (validationsDisabled) return toast("Cannot submit while validations are disabled", { type: "error" })
            await onSubmit()
        } catch (error) {
            console.error(error)
            // TODO: Replace with a more reasonable error handling function
            const errorMessage = handleError(error, t)?.replace("The ", "").replace(" you entered is", "")
            if (errorMessage) {
                setCurrentStep(steps[0])
                const errorKey = (() => {
                    if (error.message.includes("email") && !error.message.includes("contact")) return "email"
                    if (error.message.includes("phone-number")) return "phone"
                    if (error.message.includes("collaborators")) return "collaborators"
                })()

                if (errorKey)
                    setErrors((errors) => ({
                        ...errors,
                        [errorKey]: errorMessage[0].toUpperCase() + errorMessage.slice(1, -1)
                    }))
            }
        } finally {
            setLoading(false)
        }
    }

    const stepId = currentStep.id as keyof State
    const currentStepState = state?.[stepId]
    const setCurrentStepState = useCallback(createSetter(setState, stepId), [setState, stepId])

    return !pageLoading && state ? (
        <FormContext.Provider value={{ state, setState, errors, setErrors }}>
            <form className={classNames("flex flex-col flex-grow h-full", wrapperClassName)} onSubmit={handleSubmit}>
                <TopBar
                    title={title}
                    className={classNames(
                        tabView && "!px-12",
                        title && tabView && "border-b border-border-blue",
                        "top-0 sticky",
                        !title && "h-0"
                    )}
                    backButton={backButton}
                    titleClassName={"text-dark-blue text-3xl font-semibold"}
                >
                    {!tabView && (
                        <FormButtons {...{ additionalButtons, currentStep, loading, getSubmitProps, tabView }} />
                    )}
                    {process.env.APP_ENV !== "prod" && title && (
                        <label
                            className={classNames(
                                "text-xs cursor-pointer flex items-center gap-2",
                                !tabView ? "absolute -bottom-2 bg-white right-4" : "ltr:ml-auto rtl:mr-auto"
                            )}
                        >
                            <input
                                className="rounded w-4 h-4"
                                type="checkbox"
                                checked={validationsDisabled}
                                onChange={() => setValidationsDisabled(!validationsDisabled)}
                            />
                            Disable validations
                        </label>
                    )}
                </TopBar>
                {warningMessage && (
                    <Tag color="yellow" className="bg-opacity-20 rounded-3xl mx-20 p-4 mt-4 text-lg flex gap-2">
                        <AlertTriangleIcon className="w-8 h-8" />
                        {t(warningMessage)}
                    </Tag>
                )}
                <div
                    className={classNames(
                        tabView ? "flex-col justify-start" : "gap-8 justify-center px-20 py-7",
                        "flex-grow flex items-start overflow-y-auto"
                    )}
                >
                    {steps.length > 1 && (
                        <StepsNav
                            {...{ steps, currentStep, setCurrentStep, completedSteps, tabView, prohibitStepChange }}
                        />
                    )}
                    {currentStep.label && (
                        <Typography
                            type={ETypographyTypes.DARK_BLUE}
                            className="mt-7 mb-4 text-base font-semibold border-b border-border-blue w-1/2 pb-2"
                        >
                            {currentStep.label}
                        </Typography>
                    )}
                    <div
                        className={classNames(
                            tabView ? "w-full px-12 max-h-[36rem] overflow-y-auto" : "w-200",
                            stepClassName
                        )}
                    >
                        {currentStep.Component ? (
                            <currentStep.Component
                                state={currentStepState}
                                setState={setCurrentStepState}
                                fields={currentStep.fields}
                                {...{ errors, setErrors }}
                            />
                        ) : (
                            <div
                                className={classNames(
                                    currentStep.fields && "grid gap-y-6 gap-x-8 w-full",
                                    tabView && "mt-8"
                                )}
                                style={
                                    currentStep.fieldGridColumns
                                        ? { gridTemplateColumns: `repeat(${currentStep.fieldGridColumns}, 1fr)` }
                                        : undefined
                                }
                            >
                                <RenderFields
                                    fields={currentStep.fields}
                                    state={currentStepState}
                                    setState={setCurrentStepState}
                                    classList={currentStep.classList}
                                    {...{ errors, setErrors }}
                                />
                            </div>
                        )}
                    </div>
                </div>
                {tabView && !forceHideFormButtons && (
                    <FormButtons
                        className="pt-8"
                        {...{ additionalButtons, currentStep, loading, getSubmitProps, tabView }}
                    />
                )}
            </form>
        </FormContext.Provider>
    ) : null
}

export default FormWithSteps
