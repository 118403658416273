import { RenderFields } from "forms"
import { StepComponentProps } from "forms/FormWithSteps"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useCurrentUser } from "store/hooks"
import { getTreatmentFields } from "./fields"

const TreatmentDetails = ({
    isEdit,
    ...props
}: StepComponentProps<ReturnType<typeof getTreatmentFields>> & { isEdit: boolean }) => {
    const { t } = useTranslation()
    const currentUser = useCurrentUser()

    const columns = useMemo(() => {
        const treatmentFieldsFiltered = getTreatmentFields(currentUser, t, { isEdit })
        const firstColumnLength = treatmentFieldsFiltered.length / 2
        const secondColumnLength = treatmentFieldsFiltered.length - firstColumnLength
        return [treatmentFieldsFiltered.slice(0, firstColumnLength), treatmentFieldsFiltered.slice(-secondColumnLength)]
    }, [t, isEdit])

    return (
        <div className="w-full block mt-8">
            <div className="flex flex-row w-full gap-x-36">
                {columns.map((fields, index) => (
                    <div key={index} className="w-1/2 flex flex-col gap-y-7 text-dark-blue text-base">
                        <RenderFields {...props} fields={fields} />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default TreatmentDetails
