import { AppointmentStatus, User } from "@prisma/client"
import {
    BasicGoal,
    FlatCarePlan,
    FlatEvent,
    PatientStatus,
    PatientWithDiagnoses,
    QuestionnaireEventTemplate,
    RoleWithOrg,
    TeamUser
} from "@sequel-care/types"
import { OrganizationUpdateData } from "@sequel-care/types/Misc"
import { GetTrackerQuestionsPayload } from "@sequel-care/types/Tracker"
import {
    fetchDashboardPatients,
    getAppointments,
    getInitialAppData as doGetInitialAppData,
    getPatientListData,
    getPatientTags,
    getUsersInCurrentOrg,
    setAppointmentStatus as doSetAppointmentStatus,
    toggleTeamAuth as doToggleTeamAuth,
    getUserRecentEvents,
    endTreatment
} from "api"
import {
    setPublished,
    updateOrganization,
    getCarePlans,
    setCarePlanAsDefault as doSetCarePlanAsDefault,
    removeDefaultCarePlan as doRemoveDefaultCarePlan
} from "api/Administrator"
import { isBefore } from "date-fns"
import { TreatmentEndState } from "modals/TreatmentEndModal/TreatmentEndModal"
import { ChatProps } from "pages/chat"
import { ListPatient } from "pages/patients"
import { Dispatch, SetStateAction } from "react"
import { updateCurrentPatient } from "store/patient/actions"
import { AppAction, GlobalState } from "types/Redux"
import { uploadImageToCloudflare } from "utils"
import { ConversationStore } from "utils/conversations"
import { DateString, getDateAtHour0 } from "utils/dates"
import { FeatureFlags } from "utils/FeatureFlags"
import * as types from "./types"
import { TrackerManagerData } from "@sequel-care/types/Patient"
import { GoalObject } from "@sequel-care/types/Goals"
import { UserWithRolesDeep } from "@sequel-care/types/User"

export const getInitialAppData = (): AppAction<UserWithRolesDeep> => async (dispatch) => {
    try {
        const {
            user,
            patients: patientList,
            questionnaire_list: allQuestionnaires,
            exercise_list: allExercises,
            diagnoses,
            feature_flags: featureFlags,
            patient_tags: patientTags,
            care_plans: allCarePlans,
            goals
        } = await doGetInitialAppData()

        FeatureFlags.populate(featureFlags)
        ConversationStore.initializeClient()
        dispatch({ type: types.SET_CURRENT_USER, payload: user })
        dispatch(
            updateGlobal({
                patientList,
                allQuestionnaires,
                allExercises,
                diagnoses,
                patientTags,
                allCarePlans,
                goals
            })
        )
        await dispatch(setCurrentRole(user.team_member as RoleWithOrg))
        return user
    } catch (error) {
        console.error(error)
        // Nothing to do for now
    }
}

export const logOut = (signOut: () => void, redirect: () => void) => {
    ConversationStore.killAll()
    signOut()
    redirect()
    localStorage.clear()
    sessionStorage.clear()
    return { type: "store/RESET" }
}

export const setCurrentRole =
    (role: RoleWithOrg): AppAction =>
    async (dispatch) => {
        await dispatch({ type: types.SET_CURRENT_ROLE, payload: role })

        const users = await getUsersInCurrentOrg()
        dispatch({ type: types.SET_USERS_IN_CURRENT_ORG, payload: users })
    }

export const addPatientToList =
    (patientId: number): AppAction =>
    async (dispatch) => {
        try {
            const patient = await getPatientListData(patientId)
            dispatch({ type: types.ADD_PATIENT_TO_LIST, payload: patient })
        } catch (error) {
            throw error
        }
    }

export const refetchPatientInList =
    (patientId: number): AppAction =>
    async (dispatch) => {
        try {
            const patient = await getPatientListData(patientId)
            dispatch({ type: types.UPDATE_PATIENT_IN_LIST, payload: patient })
        } catch (error) {
            throw error
        }
    }

export const removePatientFromList = (patientId: number) => ({
    type: types.REMOVE_PATIENT_FROM_LIST,
    payload: patientId
})

/**
 * The global loading property controls the display of the app's loading state.
 * This property contains an array of names of components or routes that are currently loading.
 * Pass a name, as well as a loading boolean to indicate whether this name should be added or removed from the loading array.
 *
 * The useGlobalLoading hook will help you follow the state - it returns true while there are still components loading,
 * and false once the array is empty.
 */
export const setGlobalLoading =
    (name: string, loading: boolean): AppAction =>
    (dispatch) => {
        setTimeout(
            () => {
                dispatch({ type: types.SET_GLOBAL_LOADING, payload: { name, loading } })
            },
            !loading ? 400 : 0
        )
    }

export const fetchPatientTags = (): AppAction => async (dispatch) => {
    try {
        const tags = await getPatientTags()
        dispatch({ type: types.SET_PATIENT_TAGS, payload: tags })
    } catch (error) {
        throw error
    }
}

export const addNewQuestionnaire = (questionnaire: QuestionnaireEventTemplate) => ({
    type: types.ADD_NEW_QUESTIONNAIRE,
    payload: questionnaire
})
export const updateQuestionnaire = (questionnaire: QuestionnaireEventTemplate) => ({
    type: types.UPDATE_QUESTIONNAIRE,
    payload: questionnaire
})

export const togglePublish =
    (id: number, is_published: boolean): AppAction =>
    async (dispatch) => {
        await setPublished(id, is_published)
        dispatch(updateQuestionnaire({ id, is_published }))
    }

export const updateGlobal = (updates: Partial<GlobalState>) => ({ type: types.UPDATE_GLOBAL, payload: updates })

export const updateUserInCurrentOrg = (user: TeamUser) => ({
    type: types.UPDATE_USER_IN_CURRENT_ORG,
    payload: user
})

export const addUserInCurrentOrg = (user: TeamUser) => ({ type: types.ADD_USER_IN_CURRENT_ORG, payload: user })

export const fetchUserAppointments =
    (start: Date, end: Date): AppAction =>
    async (dispatch) => {
        const appointments = await getAppointments(start, end)
        dispatch({ type: types.SET_USERS_APPOINTMENTS, payload: appointments })
    }

export const setPastAppointmentStatus =
    (eventId: number, status: AppointmentStatus, date_on_timeline: string): AppAction<boolean> =>
    async (dispatch) => {
        const { success } = await doSetAppointmentStatus(eventId, status, date_on_timeline)

        if (success)
            dispatch({ type: types.SET_PAST_APPOINTMENT_STATUS, payload: { eventId, status, date_on_timeline } })

        return success
    }

export const setUpcomingAppointment = (eventId: number, date: string, updates: Partial<FlatEvent<string>>) => ({
    type: types.SET_UPCOMING_APPOINTMENT,
    payload: { eventId, date: DateString.from(date), updates }
})

export * from "../sidebars/actions"

export const getDashboardPatients = (): AppAction => async (dispatch) => {
    const patients = await fetchDashboardPatients()

    dispatch({ type: types.SET_DASHBOARD_PATIENTS, payload: patients })
}

export const updateOrgSettings =
    (settings: OrganizationUpdateData): AppAction<boolean> =>
    async (dispatch) => {
        try {
            const res = await updateOrganization(settings)

            if (res?.upload_url) await uploadImageToCloudflare(settings.logo_url as File, res.upload_url)
            dispatch({ type: types.UPDATE_ORGANIZATION, payload: res?.organization })
            return true
        } catch (error) {
            console.error(error)
            return false
        }
    }

export const toggleTeamAuth =
    (userId: number): AppAction<boolean> =>
    async (dispatch) => {
        try {
            const { uid } = await doToggleTeamAuth(userId)

            dispatch({ type: types.SET_TEAM_AUTH, payload: { uid, userId } })

            return true
        } catch (error) {
            console.error(error)
            return false
        }
    }

export const setGlobalChat = (props: Partial<ChatProps>) => ({ type: types.SET_GLOBAL_CHAT, payload: props })

export const setGlobalCarePlans = (carePlans: FlatCarePlan[]) => ({
    type: types.SET_GLOBAL_CAREPLANS,
    payload: carePlans
})

export const setSettingsLoading =
    (state: boolean): AppAction =>
    (dispatch) => {
        dispatch({ type: types.SET_SETTINGS_LOADING, payload: state })
    }

export const getCareplans =
    (organization_id: number): AppAction =>
    async (dispatch) => {
        try {
            dispatch(setSettingsLoading(true))
            const careplans = await getCarePlans(organization_id)

            dispatch(setGlobalCarePlans(careplans))
        } catch (error) {
            console.error(error)
        } finally {
            dispatch(setSettingsLoading(false))
        }
    }

export const getUserRecentlyAddedEvents = (): AppAction => async (dispatch) => {
    try {
        const events = await getUserRecentEvents()

        dispatch({ type: types.ADD_EVENTS_TO_RECENT, payload: events })
    } catch (error) {
        console.error(error)
    }
}

const updatePatientInList = (id: number, updates: Partial<PatientWithDiagnoses<string, string>>) => ({
    type: types.UPDATE_PATIENT_IN_LIST_PARTIAL,
    payload: { id, updates }
})

export const setTreatmentEnd =
    (
        patient_id: number,
        data: TreatmentEndState,
        isPatientScope: boolean,
        setPatients?: Dispatch<SetStateAction<ListPatient[]>>
    ): AppAction<boolean> =>
    async (dispatch) => {
        const updates = await endTreatment(patient_id, data)
        if (updates) {
            if (isPatientScope) dispatch(updateCurrentPatient(updates))
            else {
                const today = getDateAtHour0()
                const treatmentEndDate = updates.treatment_end ? getDateAtHour0(updates.treatment_end) : undefined
                const status: PatientStatus =
                    !treatmentEndDate || isBefore(today, treatmentEndDate) ? "current" : "past"
                const data = {
                    treatment_end: updates.treatment_end,
                    treatment_end_reson: !treatmentEndDate ? undefined : updates.treatment_end_reason,
                    status
                }
                dispatch(updatePatientInList(patient_id, data))
                if (setPatients)
                    setPatients((patients) => patients.map((p) => (p.id === patient_id ? { ...p, ...data } : p)))
            }
            return true
        }
        return false
    }

export const setGlobalTracker = (payload: GetTrackerQuestionsPayload) => ({ type: types.SET_TRACKER, payload })

export const setOrganizationTrackerManager = (data: TrackerManagerData) => ({
    type: types.SET_ORGANIZATION_TRACKER_MANAGER,
    payload: data
})

export const addGoalToList = (goal: GoalObject, patient_id: number) => ({
    type: types.ADD_GOAL_TO_LIST,
    payload: {
        patient_id,
        id: goal.id,
        img_id: goal.img_id,
        is_achieved: goal.is_achieved,
        is_deleted: false,
        label: goal.label,
        type: goal.type
    } as BasicGoal
})

export const updateGoalInList = (goal: Partial<GoalObject>) => ({
    type: types.UPDATE_GOAL_IN_LIST,
    payload: goal as BasicGoal
})
export const setCarePlanAsDefault =
    (plan_id: number, organization_id: number): AppAction<void> =>
    async (dispatch) => {
        const res = await doSetCarePlanAsDefault(organization_id, plan_id)
        if (res) dispatch({ type: types.SET_CAREPLAN_DEFAULT, payload: { plan_id, value: true } })
    }
export const removeDefaultCarePlan =
    (plan_id: number, organization_id: number): AppAction<void> =>
    async (dispatch) => {
        const res = await doRemoveDefaultCarePlan(organization_id)
        if (res) dispatch({ type: types.SET_CAREPLAN_DEFAULT, payload: { plan_id, value: false } })
    }
