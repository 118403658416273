import { ReactNode, useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { format } from "date-fns"
import { Tag } from "@prisma/client"
import { CollaboratorWithPermissions } from "@sequel-care/types"
import { useDispatch } from "store"
import { useCurrentUser, useDiagnoses } from "store/hooks"
import { toggleTherapistProfile } from "store/sidebars/actions"
import { ProfileRow } from "components/Profile"
import { ProfileDisplayedField } from "components/Profile/types"
import { Dictionary } from "lodash"
import makeRenderUserList from "components/Profile/utils"

const tagClassNames = "rounded-full bg-text-blue/10 py-1 px-3 w-fit whitespace-nowrap mr-1 mt-1.5 inline-block truncate"

export function RenderProfileBlock<T extends Dictionary<any>>({
    id,
    fields,
    entity,
    children,
    noDivider = false
}: {
    id: string
    fields: ProfileDisplayedField<string>[]
    entity: T
    children?: ReactNode
    noDivider?: boolean
}) {
    const { t } = useTranslation("patient")

    return (
        <>
            <div className="text-dark-blue font-medium text-base">{t(`patientForm.${id}`)}</div>
            {fields.map((fieldProps) => {
                const { key } = fieldProps
                return <ProfileRow key={key} fieldId={key} value={entity[key]} {...fieldProps} />
            })}
            {children}
            {!noDivider && <div className="border-b border-blue grow -mx-8" />}
        </>
    )
}

export const RenderDateValue = ({ value: date }: { value: string }) => {
    const { preferences } = useCurrentUser()

    if (!date) return null

    return <>{format(new Date(date), preferences.date_format === "d/m/y" ? "dd/MM/yyyy" : "MM/dd/yyyy")}</>
}

export const RenderCollaborators = makeRenderUserList<CollaboratorWithPermissions>({
    WrapperComponent: ({ item: { user, permissions }, ...props }) => {
        const dispatch = useDispatch()

        if (!permissions.length) return null

        return <button type="button" onClick={() => dispatch(toggleTherapistProfile(user.id))} {...props} />
    },
    getData: ({ user, permissions }) => ({
        user,
        role: permissions[0]?.permission === "case_manager" ? "case_manager" : "therapist"
    })
})

export const RenderTags = ({ value }: { value: Tag[] }) => {
    return (
        <>
            {value.map(({ name }, index) => (
                <div className={tagClassNames} key={index}>
                    {name}
                </div>
            ))}
        </>
    )
}

export const RenderDiagnoses = ({ value }: { value: number | number[] }) => {
    const diagnoses = useDiagnoses()
    const getDiagnosisName = useCallback(
        (diagnosisId?: number) => diagnosisId && diagnoses.find((diagnosis) => diagnosis.id === diagnosisId)?.name,
        [diagnoses]
    )

    if (!Array.isArray(value)) return <div className={tagClassNames}>{getDiagnosisName(value)}</div>

    return (
        <>
            {value.map((diagnosisId) => (
                <div key={diagnosisId} className={tagClassNames}>
                    {getDiagnosisName(diagnosisId)}
                </div>
            ))}
        </>
    )
}
