import * as types from "./types"
import { getPortalNotifications, markAsRead as doMarkAsRead, getInitialPortalNotifications } from "api/Notification"
import { AppAction } from "types/Redux"
import { NotificationCategories } from "types/Misc"

export const setNotificationsLoading = (loading: boolean) => ({
    type: types.SET_NOTIFICATIONS_LOADING,
    payload: loading
})
export const getNotifications =
    (category: NotificationCategories, take?: number, last_fetched_id?: number): AppAction<boolean> =>
    async (dispatch) => {
        try {
            const payload = await getPortalNotifications(category, take, last_fetched_id)
            dispatch({ type: types.GET_PORTAL_NOTIFICATIONS, payload })
            return true
        } catch (error) {
            return false
        }
    }

export const getNotificationsInitial =
    (take?: number): AppAction<boolean> =>
    async (dispatch) => {
        try {
            const payload = await getInitialPortalNotifications(take)
            dispatch({ type: types.GET_PORTAL_NOTIFICATIONS_INITIAL, payload })
            return true
        } catch (error) {
            return false
        }
    }

export const markAsRead =
    (ids: number[]): AppAction =>
    async (dispatch) => {
        try {
            if (!ids.length) return

            await doMarkAsRead(ids)
            dispatch({ type: types.MARK_AS_READ, payload: { readIds: ids } })
        } catch (error) {
            console.error("Failed to mark as read:", error)
        }
    }
