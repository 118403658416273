import { classNames } from "utils"

const sizes = {
    small: "h-4 w-4 text-xs",
    medium: "h-5 w-5 text-sm"
}

const Badge = ({
    displayNumber,
    visible,
    textColor = "text-white",
    wrapperClassNames,
    size = "medium"
}: {
    displayNumber: number
    visible: boolean
    wrapperClassNames?: string
    textColor?: string
    size?: keyof typeof sizes
}) => {
    return (
        <div
            className={classNames(
                "flex items-center justify-center rounded-full bg-current font-medium p-3",
                sizes[size],
                visible ? "visible" : "invisible",
                wrapperClassNames
            )}
        >
            <span className={textColor}>{displayNumber}</span>
        </div>
    )
}

export default Badge
