import { UsersIcon } from "components/icons"
import UserAvatar from "components/UserAvatar"
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"
import { useDispatch } from "store"
import { setGlobalChat } from "store/global/actions"
import { useCurrentUser, usePatientList } from "store/hooks"
import { classNames, getUserName } from "utils"
import { useConversations } from "utils/conversations"
import { MessageNotification } from "utils/conversations/types"
import { localeDistanceToNow } from "utils/dates"
import { notificationClassNames } from "./NotificationItem"

export const MessageNotificationList = ({ messageNotifications }: { messageNotifications: MessageNotification[] }) => {
    const { t, i18n } = useTranslation("patient")
    const currentUser = useCurrentUser()
    const patientList = usePatientList()
    const router = useRouter()
    const dispatch = useDispatch()
    const conversations = useConversations()

    return (
        <>
            {messageNotifications.map((notification, index) => {
                const participants = notification.participants.filter((p) => p.id !== currentUser.id)
                const patient = patientList.find((p) => p.id === notification.patientId)
                return (
                    <div
                        key={index}
                        className={classNames(
                            "flex px-6 cursor-pointer",
                            notificationClassNames.wrapperGap,
                            notificationClassNames.padding,
                            index > 0 && "border-t border-border-blue"
                        )}
                        onClick={() => {
                            dispatch(
                                setGlobalChat({
                                    mode: "chat",
                                    conversation: conversations.find((c) => c.sid === notification.conversationId)
                                })
                            )
                            router.push(`/chat`)
                        }}
                    >
                        {participants.length === 1 ? (
                            <UserAvatar className="my-1.5" user={participants[0]} />
                        ) : (
                            <UsersIcon className="bg-blue-200 text-med-blue p-1 w-8 h-8 rounded my-1" />
                        )}
                        <div className="flex flex-col gap-4">
                            <div className="text-text-blue font-normal">
                                {participants.length === 1
                                    ? t("common:chat_unread_msg_direct", { sender: getUserName(participants[0]) })
                                    : t(`common:chat_unread_msg_group${patient ? "_patient" : ""}`, {
                                          chat: participants.map((p) => getUserName(p)).join(", "),
                                          patient: getUserName(patient?.user)
                                      })}
                            </div>
                            <div className="text-text-blue text-sm">
                                {t("common:distanceAgo", {
                                    distance: localeDistanceToNow(new Date(notification.date), i18n)
                                })}
                            </div>
                        </div>
                    </div>
                )
            })}
            {messageNotifications.length === 0 && (
                <div className="flex items-center justify-center text-gray-400 italic h-16">
                    {i18n.t("user:notifications.noNotifications")}
                </div>
            )}
        </>
    )
}
