import PatientSnapshot from "./PatientSnapshot"
import { ExerciseResponses, QuestionnaireResponses } from "./QuestionnaireResponses"
import TaskLastResponses from "./TaskLastResponses"

export const responseSectionMap = {
    questionnaire: QuestionnaireResponses,
    task: TaskLastResponses,
    exercise: ExerciseResponses,
    appointment: PatientSnapshot
} as const

export type EventWithResponseSection = keyof typeof responseSectionMap
