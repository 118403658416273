import { FormControlProps } from "forms"
import React from "react"
import { useTranslation } from "react-i18next"

const UrgentMessagingField = ({ setValue, value }: FormControlProps<boolean>) => {
    const { t } = useTranslation("user")

    return (
        <div className="py-10 border-t border-secondary text-dark-blue flex flex-col gap-8">
            <div className="flex flex-col gap-2">
                <span className="font-medium">{t("userSettings.urgentMessages")}</span>
                <span className="text-text-blue">{t("userSettings.urgentMessagesInfo")}</span>
            </div>
            <span className="flex gap-2 items-center">
                <input
                    type="checkbox"
                    checked={value}
                    onChange={() => setValue(!value)}
                    className="rounded-sm border-med-blue"
                />
                {t("userSettings.allowUrgent")}
            </span>
        </div>
    )
}

export default UrgentMessagingField
