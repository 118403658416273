import { QuestionGridContext } from "components/Questionnaires/QuestionGrid"
import { Fragment } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useAllExercises } from "store/hooks"
import { pushToLibraryStack } from "store/patient/actions"
import { classNames } from "utils"
import DetailsScreen from "."
import { eventToTemplate } from "../utils"
import { responseComponents } from "./QuestionnaireDetails"

const ExerciseDetails = ({ exerciseId, withBack }: { exerciseId: number; withBack: boolean }) => {
    const exercises = useAllExercises()
    const exercise = exercises.find(({ id }) => exerciseId === id)
    const dispatch = useDispatch()
    const { t } = useTranslation("patient")

    return (
        <DetailsScreen
            title={exercise.title}
            description={exercise.description}
            withBack={withBack}
            duration={exercise.duration}
            related_diagnoses={[]}
            onAdd={() =>
                dispatch(
                    pushToLibraryStack({
                        view: "config",
                        eventTemplates: eventToTemplate("exercise", exercise),
                        patientId: "preserve"
                    })
                )
            }
        >
            <QuestionGridContext.Provider value={{ exercise }}>
                <div className="text-lg pb-6 text-dark-blue font-medium">{t("contentLib.insideExercise")}</div>
                {exercise.question_groups.map(({ title, questions }, index) => {
                    return (
                        <div
                            key={index}
                            className={classNames(
                                "flex flex-col gap-3 py-2",
                                index !== 0 ? "border-t border-border-blue" : "pt-0"
                            )}
                        >
                            <span className="text-med-blue text-md">{title}</span>
                            <div className="grid gap-y-5 gap-x-8 font-normal grid-cols-2">
                                {questions.map((question) => {
                                    const RenderResponse = responseComponents[question.type]
                                    return (
                                        <Fragment key={question.id}>
                                            <div
                                                className="text-secondary-2 text-sm break-words"
                                                data-testid={`question-${question.id}`}
                                            >
                                                {question.type === "text" ? (
                                                    <p dangerouslySetInnerHTML={{ __html: question.question }}></p>
                                                ) : (
                                                    question.question
                                                )}
                                            </div>
                                            <RenderResponse question={question} value={undefined} />
                                        </Fragment>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            </QuestionGridContext.Provider>
        </DetailsScreen>
    )
}

export default ExerciseDetails
