import { DiagnosisListItem, DiagnosisTreeItem } from "@sequel-care/types"
import { useMemo } from "react"
import { useDiagnoses } from "store/hooks"

export const useDiagnosisTree = (withDefaultDiagnosis = false) => {
    const diagnoses = useDiagnoses()

    return useMemo(() => {
        const prepareDiagnoses = (diagnoses: DiagnosisListItem[]) => {
            return diagnoses
                .map(injectChildren)
                .sort(({ children }) => (children.length ? -1 : 1))
                .sort((a, b) => {
                    if (a.order === null || a.order > b.order) return 1
                    if (b.order === null || a.order < b.order) return -1

                    return 0
                })
        }

        const injectChildren = (diagnosis: DiagnosisListItem): DiagnosisTreeItem => {
            const { id, name, order } = diagnosis
            const children = diagnoses.filter(({ super_diagnoses }) => super_diagnoses?.includes(id as number))
            return { id, label: name, children: prepareDiagnoses(children), order }
        }

        return prepareDiagnoses(
            diagnoses.filter(({ super_diagnoses, id }) => {
                const isDefaultDiagnosis = id === 286 // TODO: Replace with a better permanent solution
                return withDefaultDiagnosis ? !super_diagnoses?.length : !super_diagnoses?.length && !isDefaultDiagnosis
            })
        )
    }, [diagnoses])
}

export const useDiagnosisTreeWithDefault = () => useDiagnosisTree(true)
