import { BasicUser } from "@sequel-care/types"
import { AvatarWithName } from "components/UserAvatar"
import { FormControlProps } from "forms"
import SuperSelect from "forms/SuperSelect"
import { BasicItem } from "forms/SuperSelect/types"
import { pick } from "lodash"
import { useMemo } from "react"
import { useCurrentPatient } from "store/hooks"
import { basicUserPick, getUserName } from "utils"

const RenderItem = ({ item }: { item: ItemType }) => <AvatarWithName user={item.user} size={6} />

type ItemType = BasicItem & { user: BasicUser; isAvailableForSelection?: boolean }

export function InviteeFeild<V extends number | number[]>(props: FormControlProps<V>) {
    const patient = useCurrentPatient()

    const allInvitees = useMemo(() => {
        const allInvitees: ItemType[] = [
            {
                id: patient.user.id,
                label: getUserName(patient.user),
                user: pick(patient.user, basicUserPick)
            }
        ]

        patient.contacts.forEach((c) => {
            allInvitees.push({
                id: c.id,
                label: getUserName(c),
                user: pick(c, basicUserPick),
                isAvailableForSelection: !c.disabled && c.has_auth
            })
        })
        patient.collaborators
            .filter((c) => c.permissions.length > 0)
            .forEach((c) =>
                allInvitees.push({
                    id: c.user.id,
                    label: getUserName(c.user),
                    user: pick(c.user, basicUserPick)
                })
            )

        return allInvitees
    }, [patient])
    return <SuperSelect items={allInvitees} {...props} style="tags" RenderItem={RenderItem} />
}
