import { ApiPost, ApiPut, SelfAbortingGet } from "./ApiRequest"
import { ChatPreference } from "@prisma/client"
import { ChatConfig, ChatMigrationDataPayload } from "@sequel-care/types"

export async function getChatPreferencesApiCall(payload: ChatMigrationDataPayload[]) {
    const instance = new ApiPost<ChatPreference[]>(`/chat/preference`, payload)
    instance.callerName = getChatPreferencesApiCall.name
    return instance.run()
}

export async function updateChatConfiguration(sid: String, isMuted: boolean) {
    const payload = {
        sid: sid,
        is_muted: isMuted
    }
    const instance = new ApiPut<ChatConfig>(`/chat/configuration`, payload)
    instance.callerName = updateChatConfiguration.name
    return instance.run()
}
