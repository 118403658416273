import { InformationCircleIcon, LightBulbIcon } from "@heroicons/react/24/outline"
import { QuestionnaireListItem } from "@sequel-care/types"
import { FlatEvent } from "@sequel-care/types/Event"
import { Tooltip } from "components/common"
import { ScoreTag } from "components/common/Tag"
import { ThoughtsIcon } from "components/icons"
import { ETypographyTypes, Typography } from "components/Typography"
import { Fragment, useMemo } from "react"
import { Bar } from "react-chartjs-2"
import { useTranslation } from "react-i18next"
import { useCurrentPreferences } from "store/hooks"
import { registerChart } from "utils"
import { localeFormat } from "utils/dates"
import { GRAPH_STYLES } from "utils/graphs"
import SidebarSection from "../SidebarSection"
import { useLastScoresOptions } from "./utils"

const colors = [GRAPH_STYLES.COLORS.negative, GRAPH_STYLES.COLORS.warning, GRAPH_STYLES.COLORS.med_green]
registerChart()

type ScoreSectionProps = {
    questionnaire: QuestionnaireListItem
    event: FlatEvent<string>
}

export const QuestionnaireScoreBreakdown = ({ questionnaire, event }: ScoreSectionProps) => {
    const { t } = useTranslation("patient")
    const { groupScoresWithScale, hasAllCutoffs } = useMemo(() => {
        let hasAllCutoffs = true
        const groupScoresWithScale = event.group_scores.map(({ scale_id, score }) => {
            const scale = questionnaire.scales.find(({ id }) => id === scale_id)
            if (!scale.cutoffs.length) hasAllCutoffs = false
            return {
                scale,
                score,
                scale_max:
                    scale.calculation === "sum"
                        ? scale.max *
                          questionnaire.questions.filter((question) => question.scale_id === scale_id).length
                        : scale.max
            }
        })

        return { groupScoresWithScale, hasAllCutoffs }
    }, [event.group_scores])

    return (
        <SidebarSection className="py-8" title={t("sidebar.scoreBreakdown")}>
            <div className="grid grid-cols-[2fr_3fr] gap-y-3 items-center pt-4">
                {groupScoresWithScale.map(({ scale, score, scale_max }) => (
                    <Fragment key={scale.id}>
                        <div className="text-secondary-2">{scale.name}</div>
                        {hasAllCutoffs ? (
                            <ScoreTag {...{ score, scale }} />
                        ) : (
                            <div className="text-sm text-text-blue">
                                <span className="font-medium text-dark-blue text-lg">{score}</span>
                                &nbsp;&nbsp;<span className="text-xs">/</span>&nbsp;&nbsp;
                                {scale_max}
                            </div>
                        )}
                    </Fragment>
                ))}
            </div>
        </SidebarSection>
    )
}

export const QuestionnaireLastScores = ({ questionnaire, event }: ScoreSectionProps) => {
    const preferences = useCurrentPreferences()
    const { i18n, t } = useTranslation()

    const options = useLastScoresOptions(questionnaire)
    const data = useMemo(() => {
        const {
            questionnaire_scale: { cutoffs, direction }
        } = questionnaire
        const dateFormat = preferences.date_format.startsWith("m") ? "M/d" : "d/M"
        return {
            datasets: [
                {
                    data: event.last_scores.map(({ score }) => Math.round(score)),
                    backgroundColor: event.last_scores.map(({ score }) => {
                        const cutoffIndex = cutoffs.findIndex((cutoff, index) => {
                            return score >= cutoff.min && (!cutoffs[index + 1] || cutoffs[index + 1].min > score)
                        })
                        return direction === "positive" ? colors[cutoffIndex] : colors[2 - cutoffIndex]
                    })
                }
            ],
            labels: event.last_scores.map(({ date }) => localeFormat(new Date(date), dateFormat, i18n))
        }
    }, [questionnaire, preferences, event])

    const hasReflectionResponse = event.reflection_response !== null
    const reflectionStringParts = hasReflectionResponse ? t("sidebar.reflectionResponse").split("{descriptor}") : null

    return (
        <div className="flex flex-col gap-8 px-12 py-8 border-b border-border-blue">
            <Typography type={ETypographyTypes.DARK_BLUE} className="text-xl flex items-center gap-3">
                {t("patient:sidebar.last7Scores")}
                <Tooltip
                    content={
                        <div className="w-60">
                            {t("patient:sidebar.scoresDisplayedHere")}
                            <span className="italic">{t("dates:today")}</span>
                        </div>
                    }
                    placement="top"
                >
                    <InformationCircleIcon className="w-5" />
                </Tooltip>
            </Typography>
            <div className="flex gap-10 text-xs">
                <div className="w-1/2">
                    {event.last_scores.length ? (
                        <div className="w-full pt-4 px-2">
                            <Bar height={80} width={120} options={options} data={data} />
                        </div>
                    ) : (
                        <div className="text-gray-400 text-sm italic">{t("common:noDataAvailable")}</div>
                    )}
                </div>
                <div className="flex flex-col gap-3 align-center text-base w-1/2">
                    <div className="flex gap-1 text-med-blue font-medium">
                        <LightBulbIcon className="w-5 h-5 text-primary" />
                        {t("eventTypes.reflection")}
                    </div>
                    <div className="text-text-blue font-normal">
                        {hasReflectionResponse ? (
                            <span>
                                {reflectionStringParts[0]}
                                <span className={event.reflection_response ? "text-med-green" : "text-negative"}>
                                    {t(`reflectionResponse.${event.reflection_response}`)}
                                </span>
                                {reflectionStringParts[1]}
                            </span>
                        ) : (
                            t("reflectionResponse.none")
                        )}
                    </div>
                </div>
            </div>
            {event.reflection_thoughts && (
                <div className="flex flex-col gap-3">
                    <span className="flex gap-2 text-med-blue font-medium items-center">
                        <ThoughtsIcon className="w-7 h-5" />
                        {t("sidebar.patientThoughts")}
                    </span>
                    <span className="text-text-blue font-normal">{event.reflection_thoughts}</span>
                </div>
            )}
        </div>
    )
}
