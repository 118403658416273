import { flip } from "@floating-ui/react-dom"
import { offset, shift, useFloating } from "@floating-ui/react-dom"
import { useEffect, useState } from "react"
import { MenuComponent, MenuProps } from "./types"
import { assign } from "lodash"
import { MenuButton, MenuPanel } from "./MenuComponents"
import { MenuContext } from "./context"

const RenderMenu = ({
    children,
    onClose,
    placement = "bottom-start",
    offsetOptions = { crossAxis: -8 },
    id = ""
}: MenuProps) => {
    const floatingProps = useFloating({
        placement,
        middleware: [flip(), shift(), offset(offsetOptions)]
    })

    /**
     * This component handles open state on its own, instead of using the HeadlessUI Popover element.
     * The reason is that it was clashing with modal event listeners, meaning there were issues with menus inside modals.
     */
    const [isOpen, setOpen] = useState(false)
    useEffect(() => {
        if (isOpen) {
            const { reference, floating } = floatingProps.refs
            const closeMenu = (el: Event | MouseEvent) => {
                //If this is the case, the reference's onClick event handler will close the menu already. We don't do anything to avoid clash.
                if (el.target === reference.current || (reference.current as Element)?.contains(el.target as Node))
                    return onClose?.()

                if (el.target !== floating.current && !floating.current?.contains(el.target as Node)) {
                    setOpen(false)
                    onClose?.()
                }
            }
            // Use either modal (if there is one) or window as receiver of event listener.
            // We need to do this because the modal stops propagation to the window.
            const el = document.querySelector("#modal-content-wrapper") ?? window
            el.addEventListener("mousedown", closeMenu)
            return () => el.removeEventListener("mousedown", closeMenu)
        }
    }, [isOpen, onClose])

    return (
        <MenuContext.Provider value={{ isOpen, onToggle: () => setOpen(!isOpen), id, floatingProps }}>
            {children}
        </MenuContext.Provider>
    )
}

export const Menu: MenuComponent = assign(RenderMenu, { Button: MenuButton, Panel: MenuPanel })

export default Menu
