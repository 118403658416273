import store from "store"
import mixpanel from "mixpanel-browser"
import { Dictionary } from "lodash"

export const sendMixpanelReport = (text: string, additionalProps?: Dictionary<any>) => {
    const {
        global: { user },
        patient: { currentCollaborator }
    } = store.getState()
    if (!user) return

    const data = {
        distinct_id: user.team_member.user_id,
        clinic: user.team_member.organization_id,
        org_role: user.role,
        permission: currentCollaborator?.role,
        ...additionalProps
    }

    mixpanel.track(text, data)
}
