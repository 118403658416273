import { Switch as SwitchWrapper } from "@headlessui/react"
import { Dictionary } from "lodash"
import { classNames } from "utils"

const i18n = {
    use_setting: "Use setting"
}

const themes: Record<"solid" | "outline", Record<"button" | "bg", Dictionary<string>>> = {
    outline: {
        button: {
            true: "text-dark-blue",
            false: "text-secondary-2"
        },
        bg: {
            true: "border-current",
            false: "border-current"
        }
    },
    solid: {
        button: {
            true: "text-white",
            false: "text-white opacity-50"
        },
        bg: {
            true: "border-med-blue bg-med-blue",
            false: "border-secondary bg-secondary"
        }
    }
}

const Switch = ({
    value,
    setValue,
    theme = "outline",
    readOnly,
    ...props
}: {
    value: boolean
    setValue: (value: boolean) => void
    theme?: "solid" | "outline"
    "data-testid"?: string
    readOnly?: boolean
}) => {
    return (
        <SwitchWrapper
            checked={value}
            onChange={readOnly ? null : setValue}
            className={classNames(
                themes[theme].button[value.toString()],
                "flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer"
            )}
            {...props}
        >
            <span className="sr-only">{i18n.use_setting}</span>
            <span aria-hidden="true" className="pointer-events-none absolute bg-white w-full h-full rounded-md" />
            <span
                aria-hidden="true"
                className={classNames(
                    themes[theme].bg[value.toString()],
                    "pointer-events-none absolute h-5 w-9.5 border mx-auto rounded-full transition-colors ease-in-out duration-200"
                )}
            />
            <span
                aria-hidden="true"
                className={classNames(
                    value ? "translate-x-5" : "translate-x-0.5",
                    "bg-current pointer-events-none absolute left-0 inline-block h-4 w-4 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200"
                )}
            />
        </SwitchWrapper>
    )
}

export default Switch
