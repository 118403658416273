import { EventHandlePolicy } from "@sequel-care/types/Event"
import { useTranslation } from "react-i18next"

const EditPolicySelector = ({
    action,
    policy,
    setPolicy
}: {
    action: string
    policy: EventHandlePolicy
    setPolicy: (policy: EventHandlePolicy) => void
}) => {
    const { t } = useTranslation("patient")
    const actionString = t(`common:${action}`)

    const policies: EventHandlePolicy[] = ["single", "all"]

    return (
        <>
            <p className="text-sm text-text-blue">{t("eventManager.recurringDialog.text", { action: actionString })}</p>
            <fieldset className="pt-6 px-2 text-text-blue">
                <legend className="sr-only">{t("eventManager.deletePolicy")}</legend>
                <div className="space-y-4">
                    {policies.map((p) => (
                        <div key={p} className="flex items-center">
                            <input
                                id={`policy-${p}`}
                                name={`${action}-policy"`}
                                type="radio"
                                checked={p === policy}
                                onChange={() => setPolicy(p)}
                                className="focus:ring-med-blue h-4 w-4 text-med-blue border-gray-300"
                            />
                            <label htmlFor={`policy-${p}`} className="ml-3 block text-sm font-medium">
                                {t(`eventManager.recurringDialog.options.${p}`, { action: actionString })}
                            </label>
                        </div>
                    ))}
                </div>
            </fieldset>
        </>
    )
}

export default EditPolicySelector
