import { RenderFields } from "forms"
import { StepComponentProps } from "forms/FormWithSteps"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { getClinicalFields } from "./fields"

const ClinicalDetails = ({
    state,
    setState,
    errors,
    setErrors
}: StepComponentProps<ReturnType<typeof getClinicalFields>>) => {
    const { t } = useTranslation()
    const columns = useMemo(() => {
        const fields = getClinicalFields(t)
        const firstColumnLength = 2
        const secondColumnLength = fields.length - firstColumnLength
        return [fields.slice(0, firstColumnLength), fields.slice(-secondColumnLength)]
    }, [t])

    return (
        <div className="w-full block mt-8">
            <div className="flex flex-row w-full gap-x-36">
                {columns.map((fields, index) => (
                    <div key={index} className="w-1/2 flex flex-col gap-y-5 text-dark-blue text-base">
                        <RenderFields {...{ state, setState, errors, setErrors, fields }} />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ClinicalDetails
