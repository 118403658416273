import { Reducer } from "redux"
import { SidebarState } from "types/Redux"
import * as types from "./types"

const initialState: SidebarState = {
    stack: []
}

const sidebarsReducer: Reducer<SidebarState> = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.PUSH_TO_SIDEBAR_STACK: {
            return { ...state, stack: [...state.stack.map((i) => ({ ...i, isOpen: false })), payload] }
        }
        case types.DELETE_THE_LAST_ELEMENT_SIDEBAR: {
            return { ...state, stack: [...state.stack].slice(0, -1) }
        }
        case types.TOGGLE_CURRENT_SIDEBAR: {
            return { ...state, stack: [] }
        }
        case types.UPDATE_CURRENT_SIDEBAR: {
            const stack = [...state.stack]
            stack[stack.length - 1] = { ...stack[stack.length - 1], ...payload }
            return { ...state, stack }
        }
        case types.GO_TO_THE_PREVIOUS_SIDEBAR: {
            const prevStack = [...state.stack].slice(0, -1)
            if (prevStack[prevStack.length - 1]) prevStack[prevStack.length - 1].isOpen = true
            return { ...state, stack: prevStack }
        }
        default:
            return state
    }
}

export default sidebarsReducer
