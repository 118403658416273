import { Button } from "components/common"
import { useMemo } from "react"
import { classNames } from "utils"
import { FormWithStepsProps, Step } from "./types"

type FormButtonsProps = {
    className?: string
    currentStep: Step
    loading: boolean
    tabView: boolean
} & Pick<FormWithStepsProps<any>, "getSubmitProps" | "additionalButtons">

const FormButtons = ({
    className,
    additionalButtons,
    currentStep,
    loading,
    getSubmitProps,
    tabView
}: FormButtonsProps) => {
    const submitProps = useMemo(() => getSubmitProps && getSubmitProps(currentStep), [currentStep, getSubmitProps])
    return (
        <div className={classNames(className, tabView && "pb-10", "flex gap-2 justify-end px-12")}>
            {additionalButtons?.map((props, index) => (
                <Button key={index} type={props.type ?? "button"} {...props} />
            ))}
            <Button
                theme="primary"
                loading={loading}
                className={classNames("text-base mt-auto", submitProps && submitProps.className)}
                data-testid={`${currentStep.id}-form-btn`}
                {...submitProps}
            />
        </div>
    )
}

export default FormButtons
