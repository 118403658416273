import { useKeymap } from "@remirror/react"
import { FlatEvent } from "@sequel-care/types"
import { CommentData, EventComment } from "@sequel-care/types/Event"
import { IOption } from "components/PatientTimeline/EventCard/OptionsPopover"
import { isSameDay } from "date-fns"
import { MutableRefObject, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "store"
import { useCommentsByEvent, useCurrentUser, useUsersInCurrentOrg } from "store/hooks"
import { createComment, deleteComment } from "store/patient/actions"
import { EventSidebar } from "types/AppSidebar"
import { CommentPost, RenderComment } from "./CommentComponents"
import { CommentDeleteDialog } from "./CommentDeleteDialog"
import SidebarSection from "./SidebarSection"

export type ICommentDisplayProps = {
    event: FlatEvent<string>
    isCommentFetched: boolean
    containerRef: MutableRefObject<HTMLElement>
    date: Date
    initialScrollTo: EventSidebar["initialScrollTo"]
    setFetchedComments: (comments: EventComment<string>[]) => void
    fetchedComments?: EventComment<string>[]
}

const SidebarActivity = ({
    event,
    containerRef,
    isCommentFetched,
    initialScrollTo,
    fetchedComments,
    setFetchedComments
}: ICommentDisplayProps) => {
    const [commentDeleteDialog, setCommentDeleteDialog] = useState<EventComment<string>>(null)
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const currentUser = useCurrentUser()
    const comments = useCommentsByEvent(event.id)
    const [inEditMode, setEditMode] = useState<number>(null)
    const ref = useRef<HTMLDivElement>(null)
    const orgUsers = useUsersInCurrentOrg()

    const onCommentDelete = async () => {
        const deleted = await dispatch(deleteComment(commentDeleteDialog, isCommentFetched))
        if (!deleted) return false

        if (isCommentFetched) setFetchedComments(fetchedComments.filter(({ id }) => id !== commentDeleteDialog.id))
        setCommentDeleteDialog(null)
    }

    /* eslint-disable react-hooks/rules-of-hooks */
    const hooks = useMemo(
        () => [
            () => {
                useKeymap("Escape", () => {
                    if (!inEditMode) return false

                    setEditMode(null)
                    return true
                })
            }
        ],
        [inEditMode]
    )
    /* eslint-enable */

    const options = useMemo<IOption<EventComment<string>>[]>(
        () => [
            {
                label: t("common:edit"),
                description: t("patient:comments.editDescription"),
                callback: (comment) => setEditMode(comment.id)
            },
            {
                label: t("common:delete"),
                description: t("patient:comments.delete.description"),
                callback: (comment) => setCommentDeleteDialog(comment)
            }
        ],
        [t]
    )

    const onFocus = () => {
        if (inEditMode) setEditMode(null)
    }
    const onComplete = ({ id, text }: EventComment<string>) => {
        setEditMode(null)
        if (isCommentFetched)
            setFetchedComments(fetchedComments.map((comment) => (comment.id === id ? { ...comment, text } : comment)))
    }

    const dispatchComment = async (data: CommentData) => {
        data.date_on_timeline = event.date_on_timeline
        const newComment = await dispatch(createComment(event.id, data, isCommentFetched))
        if (isCommentFetched) setFetchedComments(fetchedComments.concat([newComment]))

        return Boolean(newComment)
    }

    useEffect(() => {
        if (ref.current !== null) {
            const el = containerRef.current.firstChild as HTMLElement
            if (initialScrollTo === "activity") el.scrollTo({ top: ref.current.offsetTop })
            else if (!initialScrollTo) el.scrollTo({ top: 0 })
        }
    }, [event, initialScrollTo])

    return (
        <SidebarSection title={t("common:activity")} className="py-9 gap-6" ref={ref} withoutBorder>
            {(comments ?? fetchedComments)
                ?.filter((comment) => isSameDay(new Date(event?.date_on_timeline), new Date(comment.date_on_timeline)))
                .map((comment) => {
                    const commentAuthor = orgUsers.find((user) => user.user.id === comment.created_by)
                    return comment.id !== inEditMode ? (
                        <RenderComment
                            key={comment.id}
                            commentAuthor={{ ...commentAuthor.user, profession: commentAuthor.profession }}
                            {...{ comment, currentUser, options, containerRef }}
                        />
                    ) : (
                        <CommentPost
                            key={comment.id}
                            submitText={t("common:save")}
                            autoFocus
                            commentToEdit={comment}
                            onComplete={onComplete}
                            hooks={hooks}
                            patientId={event.patient_id}
                        />
                    )
                })}
            <CommentPost
                submitText={t("patient:comments.comment_upper")}
                createComment={dispatchComment}
                onFocus={onFocus}
                patientId={event.patient_id}
            />
            <CommentDeleteDialog onDelete={onCommentDelete} {...{ commentDeleteDialog, setCommentDeleteDialog }} />
        </SidebarSection>
    )
}

export default SidebarActivity
