import { Remirror, useRemirror } from "@remirror/react"
import { BasicUser, CollaboratorWithPermissions, CommentData } from "@sequel-care/types"
import { EventComment } from "@sequel-care/types/Event"
import { IOption, OptionsPopover } from "components/PatientTimeline/EventCard/OptionsPopover"
import UserAvatar from "components/UserAvatar"
import { Button } from "components/common"
import DOMPurify from "dompurify"
import { FormEventHandler, MutableRefObject, ReactNode, forwardRef, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { ObjectMark } from "remirror"
import {
    BoldExtension,
    HistoryExtension,
    ItalicExtension,
    MentionExtension,
    UnderlineExtension
} from "remirror/extensions"
import "remirror/styles/all.css"
import Mentions from "sidebars/Event/Mentions"
import { useDispatch } from "store"
import { useCurrentUser, usePatientCollaborators, useUsersInCurrentOrg } from "store/hooks"
import { updateComment } from "store/patient/actions"
import { toggleTherapistProfile } from "store/sidebars/actions"
import { getUserName } from "utils"
import { shortenedDistanceToNow } from "utils/dates"

type CommentPostProps = {
    createComment?: (data: CommentData) => Promise<boolean> | boolean
    commentToEdit?: EventComment<string>
    onComplete?: (comment: EventComment<string>) => void
    onFocus?: () => void
    hooks?: (() => void)[]
    autoFocus?: boolean
    submitText: string
    patientId: number
}

const regex = /(<([^>]+)>)/gi

export const CommentPost = ({
    createComment,
    commentToEdit,
    onComplete,
    onFocus,
    hooks,
    autoFocus,
    submitText,
    patientId
}: CommentPostProps) => {
    const { t } = useTranslation("patient")
    const [isLoading, setLoading] = useState(false)
    const currentUser = useCurrentUser()
    const dispatch = useDispatch()
    const collaborators: CollaboratorWithPermissions[] = usePatientCollaborators()
    const orgUsers = useUsersInCurrentOrg()

    const users = useMemo(() => {
        const collaboratorUsers = collaborators.map(({ user }) => user)
        const adminUsers = orgUsers
            .filter(({ role, user_id }) => role === "admin" && collaboratorUsers.every(({ id }) => user_id !== id))
            .map(({ user }) => user as BasicUser)

        return [...collaboratorUsers, ...adminUsers]
    }, [collaborators, orgUsers])

    const { manager, state, setState, getContext } = useRemirror({
        extensions: () => [
            new BoldExtension({}),
            new ItalicExtension(),
            new HistoryExtension({}),
            new UnderlineExtension(),
            new MentionExtension({
                matchers: [{ name: "at", char: "@", matchOffset: 0 }],
                appendText: " "
            })
        ],
        stringHandler: "html",
        content: commentToEdit ? commentToEdit.text : ""
    })

    const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault()
        const htmlString = getContext().helpers.getHTML()
        const cleanHtml: string = DOMPurify.sanitize(htmlString)
        const json = getContext().helpers.getJSON()

        if (!json.content) return

        let mentions: number[] = []
        json.content.forEach((row) => {
            if (!row.content) return
            row.content.forEach((el) => {
                if (!el.marks) return

                el.marks.forEach((mark: ObjectMark) => {
                    if (mark.type === "mention") {
                        const mentionedUser = users.find((user) => {
                            const fullName = getUserName(user)
                            return fullName.toLowerCase() === (mark.attrs.label as string).toLowerCase()
                        })
                        mentions.push(mentionedUser.id)
                    }
                })
            })
        })
        if (!cleanHtml.replace(regex, "").length) return

        setLoading(true)
        const data = { text: cleanHtml, mentions, patient_id: patientId }
        try {
            if (commentToEdit) {
                const res = await dispatch(updateComment(commentToEdit, data))
                if (res) onComplete?.(res)
                else throw "update_failed"
            } else {
                const res = await createComment(data)
                if (res) {
                    getContext().clearContent()
                    setLoading(false)
                } else throw "add_failed"
            }
        } catch (error) {
            console.error(error)
            toast(t("common:genericError"), { type: "error" })
        }
    }

    return (
        <CommentWrapper author={currentUser}>
            <form className="flex flex-col gap-2 w-full m-auto" onSubmit={handleSubmit}>
                <div className="remirror-theme w-full rounded">
                    <Remirror
                        manager={manager}
                        placeholder={t("comments.placeholder")}
                        autoRender="start"
                        onChange={(params) => setState(params.state)}
                        {...{ state, hooks, autoFocus, onFocus }}
                    >
                        <Mentions users={users} />
                    </Remirror>
                </div>
                <div className="w-full flex justify-between">
                    <span className="text-xs text-secondary-2 self-end italic -mb-1 -mx-1.5">
                        {commentToEdit && t("comments.exitEditMode")}
                    </span>
                    <Button
                        data-testid="sidebar-comment_submit"
                        theme="light"
                        className="w-24 h-8"
                        type="submit"
                        loading={isLoading}
                    >
                        {submitText}
                    </Button>
                </div>
            </form>
        </CommentWrapper>
    )
}

type RenderCommentProps = {
    comment: EventComment<string>
    currentUser: BasicUser
    commentAuthor: BasicUser
    options: IOption<EventComment<string>>[]
    containerRef: MutableRefObject<HTMLElement>
}

export const RenderComment = ({ comment, currentUser, commentAuthor, options, containerRef }: RenderCommentProps) => {
    const { t } = useTranslation("common")

    const ref = useRef<HTMLDivElement>()

    const sanitizedText = useMemo(() => ({ __html: DOMPurify.sanitize(comment.text) }), [comment.text])

    if (comment.status === "deleted") return null
    return (
        <CommentWrapper author={commentAuthor} ref={ref}>
            <div className="flex justify-between w-full">
                <h3 className="text-dark-blue font-semibold">
                    {commentAuthor.first_name + " " + commentAuthor.last_name}
                </h3>
                <div className="relative flex text-secondary-2 text-xs">
                    {shortenedDistanceToNow(new Date(comment.created_at))}
                    {currentUser.id === commentAuthor.id && (
                        <div className="absolute -bottom-4 -translate-x-0.5 flex items-start gap-6">
                            <OptionsPopover {...{ options, param: comment }} />
                        </div>
                    )}
                </div>
            </div>
            <div className="text-secondary-2 text-sm">{t(`professions.${commentAuthor.profession}`)}</div>
            <p className="text-text-blue text-sm mt-4" dangerouslySetInnerHTML={sanitizedText} />
        </CommentWrapper>
    )
}

export const CommentWrapper = forwardRef<HTMLDivElement, { author: BasicUser; children: ReactNode }>(
    function CommentWrapper({ author, children }, ref) {
        const dispatch = useDispatch()
        return (
            <div ref={ref} className="relative flex flex-row items-start gap-2.5">
                <UserAvatar
                    className="mt-3"
                    user={author}
                    size={8}
                    onClick={() => dispatch(toggleTherapistProfile(author.id))}
                />
                <div
                    data-testid="sidebar-comment_card"
                    className="relative flex flex-col rounded bg-sidebar border px-4 py-3 border-border-blue w-full"
                >
                    {children}
                </div>
            </div>
        )
    }
)
