import { TeamMember, User } from "@prisma/client"
import { ProfileDisplayedField } from "./types"

import { BasicUser } from "@sequel-care/types"
import { AvatarWithName } from "components/UserAvatar"
import { FC } from "react"
import Skeleton from "react-loading-skeleton"
import { pushConversationSidebarToStack } from "../../store/sidebars/actions"
import { SequelConversation } from "../../utils/conversations"

export function defineUserField<K extends keyof User>(field: ProfileDisplayedField<K, User[K]>) {
    return field
}

export function defineTeamMemberField<K extends keyof TeamMember>(field: ProfileDisplayedField<K, TeamMember[K]>) {
    return field
}

function makeRenderUserList<Item>({
    WrapperComponent = ({ children }) => <>{children}</>,
    getData
}: {
    WrapperComponent?: FC<{ className: string; item: Item }>
    getData: (item: Item) => { user: BasicUser; role: string }
}) {
    return function RenderUserList({ value }: { value: Item[] | null }) {
        return (
            <>
                {!value && (
                    <Skeleton width="14rem" count={3} style={{ marginBottom: ".4rem" }} borderRadius="5%" inline />
                )}
                {value?.map((item, index) => (
                    <WrapperComponent
                        key={index}
                        item={item}
                        className="block w-[calc(100%_+_1rem)] ltr:text-left rtl:text-right font-medium text-sm p-2 -mx-2 cursor-pointer first:mt-2 rounded hover:bg-bg"
                    >
                        <AvatarWithName size={10} className="!gap-4" {...getData(item)} />
                    </WrapperComponent>
                ))}
            </>
        )
    }
}

export default makeRenderUserList

export const openChatFromUserProfile = (conversations: SequelConversation[], user: BasicUser) => {
    const conversation = conversations
        .filter((c) => c.participants.length === 2)
        .find((c) => c.hasParticipantWithId(user.id))

    if (conversation !== undefined) {
        return pushConversationSidebarToStack({ mode: "chat", conversation, newChatWith: null })
    }
    return pushConversationSidebarToStack({ mode: "chat", conversation: null, newChatWith: [user] })
}
