import { MinusIcon, UserGroupIcon, XMarkIcon } from "@heroicons/react/24/outline"
import { CheckIcon } from "@heroicons/react/24/solid"
import { TaskStatus } from "@prisma/client"
import { FlatEvent } from "@sequel-care/types"
import { addDays, isBefore } from "date-fns"
import { keyBy } from "lodash"
import { TFunction, useTranslation } from "react-i18next"
import { getDateAtHour0 } from "utils/dates"
import { EventDetailsField } from "./EventDetailsFields"

const statusIcons = {
    incomplete: { Icon: XMarkIcon },
    skipped: { Icon: XMarkIcon },
    completed: { Icon: CheckIcon }
}
const taskStatuses = [
    { id: "incomplete", Icon: XMarkIcon },
    { id: "declined", Icon: XMarkIcon },
    { id: "partial", Icon: MinusIcon },
    { id: "discussion_point", Icon: UserGroupIcon },
    { id: "completed", Icon: CheckIcon }
]
const taskStatusesByKey = keyBy(taskStatuses, "id")

const isTaskStatus = (status: string): status is TaskStatus => status in taskStatusesByKey

const RenderStatus = ({ status, t }: { status: TaskStatus | keyof typeof statusIcons; t: TFunction }) => {
    const { Icon } = isTaskStatus(status) ? taskStatusesByKey[status] : statusIcons[status]
    return (
        <div className="flex items-center gap-1">
            <Icon className="w-4 h-4" />
            {t(`statuses.${status}`)}
        </div>
    )
}

const StatusField = ({ task_status, completed_at, expires_within, date }: FlatEvent<string> & { date: Date }) => {
    const { t } = useTranslation("common")

    const isExpired = expires_within && isBefore(addDays(date, expires_within), addDays(getDateAtHour0(), 1))
    return (
        <EventDetailsField label={t("status")} testId="sidebar-status_field">
            <RenderStatus
                status={task_status ?? (completed_at ? "completed" : isExpired ? "skipped" : "incomplete")}
                t={t}
            />
        </EventDetailsField>
    )
}

export default StatusField
