import { MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import { Dispatch, SetStateAction, useState } from "react"
import { useTranslation } from "react-i18next"
import { TbChevronDown, TbChevronUp } from "react-icons/tb"
import { SearchProps } from "./ConversationDisplay"
import { WrapperProps } from "./ChatContentWrapper"

const ConversationSearchInput = ({
    query,
    foundIdxs,
    selected,
    setSearch,
    setQuery,
    isMessageSearch
}: SearchProps & Pick<WrapperProps, "setQuery" | "setSearch">) => {
    const { t } = useTranslation("common")
    const [text, setText] = useState(query)

    return (
        <label className="flex grow-0 gap-2 cursor-text mx-6 mb-4 px-4 py-3 bg-bg rounded-lg border border-transparent focus-within:border-med-blue/50 text-secondary">
            <MagnifyingGlassIcon className="shrink-0 w-4" />
            <input
                value={text}
                type="text"
                className="grow bg-transparent border-0 focus:ring-0 p-0 placeholder:text-inherit"
                placeholder={t("search")}
                onKeyDown={
                    isMessageSearch
                        ? (e) =>
                              e.key === "Enter" &&
                              setSearch((search) => ({
                                  ...search,
                                  query: text,
                                  selected: foundIdxs.length ? foundIdxs.length - 1 : 0
                              }))
                        : undefined
                }
                onChange={(e) => {
                    setText(e.target.value)
                    if (!isMessageSearch) setQuery(e.target.value)
                }}
            />
            {isMessageSearch && (
                <div className="self-end flex gap-2">
                    {t("therapist:chat.numberOfFound", {
                        number: foundIdxs.length - selected,
                        total: foundIdxs.length
                    })}
                    <div className="flex">
                        <button
                            type="button"
                            onClick={() => {
                                if (!foundIdxs.length) return
                                setSearch((search) => ({
                                    ...search,
                                    selected: selected === 0 ? foundIdxs.length - 1 : selected - 1
                                }))
                            }}
                        >
                            <TbChevronUp className="w-5 h-5 cursor-pointer" />
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                if (!foundIdxs.length) return
                                setSearch((search) => ({
                                    ...search,
                                    selected: selected === foundIdxs.length - 1 ? 0 : selected + 1
                                }))
                            }}
                        >
                            <TbChevronDown className="w-5 h-5 cursor-pointer" />
                        </button>
                    </div>
                </div>
            )}
        </label>
    )
}

export default ConversationSearchInput
