import { BasicUser, PatientFileWithQuestionnaires, PatientWithDiagnoses } from "@sequel-care/types"
import { ChatProps } from "pages/chat"
import { ReactChild, useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "store"
import { useCurrentUser, usePatientList, useUsersInCurrentOrg } from "store/hooks"
import { updateConversationSidebar } from "store/sidebars/actions"
import { ChatPage } from "types/AppSidebar"
import { classNames, getUserName } from "utils"
import { ConversationStore } from "utils/conversations"
import ChatContentWrapper from "./ChatContentWrapper"
import ConversationDetails from "./ConversationDetails"
import { detailsClassname } from "./ConversationList"
import GroupAvatar from "./GroupAvatar"
import { ConversationParticipant, matchQuery, patientContactsToParticipants } from "./utils"

const ConversationCreate = ({
    mode,
    currentPatient,
    inSidebar = false,
    updateChatProps
}: {
    mode: ChatPage
    currentPatient?: PatientFileWithQuestionnaires<string, string> | PatientWithDiagnoses<string, string>
    inSidebar?: boolean
    updateChatProps?: (props: Partial<ChatProps>) => void
}) => {
    const { t } = useTranslation("patient")
    const dispatch = useDispatch()
    const currentUser = useCurrentUser()
    const allPatients = usePatientList()
    const team = useUsersInCurrentOrg()

    const [query, setQuery] = useState("")
    const [patient, setPatient] = useState(currentPatient)

    const isCreateGroup = mode.includes("group")

    const userList = useMemo(() => {
        if (patient) {
            const filtered: ConversationParticipant[] = patient.collaborators
                .filter(({ permissions, user }) => {
                    return permissions.length && user.id !== currentUser.id && matchQuery(user, query)
                })
                .map(({ user }) => user)
                .concat(patientContactsToParticipants(patient.contacts))

            filtered.unshift(patient.user)

            return filtered
        } else {
            let users: ConversationParticipant[] = []
            if (mode.includes("team"))
                team.filter((t) => {
                    if (t.user_id !== currentUser.id && matchQuery(t.user, query))
                        users.push({ ...t.user, profession: t.profession })
                })
            else
                users = users.concat(
                    allPatients
                        .filter(({ user }) => matchQuery(user, query))
                        .flatMap(({ user, contacts }) => {
                            return [user, ...patientContactsToParticipants(contacts, getUserName(user))]
                        })
                )

            return users
        }
    }, [patient, query, mode])

    const openSingle = (withUser: BasicUser) => {
        let patientId = null
        if (withUser.role === "patient") patientId = allPatients.find(({ user_id }) => user_id === withUser.id)?.id
        if (withUser.role === "significant_other")
            patientId = allPatients.find(({ contacts }) => contacts.some(({ id }) => id === withUser.id))?.id

        return openConversation([withUser], patientId)
    }

    const [selectedUsers, setSelectedUsers] = useState<number[]>([])
    const openConversation = (withUsers: BasicUser[], paitentId?: number) => {
        return () => {
            const withUserIds = withUsers.map(({ id }) => id)
            const conversation = ConversationStore.getConversations(paitentId ?? patient?.id).find(
                ({ participants }) => {
                    const participantUserIds = participants
                        .map(({ id }) => id)
                        .filter((userId) => userId !== currentUser.id)
                    return (
                        withUserIds.length === participantUserIds.length &&
                        participantUserIds.every((userId) => withUserIds.includes(userId))
                    )
                }
            )

            const newProps = { mode: "chat", conversation: null } as ChatProps
            if (conversation) newProps.conversation = conversation
            else newProps.newChatWith = withUsers

            updateChatProps
                ? updateChatProps({ ...newProps, patientId: paitentId ?? (patient ? patient.id : undefined) })
                : dispatch(updateConversationSidebar(newProps))
        }
    }

    const toggleParticipant = (userId: number, isPatient?: boolean) => () => {
        let newSelected = selectedUsers.filter((id) => userId !== id)
        if (newSelected.length === selectedUsers.length) {
            newSelected.push(userId)
            if (isPatient && !currentPatient) {
                const foundPatient = allPatients.find(({ user_id }) => userId === user_id)
                setPatient(foundPatient)
            }
        }

        if (isPatient && newSelected.length < selectedUsers.length) {
            if (!currentPatient) setPatient(currentPatient)
            newSelected = []
        }
        setSelectedUsers(newSelected)
    }
    return (
        <ChatContentWrapper
            header={
                !isCreateGroup ? (
                    t("chat.create.title")
                ) : (
                    <div className="grow flex justify-between items-center">
                        {t("chat.addParticipants")}
                        <button
                            disabled={!selectedUsers.length}
                            className="text-sm text-med-blue mx-2 cursor-pointer disabled:cursor-auto disabled:text-gray-400 font-semibold"
                            onClick={openConversation(userList.filter(({ id }) => selectedUsers.includes(id)))}
                        >
                            {t("common:next")}
                        </button>
                    </div>
                )
            }
            width={inSidebar ? "w-150" : "w-full"}
            backButton={inSidebar ? (!isCreateGroup ? "list" : "create") : undefined}
            search={{ query, isMessageSearch: false }}
            setQuery={setQuery}
        >
            {!userList.length ? (
                <div className="p-8 text-center text-sm text-gray-400">{t("chat.create.collaboratorsNotFound")}</div>
            ) : (
                <>
                    {!isCreateGroup && (
                        <div
                            className={classNames("flex items-center gap-3", detailsClassname)}
                            onClick={() => {
                                const updates = { mode: (mode + "-group") as ChatPage }
                                updateChatProps
                                    ? updateChatProps(updates)
                                    : dispatch(updateConversationSidebar(updates))
                            }}
                        >
                            <GroupAvatar />
                            <div className="grow flex items-center">{t("chat.newGroup")}</div>
                        </div>
                    )}
                    {userList.map((user) => (
                        <ConversationListItem
                            key={user.id}
                            isPatientScope={Boolean(currentPatient)}
                            onClick={
                                ["create", "create-team"].includes(mode)
                                    ? openSingle(user)
                                    : toggleParticipant(user.id, user.role === "patient")
                            }
                            user={user}
                            rowRight={
                                isCreateGroup && (
                                    <div
                                        className={classNames(
                                            "rounded-full border-2 border-text-blue h-4 w-4 mx-1 transition-colors duration-100",
                                            selectedUsers.includes(user.id) && "bg-dark-blue"
                                        )}
                                    />
                                )
                            }
                        />
                    ))}
                </>
            )}
        </ChatContentWrapper>
    )
}

type ListItemProps = {
    user: ConversationParticipant
    onClick: () => void
    rowRight?: ReactChild
    isPatientScope: boolean
}
const ConversationListItem = ({ user, onClick, rowRight, isPatientScope }: ListItemProps) => {
    const { t } = useTranslation("common")
    const users = useMemo(() => [user], [user])

    const RenderDetails = useCallback(() => {
        return (
            <div className="w-full flex justify-between items-center">
                <div>
                    {user?.patient_name || getUserName(user)}
                    <div className="w-40 text-sm text-gray-400">
                        {user.relation_type
                            ? t(`relationType.${user.relation_type}`) +
                              (isPatientScope ? "" : ` (${getUserName(user)})`)
                            : user.role === "patient"
                            ? t("roles.patient")
                            : t(`professions.${user.profession}`)}
                    </div>
                </div>
                {rowRight}
            </div>
        )
    }, [user, rowRight])

    return (
        <ConversationDetails
            users={users}
            className={detailsClassname}
            onClick={onClick}
            RenderDetails={RenderDetails}
            withoutParticipantNames
        />
    )
}

export default ConversationCreate
