import { LightBulbIcon } from "@heroicons/react/24/outline"
import { EventType } from "@prisma/client"
import { BasicUser, QuestionnaireListItem, QuestionScaleWithCutoffs, RoleWithOrg } from "@sequel-care/types"
import { TFunction } from "i18next"
import Router from "next/router"
import { ComponentProps, Dispatch, SetStateAction, useEffect, useRef } from "react"

import { BarElement, CategoryScale, Chart, LinearScale, LineController, LineElement, PointElement } from "chart.js"
import annotationPlugin from "chartjs-plugin-annotation"
import ExerciseIcon from "components/icons/ExerciseIcon"
import QuestionnaireIcon from "components/icons/QuestionnaireIcon"
import TaskIcon from "components/icons/TaskIcon"
import { Dictionary, noop } from "lodash"
import { BasicQuestion } from "types/Misc"
import CalendarTime from "../components/icons/CalendarTime"
import TrackerIcon from "components/icons/TrackerIcon"

export const classNames = (...classes: (string | undefined | null | false)[]) => classes.filter(Boolean).join(" ")
export const getUserName = (user?: Pick<BasicUser, "first_name" | "last_name">) =>
    user ? `${user.first_name} ${user.last_name}` : ""

export const eventTypeIconMap: { [key in EventType]: (props: ComponentProps<"svg">) => JSX.Element } = {
    questionnaire: QuestionnaireIcon,
    exercise: ExerciseIcon,
    appointment: CalendarTime,
    task: TaskIcon,
    reflection: LightBulbIcon,
    mood: () => <>NOICON</>, // TODO: Get an icon for mood,
    tracker: TrackerIcon
}

export const getPatientIdFromPath = (router = Router) => {
    return parseInt(router.query.patientId as string)
}

export const isScoreExtreme = (scale: QuestionnaireListItem["questionnaire_scale"], score: number) => {
    if (!scale || !scale.cutoffs?.length) return false

    if (scale.direction === "positive") {
        const minLimit = Math.min(...scale.cutoffs.map((c) => c.min))
        return !isNaN(score) && score < minLimit
    } else {
        const minLimit = Math.max(...scale.cutoffs.map((c) => c.min))
        return !isNaN(score) && score > minLimit
    }
}

type EffectCallback<Deps> = (depHistory: Deps) => (() => void) | void

/**
 * Pass this hook a state value to keep track of its previous value.
 * @returns an object containing keys "value" (the history value) and "update" (set history value to current state)
 */
export const useEffectWithDepHistory = <Deps extends Dictionary<any>>(
    callback: EffectCallback<Deps | null>,
    deps: Deps
) => {
    const depHistory = useRef<Deps | null>(null)
    useEffect(() => {
        const currentHistory = depHistory.current
        depHistory.current = deps
        return callback(currentHistory)
    }, Object.values(deps))
}

export const registerChart = () => {
    Chart.register(LinearScale, CategoryScale, LineController, BarElement, annotationPlugin, PointElement, LineElement)
}

export function getPromiseWithResolve<V = unknown>() {
    let resolve: (value?: V | PromiseLike<V>) => void = noop
    const promise = new Promise<V>((resolveFn) => (resolve = resolveFn))
    return { promise, resolve }
}

const createDivWithText = (text: string, maxWidth: number = 200) => {
    const refEl = document.createElement("div")
    refEl.style.position = "absolute"
    refEl.style.top = "20px"
    refEl.style.bottom = "20px"
    refEl.style.maxWidth = maxWidth + "px"
    refEl.style.height = "fit-content"
    refEl.innerText = text
    document.body.appendChild(refEl)

    return refEl
}

export const getTextHeight = (text: string, maxWidth: number = 200) => {
    const refEl = createDivWithText(text, maxWidth)

    const height = refEl.clientHeight
    refEl.remove()
    return height
}

export const getTextWidth = (text: string) => {
    const el = createDivWithText(text)

    const width = el.clientWidth
    el.remove()
    return width
}

export const getHeightFromClasses = (classNames: string[]) => {
    return classNames.reduce<number>((acc, curr) => {
        const [_, value] = curr.split("-")

        return acc + parseInt(value) * 4
    }, 0)
}

export const isAdmin = (role: RoleWithOrg) => role?.role === "admin"

export function idsOf<ID>(list: { id: ID }[]) {
    return list.map(({ id }) => id)
}

export const uploadImageToCloudflare = (image: File, uploadUrl: string) => {
    const data = new FormData()
    data.append("file", image)
    return fetch(uploadUrl, { method: "POST", body: data })
}

export const noopTFunction: TFunction = (str: string) => str

export function getStateValue<V>(newState: SetStateAction<V>, prevState: V) {
    return typeof newState === "function" ? (newState as (v: V) => V)(prevState) : newState
}

export const basicUserPick = ["id", "profile_image", "first_name", "last_name", "role", "email"] as const

export function createSetter<S extends Dictionary<any>, K extends keyof S, V extends S[K] = S[K]>(
    setState: Dispatch<SetStateAction<S>>,
    key: K
) {
    return (newValue: SetStateAction<V>) =>
        setState((state) => ({ ...state, [key]: getStateValue(newValue, state[key]) }))
}

export const questionnaireColors = [
    "#DA69CF",
    "#6243D7",
    "#A9ACFE",
    "#F5AAF7",
    "#3523FF",
    "#5497A6",
    "#AC81D8",
    "#3C7FF9",
    "#BE8FED",
    "#23C9EC",
    "#B6BBBD",
    "#A95487",
    "#46B5F4",
    "#93B3E2"
]

export const getQuestionLabels = (question?: BasicQuestion, scale?: QuestionScaleWithCutoffs) => {
    if (!question || !scale || !("scale_id" in question)) return []

    const labelSet = question.definition?.label_set ?? 0
    return (scale.labels as string[][])[labelSet]
}
