import { SVGProps } from "react"

const ClockIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        viewBox="0 0 24 24"
        {...props}
    >
        <path stroke="none" d="M0 0h24v24H0z" />
        <circle cx={12} cy={12} r={9} />
        <path d="M12 7v5l3 3" />
    </svg>
)

export default ClockIcon
