import { PatientFileInput, TeamUser, UserCreateInput } from "@sequel-care/types"
import { CompletePatientFile, PatientFormContact } from "@sequel-care/types/Patient"
import { defaultInputClassNames, defineField, initialValuesFromFields } from "forms"
import { defineStep, FormContext, FormContextValue } from "forms/FormWithSteps"
import { TFunction } from "i18next"
import { omit, pick } from "lodash"
import { useContext, useMemo } from "react"
import { DateValue } from "react-aria"
import { useTranslation } from "react-i18next"
import { useCurrentUser } from "store/hooks"
import { dateToCalendarDate } from "utils/dates"
import ClinicalDetails from "./ClinicalDetails"
import { ClinicalInfo, getClinicalFields, getPatientUserFields, getTreatmentFields, TreatmentInfo } from "./fields"
import TreatmentDetails from "./TreatmentDetails"

export const patientFields = [
    defineField({
        id: "healthcare_provider",
        placeholder: "patient:patientForm.healthcareProvider",
        classList: { input: `${defaultInputClassNames} w-52` }
    }),
    defineField({
        id: "ssn",
        placeholder: "patient:patientForm.socialSecurityNumber",
        classList: { input: `${defaultInputClassNames} w-64` },
        validations: {
            custom: {
                isValid: (value: string) => value.length === 0 || !isNaN(parseInt(value)),
                message: "The social security number you entered is invalid"
            }
        }
    })
]

export type PatientFormState = {
    user: UserCreateInput<File | string, DateValue> & { contacts: PatientFormContact[] }
    patient: Omit<
        PatientFileInput<DateValue, string>,
        "primary_diagnosis" | "comorbidities" | "tags" | "notes" | "medication"
    >
    treatment: TreatmentInfo
    clinical: ClinicalInfo
}

export const initialPatientAddState = (currentUser: TeamUser, t: TFunction) => (): PatientFormState => ({
    user: initialValuesFromFields(getPatientUserFields()) as PatientFormState["user"],
    patient: initialValuesFromFields(patientFields),
    treatment: initialValuesFromFields(getTreatmentFields(currentUser, t)),
    clinical: initialValuesFromFields(getClinicalFields())
})

export const initialPatientUpdateState =
    (params: CompletePatientFile<string, string>): (() => PatientFormState) =>
    () => {
        const caseManager = params.collaborators.find((c) => c.permissions[0]?.permission === "case_manager")

        return {
            user: {
                ...omit(params.user, ["mobile_device_token", "country", "cognito_uid", "firebase_uid"] as const),
                date_of_birth: dateToCalendarDate(params.user.date_of_birth),
                contacts: params.contacts.filter(({ disabled }) => !disabled)
            },
            patient: {
                healthcare_provider: params.healthcare_provider,
                ssn: params.ssn?.toString() ?? ""
            },
            treatment: {
                treatment_end_reason: params.treatment_end_reason,
                treatment_start: new Date(params.treatment_start),
                treatment_end: params.treatment_end ? new Date(params.treatment_end) : undefined,
                case_manager: caseManager?.user.id ?? null,
                collaborators: [],
                tags: params.tags.map(({ id }) => id)
            },
            clinical: {
                ...pick(params, ["medication", "notes"]),
                primary_diagnosis: params.primary_diagnosis ?? null,
                comorbidities: params.comorbidities ?? []
            }
        }
    }

export const usePatientStepList = ({ isEdit }: { isEdit: boolean }) => {
    const { t } = useTranslation("patient")
    const currentUser = useCurrentUser()

    return useMemo(
        () =>
            [
                defineStep({
                    id: "user",
                    name: t("user:userForm.personalDetails"),
                    fields: getPatientUserFields(t),
                    description: t("user:userForm.personalDetailsDescription"),
                    fieldGridColumns: 9
                }),
                // {
                //     id: "patient",
                //     name: t("patientForm.insurance"),
                //     description: t("patientForm.insuranceDescription"),
                //     validations: getValidationsFromFields(patientFields),
                //     Component: InsuranceDetails
                // },
                defineStep({
                    id: "clinical",
                    name: t("patientForm.clinicalInfo"),
                    fields: getClinicalFields(t),
                    Component: ClinicalDetails
                }),
                defineStep({
                    id: "treatment",
                    name: t("patientForm.treatmentInfo"),
                    description: t("patientForm.diagnosisDescription"),
                    fields: getTreatmentFields(currentUser, t, { isEdit }),
                    Component: (props) => <TreatmentDetails {...props} isEdit={isEdit} />
                })
            ] as const,
        [isEdit]
    )
}

export const usePatientFormContext = () =>
    useContext(FormContext) as FormContextValue<ReturnType<typeof usePatientStepList>, PatientFormState>

export const leftSectionWidth = "w-[36rem]"
