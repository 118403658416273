import { EventType, WeekStart } from "@prisma/client"
import { BasicGoal, RawEventCreateInput } from "@sequel-care/types"
import { addMonths, setHours, setMinutes } from "date-fns"
import { defineField } from "forms"
import NumberField from "forms/NumberField"
import { omit } from "lodash"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { EventTemplate } from "types/Misc"
import { noopTFunction } from "utils"
import { DateString } from "utils/dates"
import { getInlineEventFields } from "./EventFields"
import { ConditionTimeUnit, ConditionType } from "./InputComponents/ConditionComponents"
import { EventSelector } from "./InputComponents/EventSelector"
import { EventConfigState } from "./types"
import { CarePlanEventConfig, getCarePlanEventFields } from "components/Settings/utils"
import { ListField, ListRow, ListWrapper } from "forms/ListField"

export const useInlineEventFields = () => {
    const { t } = useTranslation()
    return useMemo(() => getInlineEventFields(t), [t])
}

export const goalTypeToEventType: Record<string, EventType[]> = {
    activity: ["task"],
    awareness: ["questionnaire", "exercise"]
}

export const getRecurrenceInitialValue = (recurrence: EventTemplate["recurrence"]) => {
    const recurrenceUnit = recurrence?.interval % 7 === 0 ? "weeks" : "days"
    return {
        enabled: Boolean(recurrence),
        end: recurrence?.end ? new Date(recurrence.end) : addMonths(new Date(), 3),
        interval: recurrenceUnit === "weeks" ? recurrence?.interval / 7 : recurrence?.interval ?? 1,
        unit: recurrenceUnit
    }
}

export const getGoalId = (type: EventType, allGoals: BasicGoal[]) => {
    const goalType = Object.entries(goalTypeToEventType).find(([_, types]) =>
        types.some((eType) => eType === type)
    )?.[0]
    return allGoals.find(({ type }) => goalType === type)?.id
}

export type ConditionFields = ReturnType<typeof getEventConditionFields>

export const getEventConditionFields = (t = noopTFunction) => [
    defineField({
        id: "type",
        placeholder: t("patient:eventManager.questionnairePlaceholder"),
        classList: {
            wrapper: "!col-span-1 text-dark-blue",
            input: "!border-none !text-base w-full !py-0 placeholder:text-secondary-2 bg-transparent"
        },
        validations: { required: { value: true, message: t("patient:eventManager.validationMessages.event") } },
        types: ["questionnaire"],
        settingState: true,
        Component: EventSelector
    }),
    defineField({
        id: "condition_type",
        initialValue: "after",
        classList: { wrapper: "!col-span-1", input: "border-none font-medium" },
        Component: ConditionType
    }),
    defineField({
        id: "time_offset",
        initialValue: 0,
        min: 0,
        Component: NumberField,
        classList: { wrapper: "!col-span-1", input: "border-none text-dark-blue font-medium w-24" }
    }),
    defineField({
        id: "time_unit",
        initialValue: "day",
        classList: { wrapper: "!col-span-1", input: "border-none font-medium w-24" },
        Component: ConditionTimeUnit
    })
]

type RawEventParams = { questionnaire_id: number; exercise_id?: number; is_conditioned?: boolean }

export const configToRawEvent = (state: EventConfigState, params?: RawEventParams): RawEventCreateInput => {
    const { date_on_timeline, isTimeless, start, end } = state.time

    return {
        ...omit(state, ["end_time", "available_for", "linked_events", "time", "attachment"]),
        expires_within: state.available_for,
        assignee_id: state.assignee,
        duration: undefined,
        questionnaire_id: (state.questionnaire_id ?? params?.questionnaire_id) || undefined,
        exercise_id: (state?.exercise_id ?? params?.exercise_id) || undefined,
        end_time: !isTimeless
            ? setHours(setMinutes(date_on_timeline, Number(end.split(":")[1])), Number(end.split(":")[0])).toJSON()
            : undefined,
        date_on_timeline: params?.is_conditioned
            ? undefined
            : isTimeless
            ? `${DateString.from(state.time.date_on_timeline)}T00:00:00.000Z`
            : setHours(setMinutes(date_on_timeline, Number(start.split(":")[1])), Number(start.split(":")[0])).toJSON(),
        recurrence:
            params?.is_conditioned || !state.recurrence.enabled
                ? undefined
                : {
                      interval: state.recurrence.interval * (state.recurrence.unit === "weeks" ? 7 : 1),
                      end: DateString.from(state.recurrence.end)
                  },
        attachment_id: state?.attachment?.id ?? null
    }
}

export const convertWeekDayByUserSetting = (weekDay: number, weekStart: WeekStart) => {
    const weekDayByUserSetting = weekStart === "sun" ? weekDay - 1 : weekDay

    return weekDayByUserSetting < 1 ? 7 : weekDayByUserSetting > 7 ? 1 : weekDayByUserSetting
}

export const useCarePlanConfigsField = () => {
    const { t } = useTranslation()
    return useMemo(
        () => [
            defineField({
                id: "configs",
                Component: ListField<CarePlanEventConfig, EventConfigState>,
                ListWrapper: ListWrapper,
                useFields: () => getCarePlanEventFields(t, true, false),
                ItemWrapper: ListRow,
                removeAll: false,
                gridTemplateColumns: "1.5fr 0.5fr 2.5fr 1fr 1fr",
                classList: { button: "flex-col" }
            })
        ],
        [t]
    )
}
