import * as React from "react"
import { SVGProps } from "react"

const ExportIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.788 1.372c-.333 0-.65.132-.885.365-.235.234-.366.55-.366.88v3.176h4.439V1.372H2.788Zm0-1.372C2.093 0 1.426.275.934.766a2.614 2.614 0 0 0-.769 1.85v11.586c0 .695.277 1.36.769 1.851.492.49 1.159.766 1.854.766h5.326a.686.686 0 1 0 0-1.372h-.766V7.165h8.312v2.693a.686.686 0 1 0 1.372 0V2.617c0-.695-.277-1.36-.769-1.851A2.626 2.626 0 0 0 14.409 0H2.788Zm4.56 1.372v4.42h8.312V2.618c0-.33-.131-.646-.366-.88a1.254 1.254 0 0 0-.885-.365H7.348ZM5.976 7.165H1.537v7.037c0 .33.131.646.366.88.234.233.552.365.885.365h3.188V7.165Z"
      fill="#fff"
    />
    <path
      d="M13.923 11.787a.686.686 0 0 1 .97-.002l2.905 2.896a.687.687 0 0 1 0 .972l-2.905 2.896a.686.686 0 1 1-.969-.971l1.73-1.725h-5.12a.686.686 0 0 1 0-1.372h5.12l-1.73-1.724a.686.686 0 0 1-.001-.97Z"
      fill="#fff"
    />
  </svg>
)

export default ExportIcon
