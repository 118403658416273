import { ChangeEvent, useState } from "react"
import { toast } from "react-toastify"
import { PhotoIcon } from "@heroicons/react/24/solid"
import { TaskAttachment } from "@prisma/client"
import { RenderLabel } from "./RenderLabel"
import { uploadFile } from "api"
import { FormControlProps } from "./types"
import { classNames } from "utils"
import Spinner from "components/common/Spinner"
import { useTranslation } from "react-i18next"
import { TrashIcon } from "@heroicons/react/24/solid"

type UploadFileProps = FormControlProps<TaskAttachment> & {
    accept?: string
    limitSize?: number
    setFormLoading?: (state: boolean) => void
}

const DEFAULT_LIMIT_MB = 10
const BYTES_IN_MB = 1048576

const convertBytesToMb = (bytes: number): number => bytes / BYTES_IN_MB

const UploadFile = ({
    accept = "application/pdf, image/*",
    limitSize = DEFAULT_LIMIT_MB,
    setFormLoading,
    ...props
}: UploadFileProps) => {
    const { t } = useTranslation("common")
    const [isLoading, setLoading] = useState<boolean>(false)
    const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        try {
            setLoading(true)
            setFormLoading(true)
            if (event.target.files) {
                const file = event.target.files[0]

                if (convertBytesToMb(file.size) > limitSize) {
                    toast(t("fileUpload.fileSizeError", { mb: 10 }), { type: "error" })
                    return
                }
                const response = await uploadFile(file, "attachments")

                props.setValue(response)
                toast(t("common:fileUpload.success"), { type: "success" })
            }
        } catch (error) {
            console.log("UPLOAD FILE ERROR:", error)
            toast(t("common:wentWrong"), { type: "error" })
        } finally {
            setLoading(false)
            setFormLoading(false)
        }
    }

    const onRemoveFile = () => {
        props.setValue(null)
    }

    return (
        <div className={classNames("relative", props.classList?.wrapper)}>
            <RenderLabel {...props} />
            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                <div className="text-center">
                    {isLoading ? (
                        <Spinner size={10} className="upload-file-loader" />
                    ) : (
                        <>
                            <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                            <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                <label
                                    htmlFor="file-upload"
                                    className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                >
                                    <span>{t("common:fileUpload.uploadFile")}</span>
                                    <input
                                        onChange={handleFileChange}
                                        id="file-upload"
                                        name="file-upload"
                                        type="file"
                                        className="sr-only"
                                        accept={accept}
                                    />
                                </label>
                                <p className="pl-1">{t("common:fileUpload.orDragAndDrop")}</p>
                            </div>
                            <p className="text-xs leading-5 text-gray-600">
                                PNG, JPG, GIF {t("common:fileUpload.upToMB", { mb: limitSize })}
                            </p>
                        </>
                    )}
                </div>
            </div>
            {props.value && (
                <div className="flex mt-2">
                    <span className="text-xs leading-5 text-gray-600">{props.value?.original_name}</span>

                    <TrashIcon
                        className="cursor-pointer hover:text-indigo-500 h-4 cursor-pointer hover:text-indigo-500 ml-2 text-gray-600"
                        onClick={onRemoveFile}
                    />
                </div>
            )}
        </div>
    )
}

export default UploadFile
