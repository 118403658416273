import QuestionnaireScoresFilterItem from "../PatientOverview/QuestionnaireScoresCard/QuestionnaireScoresFilterItem"
import { useMemo } from "react"
import { GraphItem } from "./types"
import { useTranslation } from "react-i18next"
import { ById } from "@sequel-care/types/utils"

type QuestionnairesListProps = {
    items: ById<GraphItem>
    activeIds: string[]
    toggleItem: (id: string) => void
}

const QuestionnairesList = ({ items, activeIds, toggleItem }: QuestionnairesListProps) => {
    const { t } = useTranslation("patient")
    const sortedItems = useMemo(() => {
        return Object.values(items).sort((itemA) => (itemA.id === "q0" || itemA.id.includes("t") ? -1 : 1))
    }, [items])
    const totalScoreItems = useMemo(() => sortedItems.filter((item) => item.id.includes("q")), [items])
    const subScoreItems = useMemo(() => sortedItems.filter((item) => item.id.includes("s")), [items])
    return (
        <div className="w-1/12 overflow-y-auto mt-6">
            {totalScoreItems.length
                ? totalScoreItems.map((item, index) => {
                      const isActive = activeIds.includes(item.id)

                      return (
                          <QuestionnaireScoresFilterItem
                              key={`questionnaire-sub-item-${index}`}
                              id={+item.id}
                              title={"name" in item ? item.name : t('totalScore"')}
                              color={item.color}
                              isSelected={isActive}
                              onClick={() => {
                                  toggleItem(item.id)
                              }}
                          />
                      )
                  })
                : null}

            {subScoreItems.length ? (
                <>
                    <div className="text-xs text-gray-400 font-medium my-2">{t("dashboard.subScores")}</div>
                    {subScoreItems.map((item, index) => {
                        const isActive = activeIds.includes(item.id)

                        return (
                            <QuestionnaireScoresFilterItem
                                key={`questionnaire-sub-item-${index}`}
                                id={+item.id}
                                title={"title" in item ? item.title : item.name}
                                color={item.color}
                                isSelected={isActive}
                                onClick={() => {
                                    toggleItem(item.id)
                                }}
                                wrapClass="!mb-2"
                            />
                        )
                    })}
                </>
            ) : null}
        </div>
    )
}

export default QuestionnairesList
