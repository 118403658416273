import { useSelector } from "react-redux"
import { RootState } from "../types/Redux"
import { CarePlanSettingsModal } from "./CarePlanSettingsModal"
import { FeaturesSettingsModal } from "./FeaturesSettingsModal/FeaturesSettingsModal"
import { PatientModal } from "./PatientModal/PatientModal"
import { PatientWhatNextModal } from "./PatientWhatNextModal/PatientWhatNextModal"
import TreatmentEndModal from "./TreatmentEndModal/TreatmentEndModal"
import { UserModal } from "./UserModal/UserModal"

const ModalContainer = () => {
    const modal = useSelector((state: RootState) => state.modal)

    return (
        <>
            <UserModal isOpen={modal.type === "user"} params={modal.type === "user" && modal.params} />
            <PatientModal isOpen={modal.type === "patient"} params={modal.type === "patient" && modal.params} />
            <PatientWhatNextModal
                isOpen={modal.type === "patient_what_next"}
                params={modal.type === "patient_what_next" && modal.params}
            />
            <FeaturesSettingsModal
                isOpen={modal.type === "features_settings"}
                params={modal.type === "features_settings" && modal.params}
            />
            <CarePlanSettingsModal
                isOpen={modal.type === "careplan_settings"}
                params={modal.type === "careplan_settings" && modal.params}
            />
            <TreatmentEndModal
                isOpen={modal.type === "treatment_end"}
                params={modal.type === "treatment_end" && modal.params}
            />
        </>
    )
}

export default ModalContainer
