import { ExerciseQuestion } from "@prisma/client"
import { CompleteExercise, QuestionnaireListItem } from "@sequel-care/types"
import { createContext, useContext } from "react"
import { BasicQuestion } from "types/Misc"

type CommonContextProps = { responsesByQuestionId?: Record<string, string> }
export type ContextWithExercise = CommonContextProps & { exercise: CompleteExercise }
export type ContextWithQuestionnaire = CommonContextProps & { questionnaire: QuestionnaireListItem }

type QuestionGridContextValue = ContextWithExercise | ContextWithQuestionnaire

export const QuestionGridContext = createContext<QuestionGridContextValue>({
    responsesByQuestionId: {},
    exercise: null
})

export function useQuestionGridContext<V extends QuestionGridContextValue = QuestionGridContextValue>() {
    return useContext(QuestionGridContext) as V
}

export const useQuestionScale = (question: ExerciseQuestion | BasicQuestion) => {
    const context = useQuestionGridContext()
    // TODO: Remove type guards here after merging exercise and questionnaire
    const scale =
        "questionnaire" in context &&
        "scale_id" in question &&
        context.questionnaire.scales.find(({ id }) => question.scale_id === id)
    return scale || null
}
