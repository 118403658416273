import { updatePatientFeatures } from "api"
import { Button, Modal, Spinner, Switch } from "components/common"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { closeModal } from "store/modal/actions"
import { useCurrentPatientFeatures } from "../../store/hooks"
import { setCurrentPatientFeatures } from "../../store/patient/actions"

export const FeaturesSettingsModal = ({ isOpen, params }: { isOpen: boolean; params: { patient_id: number } }) => {
    const dispatch = useDispatch()
    const handleClose = () => dispatch(closeModal())
    const [features, setFeatures] = useState<{ [key: string]: boolean }>(null)
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation("patient")
    const featuresStored = useCurrentPatientFeatures()

    useEffect(() => {
        setFeatures(featuresStored)
    }, [isOpen, featuresStored])

    const handleChange = (type: string) => {
        setFeatures({ ...features, [type]: !features[type] })
    }

    const handleSubmit = async () => {
        setLoading(true)
        await updatePatientFeatures(params.patient_id, features)
        dispatch(setCurrentPatientFeatures(features))
        toast(t("featuresSettings.success"))
        setLoading(false)

        handleClose()
    }

    return (
        <Modal onClose={handleClose} show={isOpen} cardClassName="p-12 w-240 h-150 rounded" scrollAuto>
            <div className="flex flex-col text-dark-blue h-full justify-between">
                <div className="flex flex-col">
                    <span className="text-bold text-2xl pb-12">{t("featuresSettings.title")}</span>
                    {t("featuresSettings.subtitle")}

                    {features ? (
                        <div className="grid grid-cols-2 gap-x-14 mt-8">
                            {Object.entries(features).map(([key, isEnabled]) => {
                                return key === "feature-settings" ? null : (
                                    <div
                                        className="flex items-center w-full justify-between border-b border-border-blue py-4"
                                        key={key}
                                    >
                                        <span className="w-32 font-normal text-base">
                                            {t(`featuresSettings.${key}`)}
                                        </span>
                                        <Switch
                                            value={isEnabled}
                                            setValue={() => handleChange(key)}
                                            readOnly={key === "reflection"}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    ) : (
                        <Spinner size={10} className="m-auto" />
                    )}
                </div>

                <div className="flex justify-end">
                    <Button theme="dark_borderless" className="text-lg mt-8 w-40" onClick={handleClose}>
                        {t("common:back")}
                    </Button>
                    <Button theme="dark_primary" className="text-lg mt-8 w-40" onClick={handleSubmit} loading={loading}>
                        {t("common:done")}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}
