import { BasicUser, ChatConfig } from "@sequel-care/types"
import { Conversation, Message, Paginator, SendMediaOptions } from "@twilio/conversations"
import { updateChatConfiguration } from "api/Chat"
import { isBefore } from "date-fns"
import { groupBy, last } from "lodash"
import store from "store"
import { ArrayElement } from "type-fest/source/exact"
import { ConversationSidebar } from "types/AppSidebar"
import { getPromiseWithResolve } from "utils"
import { DateString } from "utils/dates"
import ConversationStore from "./ConversationStore"
import {
    ConversationInstanceSubscriptions,
    isMessageDeleted,
    MessageCount,
    MessageDisplayItem,
    TemporaryMessage,
    Unsubscribe
} from "./types"

class SequelConversation {
    public messageDisplayList: MessageDisplayItem[] = []
    public messageCount: MessageCount = { all: 0, unread: 0 }
    public participants: BasicUser[] = []
    public configuration: ChatConfig = {}

    private isInitialized: Promise<void> | undefined
    private messagePaginator: Paginator<Message> | null = null
    private messages: (Message | TemporaryMessage)[] = []
    private subscriptions: ConversationInstanceSubscriptions = {
        messages: [],
        messageCount: []
    }

    constructor(private conversation: Conversation) {
        const { resolve: resolveParticipantPromise, promise: participantPromise } = getPromiseWithResolve<void>()

        // By letting the ConversationStore fetch participants, we make it possible for it to aggregate fetches for multiple conversations into one
        ConversationStore.registerConversation(this, ({ participants, configuration }) => {
            this.participants = participants
            this.configuration = configuration
            resolveParticipantPromise()
        })

        this.isInitialized = new Promise(async (resolve) => {
            await Promise.all([
                participantPromise,
                conversation.lastReadMessageIndex === null
                    ? conversation.updateLastReadMessageIndex(0)
                    : Promise.resolve()
            ])

            await this.getPaginator(true)

            resolve()
        })
    }

    public get sid() {
        return this.conversation.sid
    }

    public get patientId() {
        return ConversationStore.getPatientIdFromConversation(this.conversation)
    }

    public get rawMessageListSize() {
        return this.messages.length
    }

    public get lastMessageDate() {
        const lastMessage = last(this.messageDisplayList) as Message
        return lastMessage?.dateCreated
    }

    public get hasMoreMessages() {
        return this.messagePaginator?.hasPrevPage ?? false
    }

    public get isConversationOpen() {
        const chat = store.getState().global.chat
        const sidebar = last(store.getState().sidebars.stack) as ConversationSidebar
        const sidebarConversation = sidebar?.isOpen && sidebar?.conversation
        return this.conversation.sid === sidebarConversation?.sid || this.conversation.sid === chat.conversation?.sid
    }

    public hasParticipantWithId(id: number) {
        return this.participants.find((participant) => participant.id === id) !== undefined
    }

    public async loadMoreMessages() {
        if (!this.hasMoreMessages) return

        const paginator = await this.messagePaginator?.prevPage()
        if (paginator) {
            this.messagePaginator = paginator
            await this.addMessages(paginator.items)
        }
    }

    public async sendMessage(message: string) {
        await this.isInitialized

        const temporaryMessage: TemporaryMessage = {
            isTemp: true,
            body: message,
            dateCreated: new Date(),
            conversation: this.conversation,
            author: `uid${store.getState().global.user.id}`
        }
        this.addMessages([temporaryMessage])
        this.conversation.sendMessage(message)
    }

    public async sendMedia(file: File) {
        await this.isInitialized

        const mediaMessage: SendMediaOptions = {
            contentType: file.type,
            media: file
        }

        await this.conversation.sendMessage(mediaMessage)
    }

    public async deleteMessage(item: MessageDisplayItem) {
        if (!(item instanceof Message)) return
        await this.isInitialized

        const attrs = { deleted: true }
        const index = this.messages.indexOf(item)
        await item.updateAttributes(attrs)
        if (index !== -1) this.messages.splice(index, 1)
        await this.constructMessagesGroupedList()
    }

    public subscribe<K extends keyof ConversationInstanceSubscriptions>(
        key: K,
        callback: ArrayElement<ConversationInstanceSubscriptions[K]>
    ): Unsubscribe {
        // New subscriptions are fired immediately
        callback(this.getSubscriptionData(key) as any)

        this.subscriptions[key].push(callback as any)
        return () => this.unsubscribe(key, callback)
    }

    public unsubscribe<K extends keyof ConversationInstanceSubscriptions>(
        key: K,
        callback: ArrayElement<ConversationInstanceSubscriptions[K]>
    ) {
        this.subscriptions[key] = (this.subscriptions[key] as any[]).filter((subscription) => subscription !== callback)
    }

    public async updateMessageCount(setAllRead = false) {
        const tempMessageCount = this.messages.filter((message) => "isTemp" in message).length
        const [all, unread] = await Promise.all([
            this.conversation.getMessagesCount(),
            setAllRead ? this.conversation.setAllMessagesRead() : this.conversation.getUnreadMessagesCount()
        ])

        this.messageCount = { all, unread: unread - tempMessageCount }
        this.informSubscribers("messageCount")
    }

    private getSubscriptionData(key: keyof ConversationInstanceSubscriptions) {
        switch (key) {
            case "messageCount":
                return this.messageCount
            case "messages":
                return this.messageDisplayList
            default:
                console.warn(`Encountered uncovered subscription key "${key}" in SequelConversation`)
                return null
        }
    }

    private informSubscribers(key: keyof ConversationInstanceSubscriptions) {
        this.subscriptions[key].forEach((subscription) => subscription(this.getSubscriptionData(key) as any))
    }

    private reconcileTemporaryMessages(messages: (Message | TemporaryMessage)[]) {
        const newMessages = this.messages.slice()
        const temporaryMessages = newMessages.filter((message) => "isTemp" in message)
        let newMessageCount = 0
        if (!temporaryMessages.length) {
            this.messages.push(...messages.filter((m) => !isMessageDeleted(m)))
            return
        }

        messages.forEach((message) => {
            const tempMessage = temporaryMessages.find(({ body }) => body === message.body)
            if (!tempMessage) {
                newMessages.push(message)
                newMessageCount += 1
            }

            const tempMessageIndex = this.messages.indexOf(tempMessage)
            newMessages[tempMessageIndex] = message
        })

        this.messages = newMessages.filter((m) => !isMessageDeleted(m))
        if (!newMessageCount)
            // To make sure the unread count doesn't become skewed, we need to update the message count even when
            // all received messages are replacing temporary ones
            return this.updateMessageCount(this.isConversationOpen)
    }

    public async addMessages(messages: (Message | TemporaryMessage)[]) {
        if (messages.some(({ conversation }) => conversation.sid !== this.conversation.sid))
            throw new Error(`addMessages expects only messages for the conversation with sid ${this.conversation.sid}`)

        if (!this.messagePaginator && !messages.every((message) => "isTemp" in message)) {
            // A new conversation was created. We need to add a paginator and get all messages from it
            await this.getPaginator(false)
            messages = this.messagePaginator.items.filter((m) => !isMessageDeleted(m))
            this.messages.push(...messages)
        } else {
            this.reconcileTemporaryMessages(messages)
        }

        await this.constructMessagesGroupedList()
    }

    private async getPaginator(addMessages: boolean) {
        if (this.messagePaginator) return
        try {
            this.messagePaginator = await this.conversation.getMessages()
            if (addMessages) this.addMessages(this.messagePaginator.items)
        } catch {
            // getMessages fails when there are no messages to get.
        }
    }

    private async constructMessagesGroupedList() {
        const messagesByDate = groupBy(
            this.messages
                .filter((m) => !isMessageDeleted(m))
                .sort(({ dateCreated: dateA }, { dateCreated: dateB }) => (isBefore(dateA, dateB) ? -1 : 1)),
            (message) => DateString.from(message.dateCreated)
        )
        this.messageDisplayList = Object.entries(messagesByDate).flatMap(([date, messages]) => [
            { header: true, date },
            ...messages
        ])

        this.informSubscribers("messages")

        await this.updateMessageCount(this.isConversationOpen)
    }

    public async toggleMute(shouldBeMuted: boolean) {
        const config = await updateChatConfiguration(this.conversation.sid, shouldBeMuted)
        this.configuration = config
    }
}

export default SequelConversation
