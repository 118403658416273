import { TimeUnit } from "@prisma/client"
import { FormControlProps } from "forms"
import SuperSelect from "forms/SuperSelect"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"

const conditions = ["before", "after"] as const

export const ConditionType = (props: FormControlProps<string>) => {
    const { t } = useTranslation("patient")
    const items = useMemo(
        () => conditions.map((condition) => ({ id: condition, label: t(`eventManager.conditions.${condition}`) })),
        [t]
    )

    return <SuperSelect withSearch={false} {...props} items={items} />
}

const units = ["week", "day", "hour"] as const

export const ConditionTimeUnit = (props: FormControlProps<TimeUnit>) => {
    const { t } = useTranslation("common")
    const items = useMemo(() => units.map((unit) => ({ id: unit, label: t(`timeUnits.${unit}`) })), [t])

    return <SuperSelect withSearch={false} {...props} items={items} />
}
