import { classNames } from "utils"
import { MouseEventHandler, ReactNode } from "react"

interface ITypographyProps {
    children: ReactNode
    type: ETypographyTypes
    className?: string
    onClick?: MouseEventHandler<HTMLElement>
}

export enum ETypographyTypes {
    SUBTITLE = "subtitle",
    TEXT = "text",
    BODY_SMALL = "body_small",
    BODY_LARGE = "body_large",
    MED_BLUE = "med_blue",
    BODY_BOLD = "body_bold",
    DARK_BLUE = "dark_blue",
    LABEL_LEGEND = "legends_labels"
}

type THtmlTag = "h1" | "h2" | "h3" | "h4" | "h5" | "p" | "label" | "span"

const typographyMapper: {
    [key: string]: { tag: THtmlTag; className: string }
} = {
    subtitle: {
        tag: "h1",
        className: "text-xl text-dark-blue font-semibold"
    },
    body_small: {
        tag: "h4",
        className: "text-sm  font-medium"
    },
    body_large: {
        tag: "h4",
        className: "text-lg font-semibold text-dark-blue"
    },
    med_blue: {
        tag: "h5",
        className: "text-sm text-med-blue font-semibold"
    },
    dark_blue: {
        tag: "h5",
        className: "text-sm text-dark-blue font-medium"
    },
    body_bold: {
        tag: "h3",
        className: "text-dark-blue font-semibold text-md"
    },
    text: {
        tag: "label",
        className: "text-base text-text-blue font-medium"
    },
    legends_labels: {
        tag: "label",
        className: "text-base font-semibold"
    }
}

export const Typography = ({ type, children, className, onClick }: ITypographyProps) => {
    const TypographyTag = typographyMapper[String(type)]?.tag || "h1"
    const classNameProp = typographyMapper[String(type)]?.className || ""

    return (
        <TypographyTag className={classNames(classNameProp, className)} onClick={onClick}>
            {children}
        </TypographyTag>
    )
}
