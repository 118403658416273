const NextRow = ({
    Icon,
    text,
    Button
}: {
    Icon: (props: React.ComponentProps<"svg">) => JSX.Element
    text: string
    Button?: JSX.Element
}) => {
    return (
        <>
            <div className="flex items-center">
                <div className="w-16 h-16 text-med-blue bg-med-blue bg-opacity-20 rounded p-2">
                    <Icon className="w-12 h-12" />
                </div>
                <div className="px-8 w-[520px] text-dark-blue">{text}</div>
                {Button}
            </div>
            <div className="my-5 border-b-2 border-blue" />
        </>
    )
}

export default NextRow
