import { WeekDay, weekdays } from "@sequel-care/types"
import { FormControlProps } from "forms"
import { RenderLabel } from "forms/RenderLabel"
import { defaultInputClassNames, errorClassNames } from "forms/utils"
import React from "react"
import { useTranslation } from "react-i18next"
import { classNames, createSetter } from "utils"
import { ChatActiveHoursState } from "./types"
import { Switch as SwitchWrapper } from "@headlessui/react"
import { TimeField } from "forms/TimeField"

const timeClassList = {
    input: classNames(defaultInputClassNames, "!bg-white")
}

const ActiveHoursField = ({ value, classList, error, setValue }: FormControlProps<ChatActiveHoursState>) => {
    const { t } = useTranslation("user")
    const setDays = createSetter(setValue, "days")

    return (
        <div className="w-full flex flex-col gap-8 text-dark-blue relative font-medium">
            <div className="flex flex-col gap-2">
                {t("userSettings.chatActiveHours")}
                <span className="font-normal text-text-blue">{t("userSettings.chatActiveHoursDescription")}</span>
            </div>

            <div className="flex gap-6">
                <div className={classNames(classList?.wrapper, "w-fit relative")}>
                    <RenderLabel id="days" label={t("userSettings.days")} />
                    <div
                        className={classNames(
                            "flex flex-row items-center w-150 mt-2",
                            classList?.input ?? "border rounded-3xl bg-clip-border border-med-blue"
                        )}
                    >
                        {weekdays.map((day) => (
                            <WeekDayView
                                key={day}
                                day={day}
                                value={value.days[day]}
                                toggle={(v) => setDays({ ...value.days, [day]: v })}
                            />
                        ))}
                    </div>
                    {error && <div className={errorClassNames}>{error}</div>}
                </div>
                <TimeField
                    label={t("userSettings.timeOpen")}
                    setValue={createSetter(setValue, "time_start")}
                    id="time_start"
                    value={value.time_start}
                    classList={timeClassList}
                />
                <TimeField
                    label={t("userSettings.timeClose")}
                    setValue={createSetter(setValue, "time_end")}
                    id="time_end"
                    value={value.time_end}
                    classList={timeClassList}
                />
            </div>
        </div>
    )
}

export default ActiveHoursField

export function WeekDayView({ day, value, toggle }: { day: WeekDay; value: boolean; toggle: (v: boolean) => void }) {
    return (
        <SwitchWrapper
            checked={value}
            onChange={toggle}
            className={classNames(
                "flex-1 h-10 text-center items-center cursor-pointer",
                value ? "bg-med-blue text-white" : "bg-bg text-med-blue",
                weekdays.indexOf(day) == 0 && "ltr:rounded-l-3xl rtl:rounded-r-3xl",
                weekdays.indexOf(day) == 6 && "ltr:rounded-r-3xl rtl:rounded-l-3xl"
            )}
        >
            {day[0].toUpperCase()}
        </SwitchWrapper>
    )
}
