import { ExerciseQuestionType, QuestionType } from "@prisma/client"
import { FlatEvent, PortalNotificationData, QuestionnaireListItem } from "@sequel-care/types"
import { EventHandlePolicy } from "@sequel-care/types/Event"
import { Message, Paginator } from "@twilio/conversations"
import { LastArrayElement, PartialDeep } from "type-fest"

export type PatientPage =
    | "timeline"
    | "questionnaires"
    | "tracker_manager"
    | "tracker"
    | "goals"
    | "overview"
    | "overviewQuestionnairesScore"
export type eventsTimelineSectionId = "prev" | "curr" | "next" | "skeleton"
export type SettingsLibraryPage = "questionnaires" | "careplan" | "tracker"

export type EventSection = { id: eventsTimelineSectionId; label: string; events: FlatEvent<string>[] }

export type GetNotificationsPayload = {
    notifications: PortalNotificationData[]
    category: NotificationCategories
    notificationCount?: number
}

export type GetInitialNotificationsPayload = {
    notifications: NotificationsByType
    count: { [key in NotificationCategories]: number }
    unread: UnreadNotifications
}

export enum NotificationCategories {
    All = "all",
    Comments = "comments",
    Alerts = "alerts"
}
export enum AdditionalNotifications {
    MessagesNotifications = "conversations"
}

export type CombinedNotificationCategories = NotificationCategories | AdditionalNotifications

export type NotificationsByType = {
    [key in NotificationCategories]: PortalNotificationData[]
}

export type UnreadNotifications = { [key in NotificationCategories]: number[] }

export type EventTemplate = PartialDeep<FlatEvent<string>> & { week_day?: number }

export interface MessageData {
    messagePaginator?: Paginator<Message>
    messageCount?: number
    message?: Message
}

export type EventDeleteDialogData = {
    event: FlatEvent<string>
    policy: EventHandlePolicy
}

export enum PatientDashboardRanges {
    NO_FILTER = "noFilter",
    LAST_7_DAYS = "week",
    LAST_30_DAYS = "month",
    LAST_3_MONTHS = "3months",
    LAST_YEAR = "year"
}

export type RecurrenceUnit = "weeks" | "days"

export type FetchData = { date: string; patientId: number }

export type SkeletonPlaceholder = { skeleton: true }

export type TrackerResult = { value: string[]; question: string }
export type BasicQuestion = LastArrayElement<QuestionnaireListItem["questions"]>
export type CompleteScale = LastArrayElement<QuestionnaireListItem["scales"]>

export type ScaleGroup = { questions: BasicQuestion[] }
export const isScaleGroup = (obj: Object): obj is ScaleGroup => obj && typeof obj === "object" && "questions" in obj
export const isGuideline = (question: { type: QuestionType | ExerciseQuestionType }) =>
    ["guideline", "image", "text"].includes(question.type)
