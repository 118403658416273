import { AppAction } from "../../../types/Redux"
import { UserPreferences } from "@prisma/client"
import * as types from "store/global/types"
import { updateUserPreferences } from "api"

export const updateUserPreferencesAction =
    (preferences: UserPreferences): AppAction =>
    async (dispatch) => {
        const res = await updateUserPreferences(preferences)
        dispatch({
            type: types.UPDATE_USER_PREFERENCES,
            payload: res
        })
    }
