import { QuestionnaireListItem } from "@sequel-care/types"
import { Button, TruncatedString } from "components/common"
import { ETypographyTypes, Typography } from "components/Typography"
import { ReactNode, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { usePatientList } from "store/hooks"
import { getUserName } from "utils"
import ContentLibHeader from "../ContentLibHeader"
import { diagnosesMatchPatient } from "../utils"
import DOMPurify from "dompurify"

type SidebarProps = Pick<
    Partial<QuestionnaireListItem>,
    "duration" | "target_role" | "related_diagnoses" | "min_age" | "max_age"
>
const DetailsScreen = ({
    title,
    description,
    withBack,
    children,
    onAdd,
    ...sidebarProps
}: {
    title: string
    description: string
    withBack: boolean
    onAdd?: () => void
    children: ReactNode
} & SidebarProps) => (
    <div className="w-full h-full flex flex-col">
        <ContentLibHeader view="learn_more" withBack={withBack} />
        <div className="flex h-full gap-12 px-12 py-6 divide-x-2">
            <div className="flex flex-col gap-4 w-3/4">
                <Typography type={ETypographyTypes.BODY_BOLD} className="!text-2xl">
                    {title}
                </Typography>
                <div
                    className="text-base text-text-blue font-normal"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}
                />
                <div className="py-12 px-4">{children}</div>
            </div>
            <DetailsSidebar onAdd={onAdd} {...sidebarProps} />
        </div>
    </div>
)

export default DetailsScreen

export const DetailsSidebar = ({
    related_diagnoses,
    duration,
    target_role,
    min_age,
    max_age,
    onAdd
}: SidebarProps & {
    onAdd?: () => void
}) => {
    const { t } = useTranslation("patient")

    const patients = usePatientList()
    const recommendations = useMemo(() => {
        return related_diagnoses?.length
            ? patients
                  .filter((patient) => diagnosesMatchPatient(related_diagnoses, patient, false))
                  .map((patient) => getUserName(patient.user))
                  .join(", ")
            : []
    }, [patients, related_diagnoses])

    return (
        <div className="flex flex-col gap-8 ltr:pl-6 rtl:pr-6 w-[18rem]">
            {onAdd && (
                <Button theme="primary" className="whitespace-nowrap py-3" onClick={onAdd}>
                    {t("contentLib.add")}
                </Button>
            )}
            {related_diagnoses?.length !== 0 && (
                <div>
                    <Typography type={ETypographyTypes.TEXT}>{t("diagnoses")}</Typography>
                    <div className="flex flex-wrap grow gap-1 pt-1">
                        {related_diagnoses.map((diagnosis, index) => (
                            <TruncatedString
                                key={index}
                                string={diagnosis.name}
                                maxLen={30}
                                className="text-dark-blue"
                            />
                        ))}
                    </div>
                </div>
            )}
            {duration && (
                <div>
                    <Typography type={ETypographyTypes.TEXT}>{t("contentLib.length")}</Typography>
                    <div className="text-dark-blue">{`${duration < 10 ? "0" : ""}${duration}:00`}</div>
                </div>
            )}
            {Boolean(recommendations?.length) && (
                <div>
                    <Typography type={ETypographyTypes.TEXT}>{t("contentLib.recommendedFor")}</Typography>
                    <div className="text-dark-blue grow">{recommendations}</div>
                </div>
            )}
            {Boolean(target_role) && (
                <div>
                    <Typography type={ETypographyTypes.TEXT}>
                        {t("questionnaire:create.definitionFields.targetRole")}
                    </Typography>
                    <div className="text-dark-blue grow">{t(`common:roles.${target_role}`)}</div>
                </div>
            )}
            {Boolean(min_age) && Boolean(max_age) && (
                <div>
                    <Typography type={ETypographyTypes.TEXT}>{t("contentLib.recommendedAge")}</Typography>
                    <div className="text-dark-blue grow">
                        {min_age} - {max_age}
                    </div>
                </div>
            )}
        </div>
    )
}
