import DescriptionField from "components/ContentLibrary/EventConfig/InputComponents/DescriptionField"
import { ListField, defineField, validate } from "forms"
import { FormModal, defineStep } from "forms/FormWithSteps"
import { DiagnosisSelect } from "forms/SuperSelect/wrappers"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { closeModal } from "store/modal/actions"
import { FlatCarePlan } from "@sequel-care/types"
import { ReccurrenceField } from "components/ContentLibrary/EventConfig/types"
import { ListWrapper, ListRow } from "forms/ListField"
import { Dictionary } from "lodash"
import { createCarePlan, updateCarePlan } from "api/Administrator"
import { useCurrentRole } from "store/hooks"
import { RecurrenceUnit } from "types/Misc"
import {
    CarePlanEventConfig,
    CarePlanEventTypes,
    getCarePlanEventFields,
    mapCareplanEventsToUpdate
} from "components/Settings/utils"
import { getCareplans } from "store/global/actions"
import { toast } from "react-toastify"
import DOMPurify from "dompurify"

type CarePlanSettingsModalProps = {
    isOpen: boolean
    params: FlatCarePlan
}

type CarePlanSettingFormState = {
    careplan: {
        title: string
        description: string | null
        related_diagnoses: number[]
        events: CarePlanSettingQuestionnaireBuilderItem[]
    }
}

type CarePlanSettingQuestionnaireBuilderItem = {
    questionnaire_id?: number
    exercise_id?: number
    task_id?: number
    title?: string
    recurrence: ReccurrenceField
    type: string | null
    available_for: number
}

const CarePlanBuilderSteps = () => {
    const { t } = useTranslation()
    return useMemo(
        () => [
            defineStep({
                id: "careplan",
                name: "careplan",
                fieldGridColumns: 12,
                fields: [
                    defineField({
                        id: "title",
                        placeholder: t("common:careplanBuilder.form.newCarePlanName"),
                        classList: { wrapper: "col-span-12" },
                        validations: {
                            required: {
                                value: true,
                                message: t("patient:eventManager.validationMessages.available_for")
                            }
                        }
                    }),
                    defineField({
                        id: "description",
                        label: "description",
                        initialValue: "",
                        placeholder: `${t("add")} ${t("description")}...`,
                        Component: DescriptionField,
                        classList: {
                            wrapper:
                                "rounded-lg border py-2 px-4 border-border-blue text-dark-blue placeholder-text-blue focus:border-text-blue col-span-6"
                        }
                    }),
                    defineField({
                        id: "related_diagnoses",
                        label: t("questionnaire:create.definitionFields.diagnoses") as string,
                        info: t("questionnaire:create.info.diagnoses") as string,
                        classList: { wrapper: "col-span-6" },
                        initialValue: [],
                        Component: DiagnosisSelect<number[]>,
                        withDefault: true
                    }),
                    defineField({
                        id: "events",
                        label: t("patient:eventManager.target") as string,
                        initialValue: [],
                        classList: { button: "flex-col" },
                        Component: ListField<CarePlanEventConfig, CarePlanSettingQuestionnaireBuilderItem>,
                        ListWrapper: ListWrapper,
                        useFields: () => getCarePlanEventFields(t),
                        getInitialItemValue: () => ({
                            type: null as null,
                            available_for: 1,
                            recurrence: {
                                weekDay: 1,
                                enabled: true,
                                unit: "days" as RecurrenceUnit,
                                interval: 1,
                                end: new Date()
                            }
                        }),
                        ItemWrapper: ListRow,
                        gridTemplateColumns: "0.5fr 1fr 0.5fr",
                        validations: {
                            custom: {
                                isValid: (value) => {
                                    const fields = getCarePlanEventFields(t)

                                    const errors = value.map(
                                        (condition) => validate(condition, fields) as Dictionary<string>
                                    )

                                    return errors.some((error) => Boolean(error)) ? errors : true
                                },
                                message: t("patient:eventManager.validationMessages.event")
                            }
                        }
                    })
                ]
            })
        ],
        []
    )
}

export const CarePlanSettingsModal = ({ isOpen, params }: CarePlanSettingsModalProps) => {
    const { t } = useTranslation("common")
    const dispatch = useDispatch()
    const currentRole = useCurrentRole()
    const isEdit = !!params

    const onSubmit = async (state: CarePlanSettingFormState, setErrors: (errors: Dictionary<any>) => void) => {
        if (state.careplan.events.length < 1) {
            setErrors({
                questionnaires: t("common:careplanBuilder.form.eventMinValidationMessage")
            })
            return false
        } else {
            const careplan = {
                title: state.careplan.title,
                description: DOMPurify.sanitize(state.careplan.description),
                is_deleted: false,
                diagnoses: state.careplan.related_diagnoses,
                organization_id: currentRole.organization_id === 3 ? null : currentRole.organization_id,
                ...state.careplan.events.reduce(
                    (acc, eventItem) => {
                        if (eventItem.type) {
                            const type = eventItem.type as CarePlanEventTypes
                            acc[`${type}s`] ||= []
                            acc[`${type}s`].push({
                                id: eventItem[`${type}_id`],
                                title: eventItem.title,
                                recommended_recurrence:
                                    eventItem.recurrence.unit === "weeks"
                                        ? eventItem.recurrence.interval * 7
                                        : eventItem.recurrence.interval,
                                week_day: eventItem.recurrence.unit === "weeks" ? eventItem.recurrence.weekDay : null,
                                available_for: eventItem.available_for
                            })
                        }
                        return acc
                    },
                    { questionnaires: [], exercises: [], tasks: [] }
                )
            }

            try {
                if (isEdit) {
                    await updateCarePlan({ ...careplan, id: params.id })
                    toast(t("common:careplanBuilder.form.updatedSuccessfully"), { type: "success" })
                } else {
                    await createCarePlan(careplan)
                    toast(t("common:careplanBuilder.form.createdSuccessfully"), { type: "success" })
                }
            } catch (error) {
                console.error(error)
            }

            dispatch(getCareplans(currentRole.organization_id))

            return true
        }
    }

    const initialState = useMemo(() => {
        if (params) {
            return {
                careplan: {
                    ...params,
                    related_diagnoses: params.diagnoses,
                    events: mapCareplanEventsToUpdate(params)
                }
            }
        }

        return {
            careplan: {
                title: null,
                description: undefined,
                related_diagnoses: [],
                events: []
            }
        }
    }, [params])

    return (
        <FormModal
            show={isOpen}
            setShow={() => dispatch(closeModal())}
            promptOnExit
            title={t(`common:careplanBuilder.form.${isEdit ? "update" : "create"}`)}
            useStepList={CarePlanBuilderSteps}
            initialState={() => initialState}
            onSubmit={onSubmit}
            isEdit={false}
        />
    )
}
