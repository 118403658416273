import { CalendarDate, toCalendarDate } from "@internationalized/date"
import { ActiveHoursData, OrganizationCreateData, UserCreateInput } from "@sequel-care/types"
import { createOrganization } from "api"
import { getCodes, getName } from "country-list"
import { defineField, initialValuesFromFields } from "forms"
import { FormModal, defineStep } from "forms/FormWithSteps"
import SuperSelect from "forms/SuperSelect"
import TextArea from "forms/TextArea"
import { getUserFields } from "modals/UserModal"
import CompletedState from "modals/UserModal/CompletedState"
import { isFile } from "modals/UserModal/utils"
import { Dispatch, SetStateAction, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { noopTFunction, uploadImageToCloudflare } from "utils"

const defaultActiveHours: ActiveHoursData = {
    sun: {
        enable: false,
        start: 0,
        end: 0
    },
    mon: {
        enable: true,
        start: 32400,
        end: 64800
    },
    tue: {
        enable: true,
        start: 32400,
        end: 64800
    },
    wed: {
        enable: true,
        start: 32400,
        end: 64800
    },
    thu: {
        enable: true,
        start: 32400,
        end: 64800
    },
    fri: {
        enable: true,
        start: 32400,
        end: 64800
    },
    sat: {
        enable: false,
        start: 0,
        end: 0
    }
}

const defaultPrefs: OrganizationCreateData["defaultPrefs"] = {
    date_format: "m/d/y",
    language: "en",
    week_start: "mon",
    timezone: "Asia/Jerusalem",
    chat_automated_reply: "",
    chat_mark_as_urgent_enable: true,
    chat_active_hours: defaultActiveHours
}

export const OrganizationAddModal = ({
    show,
    setShow
}: {
    show: boolean
    setShow: Dispatch<SetStateAction<boolean>>
}) => {
    const { t } = useTranslation("organization")

    const onSubmit = async (state: OrganizationAddState) => {
        const profileImage = state.user.profile_image
        try {
            const response = await createOrganization({
                manager: {
                    ...state.user,
                    date_of_birth: toCalendarDate(state.user.date_of_birth).toString(),
                    profile_image: isFile(profileImage) ? profileImage.name : profileImage === null ? null : undefined
                },
                organization: state.organization,
                defaultPrefs
            })
            if (response?.image_upload_url)
                await uploadImageToCloudflare(state.user.profile_image as File, response.image_upload_url)

            return true
        } catch (error) {
            console.error(error)
            throw error
        }
    }

    return (
        <FormModal
            promptOnExit
            title={t("organizationForm.addOrganization")}
            CompletedState={RenderCompletedState}
            {...{ show, setShow, initialState, onSubmit, useStepList }}
        />
    )
}

type CompletedStateProps = OrganizationAddState & { onClose: () => void; completed: boolean }
const RenderCompletedState = ({ user, organization, onClose, completed }: CompletedStateProps) => {
    const { t } = useTranslation("organization")
    return (
        <CompletedState
            title={t("organizationForm.createdSuccessfully", { name: organization.name })}
            {...{ user, onClose, completed }}
        />
    )
}

export const getOrganizationFields = (t = noopTFunction) =>
    [
        defineField({
            id: "name",
            placeholder: t("organization:organizationForm.organizationName"),
            classList: { wrapper: "col-span-4" }
        }),
        defineField({
            id: "description",
            Component: TextArea,
            placeholder: t("common:description"),
            classList: { wrapper: "col-span-6" }
        }),
        defineField({
            id: "website",
            placeholder: t("organization:organizationForm.website"),
            classList: { wrapper: "col-span-3" }
        }),
        defineField({
            id: "logo_url",
            placeholder: t("organization:organizationForm.logo_url"),
            classList: { wrapper: "col-span-3" }
        }),
        defineField({
            id: "country",
            placeholder: t("common:country"),
            Component: SuperSelect<string>,
            style: "classic",
            initialValue: "US",
            items: getCodes().map((code) => ({ id: code, label: getName(code) })),
            classList: { wrapper: "col-span-2" }
        }),
        defineField({
            id: "address",
            placeholder: t("common:address"),
            classList: { wrapper: "col-span-4" }
        })
    ] as const

export const initialState = () => ({
    organization: initialValuesFromFields(getOrganizationFields()),
    user: initialValuesFromFields(getUserFields()) as UserCreateInput<string | File, CalendarDate>
})

export type OrganizationAddState = ReturnType<typeof initialState>

export const useStepList = () => {
    const { t } = useTranslation()
    return useMemo(
        () =>
            [
                defineStep({
                    id: "organization",
                    name: t("common:organization"),
                    description: t("organization:organizationForm.description"),
                    fields: getOrganizationFields(t),
                    fieldGridColumns: 6
                }),
                defineStep({
                    id: "user",
                    name: t("organization:organizationForm.managerDetails"),
                    description: t("organization:organizationForm.organizationAdmin"),
                    fields: getUserFields(t, { isManager: true }),
                    fieldGridColumns: 9
                })
            ] as const,
        []
    )
}
