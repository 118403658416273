import { ReplacePropType } from "@sequel-care/types"
import { defaultInputClassNames, defineField, ListField, mergeClasses, validate } from "forms"
import { ListRow, ListWrapper } from "forms/ListField"
import NumberField from "forms/NumberField"
import { inlineLabelClasses } from "forms/utils"
import { Dictionary } from "lodash"
import { Trans } from "react-i18next"
import { classNames, noopTFunction } from "utils"
import { FeatureFlags } from "utils/FeatureFlags"
import { AssigneeField } from "./InputComponents/AssigneeField"
import { CollaboratorSelector } from "./InputComponents/CollaboratorSelector"
import TimeFields from "./InputComponents/DateOnTimelineField"
import DescriptionField from "./InputComponents/DescriptionField"
import { GoalField } from "./InputComponents/GoalField"
import { InviteeFeild } from "./InputComponents/InviteeField"
import RepeatInput from "./InputComponents/RepeatInput"
import { EventConditionState, EventFieldProps, MutualEventFieldProps } from "./types"
import UploadFile from "forms/UploadFile"
import { ConditionFields, getEventConditionFields, getGoalId, getRecurrenceInitialValue } from "./utils"
import { EventSelector } from "./InputComponents/EventSelector"
import { getDateAtHour0 } from "utils/dates"
import { EventType } from "@prisma/client"

const getAssigneeField = ({ t, initialValue, classList }: MutualEventFieldProps) =>
    defineField({
        id: "assignee",
        initialValue,
        label: t("patient:eventManager.assignee") as string,
        classList,
        Component: AssigneeField,
        validations: {
            required: { value: true, message: t("patient:eventManager.validationMessages.assignee") }
        }
    })

const getCollaboratorsField = ({ i18n }: MutualEventFieldProps) =>
    defineField({
        id: "collaborators",
        Component: CollaboratorSelector,
        label: "Collaborators",
        classList: {
            wrapper: "col-span-6 row-span-2 border border-border-blue rounded-lg text-text-blue py-2 px-4",
            input: defaultInputClassNames + " !w-24 justify-center"
        },
        info: <Trans i18n={i18n} i18nKey={"patient:eventManager.collaborators_info"} />,
        initialValue: []
    })

export const getDateOnTimelineField = ({
    t,
    initialValue,
    classList,
    withIcons = true,
    validationEnabled
}: MutualEventFieldProps & { withIcons?: boolean }) =>
    defineField({
        id: "time",
        label: t("common:date") as string,
        Component: TimeFields,
        withIcons,
        initialValue,
        classList: mergeClasses(
            {
                wrapper: "flex gap-4 items-center"
            },
            classList
        ),
        validations: validationEnabled
            ? {
                  required: { value: true, message: t("patient:eventManager.validationMessages.date") },
                  custom: {
                      isValid: (time) =>
                          time.isTimeless
                              ? Boolean(time.date_on_timeline)
                              : Object.entries(time).every(([key, value]) => key === "isTimeless" || Boolean(value))
                  }
              }
            : undefined
    })

export const getAvailableForField = ({ t, classList }: MutualEventFieldProps) =>
    defineField({
        id: "available_for",
        label: t("patient:eventManager.availableFor") as string,
        initialValue: 1,
        min: 1,
        Component: NumberField,
        classList: mergeClasses(
            {
                wrapper: "gap-2",
                label: inlineLabelClasses
            },
            classList
        ),
        validations: {
            required: {
                value: true,
                message: t("patient:eventManager.validationMessages.available_for")
            },
            custom: {
                isValid(value, data) {
                    if (!data.recurrence.enabled) return true
                    const interval = data.recurrence.interval * (data.recurrence.unit === "weeks" ? 7 : 1)
                    return value <= interval
                },
                message: t("patient:eventManager.validationMessages.availableForValidationLabel")
            }
        }
    })

const getGoalField = ({ t, initialValue }: MutualEventFieldProps) =>
    defineField({
        id: "goal",
        label: t("patient:goal") as string,
        Component: GoalField,
        initialValue,
        classList: {
            input: defaultInputClassNames,
            wrapper: "col-span-6"
        }
    })

export const getRepeatField = ({
    t,
    initialValue,
    classList,
    customWeekDay = false
}: MutualEventFieldProps & { customWeekDay?: boolean }) =>
    defineField({
        id: "recurrence",
        label: t("patient:eventManager.repeat") as string,
        Component: RepeatInput,
        customWeekDay,
        classList: mergeClasses(
            {
                wrapper: "text-dark-blue"
            },
            classList
        ),
        initialValue
    })

const getLinkedEventsField = ({ t }: MutualEventFieldProps) =>
    defineField({
        id: "linked_events",
        label: t("patient:eventManager.target") as string,
        initialValue: [],
        classList: { button: "flex-col" },
        Component: ListField<ConditionFields, ReplacePropType<EventConditionState, "type", number | string>>,
        ListWrapper: ListWrapper,
        useFields: () => getEventConditionFields(t),
        getInitialItemValue: () => ({
            type: undefined as number,
            title: "",
            condition_type: "after" as const,
            time_offset: 0,
            time_unit: "day" as const
        }),
        ItemWrapper: ListRow,
        gridTemplateColumns: "2fr 1fr 0.3fr 1fr",
        validations: {
            custom: {
                isValid: (value) => {
                    const fields = getEventConditionFields(t)

                    const errors = value.map((condition) => validate(condition, fields) as Dictionary<string>)

                    return errors.some((error) => Boolean(error)) ? errors : true
                },
                message: t("patient:eventManager.validationMessages.event")
            }
        }
    })

const getFileField = ({ t, setLoading }: MutualEventFieldProps) =>
    defineField({
        id: "attachment",
        label: t("common:uploadFiles") as string,
        initialValue: null,
        Component: (props) => <UploadFile {...props} setFormLoading={setLoading} />,
        classList: {
            wrapper: "col-span-6"
        }
    })

const getTitleField = ({ t, readOnly, initialValue, classList, type }: MutualEventFieldProps & { type: EventType }) =>
    defineField({
        id: "title",
        initialValue,
        placeholder: t("common:untitled", { type: t(`patient:eventTypes.${type}`) }),
        readOnly,
        maxLength: 35,
        validations: {
            required: { value: true, message: t("patient:eventManager.validationMessages.title") }
        },
        classList: mergeClasses(
            {
                wrapper: "text-dark-blue text-2xl font-semibold col-span-12",
                input: "w-1/2 border-b border-border-blue placeholder-text-blue focus:border-text-blue focus:placeholder-dark-blue focus:outline-none p-2"
            },
            classList
        )
    })

export const getTaskFields = ({
    t = noopTFunction,
    i18n,
    eventTemplate,
    setLoading,
    editPolicy,
    patient,
    allGoals
}: EventFieldProps) => {
    const { type, title, description, recurrence, generated_from } = eventTemplate
    const goalId = getGoalId(type, allGoals)

    const eventLinksEnabled = FeatureFlags.isEnabled("event-condition")
    return [
        getTitleField({ t, initialValue: title, readOnly: false, type: "task" }),
        defineField({
            id: "description",
            initialValue: description ?? "",
            placeholder: `${t("add")} ${t("description")}...`,
            Component: DescriptionField,
            classList: {
                wrapper:
                    "mb-8 col-span-12 w-4/6 rounded-lg border py-2 px-4 border-border-blue text-dark-blue placeholder-text-blue focus:border-text-blue"
            }
        }),
        getDateOnTimelineField({
            t,
            classList: {
                wrapper: "col-span-3",
                label: inlineLabelClasses,
                input: classNames(defaultInputClassNames, "w-48 flex")
            },
            initialValue: {
                date_on_timeline: new Date(),
                isTimeless: true
            }
        }),
        getAvailableForField({
            t,
            classList: { wrapper: "col-span-3", inputWrapper: "border border-border-blue rounded-lg p-1 !w-16" }
        }),
        getCollaboratorsField({ i18n, t }),
        generated_from || editPolicy
            ? null
            : getRepeatField({
                  t,
                  initialValue: getRecurrenceInitialValue(recurrence),
                  classList: {
                      wrapper: "col-span-3",
                      label: inlineLabelClasses
                  }
              }),
        getGoalField({ t, initialValue: goalId }),

        getAssigneeField({ t, initialValue: patient.user_id, classList: { wrapper: "col-span-6" } }),

        getFileField({ t, setLoading }),

        eventLinksEnabled && getLinkedEventsField({ t })
    ].filter(Boolean)
}

export const getAppointmentFields = ({
    t = noopTFunction,
    eventTemplate,
    editPolicy,
    user,
    patient
}: EventFieldProps) => {
    const { recurrence, generated_from } = eventTemplate

    const eventLinksEnabled = FeatureFlags.isEnabled("event-condition")

    return [
        getTitleField({
            t,
            type: "appointment",
            initialValue: t("patient:eventManager.appointmentTitle", {
                patientName: patient.user.first_name,
                userName: user.first_name
            }),
            readOnly: false,
            classList: {
                wrapper: "mb-8"
            }
        }),
        getDateOnTimelineField({
            t,
            classList: {
                wrapper: "col-span-6",
                label: inlineLabelClasses,
                input: classNames(defaultInputClassNames, "w-48 flex")
            },
            initialValue: {
                date_on_timeline: getDateAtHour0(),
                end: "13:00",
                start: "12:00",
                isTimeless: false
            }
        }),
        defineField({
            id: "invitees",
            initialValue: [patient.user_id, user.id],
            Component: InviteeFeild,
            classList: { wrapper: "col-span-6" },
            label: t("patient:eventManager.invitees") as string,
            validations: {
                custom: {
                    isValid: (value) => value.length > 1,
                    message: t("patient:eventManager.validationMessages.invitee")
                }
            }
        }),
        generated_from || editPolicy
            ? null
            : getRepeatField({
                  t,
                  initialValue: getRecurrenceInitialValue(recurrence),
                  classList: {
                      wrapper: "col-span-12",
                      label: inlineLabelClasses
                  }
              }),
        defineField({
            id: "link",
            initialValue: "",
            label: t("patient:eventManager.link") as string,
            placeholder: t("patient:eventManager.linkPlaceholder"),
            classList: { wrapper: "col-span-6" }
        }),
        defineField({
            id: "location",
            label: t("patient:eventManager.location") as string,
            initialValue: "",
            placeholder: t("patient:eventManager.locationPlaceholder"),
            classList: { wrapper: "col-span-6" }
        }),
        eventLinksEnabled && getLinkedEventsField({ t })
    ].filter(Boolean)
}

export const getQuestionnaireFields = ({
    t = noopTFunction,
    i18n,
    eventTemplate,
    editPolicy,
    patient,
    allGoals
}: EventFieldProps) => {
    const { type, title, recurrence, generated_from } = eventTemplate
    const goalId = getGoalId(type, allGoals)

    const eventLinksEnabled = FeatureFlags.isEnabled("event-condition")

    return [
        getTitleField({
            t,
            type: "questionnaire",
            initialValue: title,
            readOnly: true,
            classList: {
                wrapper: "mb-8"
            }
        }),
        getDateOnTimelineField({
            t,
            classList: {
                wrapper: "col-span-3",
                label: inlineLabelClasses,
                input: classNames(defaultInputClassNames, "w-48 flex")
            },
            initialValue: {
                date_on_timeline: new Date(),
                isTimeless: true
            }
        }),
        getAvailableForField({
            t,
            classList: { wrapper: "col-span-3", inputWrapper: "border border-border-blue rounded-lg p-1 !w-16" }
        }),
        getCollaboratorsField({ i18n, t }),
        generated_from || editPolicy
            ? null
            : getRepeatField({
                  t,
                  initialValue: getRecurrenceInitialValue(recurrence),
                  classList: {
                      wrapper: "col-span-6",
                      label: inlineLabelClasses
                  }
              }),
        getGoalField({ t, initialValue: goalId }),
        getAssigneeField({ t, initialValue: patient.user_id, classList: { wrapper: "col-span-6" } }),
        eventLinksEnabled && getLinkedEventsField({ t })
    ].filter(Boolean)
}

export const getInlineEventFields = (t = noopTFunction) => [
    defineField({
        id: "type",
        initialValue: undefined,
        placeholder: t("patient:eventManager.eventPlaceholder"),
        types: ["questionnaire", "exercise", "task"],
        Component: EventSelector,
        settingState: true,
        maxLength: 35,
        classList: {
            wrapper: "row-span-4 self-start col-span-3 text-dark-blue",
            input: "!border-none !text-base w-full !py-0 placeholder:text-secondary-2 bg-transparent"
        },
        validations: {
            required: {
                value: true,
                message: t("patient:eventManager.validationMessages.event")
            },
            custom: {
                isValid: (_, state) => Boolean(state.title),
                message: t("patient:eventManager.validationMessages.title")
            }
        }
    }),
    getDateOnTimelineField({
        t,
        withIcons: false,
        classList: {
            wrapper: "!gap-2 col-span-3",
            input: "text-dark-blue",
            label: "text-text-blue"
        }
    }),
    getRepeatField({
        t,
        classList: {
            wrapper: "col-span-6",
            input: "w-28 text-dark-blue !text-base !placeholder:text-dark-blue",
            label: "text-text-blue"
        }
    }),
    getAvailableForField({
        t,
        classList: {
            label: "text-text-blue",
            wrapper: "col-span-3 mt-3 w-20"
        }
    }),
    getAssigneeField({
        t,
        classList: {
            wrapper: "!col-span-4 flex gap-4 mt-3",
            label: classNames(inlineLabelClasses, "text-text-blue")
        }
    }),
    defineField({
        id: "description",
        initialValue: "",
        label: t("common:description") as string,
        type: "string",
        classList: {
            wrapper: "col-span-6 flex mt-3 gap-4",
            label: classNames(inlineLabelClasses, "text-text-blue")
        }
    })
]
