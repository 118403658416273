import SettingsSection from "../SettingsSection"
import { useTranslation } from "react-i18next"
import FormWithSteps from "../../../forms/FormWithSteps"
import { useState } from "react"
import { UserPreferences } from "@prisma/client"
import { useCurrentUser } from "../../../store/hooks"
import { Dictionary, last, omit } from "lodash"
import { FormError } from "../../../forms/types"
import { useUserSettingsSteps } from "./fields"
import { ChatActiveHoursState, UserPreferencesFormState } from "./types"
import { updateUserPreferencesAction } from "./actions"
import { ActiveHoursData } from "@sequel-care/types"
import { Time } from "@internationalized/date"
import { useDispatch } from "react-redux"
import { validate } from "forms"

const UserSettings = () => {
    const { t } = useTranslation()
    const steps = useUserSettingsSteps(t)
    const preferences = useCurrentUser()?.preferences
    const initialState = getInitialState(preferences)
    const [state, setState] = useState(initialState)
    const [errors, setErrors] = useState<Dictionary<FormError>>({})
    const dispatch = useDispatch()

    const onSubmit = async () => {
        if (state === initialState) return

        let newErrors = {}
        Object.values(state).forEach((stepState, index) => {
            const errors = validate(stepState, steps[index].fields)
            if (errors) newErrors = { ...newErrors, ...errors }
        })
        if (Object.keys(newErrors).length) {
            setErrors(newErrors)
            return
        }

        const handleUpdate = async () => {
            try {
                const payload = {
                    ...state.user_preferences,
                    chat_automated_reply: state.chat_preferences.chat_automated_reply,
                    chat_active_hours: stateToActiveHours(state.chat_preferences.active_hours),
                    chat_mark_as_urgent_enable: state.chat_preferences.urgent_enable
                }
                await dispatch(updateUserPreferencesAction({ id: preferences?.id, ...payload }))
            } catch (e) {}
        }
        await handleUpdate()
    }

    return (
        <SettingsSection title="user:userSettings.pageTitle" wrapperClassName="!p-0">
            <FormWithSteps
                wrapperClassName="w-full"
                onSubmit={onSubmit}
                tabView
                unlockAllSteps
                getSubmitProps={(currentStep) => {
                    const key = currentStep !== last(steps) ? "common:continue" : "common:update"
                    return { children: t(key) as string, className: "w-48" }
                }}
                {...{ steps, setState, state, setErrors, errors }}
            />
        </SettingsSection>
    )
}

export default UserSettings

const getInitialState = (preferences: UserPreferences): UserPreferencesFormState => ({
    user_preferences: omit(preferences, "id", "chat_active_hours", "chat_automated_reply"),
    chat_preferences: getInitialChatPreferencesState(preferences)
})

export const getInitialChatPreferencesState = (preferences: UserPreferences) => {
    const activeHoursData = preferences.chat_active_hours as ActiveHoursData
    const timeStart = secondsToTime(activeHoursData.mon.start)
    const timeEnd = secondsToTime(activeHoursData.mon.end)
    return {
        chat_automated_reply: preferences.chat_automated_reply,
        urgent_enable: preferences.chat_mark_as_urgent_enable,
        active_hours: {
            time_start: timeStart,
            time_end: timeEnd,
            days: activeHoursDataToDays(activeHoursData)
        }
    }
}

function secondsToTime(totalSeconds: number) {
    const totalMinutes = Math.floor(totalSeconds / 60)
    const seconds = totalSeconds % 60
    const hours = Math.floor(totalMinutes / 60)
    const minutes = totalMinutes % 60
    return new Time(hours, minutes, seconds)
}

function timeToSeconds(time: Time) {
    return time.second + time.minute * 60 + time.hour * 3600
}

function activeHoursDataToDays(data: ActiveHoursData) {
    return {
        sun: data.sun.enable,
        mon: data.mon.enable,
        tue: data.tue.enable,
        wed: data.wed.enable,
        thu: data.thu.enable,
        fri: data.fri.enable,
        sat: data.sat.enable
    }
}

export function stateToActiveHours(data: ChatActiveHoursState) {
    const timing = {
        start: timeToSeconds(data.time_start),
        end: timeToSeconds(data.time_end)
    }
    return {
        sun: { enable: data.days.sun, ...timing },
        mon: { enable: data.days.mon, ...timing },
        tue: { enable: data.days.tue, ...timing },
        wed: { enable: data.days.wed, ...timing },
        thu: { enable: data.days.thu, ...timing },
        fri: { enable: data.days.fri, ...timing },
        sat: { enable: data.days.sat, ...timing }
    }
}
