import { classNames } from "../../../utils"

const QuestionnaireScoresFilterItem = ({
    id,
    color,
    title,
    isSelected,
    onClick,
    wrapClass
}: {
    id: number | string
    color: string
    title: string
    isSelected: boolean
    onClick: (id: number | string, isSelected: boolean) => void
    wrapClass?: string
}) => (
    <div
        className={classNames("flex items-center text-text-blue font-semibold mb-1", wrapClass)}
        onClick={() => {
            onClick(id, isSelected)
        }}
    >
        <input
            type="checkbox"
            checked={isSelected}
            className="rounded border-med-blue w-5 h-5 mr-2 focus:ring-transparent"
            id="questionnaireFilterItem"
            readOnly
            style={{ color, borderColor: color }}
        />
        <div className="truncate text-xs font-semibold">{title}</div>
    </div>
)

export default QuestionnaireScoresFilterItem
