import { BasicUser, UserCreateInput } from "@sequel-care/types"
import { Dialog } from "components/common"
import { noop } from "lodash"
import { MutableRefObject, PropsWithChildren, useCallback, useEffect, useRef, useState } from "react"
import { DateValue } from "react-aria"
import { useTranslation } from "react-i18next"
import { getPromiseWithResolve } from "utils"

type EmailResolution = "keep" | "change" | null
export type HandleUserEmail<S> = (state: S, callback: (email: string | null) => Promise<boolean>) => Promise<boolean>

export function UserEmailHandler<S extends { user: UserCreateInput<File | string, DateValue> }>({
    user,
    children,
    handlerRef
}: PropsWithChildren<{
    user: BasicUser
    handlerRef: MutableRefObject<HandleUserEmail<S>>
}>) {
    const { t } = useTranslation("patient")

    const [showEmailWarning, setShowEmailWarning] = useState(false)
    const resolveEmailWarning = useRef<(value: EmailResolution) => void>(noop)
    const newEmail = useRef("")
    const closeEmailDialog = useCallback(
        (value: EmailResolution) => () => {
            setShowEmailWarning(false)
            resolveEmailWarning.current(value)
            return true
        },
        []
    )

    useEffect(() => {
        handlerRef.current = async (state, callback) => {
            if (!user || state.user.email === user.email) return callback(state.user.email)

            newEmail.current = state.user.email
            setShowEmailWarning(true)
            const { promise, resolve } = getPromiseWithResolve<EmailResolution>()
            resolveEmailWarning.current = resolve
            const result = await promise
            if (result === null) return false
            return callback(result === "keep" ? user.email : state.user.email)
        }
    }, [user])

    return (
        <>
            {user && (
                <Dialog
                    type="warn"
                    open={showEmailWarning}
                    onClose={closeEmailDialog(null)}
                    onConfirm={closeEmailDialog("change")}
                    onDismiss={closeEmailDialog("keep")}
                    buttonText={t("emailChange.saveNew")}
                    cancelText={t("emailChange.keepOld")}
                    title={t("emailChange.title")}
                >
                    {t("emailChange.warning")}
                    <div className="grid grid-cols-3 mt-4 gap-x-4">
                        <div className="text-medium text-text-blue">{t("emailChange.new")}</div>
                        <div className="col-span-2">{newEmail.current}</div>
                        <div className="text-medium text-text-blue">{t("emailChange.previous")}</div>
                        <div className="col-span-2">{user.email}</div>
                    </div>
                </Dialog>
            )}
            {children}
        </>
    )
}
