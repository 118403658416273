import ProfileHeader from "./ProfileHeader"
import ProfileRow from "./ProfileRow"
import { BasicUser } from "@sequel-care/types"
import ProfileActions from "./ProfileActions"
import { OrgRole, Profession } from "@prisma/client"

type ProfileProps = {
    user: BasicUser
    profession?: Profession
    role?: OrgRole | "patient"
    onSendMail: () => void
    onProfileEdit: () => void
    onOpenSettings: () => void
    onOpenChat: () => void
    isPatient: boolean
    isEditable: boolean
    isSendMailEnabled: boolean
    isChatAvailable: boolean
    onSidebarGoBack: () => void
    patientName?: string
    children?: any
}

const Profile = ({
    user,
    profession,
    role,
    onSendMail,
    onProfileEdit,
    onOpenSettings,
    onOpenChat,
    isPatient,
    isEditable,
    isSendMailEnabled,
    isChatAvailable,
    onSidebarGoBack,
    patientName,
    children
}: ProfileProps) => {
    return (
        <div className="p-9 px-6 flex flex-col gap-6 overflow-hidden">
            <ProfileHeader {...{ user, profession, role, onSidebarGoBack }} />
            <ProfileActions
                {...{
                    isPatient,
                    isEditable,
                    isSendMailEnabled,
                    isChatAvailable,
                    onSendMail,
                    onProfileEdit,
                    onOpenSettings,
                    onOpenChat,
                    patientName
                }}
            />
            {children}
        </div>
    )
}
export { Profile, ProfileRow }
