import * as React from "react"
import { SVGProps } from "react"

const QuestionnaireIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
	viewBox="0 0 24 24"
    fill="none"
	strokeWidth={0.5}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.234 17.569a.79.79 0 0 1 .79-.79h5.117a.79.79 0 0 1 0 1.58h-5.117a.79.79 0 0 1-.79-.79ZM10.234 3.24a.79.79 0 0 1 .79-.79h8.187a.79.79 0 1 1 0 1.579h-8.187a.79.79 0 0 1-.79-.79ZM10.234 7.334a.79.79 0 0 1 .79-.79h5.117a.79.79 0 1 1 0 1.58h-5.117a.79.79 0 0 1-.79-.79ZM10.234 13.474a.79.79 0 0 1 .79-.79h8.187a.79.79 0 0 1 0 1.58h-8.187a.79.79 0 0 1-.79-.79ZM1.813 3.007a.234.234 0 0 0-.234.234v4.094c0 .129.105.233.234.233h4.093c.13 0 .234-.104.234-.233V3.24a.234.234 0 0 0-.234-.234H1.813ZM0 3.241c0-1 .812-1.813 1.813-1.813h4.093c1.001 0 1.813.812 1.813 1.813v4.094a1.813 1.813 0 0 1-1.813 1.812H1.813A1.813 1.813 0 0 1 0 7.335V3.24ZM1.813 13.24a.234.234 0 0 0-.234.233v4.094c0 .129.105.233.234.233h4.093c.13 0 .234-.104.234-.233v-4.094a.234.234 0 0 0-.234-.234H1.813ZM0 13.472c0-1.001.812-1.813 1.813-1.813h4.093c1.001 0 1.813.812 1.813 1.813v4.094a1.813 1.813 0 0 1-1.813 1.812H1.813A1.813 1.813 0 0 1 0 17.566v-4.093Z"
      fill="currentColor"
    />
  </svg>
)

export default QuestionnaireIcon