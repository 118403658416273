import { CheckIcon } from "@heroicons/react/24/solid"
import { Dispatch, SetStateAction } from "react"
import { classNames } from "utils"
import { Step } from "./types"

type CommonProps<Steps extends readonly Step[]> = {
    steps: Steps
    currentStep: Step
    setCurrentStep: Dispatch<SetStateAction<Step>>
    completedSteps: string[]
    tabView?: boolean
    prohibitStepChange?: boolean
}
type StepNavItemProps<Steps extends readonly Step[]> = CommonProps<Steps> & { index: number; step: Step }

function StepsNav<Steps extends readonly Step[]>(props: CommonProps<Steps>) {
    if (props.tabView)
        return (
            <nav aria-label="Progress" className="w-full pt-1 flex-shrink-0 sticky top-0 px-12">
                <ol role="list" className="border-b flex gap-10">
                    {props.steps.map((step, index) => (
                        <TabStep key={step.id} {...props} {...{ step, index }} />
                    ))}
                </ol>
            </nav>
        )
    return (
        <nav aria-label="Progress" className="w-80 pt-3 flex-shrink-0 sticky top-0">
            <ol role="list" className="overflow-hidden">
                {props.steps.map((step, index) => (
                    <RenderStep key={step.id} {...props} {...{ step, index }} />
                ))}
            </ol>
        </nav>
    )
}

function TabStep<Steps extends readonly Step[]>({
    steps,
    step,
    index,
    currentStep,
    setCurrentStep,
    completedSteps,
    prohibitStepChange
}: StepNavItemProps<Steps>) {
    const isCurrent = step.id === currentStep.id
    const isCompleted = completedSteps.includes(step.id)
    const canNavigate = !prohibitStepChange && (isCompleted || completedSteps.includes(steps[index - 1]?.id))

    return (
        <li
            className={classNames(
                isCurrent ? "text-dark-blue border-b-2 !border-med-blue" : "text-secondary",
                "inline-block p-1 mt-2 -mb-px cursor-pointer font-medium text-base"
            )}
            onClick={() => canNavigate && setCurrentStep(step)}
        >
            {step.name}
        </li>
    )
}

function RenderStep<Steps extends readonly Step[]>({
    steps,
    step,
    index,
    currentStep,
    setCurrentStep,
    completedSteps,
    prohibitStepChange
}: StepNavItemProps<Steps>) {
    const isLast = index !== steps.length - 1
    const isCompleted = completedSteps.includes(step.id)
    const canNavigate = !prohibitStepChange && (isCompleted || completedSteps.includes(steps[index - 1]?.id))
    const isCurrent = step === currentStep

    return (
        <li className={classNames(isLast && "pb-10", "relative")}>
            {isLast ? (
                <div
                    className={classNames(
                        "-ml-px absolute mt-0.5 top-4 ltr:left-4 rtl:right-4 w-0.5 h-full",
                        isCompleted && steps.indexOf(currentStep) > index ? "bg-med-blue" : "bg-gray-300"
                    )}
                    aria-hidden="true"
                />
            ) : null}
            <div className="relative flex gap-4 items-start group" aria-current={isCurrent ? "step" : undefined}>
                <span
                    className="h-9 flex items-center"
                    aria-hidden={isCompleted ? "true" : undefined}
                    onClick={() => canNavigate && setCurrentStep(step)}
                >
                    <span
                        className={classNames(
                            "relative z-10 w-8 h-8 flex items-center justify-center rounded-full",
                            isCompleted
                                ? "bg-med-blue group-hover:bg-dark-blue"
                                : `bg-white border-2 ${
                                      isCurrent ? "border-med-blue" : "border-gray-300 group-hover:border-gray-400"
                                  }`,
                            !isCurrent && canNavigate && "cursor-pointer"
                        )}
                    >
                        {isCompleted ? (
                            <CheckIcon className="w-5 h-5 text-white" aria-hidden="true" />
                        ) : (
                            <span
                                className={classNames(
                                    "h-2.5 w-2.5 rounded-full",
                                    isCurrent ? "bg-med-blue" : "bg-transparent group-hover:bg-gray-300"
                                )}
                            />
                        )}
                    </span>
                </span>
                <span className="min-w-0 flex flex-col">
                    <span
                        className={classNames(
                            "text-xs font-semibold tracking-wide uppercase",
                            (isCurrent && "text-med-blue") || (!isCurrent && !isCompleted && "text-gray-500")
                        )}
                    >
                        {step.name}
                    </span>
                    <span className="text-sm text-gray-500">{step.description}</span>
                </span>
            </div>
        </li>
    )
}

export default StepsNav
