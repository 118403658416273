import { User, UserPreferences } from "@prisma/client"
import { CreateUserPayload, UpdateTeamMemberParams, UserCreateResult } from "@sequel-care/types"
import { PatientCreateParams, PatientUpdateParams } from "@sequel-care/types/User"
import { ApiGet, ApiPost, ApiPut } from "./ApiRequest"

export const createUser = async (payload: CreateUserPayload) => {
    const instance = new ApiPost<UserCreateResult<"team">>("/organization/team", payload)
    instance.callerName = createUser.name
    return instance.run()
}

export const updateUser = async (userId: number, payload: UpdateTeamMemberParams) => {
    const instance = new ApiPut<UserCreateResult<"team">>(`/user/${userId}`, payload)
    instance.callerName = updateUser.name
    return instance.run()
}

export const updateUserPreferences = async (preferences: Omit<UserPreferences, "id">) => {
    const instance = new ApiPut<UserPreferences>(`/user/preferences`, preferences)
    instance.callerName = updateUserPreferences.name
    return instance.run()
}

export const createPatient = async (payload: PatientCreateParams) => {
    const instance = new ApiPost<UserCreateResult<"patient">>("/patient", payload)
    instance.callerName = createPatient.name
    return instance.run()
}

export const updatePatient = async (patientId: number, payload: PatientUpdateParams) => {
    const instance = new ApiPut<UserCreateResult<"patient">>(`/patient/${patientId}`, payload)
    instance.callerName = updatePatient.name
    return instance.run()
}

export const getUser = async (userId: number) => {
    const instance = new ApiGet<User>(`/user/${userId}`)
    instance.callerName = getUser.name
    return instance.run()
}

export const sendEmailWithDetails = async (id: number) => {
    const instance = new ApiPost(`patient/${id}/send_email_with_details`)
    instance.callerName = sendEmailWithDetails.name
    return instance.run()
}

export const toggleTeamAuth = async (userId: number) => {
    const instance = new ApiPut<{ success: boolean; uid?: string }>(`user/${userId}/auth`)
    instance.callerName = toggleTeamAuth.name
    return instance.run()
}
