import { offset } from "@floating-ui/react-dom"
import { Tooltip, TruncatedString } from "components/common"
import { addDays, getDay, isBefore, isSameDay, previousMonday, previousSunday } from "date-fns"
import { isArray, pick } from "lodash"
import { useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import Skeleton from "react-loading-skeleton"
import { useDispatch } from "react-redux"
import { useAllQuestionnaires, useCurrentUser, useGanttEvents } from "store/hooks"
import { getEventsForGantt } from "store/patient/actions"
import { classNames, eventTypeIconMap, useEffectWithDepHistory } from "utils"
import { weekdays } from "utils/dates"
import { EventConfigState } from "./EventConfig/types"
import { DateData, EventForGantt, GanttItem } from "./types"
import { calculateCurrentEvents, COLORS, generateGanttSkeleton, getTimelineForGantt, populateGantt } from "./utils"

const dateWidthInRem = 10.3
const dateWidth = "w-[10.3rem]"
const marginInRem = 0.4
const getItemStyle = (item: GanttItem, index: number) => ({
    top: `${2.5 * (index + 1) + 0.5}rem`,
    left: `${dateWidthInRem * item.startIndex + marginInRem}rem`,
    width: `${dateWidthInRem * item.length - marginInRem * 2}rem`,
    minHeight: "2rem"
})

const getWeekStartByDay: Record<0 | 1, typeof previousSunday> = {
    0: previousSunday,
    1: previousMonday
}

const QuestionnaireGantt = ({
    eventTemplate,
    patientId
}: {
    eventTemplate: EventConfigState | EventConfigState[]
    patientId: number
}) => {
    const eventsForGantt = useGanttEvents()
    const questionnaires = useAllQuestionnaires()
    const user = useCurrentUser()
    const { t, i18n } = useTranslation("common")
    const dispatch = useDispatch()

    const formOneRow = (eventConfig: EventConfigState | EventConfigState[]) => {
        const currentEvents = calculateCurrentEvents(
            Array.isArray(eventConfig) ? eventConfig : [eventConfig],
            t,
            endDate
        )
        if (!eventsForGantt)
            return populateGantt(dates, currentEvents).concat(
                generateGanttSkeleton([
                    { count: 1, interval: 7, length: 4 },
                    { count: 2, interval: 3, length: 2 }
                ])
            )

        return populateGantt(
            dates,
            currentEvents.concat(
                eventsForGantt
                    .flatMap(({ events }) => events)
                    .map<EventForGantt>((event) => {
                        return {
                            ...pick(event, [
                                "id",
                                "expires_within",
                                "questionnaire_id",
                                "date_on_timeline",
                                "type"
                            ] as const),
                            title: event.title ?? questionnaires.find(({ id }) => id === event.questionnaire_id)?.title
                        }
                    })
            )
        )
    }

    const { startDate, dates, months, endDate } = useMemo(() => {
        const date = isArray(eventTemplate)
            ? eventTemplate.reduce((earliestStart, eventConfig) => {
                  if (!earliestStart || !isBefore(earliestStart, eventConfig.time.date_on_timeline)) {
                      return eventConfig.time.date_on_timeline
                  }
                  return earliestStart
              }, null as Date)
            : eventTemplate.time.date_on_timeline

        const weekDay = getDay(date)
        const weekStartDay = weekdays.findIndex((day) => day === user.preferences.week_start)
        const startDate = weekDay === weekStartDay ? date : getWeekStartByDay[weekStartDay as 0 | 1](date)

        const endDate = addDays(startDate, 6)
        const { dates, months } = getTimelineForGantt(startDate, endDate, i18n)
        return { startDate, endDate, dates, months }
    }, [eventTemplate])

    useEffectWithDepHistory(
        (deps) => {
            if (!deps || !isSameDay(deps[0], startDate)) dispatch(getEventsForGantt(patientId, startDate))
        },
        [startDate]
    )

    const ganttData = useMemo(() => {
        return formOneRow(eventTemplate)
    }, [eventTemplate, eventsForGantt])

    const heightInRem = 8.5 + 2.5 * ganttData.length

    const monthsRef = useRef<HTMLDivElement>()
    const [scrollPos, setScrollPos] = useState(0)
    useEffect(() => {
        monthsRef.current?.scrollTo({ left: scrollPos })
    }, [scrollPos])

    return (
        <div className="mt-8">
            <div className="overflow-x-hidden overflow-y-hidden w-[72rem] mx-12" ref={monthsRef}>
                <div className="relative h-8" style={{ width: dates.length * dateWidthInRem + "rem" }}>
                    {months.map(({ dateIndex, name }) => (
                        <div
                            key={name}
                            className="absolute bottom-2 text-sm text-dark-blue"
                            style={{ left: `calc(${dateWidthInRem * dateIndex}rem + 6px)` }}
                        >
                            {name}
                        </div>
                    ))}
                </div>
            </div>
            <div className="shrink-0 flex w-[78rem] px-12 relative">
                <div
                    className="flex border border-border-blue bg-sidebar overflow-y-auto overflow-x-hidden w-full relative pb-3"
                    style={{ maxHeight: "15rem" }}
                    onScroll={(e) => setScrollPos((e.target as HTMLElement).scrollLeft)}
                >
                    <RenderDates {...{ dates, heightInRem }} />
                    {ganttData.map((items, index) =>
                        items.map((item) => {
                            const { event, skeleton } = item
                            return (
                                <div
                                    key={`${!skeleton ? event.id : "skeleton"}@${item.startIndex}`}
                                    className="h-fit z-10 absolute"
                                    style={getItemStyle(item, index)}
                                >
                                    {skeleton && <Skeleton width="100%" height="1.5rem" />}
                                    {event && <RenderItem {...item} />}
                                </div>
                            )
                        })
                    )}
                </div>
            </div>
        </div>
    )
}

const RenderDates = ({ dates, heightInRem }: { dates: DateData[]; heightInRem: number }) => {
    return (
        <>
            {dates.map(({ date, formattedDate }, index) => (
                <div
                    key={date}
                    className={classNames(
                        "shrink-0 text-xs justify-center text-text-blue font-medium border-border-blue",
                        index && "ltr:border-l rtl:border-r",
                        dateWidth
                    )}
                    style={{ height: heightInRem - 5.5 + "rem" }}
                >
                    <div
                        className="sticky pt-3 pb-5 top-0 text-center z-[40]"
                        style={{
                            backgroundImage: `linear-gradient(to bottom, ${COLORS.sidebar}dd, ${COLORS.sidebar}dd 70%, transparent)`
                        }}
                    >
                        {formattedDate}
                    </div>
                </div>
            ))}
        </>
    )
}

const RenderItem = ({ event, length, hasTooltip = false }: GanttItem & { hasTooltip?: boolean }) => {
    const dateWidthPercentage = Math.round(100 / length)
    const backgroundColor = event && (!event.isHighlighted ? COLORS.borderBlue : COLORS.textBlue)
    const gradientTransparency = !event.isHighlighted ? 45 : 15

    const Icon = eventTypeIconMap[event.type]
    return (
        <div
            className={classNames(
                "rounded p-2 text-xs font-medium relative flex gap-2 items-center",
                !event.isHighlighted ? "text-text-blue" : "text-white"
            )}
            style={{
                backgroundImage:
                    length > 1 &&
                    `linear-gradient(
                            to right,
                            ${backgroundColor},
                            ${backgroundColor} ${dateWidthPercentage - 7}%,
                            ${backgroundColor}${gradientTransparency} ${dateWidthPercentage + Math.round(20 / length)}%
                        )`,
                backgroundColor: length === 1 && backgroundColor
            }}
        >
            <Icon className="w-5 h-5" />
            {length > 1 && (
                <div
                    className="absolute z-100 top-0 h-full border ltr:border-l-0 rtl:border-r-0 ltr:rounded-r rtl:rounded-l border-dashed"
                    style={{
                        width: 100 - dateWidthPercentage + "%",
                        left: dateWidthPercentage + "%",
                        borderColor: COLORS.borderBlueDarker
                    }}
                />
            )}
            <TruncatedString maxLen={13} className="cursor-default" string={event.title}></TruncatedString>
        </div>
    )
}

export default QuestionnaireGantt
