import { flip, useFloating } from "@floating-ui/react-dom"
import { Popover } from "@headlessui/react"
import { ModalCompatiblePortal } from "components/common"
import { formatDate } from "components/ContentLibrary/utils"
import { RenderLabel } from "forms/RenderLabel"
import { FormControlProps } from "forms/types"
import { defaultInputClassNames } from "forms/utils"
import { ComponentProps, PropsWithChildren } from "react"
import { useTranslation } from "react-i18next"
import { TbCalendar, TbChevronDown, TbX } from "react-icons/tb"
import { useCurrentUser } from "store/hooks"
import { classNames } from "utils"
import { Calendar, CalendarProps } from "./Calendar"

const DateSelector = ({ children, readOnly, ...props }: PropsWithChildren<CalendarProps & FormControlProps<Date>>) => {
    const { x, y, reference, floating, strategy } = useFloating({
        placement: "bottom-start",
        middleware: [flip()]
    })

    return (
        <Popover className={props.classList?.wrapper}>
            <RenderLabel {...props} />
            <Popover.Button
                ref={reference}
                className={props.classList?.input ?? defaultInputClassNames}
                disabled={readOnly}
            >
                {children}
            </Popover.Button>
            <ModalCompatiblePortal>
                <Popover.Panel
                    ref={floating}
                    className="z-100 w-80 bg-white shadow-md border border-border my-2 p-4 rounded-md"
                    style={{ position: strategy, top: y ?? "", left: x ?? "" }}
                >
                    {({ close }) => (
                        <Calendar
                            {...props}
                            setValue={(value) => {
                                props.setValue(value)
                                close()
                            }}
                        />
                    )}
                </Popover.Panel>
            </ModalCompatiblePortal>
        </Popover>
    )
}

export const DateSelectorWithIcons = ({
    allowClear = true,
    ...props
}: Omit<ComponentProps<typeof DateSelector>, "children"> & { allowClear?: boolean }) => {
    const { i18n } = useTranslation()
    const currentUser = useCurrentUser()
    return (
        <DateSelector {...props}>
            {({ open }: { open: boolean }) => (
                <>
                    <div className="flex gap-3 items-center">
                        <TbCalendar className="w-6 h-6" />
                        <span>
                            {props.value ? formatDate(props.value, currentUser.preferences, i18n) : props.placeholder}
                        </span>
                    </div>
                    <div className="flex gap-2">
                        {allowClear && props.value && (
                            <TbX
                                className="text-xs hover:scale-125 transition-transform self-center"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    props.setValue(undefined)
                                }}
                            />
                        )}
                        <TbChevronDown className={classNames("text-xl transition self-center", open && "rotate-180")} />
                    </div>
                </>
            )}
        </DateSelector>
    )
}

export default DateSelector
