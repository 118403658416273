import { QuestionScaleWithCutoffs } from "@sequel-care/types"
import { QuestionnaireScaleWithCutoffs } from "@sequel-care/types/Questionnaire"
import { subDays, subMonths, subYears } from "date-fns"
import { PatientDashboardRanges } from "types/Misc"
import { createFetchRange, getDateAtHour0 } from "./dates"

export function getAttrsByCutoff<ItemType = string>(
    score: number,
    scale: QuestionScaleWithCutoffs | QuestionnaireScaleWithCutoffs,
    attrArr?: ItemType[]
): ItemType | string {
    const cutoffIndex = scale.cutoffs.findIndex((cutoff, index) => {
        return score >= cutoff.min && (!scale.cutoffs[index + 1] || scale.cutoffs[index + 1].min > score)
    })
    if (cutoffIndex === -1) return

    return attrArr
        ? attrArr[scale.direction === "positive" ? cutoffIndex : scale.cutoffs.length - 1 - cutoffIndex]
        : scale.cutoffs[cutoffIndex]?.color
}

export const getRangeEdges = (range: PatientDashboardRanges, currentPatient?: { treatment_start: string }) => {
    return createFetchRange(({ dayStart, dayEnd }) => {
        const start = (() => {
            switch (range) {
                case PatientDashboardRanges.LAST_7_DAYS:
                    return subDays(dayStart, 6)
                case PatientDashboardRanges.LAST_30_DAYS:
                    return subDays(dayStart, 29)
                case PatientDashboardRanges.LAST_3_MONTHS:
                    return subMonths(dayStart, 3)
                case PatientDashboardRanges.LAST_YEAR:
                    return subYears(dayStart, 1)
                case PatientDashboardRanges.NO_FILTER:
                    return currentPatient ? getDateAtHour0(currentPatient.treatment_start) : undefined
            }
        })()
        return { end: dayEnd, start }
    })
}

export const GRAPH_STYLES = {
    COLORS: {
        cutoff: "#97B0E3",
        med_blue: "#3C7FF9",
        med_green: "#23DBB5",
        negative: "#E5596A",
        warning: "#F5C33B",
        dark_blue: "#153E7B",
        secondary: "#97B0E3",
        border_blue: "#DEE5F2",
        linear_gardient_1: "rgba(60, 127, 249, 1)",
        linear_gardient_2: "rgba(151, 176, 227, 0)",
        tooltip: "#ffffff",
        tooltipText: "#5B7EB1",
        cutoffs: ["rgba(229, 89, 106, 0.08)", "rgba(248, 177, 39, 0.08)", "rgba(83, 200, 179, 0.08)"],
        light_blue: "#DBEAFE",
        blue: "#93C5FD",
        gray: "#D1D5DB"
    },
    FONTS: {
        axes: "Inter"
    },
    HEIGHT: 100
}

export const questionnaireColors = [
    "#DA69CF",
    "#6243D7",
    "#A9ACFE",
    "#F5AAF7",
    "#3523FF",
    "#5497A6",
    "#AC81D8",
    "#3C7FF9",
    "#BE8FED",
    "#23C9EC",
    "#B6BBBD",
    "#A95487",
    "#46B5F4",
    "#93B3E2"
]
