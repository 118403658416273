import { ButtonHTMLAttributes, DetailedHTMLProps, forwardRef, MutableRefObject, ReactNode } from "react"
import { classNames } from "utils"
import { Spinner } from "components/common"

const themes = {
    primary: "bg-med-blue text-white border border-med-blue rounded-md hover:bg-dark-blue hover:border-dark-blue",
    primaryDisabled: "bg-med-blue text-white border border-med-blue rounded-md opacity-50 cursor-default",
    secondary: "bg-white text-med-blue border border-med-blue rounded-md hover:bg-med-blue hover:text-white",
    secondary_borderless: "text-med-blue hover:text-dark-blue font-medium",
    basic: "text-med-blue border border-transparent rounded-md hover:text-dark-blue font-semibold",
    text: "text-secondary border border-secondary rounded-none hover:bg-dark-blue hover:text-white rounded-sm",
    secondary_light: "text-text-blue border border-text-blue rounded-md px-2 py-1",
    secondary_dark:
        "text-dark-blue border border-dark-blue hover:bg-dark-blue hover:text-white disabled:border-gray-300 disabled:bg-slate-100 disabled:text-dark-blue",
    light: "text-text-blue rounded border border-border-blue text-xs py-2 bg-text-blue/10 hover:bg-text-blue/20 hover:text-dark-blue",
    dark_borderless: "text-dark-blue text-base",
    warn: "text-white bg-negative hover:bg-dark-negative rounded",
    secondary_warn: "text-negative border border-negative bg-white hover:bg-negative hover:text-white rounded",
    dark_primary: "bg-dark-blue text-white border border-dark-blue rounded-md hover:bg-dark-blue hover:border-dark-blue"
}

interface IButtonProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    children?: ReactNode
    theme?: keyof typeof themes
    className?: string
    loading?: boolean
    noPadding?: boolean
    "data-testid"?: string
}

const ButtonComponent = (
    { children, theme = "basic", loading = false, noPadding = false, ...props }: IButtonProps,
    ref: MutableRefObject<HTMLButtonElement>
) => {
    return (
        <button
            {...props}
            disabled={props.disabled ?? loading}
            ref={ref}
            className={classNames(
                themes[theme !== "primary" || !props.disabled ? theme : "primaryDisabled"],
                "flex justify-center items-center text-sm",
                theme !== "basic" && !noPadding && "py-2 px-6",
                props.className
            )}
        >
            {!loading ? children : <Spinner size={5} />}
        </button>
    )
}

export default forwardRef<HTMLButtonElement, IButtonProps>(ButtonComponent)
