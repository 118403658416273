import { InformationCircleIcon } from "@heroicons/react/24/outline"
import { ScoreData } from "@sequel-care/types"
import { ById } from "@sequel-care/types/utils"
import { Spinner, Tooltip } from "components/common"
import MinusIcon from "components/icons/Minus"
import { Chart } from "react-chartjs-2"
import { useTranslation } from "react-i18next"
import { GraphType } from "types/Redux"
import { classNames, registerChart } from "utils"
import { FeatureFlags } from "utils/FeatureFlags"
import DashboardCard from "../DashboardCard"
import GraphLegend from "./GraphLegend"
import { ResolutionSelector } from "./GraphStateSelectors"
import { GraphItem } from "./types"
import { useGraphProps } from "./utils"
import QuestionnairesList from "./QuestionnairesList"

export { RangeSelector } from "./GraphStateSelectors"
export { getGraphItemFromQuestionnaire, useActiveIds } from "./utils"
export type { GraphItem }

registerChart()

type ScoreGraphProps = {
    graphKey: GraphType
    loading: boolean
    items: ById<GraphItem>
    scoresById: ById<ScoreData[]>
    activeIds: string[]
    toggleItem?: (id: string) => void
    inDashboard?: boolean
    title?: string
}

const ScoreGraph = ({ graphKey, loading, items, activeIds, scoresById, toggleItem, title }: ScoreGraphProps) => {
    const { datasets, options, labels, noDataIndication } = useGraphProps({
        items,
        loading,
        graphKey,
        activeIds,
        scoresById
    })
    const { t } = useTranslation("patient")
    const isTrackerEnabled = FeatureFlags.isEnabled("tracker")
    const isFullGraphWidth =
        graphKey !== "questionnaire" || (graphKey === "questionnaire" && Object.keys(items).length < 2)
    return (
        <DashboardCard
            title={<GraphTitle title={title} />}
            className="mt-4"
            headerRight={<ResolutionSelector graphKey={graphKey} />}
        >
            <div className="flex justify-between">
                {Object.values(items).length > 1 && graphKey !== "questionnaire" && (
                    <GraphLegend {...{ items, toggleItem, activeIds }} />
                )}
                {isTrackerEnabled && graphKey === "overview" && (
                    <div className="flex flex-col pt-2">
                        <span className="text-med-green flex">
                            <MinusIcon className="w-5 h-5" /> {t("dashboard.lifeEvents.positive")}
                        </span>
                        <span className="text-negative flex">
                            <MinusIcon className="w-5 h-5" /> {t("dashboard.lifeEvents.negative")}
                        </span>
                    </div>
                )}
            </div>
            {!loading && (
                <div className={classNames(isFullGraphWidth ? "" : "flex")}>
                    <div className={classNames(isFullGraphWidth ? "" : "!w-11/12")}>
                        {noDataIndication ? (
                            <div className="flex justify-center items-center h-[40rem]">
                                {noDataIndication && (
                                    <div className=" left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-text-blue/70 w-52 text-center font-medium">
                                        {noDataIndication}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <Chart
                                height={100}
                                id="taskGraph"
                                data={{ datasets, labels }}
                                options={options}
                                className="-mx-4"
                                type="line"
                                dir="ltr" // This is necessary for the bar chart labels to be positioned correctly
                            />
                        )}
                    </div>
                    {graphKey === "questionnaire" && !isFullGraphWidth ? (
                        <QuestionnairesList items={items} activeIds={activeIds} toggleItem={toggleItem} />
                    ) : null}
                </div>
            )}

            {loading && (
                <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                    <Spinner size={10} />
                </div>
            )}
        </DashboardCard>
    )
}

const GraphTitle = ({ title = "" }: { title: string }) => {
    const { t } = useTranslation("patient")
    return (
        <>
            <span>{t("dashboard.questionnaireBreakdown", { title })}</span>
            <Tooltip
                content={<div className="w-56 text-sm pb-1">{t("dashboard.scoreCuttofs")}</div>}
                placement="bottom"
            >
                <InformationCircleIcon className="w-5" />
            </Tooltip>
        </>
    )
}

export default ScoreGraph
