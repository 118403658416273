import { SVGProps } from "react"

const ExerciseIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
    >
        <path d="M0 0h24v24H0z" stroke="none" />
        <path d="m12 3-4 7h8z" />
        <circle cx={17} cy={17} r={3} />
        <rect x={4} y={14} width={6} height={6} rx={1} />
    </svg>
)

export default ExerciseIcon
