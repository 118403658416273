import { SVGProps } from "react"

const Package = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" {...props}>
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="m10.817 1 9.666 5.438v10.875l-9.666 5.437-9.667-5.438V6.438L10.817 1ZM10.817 11.875l9.666-5.438M10.817 11.875V22.75M10.817 11.875 1.15 6.437M15.65 3.719 5.983 9.156"
        />
    </svg>
)
export default Package
