import { assign } from "lodash"
import { useCallback, useEffect, useState } from "react"
import { ToggleContext } from "./context"
import { ToggleButton, ToggleChevron, ToggleContent } from "./ToggleComponents"
import { ToggleComponent, ToggleProps } from "./types"

export const Toggle: ToggleComponent = assign(
    ({ openByDefault = false, children, handleToggle }: ToggleProps) => {
        const [isOpen, setIsOpen] = useState(openByDefault)

        useEffect(() => {
            if (openByDefault !== isOpen) setIsOpen(openByDefault)
        }, [openByDefault])

        const onToggle = useCallback(() => {
            setIsOpen((open) => !open)
        }, [])

        return (
            <ToggleContext.Provider value={{ isOpen, onToggle: handleToggle || onToggle }}>
                {children}
            </ToggleContext.Provider>
        )
    },
    { Chevron: ToggleChevron, Content: ToggleContent, Button: ToggleButton }
)

export default Toggle
