import { EllipsisVerticalIcon } from "@heroicons/react/24/solid"
import { Menu } from "components/common"
import { DotsHorizontalIcon } from "components/icons"

export interface IOption<P> {
    label: string
    description: string
    callback: (param: P) => void
}

export function OptionsPopover<P>({
    options,
    param,
    iconAlignment = "horizontal",
    buttonWidth = "w-5"
}: {
    options: IOption<P>[]
    param: P
    iconAlignment?: "vertical" | "horizontal"
    buttonWidth?: string
}) {
    // const Menu: MenuComponent = ({ button, children, onClose, placement = 'bottom-start', offsetOptions = { crossAxis: -8 }, panelClassName, buttonClassName }) => {
    const ButtonIcon = iconAlignment === "horizontal" ? DotsHorizontalIcon : EllipsisVerticalIcon
    return (
        <Menu placement="left-start" offsetOptions={{ alignmentAxis: -4 }}>
            <Menu.Button className="cursor-pointer">
                <ButtonIcon
                    data-testid="options-button"
                    className={`${buttonWidth} text-secondary hover:text-med-blue`}
                />
            </Menu.Button>
            <Menu.Panel>
                {({ close }) => (
                    <div className="optionsPopover border border-border-blue  bg-white">
                        {options.map(({ callback, label, description }) => (
                            <button
                                key={label}
                                data-testid="popover-option"
                                className="flex items-start flex-col hover:bg-bg py-3 px-6 w-44 text-left"
                                onClick={() => {
                                    close()
                                    callback(param)
                                }}
                            >
                                <span className="text-sm text-dark-blue">{label}</span>
                                <span className="text-xs text-text-blue">{description}</span>
                            </button>
                        ))}
                    </div>
                )}
            </Menu.Panel>
        </Menu>
    )
}
